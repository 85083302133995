import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { Box, Stack, Tooltip, styled } from "@mui/material";
import { MeasureBreakdownResult } from "api/analytics";
import { AnalyticsContainer, Badge, DirectionGroup, DirectionIcon, IconButton } from "components_new";
import React, { FC, useMemo } from "react";

import { AttributesTable } from "features/zoning-uploader/AttributesTable";

import { Dimension, LEFT_SIDE_DIRECTIONS, SelectedVolume } from "types";

import { getDirectionLabel } from "utils/ui";

import { AnalyticsBreakdowns } from "./AnalyticsBreakdowns";

export interface RoadAttributeValue {
  label: string;
  value: any;
  mainAttribute?: boolean;
}

export interface RoadAttributeProps {
  values?: RoadAttributeValue[];
  openState: boolean;
  setOpenState: (openState: boolean) => void;
}

interface RoadAnalyticsProps {
  isPedestriansMode: boolean;
  selectedRoadVolume: SelectedVolume;
  selectedRoadVolumeId: string | number | null;
  breakdowns: MeasureBreakdownResult[];
  roadAttributeProps?: RoadAttributeProps;
  roadMetadataDimensions: Dimension[];
  changeRoadVolumeId: (id: string) => void;
}

const DirectionLabel = styled("div")`
  font-size: var(--font-size-sm);
  font-weight: bold;
  margin-right: var(--padding-md);
  line-height: 21px;
`;

export const ChartWrapper = styled("div")`
  border-radius: 4px;
  border: 1px solid var(--color-border);
  margin-bottom: var(--padding-lg);
`;

export const ShowMoreAttributeBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RoadAnalytics: FC<RoadAnalyticsProps> = ({
  isPedestriansMode,
  selectedRoadVolume,
  selectedRoadVolumeId,
  roadMetadataDimensions,
  breakdowns = [],
  roadAttributeProps,
  changeRoadVolumeId,
}) => {
  const roadAttributes = useMemo(
    () =>
      roadAttributeProps?.values
        ?.filter((attribute) => roadAttributeProps?.openState === true || attribute.mainAttribute === true)
        .reduce((acc, item) => {
          acc[item.label] = item.value;
          return acc;
        }, {} as { [name: string]: any }),
    [roadAttributeProps],
  );

  const getDirectionBadge = (selectedRoadVolume: SelectedVolume, roadSegmentId: string, isFromToDirection: boolean) => {
    const direction = isFromToDirection ? selectedRoadVolume.ftDirection : selectedRoadVolume.tfDirection;

    return (
      <Badge
        clickable
        icon={<DirectionIcon direction={direction} />}
        onClick={() => {
          changeRoadVolumeId(roadSegmentId);
        }}
        variant={selectedRoadVolumeId === roadSegmentId ? "filled" : "outlined"}
        label={getDirectionLabel(direction)}
      />
    );
  };

  const getDirectionBadges = (selectedRoadVolume: SelectedVolume) => {
    if (selectedRoadVolume.tfDirection && LEFT_SIDE_DIRECTIONS.indexOf(selectedRoadVolume.tfDirection) !== -1) {
      return (
        <>
          {getDirectionBadge(selectedRoadVolume, selectedRoadVolume.tfSegmentId || "", false)}
          {getDirectionBadge(selectedRoadVolume, selectedRoadVolume.ftSegmentId, true)}
        </>
      );
    }

    return (
      <>
        {getDirectionBadge(selectedRoadVolume, selectedRoadVolume.ftSegmentId, true)}
        {selectedRoadVolume.tfDirection &&
          getDirectionBadge(selectedRoadVolume, selectedRoadVolume.tfSegmentId || "", false)}
      </>
    );
  };

  const segmentDetailOpenButton = (
    <Tooltip title={roadAttributeProps?.openState ? "See less attributes" : "See all attributes"}>
      <IconButton
        sx={{ padding: "0" }}
        onClick={() => roadAttributeProps?.setOpenState(!roadAttributeProps?.openState)}
        size="small"
      >
        {roadAttributeProps?.openState ? (
          <KeyboardDoubleArrowUpIcon fontSize="small" />
        ) : (
          <KeyboardDoubleArrowDownIcon fontSize="small" />
        )}
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      {!isPedestriansMode && (
        <DirectionGroup>
          <DirectionLabel>Direction</DirectionLabel>
          <Stack direction={"row"} spacing={1}>
            {getDirectionBadges(selectedRoadVolume)}
          </Stack>
        </DirectionGroup>
      )}
      <AnalyticsContainer heightOperand={102}>
        {roadAttributeProps ? (
          <ChartWrapper>
            <AttributesTable
              columnsRatio={[45, 55]}
              tableHeadLabels={[
                "Attribute",
                <ShowMoreAttributeBox> Value {segmentDetailOpenButton} </ShowMoreAttributeBox>,
              ]}
              attributes={roadAttributes}
              headerClick={() => roadAttributeProps?.setOpenState(!roadAttributeProps?.openState)}
              maxHeight={"400px"}
            />
          </ChartWrapper>
        ) : null}
        <AnalyticsBreakdowns breakdowns={breakdowns} dimensions={roadMetadataDimensions} containersIdPrefix="roads" />
      </AnalyticsContainer>
    </>
  );
};

export { RoadAnalytics };
