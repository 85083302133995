import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { ScreenlineGeometry } from "api/analytics/index.d";
import { isEqual } from "lodash";
import { Popup } from "mapbox-gl";
import { MutableRefObject, useEffect, useMemo, useRef } from "react";

import { ModuleData } from "features/map/ModuleManager";

import { useAppDispatch, useAppSelector, usePrevious, useStateRef } from "hooks";

import { DataState } from "store/interfaces";
import { selectRoadNetworkType } from "store/sections/analytics";
import { screenlinesActions, selectScreenlineCountsObj, selectSelectedScreenline } from "store/sections/screenlines";

import { MeasureType, RoadNetworkType } from "types";

import {
  useChangeScreenlinesOpacity,
  useChangeShowScreenlines,
  useFetchScreenlineCountsWithConfig,
  useFetchScreenlineCountsWithScreenlines,
  useFetchScreenlineDetails,
  useFetchScreenlineValidation,
  useFetchScreenlinesValidationSummary,
  useGetScreenlineIntersectionsSourceData,
  useGetScreenlineSegmentsSourceData,
  useGetScreenlinesSourceData,
  useHandleSetDraftFeature,
  useHandleSetDrawMode,
  useHighlightScreenline,
  useSetSelectedIntersectionId,
  useSetSelectedScreenlineId,
} from "./ControllerCallbacks";
import { getScreenlinesLayers } from "./map-data/layers";
import {
  SCREENLINES_INTERSECTIONS_SOURCE_ID,
  SCREENLINES_SEGMENTS_SOURCE_ID,
  SCREENLINES_SOURCE_ID,
} from "./map-data/sources";
import { getScreenlinesSources } from "./map-data/sources";

interface Props {
  map: MutableRefObject<mapboxgl.Map | null>;
  draw: MutableRefObject<any | null>;
  isModuleLoaded: boolean;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  setScreenlinesModuleData: (moduleData: ModuleData) => void;
}

export const MapController = ({ map, draw, isModuleLoaded, layerManagerRef, setScreenlinesModuleData }: Props) => {
  const dispatch = useAppDispatch();

  const mapboxScreenlineHoverPopupRef = useRef<Popup | null>(null);
  const mapboxIntersectionClickPopupRef = useRef<Popup | null>(null);
  const mapboxGeometryClickPopupRef = useRef<Popup | null>(null);

  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);

  const timePeriod = useAppSelector((state) => state.global.timePeriod);

  const measure = useAppSelector((state) => state.filters.measure);

  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);

  const roadFilters = useAppSelector((state) => state.filters.roadFilters);

  const roadClasses = useAppSelector((state) => state.filters.roadClasses);
  const roadClassesRef = useStateRef(roadClasses);

  const networkType = useAppSelector(selectRoadNetworkType);
  const networkTypeRef = useStateRef(networkType);

  const selectedRoadVolume = useAppSelector((state) => state.analytics.selectedRoadVolume);
  const selectedIntersections = useAppSelector((state) => state.roadIntersections.selectedIntersections);

  const screenlines = useAppSelector((state) => state.screenlines.screenlines);
  const screenlinesRef = useStateRef(screenlines);

  const fetchScreenlineCounts = useAppSelector((state) => state.screenlines.fetchScreenlinesCounts);
  const screenlineCountsObj = useAppSelector(selectScreenlineCountsObj);

  const screenlinesMaxCount = useAppSelector((state) => state.screenlines.maxCount);
  const screenlinesMaxCountRef = useStateRef(screenlinesMaxCount);

  const selectedScreenlineId = useAppSelector((state) => state.screenlines.selectedScreenlineId);
  const selectedScreenlineIdRef = useStateRef(selectedScreenlineId);
  const previousSelectedScreenlineId = usePrevious(selectedScreenlineId);

  const selectedIntersectionId = useAppSelector((state) => state.screenlines.selectedIntersectionId);
  const selectedIntersectionIdRef = useStateRef(selectedIntersectionId);

  const selectedScreenline = useAppSelector(selectSelectedScreenline);

  const isEditScreenlineMode = useAppSelector((state) => state.screenlines.draftScreenline !== null);
  const isEditScreenlineModeRef = useStateRef(isEditScreenlineMode);

  const isScreelineEditorOpen = useAppSelector((state) => state.screenlines.isScreelineEditorOpen);
  const isScreenlineEditorOpenRef = useStateRef(isScreelineEditorOpen);

  const screenlineValidationData = useAppSelector((state) => state.screenlines.screenlineValidation.data);
  const screenlineValidationDataRef = useStateRef(screenlineValidationData);

  const draftFeature = useAppSelector((state) => state.screenlines.draftFeature);
  const previousDraftFeature = usePrevious(draftFeature);

  const draftScreenline = useAppSelector((state) => state.screenlines.draftScreenline);

  const showScreenlines = useAppSelector((state) => state.screenlines.showScreenlines);
  const showScreenlinesRef = useStateRef(showScreenlines);

  const candidateScreenlineIntersections = useAppSelector(
    (state) => state.screenlines.candidateScreenlineIntersections,
  );

  const isDrawMode = useAppSelector((state) => state.screenlines.isDrawMode);
  const isDrawModeRef = useStateRef(isDrawMode);

  const opacityFactor = useAppSelector((state) => state.screenlines.opacityFactor);
  const widthFactor = useAppSelector((state) => state.screenlines.widthFactor);

  const isNonDirectional = useMemo(
    () => selectedScreenline?.network === RoadNetworkType.Pedestrian || measure === MeasureType.PEDESTRIANS,
    [selectedScreenline, measure],
  );

  const getScreenlinesSourceData = useGetScreenlinesSourceData(
    screenlines,
    screenlineCountsObj,
    screenlinesMaxCount,
    roadClasses,
  );
  const getScreenlineSegmentsSourceData = useGetScreenlineSegmentsSourceData(
    screenlineValidationData?.resolvedSegments,
    screenlineValidationData?.candidateSegments,
    candidateScreenlineIntersections.data,
    isEditScreenlineMode || Boolean(draftFeature),
    isDrawMode,
    selectedScreenline?.visible ?? true,
    isNonDirectional,
    Boolean(selectedScreenline && selectedScreenline.network === networkType),
  );
  const getScreenlineIntersectionsSourceData = useGetScreenlineIntersectionsSourceData(
    screenlineValidationData?.validatedSegmentIntersections,
    screenlineValidationData?.candidateSegments,
    candidateScreenlineIntersections.data,
    isEditScreenlineMode || Boolean(draftFeature),
    isDrawMode,
    selectedScreenline?.visible ?? true,
    Boolean(selectedScreenline && selectedScreenline.network === networkType),
  );
  const changeShowScreenlines = useChangeShowScreenlines(layerManagerRef, showScreenlines);
  const setSelectedScreenlineId = useSetSelectedScreenlineId();
  const setSelectedIntersectionId = useSetSelectedIntersectionId();

  const filteredScreenlinesByNetworkType = useMemo(
    () => screenlines.filter((screenline) => screenline.network === networkType),
    [screenlines, networkType],
  );

  const fetchScreenlineCountsWithScreenlines = useFetchScreenlineCountsWithScreenlines(
    filteredScreenlinesByNetworkType,
  );
  const fetchScreenlineCountsWithConfig = useFetchScreenlineCountsWithConfig(
    timePeriod,
    roadClasses,
    measure,
    roadFilters,
  );

  const fetchScreenlineDetails = useFetchScreenlineDetails(
    timePeriod,
    roadClasses,
    selectedScreenline,
    measure,
    roadFilters,
    roadsMetadata.data,
    isScreelineEditorOpen,
  );

  const currentScreenline = useMemo(() => draftScreenline || selectedScreenline, [draftScreenline, selectedScreenline]);

  const fetchScreenlineValidation = useFetchScreenlineValidation(
    timePeriod,
    roadClasses,
    currentScreenline?.geometry,
    currentScreenline?.segmentIntersections,
    currentScreenline?.network === networkType,
  );

  const fetchScreenlinesValidationSummary = useFetchScreenlinesValidationSummary(timePeriod);

  const handleSetDraftFeature = useHandleSetDraftFeature();

  const handleSetDrawMode = useHandleSetDrawMode();

  const changeScreenlinesOpacity = useChangeScreenlinesOpacity(map, layerManagerRef);

  const highlightScreenline = useHighlightScreenline(map);

  useEffect(() => {
    if (!isModuleLoaded) return;

    const source = map.current?.getSource(SCREENLINES_SOURCE_ID) as any;
    source?.setData(getScreenlinesSourceData());
  }, [map, isModuleLoaded, screenlinesMaxCount, getScreenlinesSourceData]);

  useEffect(() => {
    if (!isModuleLoaded) return;

    const segmentsSource = map.current?.getSource(SCREENLINES_SEGMENTS_SOURCE_ID) as any;
    const intersectionsSource = map.current?.getSource(SCREENLINES_INTERSECTIONS_SOURCE_ID) as any;

    segmentsSource?.setData(getScreenlineSegmentsSourceData());
    intersectionsSource?.setData(getScreenlineIntersectionsSourceData());
  }, [map, isModuleLoaded, getScreenlineIntersectionsSourceData, getScreenlineSegmentsSourceData]);

  useEffect(() => {
    if (!isModuleLoaded) return;

    if (previousDraftFeature?.id && previousDraftFeature.id !== draftFeature?.id) {
      map.current?.setFeatureState(
        {
          source: SCREENLINES_SOURCE_ID,
          id: previousDraftFeature.id,
        },
        { edit: false, editGeometry: false },
      );
    }

    if (draftFeature) {
      map.current?.setFeatureState(
        {
          source: SCREENLINES_SOURCE_ID,
          id: draftFeature.id,
        },
        { edit: true, editGeometry: true },
      );
    }
  }, [map, previousDraftFeature?.id, draftFeature, isModuleLoaded]);

  useEffect(() => {
    highlightScreenline(selectedScreenlineId);
  }, [selectedScreenlineId, highlightScreenline]);

  useEffect(() => {
    if (!isModuleLoaded || selectedScreenlineId === null) return;
    const screenlineFeatureState = map.current?.getFeatureState({
      source: SCREENLINES_SOURCE_ID,
      id: selectedScreenlineId,
    });

    if (screenlineFeatureState?.selected) {
      map.current?.setFeatureState(
        {
          source: SCREENLINES_SOURCE_ID,
          id: selectedScreenlineId,
        },
        { click: true },
      );
    }

    if (isEditScreenlineMode) {
      if (previousSelectedScreenlineId && previousSelectedScreenlineId !== selectedScreenlineId) {
        map.current?.setFeatureState(
          {
            source: SCREENLINES_SOURCE_ID,
            id: previousSelectedScreenlineId,
          },
          { edit: false },
        );
      }

      map.current?.setFeatureState(
        {
          source: SCREENLINES_SOURCE_ID,
          id: selectedScreenlineId,
        },
        { edit: true },
      );
    } else if (screenlineFeatureState?.edit) {
      map.current?.setFeatureState(
        {
          source: SCREENLINES_SOURCE_ID,
          id: selectedScreenlineId,
        },
        { edit: false },
      );
    }
  }, [map, previousSelectedScreenlineId, selectedScreenlineId, isEditScreenlineMode, isModuleLoaded]);

  useEffect(() => {
    if (!isModuleLoaded || !screenlines.length) return;

    screenlines.forEach((screenline) => {
      map.current?.setFeatureState(
        {
          source: SCREENLINES_SOURCE_ID,
          id: screenline.id,
        },
        { "network-mismatch": screenline.network !== networkType },
      );
    });
  }, [map, isModuleLoaded, screenlines, networkType]);

  useEffect(() => {
    if (!isScreelineEditorOpen && selectedScreenline && selectedScreenline.network !== networkType) {
      setSelectedScreenlineId(null);
    }
  }, [networkType, isScreelineEditorOpen, selectedScreenline, setSelectedScreenlineId]);

  useEffect(() => {
    if (roadsMetadata.state === DataState.AVAILABLE && fetchScreenlineCounts) {
      fetchScreenlineCountsWithScreenlines();
    }
  }, [roadsMetadata.state, fetchScreenlineCounts, fetchScreenlineCountsWithScreenlines]);

  useEffect(() => {
    if (
      roadsMetadata.state === DataState.AVAILABLE &&
      roadsMetadata.data?.measures.find((m) => m.columnName === measure)
    ) {
      fetchScreenlineCountsWithConfig();
    }
  }, [roadsMetadata, measure, fetchScreenlineCountsWithConfig]);

  useEffect(() => {
    if (roadsMetadata.state === DataState.AVAILABLE) fetchScreenlineDetails();
  }, [roadsMetadata.state, fetchScreenlineDetails]);

  useEffect(() => {
    if (roadsMetadata.state === DataState.AVAILABLE) fetchScreenlineValidation();
  }, [roadsMetadata.state, fetchScreenlineValidation]);

  useEffect(() => {
    if (roadsMetadata.state === DataState.AVAILABLE) fetchScreenlinesValidationSummary();
  }, [roadsMetadata.state, fetchScreenlinesValidationSummary]);

  useEffect(() => {
    if (!isScreelineEditorOpen) {
      draw.current?.deleteAll();
    }
  }, [isScreelineEditorOpen, draw]);

  useEffect(() => {
    if (!draftFeature) {
      draw.current?.deleteAll();
      return;
    }

    if (timePeriod && !isEqual(draftFeature?.geometry, selectedScreenline?.geometry) && networkType) {
      dispatch(
        screenlinesActions.fetchCandidateScreenlineIntersections(
          {
            geometry: draftFeature.geometry as ScreenlineGeometry,
            timePeriod,
            roadClasses: roadClasses || [],
            network: networkType,
          },
          draftFeature.id === selectedScreenline?.id ? selectedScreenline : null,
        ),
      );
    }
  }, [dispatch, selectedScreenline, roadClasses, timePeriod, draftFeature, draw, networkType]);

  useEffect(() => {
    if (!isEditScreenlineMode && mapboxIntersectionClickPopupRef.current) {
      mapboxIntersectionClickPopupRef.current.remove();
    }
  }, [isEditScreenlineMode]);

  useEffect(() => {
    if ((selectedRoadVolume || selectedIntersections?.length) && !isScreelineEditorOpen) {
      dispatch(screenlinesActions.setSelectedScreenlineId(null));
    }
  }, [dispatch, isScreelineEditorOpen, selectedRoadVolume, selectedIntersections]);

  useEffect(() => {
    return () => {
      dispatch(screenlinesActions.setEditProps(false));
      dispatch(screenlinesActions.setEditIntersections(false));
      dispatch(screenlinesActions.setDrawMode(false));
    };
  }, [dispatch]);

  // Set module data
  useEffect(() => {
    if (selectedFocusArea) {
      const sources = getScreenlinesSources();
      const commonLayers = getScreenlinesLayers(showScreenlinesRef, roadClassesRef, opacityFactor, widthFactor);
      setScreenlinesModuleData({
        sources,
        layers: commonLayers,
        data: {
          screenlinesRef,
          isEditScreenlineModeRef,
          selectedScreenlineIdRef,
          selectedIntersectionIdRef,
          mapboxScreenlineHoverPopupRef,
          mapboxIntersectionClickPopupRef,
          mapboxGeometryClickPopupRef,
          screenlineValidationDataRef,
          isDrawModeRef,
          isScreenlineEditorOpenRef,
          networkTypeRef,
          setSelectedScreenlineId,
          setSelectedIntersectionId,
          changeShowScreenlines,
          handleSetDraftFeature,
          handleSetDrawMode,
          changeScreenlinesOpacity,
        },
      });
    }
  }, [
    selectedFocusArea,
    screenlinesRef,
    screenlinesMaxCountRef,
    isEditScreenlineModeRef,
    selectedScreenlineIdRef,
    selectedIntersectionIdRef,
    roadClassesRef,
    screenlineValidationDataRef,
    showScreenlinesRef,
    isDrawModeRef,
    isScreenlineEditorOpenRef,
    opacityFactor,
    widthFactor,
    networkTypeRef,
    setScreenlinesModuleData,
    setSelectedScreenlineId,
    setSelectedIntersectionId,
    changeShowScreenlines,
    handleSetDraftFeature,
    handleSetDrawMode,
    changeScreenlinesOpacity,
  ]);

  return null;
};
