import { MutableRefObject, useCallback } from "react";
import { useDispatch } from "react-redux";

import { buildFilters } from "features/filters/utils";
import { getLayerFromZoom, updateFeatureState } from "features/map/utils";

import { buildSegmentsPredicate, buildZonesPredicate } from "components/pages/analytics/select-link/utils";

import { analyticsActions } from "store/sections/analytics";
import { selectLinkActions } from "store/sections/selectLink";

import {
  FiltersType,
  FocusAreaItem,
  ODTileLayer,
  SegmentsGroup,
  SelectLinkAnalysisOptions,
  SelectLinkConfigEssentials,
  SelectLinkConfigUpdateRequest,
  SelectedArea,
  ZoneSelectionMode,
} from "types";

import { OD_ZONE_SOURCE_ID } from "../od/map-data/od/sources";
import { PERMANENT_HIGHLIGHTED_FEATURE } from "./MapController";

export const useUpdateFeatureStateForZone = (
  map: MutableRefObject<mapboxgl.Map | null>,
  zoneLayers: ODTileLayer[] | undefined,
) =>
  useCallback(
    (sourceId: string, zone: SelectedArea, zoneMode: string, stateName: string, status?: boolean, layer?: string) => {
      if (!map.current) return;

      const zoneLayerId = layer || (getLayerFromZoom(map.current.getZoom(), zoneLayers)?.name as string);
      const sourceName = `${sourceId} ${zoneLayerId}`;

      if (map.current.getSource(sourceName)) {
        updateFeatureState(
          map.current,
          sourceName,
          zoneLayerId,
          zone.id,
          stateName + (zoneMode === ZoneSelectionMode.Origins ? "Origin" : "Destination"),
          status,
        );
      }
    },
    [map, zoneLayers],
  );

export const useUpdateFeatureStateForZoneInAllLayers = (
  map: MutableRefObject<mapboxgl.Map | null>,
  zoneLayers: ODTileLayer[] | undefined,
  updateFeatureStateForZone: (
    sourceId: string,
    zone: SelectedArea,
    zoneMode: string,
    stateName: string,
    status?: boolean | undefined,
    layer?: string | undefined,
  ) => void,
) =>
  useCallback(
    (zone: SelectedArea, zoneMode: string, stateName: string, status?: boolean) => {
      if (!map.current) return;

      zoneLayers?.forEach((layer) => {
        updateFeatureStateForZone(OD_ZONE_SOURCE_ID, zone, zoneMode, stateName, status, layer.name);
      });
    },
    [map, zoneLayers, updateFeatureStateForZone],
  );

export const useUpdateFeatureStateWithSelectedZones = (
  map: MutableRefObject<mapboxgl.Map | null>,
  updateFeatureStateForZone: (
    sourceId: string,
    zone: SelectedArea,
    zoneMode: string,
    stateName: string,
    status?: boolean | undefined,
    layer?: string | undefined,
  ) => void,
) =>
  useCallback(
    (zones: SelectedArea[], zoneMode: string, sourceId: string, layerId: string) => {
      if (!map.current) return;

      zones.forEach((zone) => {
        updateFeatureStateForZone(sourceId, zone, zoneMode, PERMANENT_HIGHLIGHTED_FEATURE, true, layerId);
      });
    },
    [map, updateFeatureStateForZone],
  );

export const useUpdateFeatureStateWithSelectedODs = (
  zoneLayers: ODTileLayer[] | undefined,
  selectedOriginsRef: React.MutableRefObject<SelectedArea[]>,
  selectedDestinationsRef: React.MutableRefObject<SelectedArea[]>,
  updateFeatureStateWithSelectedZones: (
    zones: SelectedArea[],
    zoneMode: string,
    sourceId: string,
    layerId: string,
  ) => void,
) =>
  useCallback(
    (sourceId: string) => {
      zoneLayers?.forEach((layer) => {
        updateFeatureStateWithSelectedZones(
          selectedOriginsRef.current,
          ZoneSelectionMode.Origins,
          sourceId,
          layer.name,
        );
        updateFeatureStateWithSelectedZones(
          selectedDestinationsRef.current,
          ZoneSelectionMode.Destinations,
          sourceId,
          layer.name,
        );
      });
    },
    [zoneLayers, selectedOriginsRef, selectedDestinationsRef, updateFeatureStateWithSelectedZones],
  );

export const useDeleteSelectedZonesFromFeatureState = (
  zoneLayers: ODTileLayer[] | undefined,
  updateFeatureStateForZone: (
    sourceId: string,
    zone: SelectedArea,
    zoneMode: string,
    stateName: string,
    status?: boolean | undefined,
    layer?: string | undefined,
  ) => void,
) =>
  useCallback(
    (zoneToDelete: SelectedArea) => {
      // delete feature from the main source (where interactive selection occurs), as well as from selected zones source
      [OD_ZONE_SOURCE_ID].forEach((sourceId) => {
        // because deletion is possible from the list on the different zoom level, need to go through all layers
        zoneLayers?.forEach((zoneLayer) => {
          // because deletion is possible by unclicking, need to remove the feature in both modes
          [ZoneSelectionMode.Origins, ZoneSelectionMode.Destinations].forEach((zsm) => {
            updateFeatureStateForZone(
              sourceId,
              zoneToDelete,
              zsm,
              PERMANENT_HIGHLIGHTED_FEATURE,
              false,
              zoneLayer.name,
            );
          });
        });
      });
    },
    [zoneLayers, updateFeatureStateForZone],
  );

export const useDeleteSelectedZone = (
  zoneSelectionModeRef: React.MutableRefObject<ZoneSelectionMode>,
  selectedOriginsRef: React.MutableRefObject<SelectedArea[]>,
  selectedDestinationsRef: React.MutableRefObject<SelectedArea[]>,
  setSelectedOrigins: (zones: SelectedArea[]) => void,
  setSelectedDestinations: (zones: SelectedArea[]) => void,
  deleteSelectedZonesFromFeatureState: (zoneToDelete: SelectedArea) => void,
) =>
  useCallback(
    (zoneToDelete: SelectedArea) => {
      const zoneMode = zoneSelectionModeRef.current;
      if (zoneMode === ZoneSelectionMode.Origins) {
        const newZones = selectedOriginsRef.current.filter((zone) => zone.id !== zoneToDelete.id);
        setSelectedOrigins(newZones);
        selectedOriginsRef.current = newZones;
      } else {
        const newZones = selectedDestinationsRef.current.filter((zone) => zone.id !== zoneToDelete.id);
        setSelectedDestinations(newZones);
        selectedDestinationsRef.current = newZones;
      }
      deleteSelectedZonesFromFeatureState(zoneToDelete);
    },
    [
      selectedOriginsRef,
      selectedDestinationsRef,
      zoneSelectionModeRef,
      deleteSelectedZonesFromFeatureState,
      setSelectedOrigins,
      setSelectedDestinations,
    ],
  );

export const useSelectZoneInternal = (
  selectedOriginsRef: React.MutableRefObject<SelectedArea[]>,
  selectedDestinationsRef: React.MutableRefObject<SelectedArea[]>,
  setSelectedOrigins: (zones: SelectedArea[]) => void,
  setSelectedDestinations: (zones: SelectedArea[]) => void,
  updateFeatureStateForZone: (
    sourceId: string,
    zone: SelectedArea,
    zoneMode: string,
    stateName: string,
    status?: boolean | undefined,
    layer?: string | undefined,
  ) => void,
) =>
  useCallback(
    (selectedZones: SelectedArea[], selectedZone: SelectedArea, zoneMode: string) => {
      if (!selectedZones.find((zone) => zone.id === selectedZone.id)) {
        const newZones = [...selectedZones, selectedZone];
        if (zoneMode === ZoneSelectionMode.Origins) {
          setSelectedOrigins(newZones);
          selectedOriginsRef.current = newZones;
        } else {
          setSelectedDestinations(newZones);
          selectedDestinationsRef.current = newZones;
        }
        updateFeatureStateForZone(OD_ZONE_SOURCE_ID, selectedZone, zoneMode, PERMANENT_HIGHLIGHTED_FEATURE, true);
      }
    },
    [
      selectedOriginsRef,
      selectedDestinationsRef,
      updateFeatureStateForZone,
      setSelectedOrigins,
      setSelectedDestinations,
    ],
  );

export const useSelectZoning = (
  selectedOriginsRef: React.MutableRefObject<SelectedArea[]>,
  selectedDestinationsRef: React.MutableRefObject<SelectedArea[]>,
  zoneSelectionModeRef: React.MutableRefObject<ZoneSelectionMode>,
  deleteSelectedZone: (zoneToDelete: SelectedArea) => void,
  selectZoneInternal: any,
) => {
  return useCallback(
    (selectedZone: SelectedArea | null) => {
      if (!selectedZone) return;

      const findZone = (zones: SelectedArea[], zoneToFind: SelectedArea): SelectedArea | undefined => {
        return zones.find((zone) => zone.id === zoneToFind.id);
      };

      const zoneSelectionMode = zoneSelectionModeRef.current;

      // when trying to select O that has already been selected as D, or other way around, nothing should happen
      const existingZoneInAnotherMode =
        zoneSelectionMode === ZoneSelectionMode.Origins
          ? findZone(selectedDestinationsRef.current, selectedZone)
          : findZone(selectedOriginsRef.current, selectedZone);

      if (existingZoneInAnotherMode) return;

      const existingZoneInSameMode =
        zoneSelectionMode === ZoneSelectionMode.Origins
          ? findZone(selectedOriginsRef.current, selectedZone)
          : findZone(selectedDestinationsRef.current, selectedZone);

      // if already selected, delete; if not, add
      if (existingZoneInSameMode) {
        deleteSelectedZone(selectedZone);
      } else {
        selectZoneInternal(
          zoneSelectionMode === ZoneSelectionMode.Origins
            ? selectedOriginsRef.current
            : selectedDestinationsRef.current,
          selectedZone,
          zoneSelectionMode,
        );
      }
    },
    [selectedOriginsRef, selectedDestinationsRef, zoneSelectionModeRef, deleteSelectedZone, selectZoneInternal],
  );
};

/**
 * This callback is triggered when select link results become available
 * to visually add permanently selected links to the select link road volume map.
 */
export const useUpdateFeatureStateWithSelectedLinks = (
  segmentsIdToIdxMapData: Map<string, number> | null,
  updateFeatureStateForRoads: React.MutableRefObject<
    ((segmentIdx: number | null, stateName: string, status?: boolean | undefined) => void) | null
  >,
) => {
  return useCallback(
    (segmentGroups: SegmentsGroup[]) => {
      if (updateFeatureStateForRoads.current && segmentsIdToIdxMapData)
        segmentGroups.forEach((group) => {
          group.segments.forEach((link) => {
            const fromToSegmentIndex = segmentsIdToIdxMapData.get(link.fromToId);

            if (fromToSegmentIndex) {
              updateFeatureStateForRoads.current!(fromToSegmentIndex, PERMANENT_HIGHLIGHTED_FEATURE, true);
            }
          });
        });
    },
    [segmentsIdToIdxMapData, updateFeatureStateForRoads],
  );
};

export const useUpdateSelectLinkConfiguration = (configId: string | undefined, minVolume: number) => {
  const dispatch = useDispatch();

  return useCallback(
    (update: SelectLinkConfigEssentials) => {
      if (configId) {
        const updateRequest = {
          segmentsGroups: update.segmentsGroups,
          segmentsGroupsOp: update.segmentsGroupsOp,
          origins: update.origins,
          destinations: update.destinations,
          minCount: minVolume,
          roadFilters: update.roadFilters,
        } as SelectLinkConfigUpdateRequest;
        dispatch(selectLinkActions.updateSelectLinkConfig(configId, updateRequest));
      }
    },
    [dispatch, configId, minVolume],
  );
};

/**
 * This callback prepares payload and sends select link request to the BE.
 */
export const useGetSelectLinkSegmentCounts = (measure: string | null, timePeriod: string | null) => {
  const dispatch = useDispatch();

  return useCallback(
    (
      filters: FiltersType,
      selectedFocusArea: FocusAreaItem,
      segmentGroups: SegmentsGroup[],
      origins: SelectedArea[],
      destinations: SelectedArea[],
      predicateLogic: string,
      { minVolume }: SelectLinkAnalysisOptions,
    ) => {
      if (timePeriod && measure) {
        dispatch(
          selectLinkActions.fetchSelectLinkSegmentCounts({
            timePeriod,
            level: "BlockGroup",
            compression: "gzip",
            format: "protobuf",
            areaOfInterest: null,
            filter: buildFilters(filters),
            measure,
            precision: 0,
            selectedSegmentsPredicate: buildSegmentsPredicate(segmentGroups, predicateLogic),
            selectedOriginsPredicate: buildZonesPredicate(origins),
            selectedDestinationsPredicate: buildZonesPredicate(destinations),
            aggTotal: false,
            countsFilter: {
              min: minVolume || 0,
              max: 1e9,
            },
          }),
        );
      }
    },
    [measure, timePeriod, dispatch],
  );
};

export const useGetSelectLinkMetadata = (timePeriod: string | null) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    if (timePeriod) {
      dispatch(
        analyticsActions.fetchSelectLinkMetadata({
          timePeriod,
          includeDisabled: false,
        }),
      );
    }
  }, [dispatch, timePeriod]);
};
