import { MapLayerMouseEvent, Map as MapboxMap, Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, RefObject, SetStateAction } from "react";

import { updateFeatureState } from "features/map/utils";
import { RoadIntersectionPopupContentProps } from "features/road-intersections/RoadIntersectionPopupContent";

import { RoadIntersectionLevel } from "types";

import {
  ROAD_INTERSECTIONS_LAYER_ID,
  getRoadIntersectionLayerNameForLevel,
  getRoadIntersectionSourceLayerNameForLevel,
} from "../layers";
import { ROAD_INTERSECTIONS_SOURCE_ID, getRoadIntersectionSourceNameForLevel } from "../sources";

export const initUserInteractionsWithMap = (
  map: MapboxMap,
  draw: any,
  levels: RoadIntersectionLevel[],
  roadIntersectionPopupRef: MutableRefObject<HTMLDivElement | null>,
  mapboxRoadIntersectionHoverPopupRef: RefObject<Popup>,
  setIntersectionPopupPropsRef: MutableRefObject<Dispatch<
    SetStateAction<RoadIntersectionPopupContentProps | null>
  > | null>,
  suppressRoadVolumePopupAndStatesRef: MutableRefObject<boolean>,
  selectedIntersectionIdRef: MutableRefObject<string | null>,
  selectedIntersectionsRef: MutableRefObject<string[] | null>,
  isScreenlineDrawModeRef: MutableRefObject<boolean>,
  isScreenlineEditorOpenRef: MutableRefObject<boolean>,
  setIsDrawModeActive: Dispatch<SetStateAction<boolean>>,
  setSelectedIntersectionId: (selectedIntersectionId: string | null) => void,
  setSelectedIntersections: (selectedIntersections: string[] | null) => void,
  closeRoadsAnalyticsPanelRef?: MutableRefObject<(() => void) | null>,
  clearSelectedIntersectionsRef?: MutableRefObject<(() => void) | null>,
) => {
  const clusterLevels = levels.filter((level) => level.maxRadius);
  const baseSourceLayerId = levels.find((level) => !level.maxRadius)?.tileService.layerName;

  const allLayerIds = levels.map((level) => getRoadIntersectionLayerNameForLevel(level));
  const clusterLayerIds = clusterLevels.map((level) => getRoadIntersectionLayerNameForLevel(level));
  let hoveredItem: { id: string; source: string; sourceLayer: string } | null = null;

  const getClusterLevelAtZoomLevel = (zoom: number): RoadIntersectionLevel | undefined => {
    return levels.find((level) => level.minZoomLevel <= zoom && level.maxZoomLevel > zoom);
  };

  const setHoveredItem = (itemId: string | undefined, sourceId: string, sourceLayerId: string) => {
    if (map && map.getSource(sourceId)) {
      if (hoveredItem && hoveredItem.id !== itemId) {
        map.setFeatureState(
          {
            source: hoveredItem.source,
            sourceLayer: hoveredItem.sourceLayer,
            id: hoveredItem.id,
          },
          { hover: false },
        );
      }

      if (itemId) {
        if (sourceId === ROAD_INTERSECTIONS_SOURCE_ID) {
          map.getCanvas().style.cursor = "default";
        } else {
          map.getCanvas().style.cursor = "zoom-in";
        }
        map.setFeatureState(
          {
            source: sourceId,
            sourceLayer: sourceLayerId,
            id: itemId,
          },
          { hover: true },
        );
        hoveredItem = { id: itemId, source: sourceId, sourceLayer: sourceLayerId };
      }
    }
  };

  const setHoveredNodeId = (nodeId: string | undefined, level: RoadIntersectionLevel) => {
    const sourceLayerName = getRoadIntersectionSourceLayerNameForLevel(level);

    if (sourceLayerName) {
      setHoveredItem(nodeId, getRoadIntersectionSourceNameForLevel(level), sourceLayerName);
    }
  };

  const toggleSelectHighlightIntersection = (nodeId: string | undefined, status: boolean) => {
    updateFeatureState(map, ROAD_INTERSECTIONS_SOURCE_ID, baseSourceLayerId, nodeId, "selectHighlight", status);
  };

  const toggleHoverIntersection = (nodeId: string | undefined, status: boolean) => {
    updateFeatureState(map, ROAD_INTERSECTIONS_SOURCE_ID, baseSourceLayerId, nodeId, "hover", status);
  };

  const handleClickForClusterLayer = (e: MapLayerMouseEvent) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: clusterLayerIds,
    });
    const feature = features?.[0];

    if (mapboxRoadIntersectionHoverPopupRef.current && mapboxRoadIntersectionHoverPopupRef.current.isOpen()) {
      mapboxRoadIntersectionHoverPopupRef.current.remove();
    }

    if (feature && feature?.properties?.cluster_size > 1) {
      const clusterLevel = getClusterLevelAtZoomLevel(map.getZoom());
      if (map && clusterLevel?.maxRadius) {
        map.easeTo({
          center: e.lngLat,
          zoom: clusterLevel.maxZoomLevel + 1,
        });
      }
    }
  };

  const handleClickForBaseLayer = (e: MapLayerMouseEvent) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: [ROAD_INTERSECTIONS_LAYER_ID],
    });
    const featureId = features?.[0]?.id?.toString();

    if (featureId) {
      const previouslySelectedIntersectionId = selectedIntersectionIdRef.current?.toString();

      if (mapboxRoadIntersectionHoverPopupRef.current && mapboxRoadIntersectionHoverPopupRef.current.isOpen()) {
        mapboxRoadIntersectionHoverPopupRef.current.remove();
      }

      if (
        !map.getLayer(ROAD_INTERSECTIONS_LAYER_ID) ||
        !map.getLayoutProperty(ROAD_INTERSECTIONS_LAYER_ID, "visibility")
      ) {
        return;
      }

      // Close road analytics panel, if any
      closeRoadsAnalyticsPanelRef?.current?.();

      // Clear multi-selection
      clearSelectedIntersectionsRef?.current?.();

      if (previouslySelectedIntersectionId) {
        toggleHoverIntersection(previouslySelectedIntersectionId, false);
        toggleSelectHighlightIntersection(previouslySelectedIntersectionId, false);
        hoveredItem = null;
      }

      if (featureId === previouslySelectedIntersectionId) {
        // unselect
        setSelectedIntersectionId(null);
      } else {
        // select new feature
        setSelectedIntersectionId(featureId);
        toggleSelectHighlightIntersection(featureId, true);
      }
    }
  };

  const handleMouseMove = (e: MapLayerMouseEvent) => {
    map.getCanvas().style.cursor = "default";
    const feature = e.features?.[0];
    const featureId = feature?.id?.toString();

    if (mapboxRoadIntersectionHoverPopupRef.current) {
      mapboxRoadIntersectionHoverPopupRef.current.remove();
    }

    if (featureId) {
      const clusterLevel = getClusterLevelAtZoomLevel(map.getZoom());
      if (clusterLevel && feature?.layer.id === getRoadIntersectionLayerNameForLevel(clusterLevel)) {
        setHoveredNodeId(featureId, clusterLevel);
        suppressRoadVolumePopupAndStatesRef.current = true;

        const { legs, cluster_size } = feature?.properties as any;

        const { volume } = map.getFeatureState({
          source: getRoadIntersectionSourceNameForLevel(clusterLevel),
          sourceLayer: getRoadIntersectionSourceLayerNameForLevel(clusterLevel),
          id: featureId,
        });

        setIntersectionPopupPropsRef.current?.({
          legs,
          size: cluster_size,
          volume,
        });

        if (roadIntersectionPopupRef.current) {
          (mapboxRoadIntersectionHoverPopupRef.current as mapboxgl.Popup) = new Popup({
            closeButton: false,
            closeOnClick: false,
            offset: 15,
          })
            .setLngLat(e.lngLat)
            .setDOMContent(roadIntersectionPopupRef.current as Node)
            .addTo(map);
        }
      }
    }
  };

  const handleMouseLeave = () => {
    if (hoveredItem && map) {
      const clusterLevel = getClusterLevelAtZoomLevel(map.getZoom());
      if (clusterLevel && map.getSource(getRoadIntersectionSourceNameForLevel(clusterLevel))) {
        map.getCanvas().style.cursor = "";

        if (mapboxRoadIntersectionHoverPopupRef.current) {
          mapboxRoadIntersectionHoverPopupRef.current.remove();
        }

        map.setFeatureState(
          {
            source: hoveredItem.source,
            sourceLayer: hoveredItem.sourceLayer,
            id: hoveredItem.id,
          },
          { hover: false },
        );
        hoveredItem = null;
        suppressRoadVolumePopupAndStatesRef.current = false;
      }
    }
  };

  const selectHighlightIntersections = (newIds: string[] | null) => {
    if (selectedIntersectionsRef.current) {
      selectedIntersectionsRef.current.forEach((id) => {
        toggleSelectHighlightIntersection(id, false);
      });
    }

    if (newIds) {
      newIds.forEach((id) => {
        toggleSelectHighlightIntersection(id, true);
      });
    }
  };

  const handleSetSelectedIntersections = (e: any) => {
    if (isScreenlineDrawModeRef?.current || isScreenlineEditorOpenRef?.current) return;

    // Clear existing selection
    selectHighlightIntersections(null);
    setSelectedIntersections(null);

    const polygons = e.features.filter((feature: any) => feature.geometry.type === "Polygon");

    if (!polygons.length) return;

    closeRoadsAnalyticsPanelRef?.current?.();

    const polygon = polygons[0];
    const intersections = map.queryRenderedFeatures(undefined, {
      layers: [ROAD_INTERSECTIONS_LAYER_ID],
      filter: ["within", polygon.geometry],
    });

    const intersectionIds = intersections
      .map((intersection) => intersection.id?.toString())
      .filter((id): id is string => id !== undefined);

    if (selectedIntersectionIdRef.current) {
      toggleSelectHighlightIntersection(selectedIntersectionIdRef.current, false);
      setSelectedIntersectionId(null);
    }

    selectHighlightIntersections(intersectionIds);
    setSelectedIntersections(intersectionIds);

    // Remove drawn polygon and deactivate draw mode
    draw.deleteAll();
    setIsDrawModeActive(false);
  };

  map.on("mousemove", allLayerIds, handleMouseMove);
  map.on("mouseleave", allLayerIds, handleMouseLeave);

  map.on("click", clusterLayerIds, handleClickForClusterLayer);
  map.on("click", ROAD_INTERSECTIONS_LAYER_ID, handleClickForBaseLayer);

  map.on("draw.create", handleSetSelectedIntersections);

  return {
    cleanUserInteractionsHandlers: () => {
      map.off("mousemove", allLayerIds, handleMouseMove);
      map.off("mouseleave", allLayerIds, handleMouseLeave);

      map.off("click", clusterLayerIds, handleClickForClusterLayer);
      map.off("click", ROAD_INTERSECTIONS_LAYER_ID, handleClickForBaseLayer);

      map.off("draw.create", handleSetSelectedIntersections);
    },
  };
};
