import { GeocodingSearchResultsFeature, SearchResultItem } from "types";

export const convertFeatureResultToGeocodingSearchResultsFeature = (
  feature: SearchResultItem,
): GeocodingSearchResultsFeature => {
  return {
    id: feature.Segment.id,
    place_name: feature.Segment.description,
    center: [feature.Segment.markerCoordinates.lon, feature.Segment.markerCoordinates.lat],
  };
};

// Function to limit the set of properties for the search results from the Geocoding API
export const convertPlaceSearchResultToGeocodingSearchResultsFeature = (
  feature: GeocodingSearchResultsFeature,
): GeocodingSearchResultsFeature => {
  return {
    id: feature.id,
    place_name: feature.place_name,
    center: feature.center,
  };
};
