import { Check, Clear } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { IconButton } from "components_new";
import { FC } from "react";

export interface DeleteVertexPopupContentProps {
  draw: any;
  onClose: () => void;
}

export const DeleteVertexPopupContent: FC<DeleteVertexPopupContentProps> = ({ draw, onClose }) => {
  const handleDeleteVertex = () => {
    onClose();
    draw.trash();
  };

  return (
    <Grid container alignItems={"center"} padding={1}>
      <Typography fontSize={12} fontWeight={500} marginRight={2}>
        Delete vertex
      </Typography>
      <>
        <IconButton size="small" color="success" onClick={handleDeleteVertex}>
          <Check fontSize="inherit" />
        </IconButton>
        <IconButton size="small" color="error" onClick={onClose}>
          <Clear fontSize="inherit" />
        </IconButton>
      </>
    </Grid>
  );
};
