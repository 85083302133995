import { Clear } from "@mui/icons-material";
import { Box, Grid, ListItemText, Tooltip } from "@mui/material";
import {
  Divider,
  MapControlContainer,
  MenuItem,
  PolygonByPoints,
  SliderControl,
  TextField,
  VisibilityIconButton,
} from "components_new";
import { FC, MutableRefObject, useEffect, useMemo, useState } from "react";

import { DrawIconButton } from "features/dataset-editor/drawingTools";
import { ModuleData } from "features/map/ModuleManager";
import { calculateWidthFactor, changeRoadIntersectionsLayersPaintExpressions } from "features/road-intersections";

import { useAppDispatch, useAppSelector } from "hooks";

import { globalActions } from "store/sections/global";
import { roadIntersectionsActions, selectRoadIntersectionsLoading } from "store/sections/roadIntersections";

import { AggregationFunction, MapLayerContainerId } from "types";

import { useSetIsDrawModeActive } from "./ControllerCallbacks";

export interface RoadIntersectionsMapLayersProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  draw: MutableRefObject<any | null>;
  moduleData: ModuleData;
  closeRoadIntersectionAnalyticsPanelRef: MutableRefObject<(() => void) | null>;
}

function valueLabelFormat(value: number) {
  return `x ${Math.round(value * 100) / 100}`;
}

export const RoadIntersectionsMapLayers: FC<RoadIntersectionsMapLayersProps> = ({
  map,
  draw,
  moduleData,
  closeRoadIntersectionAnalyticsPanelRef,
}) => {
  const { changeShowRoadIntersections, changeRoadIntersectionsOpacity, levels } = moduleData.data;

  const dispatch = useAppDispatch();
  const collapsedMapLayerContainers = useAppSelector((state) => state.global.collapsedMapLayerContainers);
  const loading = useAppSelector(selectRoadIntersectionsLoading);

  const showRoadIntersections = useAppSelector((state) => state.roadIntersections.showRoadIntersections);
  const minVolumes = useAppSelector((state) => state.roadIntersections.minVolumes);
  const maxVolumes = useAppSelector((state) => state.roadIntersections.maxVolumes);

  const savedOpacityFactor = useAppSelector((state) => state.roadIntersections.opacityFactor);
  const savedWidthFactor = useAppSelector((state) => state.roadIntersections.widthFactor);
  const isDrawModeActive = useAppSelector((state) => state.roadIntersections.isDrawModeActive);
  const selectedIntersections = useAppSelector((state) => state.roadIntersections.selectedIntersections);
  const isBaseIntersectionLevel = useAppSelector((state) => state.roadIntersections.isBaseIntersectionLevel);
  const aggregationFunction = useAppSelector((state) => state.roadIntersections.aggregationFunction);

  // Screenline state is needed to restrict some drawing functions
  const isScreenlineEditorOpen = useAppSelector((state) => state.screenlines.isScreelineEditorOpen);

  const [widthFactor, setWidthFactor] = useState(savedWidthFactor);
  const [opacityFactor, setOpacityFactor] = useState(savedOpacityFactor);

  const handleChangeWidth = (newValue: number) => {
    dispatch(roadIntersectionsActions.setWidthFactor(newValue));
  };

  const handleChangeOpacity = (newValue: number) => {
    dispatch(roadIntersectionsActions.setOpacityFactor(newValue));
    changeRoadIntersectionsOpacity(newValue);
  };

  const handleToggleRoadIntersections = () => {
    dispatch(roadIntersectionsActions.setShowRoadIntersections(!showRoadIntersections));
    changeShowRoadIntersections(!showRoadIntersections);
  };

  const handleChangeAggregationFunction = (fn: AggregationFunction) => {
    if (fn !== aggregationFunction) {
      dispatch(roadIntersectionsActions.setAggregationFunctionAndClearVolumes(fn));
    }
  };

  const setIsDrawMode = useSetIsDrawModeActive();

  const handleDrawMode = () => {
    if (isDrawModeActive) {
      setIsDrawMode(false);
      draw.current?.changeMode("simple_select");
    } else {
      setIsDrawMode(true);
      draw.current?.changeMode("draw_polygon");
    }
  };

  const handleClearSelection = () => {
    closeRoadIntersectionAnalyticsPanelRef.current?.();
  };

  // Set layers width factor after fetching counts and determining max volumes
  useEffect(() => {
    if (map.current) {
      changeRoadIntersectionsLayersPaintExpressions(map.current, levels, savedWidthFactor, minVolumes, maxVolumes);
    }
  }, [map, levels, savedWidthFactor, minVolumes, maxVolumes]);

  const AGGREGATION_FUNCTIONS = Object.entries(AggregationFunction).map(([key, value]) => ({
    value,
    label: key,
  }));

  const areIntersectionsSelected = useMemo(
    () => selectedIntersections && selectedIntersections.length > 0,
    [selectedIntersections],
  );

  return (
    <>
      <MapControlContainer
        title="Intersection Volumes"
        primaryAction={<VisibilityIconButton visible={showRoadIntersections} onClick={handleToggleRoadIntersections} />}
        collapse
        expanded={!collapsedMapLayerContainers.includes(MapLayerContainerId.ROAD_INTERSECTIONS)}
        onChange={() =>
          dispatch(globalActions.toggleLayerContainerCollapsedState(MapLayerContainerId.ROAD_INTERSECTIONS))
        }
      >
        <Box padding={1}>
          <SliderControl
            label="Opacity"
            disabled={loading}
            value={opacityFactor}
            defaultValue={1}
            min={0}
            max={1}
            step={0.1}
            marks={[
              {
                value: 1,
                label: "",
              },
            ]}
            onChange={(e, value) => setOpacityFactor(value as number)}
            onChangeCommitted={(e, value) => handleChangeOpacity(value as number)}
          />
          <SliderControl
            label="Width"
            disabled={loading}
            value={widthFactor}
            defaultValue={1}
            min={0}
            max={2}
            step={0.1}
            marks={[
              {
                value: 1,
                label: "",
              },
            ]}
            scale={calculateWidthFactor}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            valueLabelDisplay="auto"
            onChange={(e, value) => setWidthFactor(value as number)}
            onChangeCommitted={(e, value) => handleChangeWidth(value as number)}
          />
        </Box>
        <Divider />

        <Box padding={1}>
          <Grid container columns={2} marginTop={0.5} gap={1} alignItems={"center"}>
            <Grid item xs>
              <TextField label="Aggregation" select disabled={loading} value={aggregationFunction} fullWidth>
                {AGGREGATION_FUNCTIONS.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    onClick={() => handleChangeAggregationFunction(option.value)}
                  >
                    <ListItemText
                      primary={option.label}
                      primaryTypographyProps={{ noWrap: true, fontSize: 14 }}
                      sx={{ margin: 0 }}
                    />
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sx={{ marginTop: 0.5 }}>
              <Grid container columns={2} gap={1}>
                <Grid item xs>
                  <Tooltip
                    title={
                      isScreenlineEditorOpen
                        ? "Close screenlines editor to be able to select road intersections"
                        : !isBaseIntersectionLevel
                        ? "Zoom in to be able to select road intersections"
                        : "Draw polygon to select multiple intersections"
                    }
                  >
                    <span>
                      <DrawIconButton
                        height={38}
                        selected={isDrawModeActive}
                        disabled={!isBaseIntersectionLevel || isScreenlineEditorOpen || !showRoadIntersections}
                        size="small"
                        onClick={handleDrawMode}
                      >
                        <PolygonByPoints sx={{ marginBottom: "2px" }} />
                      </DrawIconButton>
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs>
                  <Tooltip title={areIntersectionsSelected ? "Clear selection" : "Nothing to clear"}>
                    <span>
                      <DrawIconButton
                        height={38}
                        color="default"
                        disabled={!areIntersectionsSelected}
                        onClick={handleClearSelection}
                      >
                        <Clear />
                      </DrawIconButton>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </MapControlContainer>
    </>
  );
};
