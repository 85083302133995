import styled from "@emotion/styled";
import { Grid, Stack } from "@mui/material";
import { CircularProgress, CountBadge } from "components_new";
import { FC } from "react";

import { useAppSelector } from "hooks";

import { selectRoadIntersectionsLoading } from "store/sections/roadIntersections";

import { getAggregationFunctionLabel } from "./utils";

export interface RoadIntersectionPopupContentProps {
  legs?: number;
  size?: number;
  volume: number;
}

export const IntersectionDetails = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding: 0 0.5rem;
  white-space: nowrap;
`;

export const RoadIntersectionPopupContent: FC<RoadIntersectionPopupContentProps> = ({ legs, size, volume }) => {
  const loading = useAppSelector(selectRoadIntersectionsLoading);
  const aggregationFunction = useAppSelector((state) => state.roadIntersections.aggregationFunction);

  return (
    <>
      {loading ? (
        <Grid container alignItems={"center"} justifyContent={"center"} minWidth={200} minHeight={32}>
          <CircularProgress size={16} color="secondary" />
        </Grid>
      ) : (
        <Stack alignItems="center" direction="row" padding={"4px 8px"}>
          {legs && <IntersectionDetails>{`Intersection (${legs} legs)`}</IntersectionDetails>}
          {size && (
            <IntersectionDetails>{`Cluster of ${size} intersection${
              size === 1 ? "" : "s"
            } (${getAggregationFunctionLabel(aggregationFunction)})`}</IntersectionDetails>
          )}
          <CountBadge label={volume} />
        </Stack>
      )}
    </>
  );
};
