import { Box, styled } from "@mui/material";
import React, { FC, useState } from "react";
import {
  Bar,
  CartesianGrid,
  Cell,
  BarChart as RechartsBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { getFlowInfo } from "features/map/utils";

import { ODPopup } from "components";

import { ExtendedQueryType, FlowPattern, HoveredFlow, QueryType, ZoningLevel } from "types";

interface DataChart {
  id: string;
  name: string;
  value: number;
  flowPattern: FlowPattern;
  level: string;
  isGate: boolean;
  external: boolean;
  areaName: string;
}

interface BarChartProps {
  isDemandScenario: boolean;
  data: DataChart[];
  zoningLevels: ZoningLevel[] | undefined;
  flowsDirection: QueryType | ExtendedQueryType;
  hoveredFlow: HoveredFlow | null;
  setHoveredFlow: (hoveredTopFlow: HoveredFlow | null) => void;
  getCustomColor?: (pattern: string) => string;
}

const ChartWrapper = styled(Box)`
  width: 100%;
  padding: 35px 15px 0 5px;
`;

const TooltipWrapper = styled(Box)`
  min-width: 200px;
  min-height: 60px;
  font-family: "Inter";
  font-size: 11px;
  color: var(--color-text);
  border-radius: 6px;
  box-shadow: var(--box-shadow-sm);
  padding: 1px 0 3px 0;
  background-color: #ffffff;
`;

const CustomToolTip = ({ active, payload, label, data, flowsDirection, zoningLevels, isDemandScenario }: any) => {
  if (active) {
    const flow = data.find(
      (item: {
        index: number;
        id: string;
        name: string;
        value: number;
        flowPattern: FlowPattern;
        level: string;
        isGate: boolean;
      }) => item.name === label,
    );

    return (
      flow && (
        <TooltipWrapper>
          <ODPopup
            count={payload[0].value.toLocaleString("en-US")}
            type={flow?.flowPattern === FlowPattern.internalRound ? ExtendedQueryType.INTERNAL : flowsDirection}
            flowInfo={getFlowInfo(
              flowsDirection,
              flow.level,
              flow.id,
              flow.areaName,
              flow.levelName,
              flow.countryName,
              flow.isGate,
              zoningLevels,
              label,
              isDemandScenario,
            )}
          />
        </TooltipWrapper>
      )
    );
  }
  return null;
};

const BLUE_CHART_COLOR = "#4584D1";
const CHART_FOCUS_COLOR = "#139EEC";
const BAR_DEFAULT_HEIGHT = 20;
const BAR_CATEGORY_GAP = 4;
const DEFAULT_HORIZONATAL_LAYOUT_HEIGHT = "170";

export const TopFlowsChart: FC<BarChartProps> = ({
  isDemandScenario,
  data,
  hoveredFlow,
  flowsDirection,
  zoningLevels,
  setHoveredFlow,
  getCustomColor,
}) => {
  const [focusBar, setFocusBar] = useState(null);

  const getCellFill = (entry: DataChart, index: number) => {
    if (getCustomColor) {
      if (hoveredFlow) {
        return hoveredFlow.id === entry.id &&
          hoveredFlow.external === entry.external &&
          hoveredFlow.isGate === entry.isGate
          ? getCustomColor("hover")
          : getCustomColor(entry.flowPattern || "");
      } else {
        return focusBar && focusBar === index ? getCustomColor("hover") : getCustomColor(entry.flowPattern || "");
      }
    }

    if (hoveredFlow) {
      return hoveredFlow.id === entry.id &&
        hoveredFlow.external === entry.external &&
        hoveredFlow.isGate === entry.isGate
        ? CHART_FOCUS_COLOR
        : BLUE_CHART_COLOR;
    } else {
      return focusBar !== null ? (focusBar === index ? CHART_FOCUS_COLOR : BLUE_CHART_COLOR) : BLUE_CHART_COLOR;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <ChartWrapper
        style={{
          height: `${DEFAULT_HORIZONATAL_LAYOUT_HEIGHT}px`,
          paddingTop: "16px",
          paddingBottom: "0px" /* distance to legend */,
          paddingLeft: "8px",
          paddingRight: "4px",
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <RechartsBarChart
            data={data}
            layout="horizontal"
            barSize={BAR_DEFAULT_HEIGHT}
            barCategoryGap={BAR_CATEGORY_GAP}
            margin={{
              top: 0,
              left: 16 /* avoids cutoff for long labels for largest top flows at County level */,
              right: 0,
              bottom: 0,
            }}
            onMouseMove={(state: any) => {
              if (state.isTooltipActive) {
                setFocusBar(state.activeTooltipIndex);
                if (setHoveredFlow) {
                  setHoveredFlow({
                    id: state.activePayload[0].payload.id,
                    external: state.activePayload[0].payload.external,
                    isGate: state.activePayload[0].payload.isGate,
                  });
                }
              } else {
                setFocusBar(null);
                if (setHoveredFlow) {
                  setHoveredFlow(null);
                }
              }
            }}
            onMouseLeave={() => {
              setFocusBar(null);
              if (setHoveredFlow) {
                setHoveredFlow(null);
              }
            }}
          >
            <CartesianGrid horizontal={true} vertical={false} style={{ opacity: 0.5 }} />
            <XAxis
              type="category"
              dataKey="name"
              padding={{ right: 20 }}
              axisLine={false}
              tickLine={false}
              style={{
                fontSize: "0.62rem",
                fontFamily: "Inter",
              }}
            />
            <YAxis
              type="number"
              dataKey="value"
              axisLine={false}
              tickLine={false}
              style={{
                fontSize: "0.62rem",
                fontFamily: "Inter",
              }}
              width={50}
            />
            <Bar dataKey="value" stackId="a" radius={[5, 5, 0, 0]}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={getCellFill(entry, index)} />
              ))}
            </Bar>
            <Tooltip
              cursor={false}
              content={
                <CustomToolTip
                  data={data}
                  flowsDirection={flowsDirection}
                  zoningLevels={zoningLevels}
                  isDemandScenario={isDemandScenario}
                />
              }
            />
          </RechartsBarChart>
        </ResponsiveContainer>
      </ChartWrapper>
    </div>
  );
};
