import { UniqueIdentifier } from "@dnd-kit/core";
import styled from "@emotion/styled";
import { CreateNewFolder } from "@mui/icons-material";
import { useAuth } from "ImsAuthorization";
import { ConfigDocumentUpdateRequest, DatasetCopyRequest, DatasetCreationRequest } from "api/analytics/index.d";
import { Button } from "components_new";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";

import { DatasetContainers, FolderDatasetDialog } from "features";

import { PageContainer } from "components";

import { useAppDispatch, useAppSelector, usePageTracking } from "hooks";

import { DataState } from "store/interfaces";
import { datasetFoldersActions } from "store/sections/userFolders";

import { addCustomGAEvent } from "utils/addCustomGAEvent";

const DatasetsPageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  height: 38px;
  margin-bottom: 1.8rem;

  & > h1 {
    display: block;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
  }
`;

export const DatasetsPage: FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  const [isShowNewFolderDialog, setIsShowNewFolderDialog] = useState(false);

  const folders = useAppSelector((state) => state.datasetFolders.folders);
  const loading = useAppSelector((state) => state.datasetFolders.loading);
  const errorDialogMessage = useAppSelector((state) => state.datasetFolders.errorDialogMessage);
  const errorMessage = useAppSelector((state) => state.datasetFolders.errorMessage);
  const isTokenLoaded = useAppSelector((state) => state.analytics.authorizationTokenLoaded);
  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);

  const fetchFoldersInterval = useRef<NodeJS.Timeout>();
  const isFoldersIntervalSettable = useMemo(
    () => folders.state === DataState.AVAILABLE && !loading && !fetchFoldersInterval.current,
    [folders.state, loading],
  );

  usePageTracking();

  useEffect(() => {
    if (isTokenLoaded) {
      dispatch(datasetFoldersActions.fetchFoldersStructure());
    }
  }, [isTokenLoaded, dispatch]);

  useEffect(() => {
    if (isFoldersIntervalSettable) {
      fetchFoldersInterval.current = setInterval(() => {
        dispatch(datasetFoldersActions.fetchFoldersStructure());
      }, 120000);
    }
  }, [isFoldersIntervalSettable, dispatch]);

  useEffect(() => {
    return () => {
      fetchFoldersInterval.current && clearInterval(fetchFoldersInterval.current);
    };
  }, []);

  useEffect(() => {
    if (errorMessage) {
      // save all spaces in error message

      toast.error(
        <div>
          {errorMessage.split(" ").map((str, i) => (
            <span key={i}>{str}&nbsp;</span>
          ))}
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
        },
      );
      dispatch(datasetFoldersActions.clearErrorMessage());
      toast.clearWaitingQueue();
    }
  }, [errorMessage, dispatch]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!loading && !errorDialogMessage && isShowNewFolderDialog) {
      setIsShowNewFolderDialog(false);
    }
  }, [loading, errorDialogMessage]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onRenameFolder = (folderId: string, folderName: string) => {
    addCustomGAEvent("datasets", "rename_dataset_folder", "dataset_folders", user, userOrganizationName);
    dispatch(datasetFoldersActions.renameDatasetFolder(folderId, folderName));
  };

  const onDeleteFolder = (folderId: string) => {
    addCustomGAEvent("datasets", "delete_dataset_folder", "dataset_folders", user, userOrganizationName);
    dispatch(datasetFoldersActions.deleteDatasetFolder(folderId));
  };

  const onAddDataset = (config: DatasetCreationRequest, openEditor: boolean) => {
    addCustomGAEvent("datasets", "add_dataset", "dataset_configs", user, userOrganizationName);
    dispatch(datasetFoldersActions.addDatasetInFolder(config, openEditor));
  };

  const onChangeFolderIndex = (folderId: UniqueIdentifier, index: number) => {
    addCustomGAEvent("datasets", "move_dataset_folder", "dataset_folders", user, userOrganizationName);
    dispatch(datasetFoldersActions.changeFolderIndex(folderId, index));
  };

  const onRenameDataset = (datasetId: string, datasetName: string, isComputed: boolean) => {
    addCustomGAEvent("datasets", "rename_dataset", "dataset_configs", user, userOrganizationName);
    dispatch(datasetFoldersActions.renameDataset(datasetId, datasetName, isComputed));
  };

  const onDeleteDataset = (datasetId: string, computedDatasetId: string | undefined) => {
    addCustomGAEvent("datasets", "delete_dataset", "dataset_configs", user, userOrganizationName);
    dispatch(datasetFoldersActions.deleteDataset(datasetId, computedDatasetId));
  };

  const onChangeDatasetIndex = (folderId: string, catalogItemId: string, index: number) => {
    addCustomGAEvent("datasets", "move_dataset", "dataset_configs", user, userOrganizationName);
    dispatch(datasetFoldersActions.changeCatalogItemIndex(folderId, catalogItemId, index));
  };

  const onCopyDataset = (
    datasetId: string,
    config: DatasetCopyRequest,
    folderId: string,
    licensedAreaId: string,
    openEditor: boolean,
  ) => {
    addCustomGAEvent("datasets", "copy_dataset", "dataset_configs", user, userOrganizationName);
    dispatch(datasetFoldersActions.copyDataset(datasetId, config, folderId, licensedAreaId, openEditor));
  };

  const onEditZoning = (zoningItemId: string, name: string, description: string) => {
    addCustomGAEvent("custom_zoning", "edit_custom_zoning", name, user, userOrganizationName);
    dispatch(datasetFoldersActions.editZoning(zoningItemId, name, description));
  };

  const onDeleteCustomZoning = (itemId: UniqueIdentifier) => {
    addCustomGAEvent("custom_zoning", "delete_custom_zoning", String(itemId), user, userOrganizationName);
    dispatch(datasetFoldersActions.deleteCustomZoning(itemId));
  };

  const onDeleteCatalogDocument = (itemId: UniqueIdentifier) => {
    addCustomGAEvent("screenlines", "delete_screenlines", String(itemId), user, userOrganizationName);
    dispatch(datasetFoldersActions.deleteConfigDocument(itemId));
  };

  const onEditCatalogDocument = (itemId: string, config: ConfigDocumentUpdateRequest) => {
    addCustomGAEvent("screenlines", "edit_screenlines", String(itemId), user, userOrganizationName);
    dispatch(datasetFoldersActions.editConfigDocument(itemId, config));
  };

  const handleAddColumn = () => {
    setIsShowNewFolderDialog(true);
  };

  const handleAddNewFolderSubmit = (folderName: string) => {
    addCustomGAEvent("datasets", "add_dataset_folder", "dataset_folders", user, userOrganizationName);
    dispatch(datasetFoldersActions.createDatasetFolder(folderName));
  };

  const handleCloseAddNewFolderDialog = () => {
    dispatch(datasetFoldersActions.clearErrorMessage());
    setIsShowNewFolderDialog(false);
  };

  const foldersData = folders.data;

  return (
    <PageContainer>
      <aside />
      <div>
        <DatasetsPageHeader>
          <h1>Datasets</h1>
          <Button
            startIcon={<CreateNewFolder />}
            size="medium"
            variant="outlined"
            disabled={!Boolean(foldersData?.permissions?.create.allow)}
            onClick={handleAddColumn}
          >
            New Folder
          </Button>
        </DatasetsPageHeader>
        {foldersData && (
          <DatasetContainers
            userFolders={foldersData.folders}
            loading={loading}
            errorDialogMessage={errorDialogMessage}
            onRenameFolder={onRenameFolder}
            onDeleteFolder={onDeleteFolder}
            onChangeFolderIndex={onChangeFolderIndex}
            onAddDataset={onAddDataset}
            onRenameDataset={onRenameDataset}
            onDeleteDataset={onDeleteDataset}
            onChangeDatasetIndex={onChangeDatasetIndex}
            onCopyDataset={onCopyDataset}
            onEditZoning={onEditZoning}
            onDeleteCustomZoning={onDeleteCustomZoning}
            onDeleteCatalogDocument={onDeleteCatalogDocument}
            onEditCatalogDocument={onEditCatalogDocument}
          />
        )}
        {isShowNewFolderDialog && (
          <FolderDatasetDialog
            open
            title="New folder"
            buttonLabel="Add"
            description="Add a new folder for convenient grouping of datasets"
            fieldLabel="Folder name"
            errorMessage={errorDialogMessage}
            onSubmit={handleAddNewFolderSubmit}
            onClose={handleCloseAddNewFolderDialog}
          />
        )}
      </div>
      <aside />
    </PageContainer>
  );
};
