import { DemandSelectedArea } from "api/analytics/publications";
import memoryStore, { MemoryStoreKeys } from "api/memoryStore";
import scaleCluster from "d3-scale-cluster";
import mapboxgl, { MapLayerMouseEvent, Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, SetStateAction } from "react";

import { sequentialSchemes } from "features/map/layerColors";
import { closeAllMapboxPopups, getPopupQueryType, getSqMiDensity } from "features/map/utils";

import { ODPopupProps } from "components";

import { Counts, ODTileLayer, QueryType } from "types";

import { ZONES_LAYER_FILL, ZONE_BORDERS_LAYER } from "./layers";
import { OUT_ZONES_SOURCE_ID } from "./sources";

export const getDemandZoneCountsHandlers = (
  map: mapboxgl.Map,
  tileService: ODTileLayer,
  counts: MutableRefObject<Counts>,
  selectedZone: MutableRefObject<DemandSelectedArea | null>,
  queryType: MutableRefObject<QueryType>,
  colorScheme: MutableRefObject<string>,
  ODPopupRef: React.RefObject<HTMLDivElement>,
  mapboxODZoneHoverPopupRef: MutableRefObject<mapboxgl.Popup | null>,
  setODPopupProps: React.RefObject<Dispatch<SetStateAction<ODPopupProps | null>>>,
  showZoneCountsRef: MutableRefObject<boolean>,
  setSelectedZone: (selectedZone: DemandSelectedArea | null) => void,
  setColorScale: (colorScale: any) => void,
) => {
  let hoveredZoneId: number | null = null;
  let clickedZoneId: number | null = (selectedZone.current?.id as unknown as number) ?? null;

  const getColorScale = (layerIDs: Map<number, number>) => {
    const sqmi: number[] = [];
    const zoneCounts = memoryStore.getItem(MemoryStoreKeys.DEMAND_ZONE_COUNTS) || new Map();

    for (const [zoneId, sqKm] of layerIDs) {
      sqmi.push(getSqMiDensity(zoneCounts.get(zoneId) ?? 0, sqKm));
    }

    const currentColorScheme = sequentialSchemes[colorScheme.current];
    const scale = scaleCluster().domain(sqmi).range(currentColorScheme);
    return scale;
  };

  const updateFeatureStatesByCounts = ({
    layerIDs,
    scale,
  }: {
    layerIDs: Map<number, number>;
    scale: (n: number) => string;
  }) => {
    if (!counts.current) return;

    const zoneCounts = memoryStore.getItem(MemoryStoreKeys.DEMAND_ZONE_COUNTS) || new Map();

    if (!zoneCounts.size) {
      return;
    }

    for (const [zoneId, sqKm] of layerIDs) {
      const count = zoneCounts.get(zoneId) ?? 0;
      const color = scale(getSqMiDensity(count, sqKm));
      map.setFeatureState(
        {
          source: OUT_ZONES_SOURCE_ID,
          sourceLayer: "default",
          id: zoneId,
        },
        {
          color,
          count,
        },
      );
    }
  };

  const updateODCounts = () => {
    if (!map.getSource(OUT_ZONES_SOURCE_ID)) return;

    const layerIDs = (memoryStore.getItem(MemoryStoreKeys.DEMAND_ZONE_IDS) as Map<number, number>) || new Map();
    const scale = getColorScale(layerIDs);

    setColorScale(scale);
    updateFeatureStatesByCounts({
      layerIDs,
      scale,
    });
  };

  const updateLayersVisibility = () => {
    if (!map) {
      return;
    }

    if (showZoneCountsRef.current && map.getLayer(ZONES_LAYER_FILL) && map.getLayer(ZONE_BORDERS_LAYER)) {
      map.setLayoutProperty(ZONES_LAYER_FILL, "visibility", "visible");
      map.setLayoutProperty(ZONE_BORDERS_LAYER, "visibility", "visible");
    } else if (map.getLayer(ZONES_LAYER_FILL) && map.getLayer(ZONE_BORDERS_LAYER)) {
      map.setLayoutProperty(ZONES_LAYER_FILL, "visibility", "none");
      map.setLayoutProperty(ZONE_BORDERS_LAYER, "visibility", "none");
    }
  };

  const updateODCountsAndLayersVisibility = () => {
    if (!(map as any)?.style) return;

    updateLayersVisibility();
    updateODCounts();
  };

  const handleUpdateODCounts = (event: any) => {
    if (!event.isSourceLoaded) return;

    if (event.sourceId === OUT_ZONES_SOURCE_ID) {
      updateODCounts();
    }
  };

  const handleMousemove = (e: MapLayerMouseEvent) => {
    if (e.features?.length) {
      const feature = e.features[0];
      const featureId = Number(feature.properties?.[tileService.idField] || feature.id);
      const count = memoryStore.getItem(MemoryStoreKeys.DEMAND_ZONE_COUNTS)?.get(featureId) || 0;

      if (hoveredZoneId) {
        map.setFeatureState(
          {
            source: OUT_ZONES_SOURCE_ID,
            sourceLayer: "default",
            id: hoveredZoneId,
          },
          { hover: false },
        );
      }

      hoveredZoneId = featureId;
      map.setFeatureState(
        {
          source: OUT_ZONES_SOURCE_ID,
          sourceLayer: "default",
          id: featureId,
        },
        { hover: true },
      );

      if (typeof setODPopupProps.current === "function") {
        setODPopupProps.current({
          count,
          countByDensity: getSqMiDensity(count, feature.properties?.area_sqkm),
          type: getPopupQueryType(selectedZone.current?.id, featureId, queryType.current),
        });
      }

      if (mapboxODZoneHoverPopupRef.current) {
        mapboxODZoneHoverPopupRef.current.remove();
      }

      if (ODPopupRef.current) {
        (mapboxODZoneHoverPopupRef.current as mapboxgl.Popup) = new Popup({
          closeButton: false,
          closeOnClick: false,
          offset: 15,
        })
          .setLngLat(e.lngLat)
          .setDOMContent(ODPopupRef.current as Node)
          .addTo(map);
      }
    }
  };

  const handleMouseleave = () => {
    if (hoveredZoneId) {
      map.setFeatureState(
        {
          source: OUT_ZONES_SOURCE_ID,
          sourceLayer: "default",
          id: hoveredZoneId,
        },
        { hover: false },
      );
    }

    if (mapboxODZoneHoverPopupRef.current) {
      mapboxODZoneHoverPopupRef.current.remove();
    }

    hoveredZoneId = null;
  };

  const handleMouseClick = (e: MapLayerMouseEvent) => {
    const layerIDs = Array.from(memoryStore.getItem(MemoryStoreKeys.DEMAND_ZONE_IDS)?.keys() || []);
    const mergedIds = new Set([...layerIDs]);

    if (e.features?.length) {
      const feature = e.features[0];
      const featureId = Number(feature.properties?.[tileService.idField] || feature.id);
      const isZoneExists = mergedIds.has(featureId);

      if (clickedZoneId) {
        map.setFeatureState(
          {
            source: OUT_ZONES_SOURCE_ID,
            sourceLayer: "default",
            id: clickedZoneId,
          },
          { click: false },
        );
      }

      if (isZoneExists && featureId !== selectedZone.current?.id) {
        if (featureId) {
          clickedZoneId = featureId;
          map.setFeatureState(
            {
              source: OUT_ZONES_SOURCE_ID,
              sourceLayer: "default",
              id: featureId,
            },
            { click: true },
          );

          setSelectedZone({
            id: featureId,
            itemType: "zone",
          });
        }
      } else if (isZoneExists && featureId === selectedZone.current?.id) {
        clickedZoneId = null;
        setSelectedZone(null);
      }
    }

    if (mapboxODZoneHoverPopupRef.current) {
      mapboxODZoneHoverPopupRef.current.remove();
    }
  };

  map.on("sourcedata", handleUpdateODCounts);
  map.on("mousemove", [ZONES_LAYER_FILL], handleMousemove);
  map.on("mouseleave", [ZONES_LAYER_FILL], handleMouseleave);
  map.on("click", [ZONES_LAYER_FILL], handleMouseClick);

  return {
    updateODCountsAndLayersVisibility,
    cleanDemandZoneCountsHandlers: () => {
      closeAllMapboxPopups(map);

      if (ODPopupRef.current) {
        ODPopupRef.current.remove();
      }

      map.off("sourcedata", handleUpdateODCounts);
      map.off("mousemove", [ZONES_LAYER_FILL], handleMousemove);
      map.off("mouseleave", [ZONES_LAYER_FILL], handleMouseleave);
      map.off("click", [ZONES_LAYER_FILL], handleMouseClick);
    },
  };
};
