import { Box, Divider, FormControlLabel, Stack, Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";

import { RoadClassSelector } from "components";

import { useAppSelector } from "hooks";

import { RoadClass } from "types";

import { StyledCheckbox } from "./ScreenlineEditor";

export interface ImportOptionsTabProps {
  selectedRoadClasses: RoadClass[] | null;
  setSelectedRoadClasses: Dispatch<SetStateAction<RoadClass[] | null>>;
  appendToExistingScreenlines: boolean;
  setAppendToExistingScreenlines: Dispatch<SetStateAction<boolean>>;
  ignoreDisjointFeatures: boolean;
  setIgnoreDisjointFeatures: Dispatch<SetStateAction<boolean>>;
}

export const ImportOptionsTab: FC<ImportOptionsTabProps> = ({
  selectedRoadClasses,
  setSelectedRoadClasses,
  appendToExistingScreenlines,
  setAppendToExistingScreenlines,
  ignoreDisjointFeatures,
  setIgnoreDisjointFeatures,
}) => {
  const roadClasses = useAppSelector((state) => state.analytics.roadsMetadata.data?.roadClasses);
  const roadCategories = useAppSelector((state) => state.analytics.roadsMetadata.data?.roadCategories);
  const savedRoadClasses = useAppSelector((state) => state.filters.roadClasses);
  const screenlines = useAppSelector((state) => state.screenlines.screenlines);
  const disjointFeaturesCount = useAppSelector(
    (state) => state.screenlines.uploadedScreenlines.data?.disjointCount || 0,
  );

  return (
    <Stack paddingTop={3} rowGap={0.5} divider={<Divider />}>
      <Box>
        <RoadClassSelector
          roadClasses={roadClasses}
          roadCategories={roadCategories}
          selectedRoadClasses={selectedRoadClasses}
          savedRoadClasses={savedRoadClasses}
          setSelectedRoadClasses={setSelectedRoadClasses}
        />
        <Typography fontSize={11} marginTop={0.5}>
          {selectedRoadClasses?.length === 0 ? (
            <>
              Imported screenlines will not include any intersections since no road classes are selected. Screenline
              intersections can later be added.
            </>
          ) : (
            <>
              Imported screenlines will include intersections with the selected{" "}
              <Typography fontSize={11} component={"span"} fontWeight={700}>
                {`${selectedRoadClasses?.length || 0} of ${roadClasses?.length || 0} road classes`}
              </Typography>
              . Screenline intersections can later be edited.
            </>
          )}
        </Typography>
      </Box>
      {screenlines.length > 0 && (
        <FormControlLabel
          control={
            <StyledCheckbox
              sx={{ padding: 1 }}
              checked={appendToExistingScreenlines}
              onChange={(e) => setAppendToExistingScreenlines(e.target.checked)}
            />
          }
          label="Append to existing screenlines"
          slotProps={{ typography: { fontSize: 11, fontWeight: 600 } }}
        />
      )}
      {disjointFeaturesCount > 0 && (
        <FormControlLabel
          control={
            <StyledCheckbox
              sx={{ padding: 1 }}
              checked={ignoreDisjointFeatures}
              onChange={(e) => setIgnoreDisjointFeatures(e.target.checked)}
            />
          }
          label="Ignore screenlines fully outside of the selected area of interest"
          slotProps={{ typography: { fontSize: 11, fontWeight: 600 } }}
        />
      )}
    </Stack>
  );
};
