import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { styled } from "@mui/material";
import { useAuth } from "ImsAuthorization";
import { CircularProgress } from "components_new";
import { LngLatLike, Marker } from "mapbox-gl";
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { getFiltersByMeasure } from "features/filters/Filters";
import { buildFilters, filtersTypeFromFilterArguments, getCurrentMeasure } from "features/filters/utils";
import { ModuleData } from "features/map/ModuleManager";
import { EmptyResultWarning, EmptyResultWarningType } from "features/select-link/EmptyResultWarning";
import { SegmentMarker } from "features/select-link/types";
import { selectColorOrGenerate } from "features/select-link/utils";

import { RightSidebar, ToggleButtons } from "components";

import {
  addSegmentToGroups,
  areConfigsEqual,
  deleteSegmentFromGroup,
  getMaxSegmentsGroupId,
  getSegments,
  getSegmentsFromToIds,
  getSegmentsIds,
  initConfig,
  segmentExistsInGroups,
  toSelectLinkConfigEssentials,
} from "components/pages/analytics/select-link/utils";

import { useAppDispatch, useAppSelector, usePrompt } from "hooks";

import { DataState } from "store/interfaces";
import { filtersActions } from "store/sections/filters";
import { selectLinkActions } from "store/sections/selectLink";

import {
  FiltersType,
  FocusAreaItem,
  SegmentsGroup,
  SelectLinkAnalysisOptions,
  SelectLinkConfigEssentials,
  SelectLinkMode,
  SelectLinkPredicateLogic,
  SelectedArea,
  SelectedSegmentConfig,
  SelectedVolume,
  ZoneSelectionMode,
} from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";
import { getSelectedSegmentConfig } from "utils/ui";

import { MapController, PERMANENT_HIGHLIGHTED_FEATURE } from "./MapController";
import { SelectLinkConfigSettings } from "./SelectLinkConfigSettings";
import { SelectLinkLinksConfigPanel } from "./SelectLinkLinksConfigPanel";
import { SelectLinkZonesConfigPanel } from "./SelectLinkZonesConfigPanel";

interface SelectLinkModuleComponentProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  selectLinkMode: SelectLinkMode;
  selectLinkConfigLinksModuleData: ModuleData | null;
  isExportPermitted: boolean;
  selectLinkConfigZonesModuleData: ModuleData | null;
  setSelectLinkConfigLinksModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  setSelectLinkConfigZonesModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  setSelectLinkResultsModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  updateODModeCounts: MutableRefObject<(() => void) | null>;
  updateRoadsModeCounts: MutableRefObject<(() => void) | null>;
  deleteSelectedZone: (zone: SelectedArea) => void;
  setSelectLinkMode: Dispatch<SetStateAction<SelectLinkMode>>;
}

const SegmentsContainer = styled("div")`
  display: flex;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
  height: calc(100% - 169px);
  width: 100%;
`;

const SelectLinkModuleComponent = ({
  map,
  layerManagerRef,
  selectLinkMode,
  selectLinkConfigLinksModuleData,
  isExportPermitted,
  selectLinkConfigZonesModuleData,
  setSelectLinkConfigLinksModuleData,
  setSelectLinkConfigZonesModuleData,
  setSelectLinkResultsModuleData,
  updateODModeCounts,
  updateRoadsModeCounts,
  deleteSelectedZone,
  setSelectLinkMode,
}: SelectLinkModuleComponentProps) => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);
  const currentRoadFilters = useAppSelector((state) => state.filters.roadFilters);
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const segmentsIdToIdxMap = useAppSelector((state) => state.analytics.selectLinkSegmentsIdToIdxMap);

  const selectLinkSegmentCounts = useAppSelector((state) => state.selectLink.selectLinkSegmentCounts);

  // holds select link configuration after the last `Run Analysis` button click
  const resultsSelectLinkConfig = useAppSelector((state) => state.selectLink.resultsSelectLinkConfig);

  // holds select link confguration after the last `Save` button click
  const lastSavedSelectLinkConfig = useAppSelector((state) => state.selectLink.savedSelectLinkConfig);

  // holds select link configuration that is being edited by the user
  const currentSelectLinkConfig = useAppSelector((state) => state.selectLink.currentSelectLinkConfig);

  // Measures
  const measure = useAppSelector((state) => state.filters.measure);
  const selectLinkMeasures = useAppSelector((state) => state.analytics.selectLinkMetadata.data?.measures);
  const currentRoadMeasure = useMemo(
    () => getCurrentMeasure(selectLinkMeasures, measure),
    [selectLinkMeasures, measure],
  );

  // Config
  const [selectLinkOptions, setSelectLinkOptions] = useState<SelectLinkAnalysisOptions>({
    minVolume: currentSelectLinkConfig?.minCount ?? selectLinkConfigLinksModuleData?.data.minAllowedVolume,
  });

  // Zones
  const [selectedOrigins, setSelectedOrigins] = useState<SelectedArea[]>(currentSelectLinkConfig?.origins ?? []);
  const [selectedDestinations, setSelectedDestinations] = useState<SelectedArea[]>(
    currentSelectLinkConfig?.destinations ?? [],
  );
  const [selectedZone, setSelectedZone] = useState<SelectedArea | null>(null);

  // Links
  const [activeGroupId, setActiveGroupId] = useState<string>("");
  const [selectedLinkGroups, setSelectedLinkGroups] = useState<SegmentsGroup[]>(
    currentSelectLinkConfig?.segmentsGroups ?? [],
  );
  const [maxSegmentsGroupId, setMaxSegmentsGroupId] = useState<number>(
    currentSelectLinkConfig?.segmentsGroups ? getMaxSegmentsGroupId(currentSelectLinkConfig.segmentsGroups) : 0,
  );
  const [selectLinkPredicateLogic, setSelectLinkPredicateLogic] = useState<SelectLinkPredicateLogic>(
    currentSelectLinkConfig?.segmentsGroupsOp ?? SelectLinkPredicateLogic.And,
  );
  const [selectLinkMarkers, setSelectLinkMarkers] = useState<SegmentMarker[]>([]);

  const [isEmptyResultWarningOpen, setIsEmptyResultWarningOpen] = useState(false);
  const [isErrorWarningOpen, setIsErrorWarningOpen] = useState(false);

  // Revert
  const [isRevert, setIsRevert] = useState(false);
  const [segmentIdsToRemove, setSegmentIdsToRemove] = useState<string[]>([]);
  const [originsToRemove, setOriginsToRemove] = useState<SelectedArea[]>([]);
  const [destinationsToRemove, setDestinationsToRemove] = useState<SelectedArea[]>([]);

  const areSelectLinkResultsAvailable = useMemo(() => {
    return selectLinkSegmentCounts.state === DataState.AVAILABLE;
  }, [selectLinkSegmentCounts.state]);

  const areCurrentConfigChanges = useCallback(
    (refConfig: SelectLinkConfigEssentials) => {
      if (selectLinkOptions.minVolume) {
        const currentConfig = {
          segmentsGroups: selectedLinkGroups,
          segmentsGroupsOp: selectLinkPredicateLogic,
          origins: selectedOrigins,
          destinations: selectedDestinations,
          minCount: selectLinkOptions.minVolume,
          roadFilters: buildFilters(currentRoadFilters),
        } as SelectLinkConfigEssentials;
        return !areConfigsEqual(currentConfig, refConfig);
      }
      return false;
    },
    [
      selectedLinkGroups,
      selectedOrigins,
      selectedDestinations,
      selectLinkPredicateLogic,
      selectLinkOptions.minVolume,
      currentRoadFilters,
    ],
  );

  // are there any changes since the last Save button click
  const areConfigChangesSinceSave = useMemo(() => {
    if (lastSavedSelectLinkConfig.data) {
      return areCurrentConfigChanges(toSelectLinkConfigEssentials(lastSavedSelectLinkConfig.data));
    }

    return false;
  }, [areCurrentConfigChanges, lastSavedSelectLinkConfig.data]);

  // are there any changes since the last Run Analysis button click
  const areConfigChangesSinceRunAnalysis = useMemo(() => {
    if (resultsSelectLinkConfig) {
      const result = areCurrentConfigChanges(resultsSelectLinkConfig);
      return result;
    }
    return false;
  }, [areCurrentConfigChanges, resultsSelectLinkConfig]);

  usePrompt("Changes that you made may not be saved.", areConfigChangesSinceSave);

  const areSelectLinkResultsEmpty = useMemo(() => {
    return (
      Boolean(selectLinkSegmentCounts?.data) &&
      (selectLinkSegmentCounts?.data?.size || 0) === 0 &&
      selectLinkMode === SelectLinkMode.RESULTS
    );
  }, [selectLinkMode, selectLinkSegmentCounts.data]);

  const zoneSelectionModeRef = useRef<ZoneSelectionMode>(ZoneSelectionMode.Origins);

  const selectedOriginsRef = useRef<SelectedArea[]>([]);
  const selectedDestinationsRef = useRef<SelectedArea[]>([]);

  const updateFeatureStateForRoadsRef = useRef<
    ((segmentIdx: number | null, stateName: string, status?: boolean | undefined) => void) | null
  >(null);

  const updateFeatureStateForZoneRef = useRef<
    ((zone: SelectedArea, zoneMode: string, stateName: string, status?: boolean) => void) | null
  >(null);

  const updateSelectLinkConfiguration = useRef<(update: SelectLinkConfigEssentials) => void>(() => {});

  const getSelectLinkSegmentCounts = useRef<
    (
      filters: FiltersType,
      selectedFocusArea: FocusAreaItem,
      segmentGroups: SegmentsGroup[],
      origins: SelectedArea[],
      destinations: SelectedArea[],
      predicateLogic: string,
      { minVolume }: SelectLinkAnalysisOptions,
    ) => void
  >(() => {});

  const createNewSegmentMarker = useCallback(
    (segmentId: string, lon: number, lat: number, color?: string) => {
      const marker = new Marker({ scale: 0.6, offset: [0, 0], color: color });

      marker.setLngLat([lon, lat]).addTo(map.current!);

      return {
        segmentId,
        marker,
      };
    },
    [map],
  );

  // Syncs feature state of the map for the currently selected segments and cleans the state for the segments that were removed
  const syncSelectedSegmentsState = useCallback(
    (selectedLinkGroups: SegmentsGroup[], segmentIdsToRemove: string[], stateName: string) => {
      const segmentFromToIds = getSegmentsFromToIds(selectedLinkGroups) as Set<string>;

      // Update feature state for all segments
      segmentFromToIds.forEach((segmentFromToId) => {
        const segmentIdx = segmentsIdToIdxMap.data?.get(segmentFromToId);

        if (updateFeatureStateForRoadsRef.current && segmentIdx) {
          updateFeatureStateForRoadsRef.current(segmentIdx, stateName, true);
        }
      });

      // Remove state from segments that are no longer in the groups
      segmentIdsToRemove.forEach((segmentFromToId) => {
        const segmentIdx = segmentsIdToIdxMap.data?.get(segmentFromToId);

        if (updateFeatureStateForRoadsRef.current && !segmentFromToIds.has(segmentFromToId) && segmentIdx) {
          updateFeatureStateForRoadsRef.current(segmentIdx, stateName, false);
        }
      });
    },
    [segmentsIdToIdxMap],
  );

  // Syncs feature state of the map for the currently selected zones and cleans the state for the zones that were removed
  const syncSelectedZonesState = useCallback(
    (zonesToDisplay: SelectedArea[], zonesToRemove: SelectedArea[], zoneMode: string, stateName: string) => {
      // Update feature state for all zones in all layers
      zonesToDisplay.forEach((zone) => {
        if (updateFeatureStateForZoneRef.current) {
          updateFeatureStateForZoneRef.current(zone, zoneMode, stateName, true);
        }
      });

      // Remove state from zones that are no longer in the list, in all layers
      zonesToRemove.forEach((zone) => {
        if (updateFeatureStateForZoneRef.current) {
          updateFeatureStateForZoneRef.current(zone, zoneMode, stateName, false);
        }
      });
    },
    [],
  );

  const syncMarkersWithSegments = useCallback(
    (selectedLinkGroups: SegmentsGroup[]) => {
      const currentMarkers = new Set(selectLinkMarkers.map((marker) => marker.segmentId));
      const segmentsInGroups = getSegmentsIds(selectedLinkGroups);

      // Add missing markers
      selectedLinkGroups.forEach((group) => {
        group.segments.forEach((segment) => {
          if (!currentMarkers.has(segment.segmentId)) {
            const newMarker = createNewSegmentMarker(segment.segmentId, segment.lon, segment.lat, group.color);
            setSelectLinkMarkers((prevMarkers) => [...prevMarkers, newMarker]);
          }
        });
      });

      // Remove markers that are no longer in the groups
      setSelectLinkMarkers((prevMarkers) =>
        prevMarkers.filter((marker) => {
          if (!segmentsInGroups.has(marker.segmentId)) {
            marker.marker.remove();
            return false;
          }
          return true;
        }),
      );
    },
    [selectLinkMarkers, createNewSegmentMarker],
  );

  const createMarkerAndAddToMap = useCallback(
    (segmentConfig: SelectedSegmentConfig, segmentGroups: SegmentsGroup[]) => {
      const activeSegmentsGroup = activeGroupId;

      if (!activeSegmentsGroup) return;

      const group = segmentGroups.find((group) => group.groupName === activeSegmentsGroup);
      const { segmentId, lon, lat } = segmentConfig;
      const segmentMarker = createNewSegmentMarker(segmentId, lon, lat, group?.color);

      selectLinkMarkers.push(segmentMarker);
      setSelectLinkMarkers(selectLinkMarkers);
    },
    [activeGroupId, selectLinkMarkers, createNewSegmentMarker],
  );

  /**
   * Finds the marker to delete by its coordinates and removes it from the state and from the map, if it exists.
   */
  const deleteMarkerForSegmentAndRemoveFromMap = useCallback(
    (
      markers: SegmentMarker[],
      segment: SelectedSegmentConfig,
      setMarkers: (markers: SegmentMarker[]) => void,
    ): void => {
      const newMarkers = markers.filter(({ segmentId, marker }) => {
        if (segmentId === segment.segmentId) marker.remove();

        return segmentId !== segment.segmentId;
      });
      setMarkers(newMarkers);
    },
    [],
  );

  const handleAddNewSegmentsGroup = useCallback(
    (group: SegmentsGroup, id: number) => {
      const newGroups = [...selectedLinkGroups, group];
      setSelectedLinkGroups(newGroups);
      setActiveGroupId(group.groupName);
      setMaxSegmentsGroupId(id);
    },
    [selectedLinkGroups, setSelectedLinkGroups, setMaxSegmentsGroupId],
  );

  useEffect(() => {
    if (selectLinkConfigLinksModuleData) {
      const segmentsInGroups = getSegmentsIds(selectedLinkGroups);

      if (segmentsInGroups.size !== selectLinkMarkers.length) {
        syncMarkersWithSegments(selectedLinkGroups);
      }
    }
  }, [selectLinkConfigLinksModuleData, selectLinkMarkers, selectedLinkGroups, syncMarkersWithSegments]);

  useEffect(() => {
    if (areSelectLinkResultsEmpty) {
      setIsEmptyResultWarningOpen(true);
    }
  }, [areSelectLinkResultsEmpty]);

  useEffect(() => {
    if (selectLinkSegmentCounts.state === DataState.ERROR || lastSavedSelectLinkConfig.state === DataState.ERROR) {
      setIsErrorWarningOpen(true);

      if (selectLinkMode === SelectLinkMode.RESULTS) {
        setSelectLinkMode(SelectLinkMode.LINKS);
      }
    }
  }, [selectLinkSegmentCounts.state, lastSavedSelectLinkConfig.state, selectLinkMode, setSelectLinkMode]);

  // Create a new select link group if none exists
  useEffect(() => {
    if (
      lastSavedSelectLinkConfig.data &&
      !lastSavedSelectLinkConfig.data?.segmentsGroups?.length &&
      !selectedLinkGroups.length
    ) {
      const id = maxSegmentsGroupId + 1;
      const newGroup = {
        groupName: `${id}`,
        segments: [],
        color: selectColorOrGenerate(new Set(selectedLinkGroups.map(({ color }) => color))),
      };

      handleAddNewSegmentsGroup(newGroup, id);
    }
  }, [lastSavedSelectLinkConfig.data, selectedLinkGroups, maxSegmentsGroupId, handleAddNewSegmentsGroup]);

  // Update select link export button state based on the availability of results and pending config changes
  useEffect(() => {
    dispatch(
      selectLinkActions.setSelectLinkExportDisabled(
        !isExportPermitted ||
          !areSelectLinkResultsAvailable ||
          areSelectLinkResultsEmpty ||
          areConfigChangesSinceRunAnalysis,
      ),
    );
  }, [
    areSelectLinkResultsAvailable,
    areSelectLinkResultsEmpty,
    areConfigChangesSinceRunAnalysis,
    areConfigChangesSinceSave,
    isExportPermitted,
    dispatch,
  ]);

  // Update current select link config as user edits it
  useEffect(() => {
    dispatch(
      selectLinkActions.updateCurrentSelectLinkConfig({
        segmentsGroups: selectedLinkGroups,
        segmentsGroupsOp: selectLinkPredicateLogic,
        origins: selectedOrigins,
        destinations: selectedDestinations,
        minCount: selectLinkOptions.minVolume,
        roadFilters: buildFilters(currentRoadFilters),
      }),
    );
  }, [
    dispatch,
    selectedLinkGroups,
    selectLinkPredicateLogic,
    selectedOrigins,
    selectedDestinations,
    selectLinkOptions.minVolume,
    currentRoadFilters,
  ]);

  // Update resulting select link config when new select link results are available
  useEffect(() => {
    if (areSelectLinkResultsAvailable && selectLinkMode === SelectLinkMode.RESULTS && !isRevert) {
      dispatch(
        selectLinkActions.updateResultsSelectLinkConfig({
          segmentsGroups: selectedLinkGroups,
          segmentsGroupsOp: selectLinkPredicateLogic,
          origins: selectedOrigins,
          destinations: selectedDestinations,
          minCount: selectLinkOptions.minVolume,
          roadFilters: buildFilters(currentRoadFilters),
        }),
      );
    }
  }, [
    dispatch,
    isRevert,
    areSelectLinkResultsAvailable,
    selectLinkMode,
    selectedLinkGroups,
    selectLinkPredicateLogic,
    selectedOrigins,
    selectedDestinations,
    selectLinkOptions.minVolume,
    currentRoadFilters,
  ]);

  /**
   * This callback zooms onto the selected link segment when it is being clicked on the right side config pane.
   */
  const handleZoomOnSegment = useCallback(
    (lngLat: LngLatLike, zoom: number = 14) => {
      if (map.current) {
        map.current.flyTo({
          center: lngLat,
          zoom,
        });
      }
    },
    [map],
  );

  const updateMapOnDeleteSelectedLink = useCallback(
    (segment: SelectedSegmentConfig) => {
      // switch off permanent highlight feature and delete the marker for both roads and select link layers
      if (updateFeatureStateForRoadsRef.current) {
        const segmentFromToId = segment.fromToId;
        const segmentsFromToIds = getSegmentsFromToIds(selectedLinkGroups, true) as string[];
        const segmentIdx = segmentsIdToIdxMap.data?.get(segmentFromToId);

        if (segmentsFromToIds.filter((id) => id === segmentFromToId).length === 1 && segmentIdx) {
          updateFeatureStateForRoadsRef.current(segmentIdx, "selectHighlight", false);
          updateFeatureStateForRoadsRef.current(segmentIdx, PERMANENT_HIGHLIGHTED_FEATURE, false);
        }
      }
      deleteMarkerForSegmentAndRemoveFromMap(selectLinkMarkers, segment, setSelectLinkMarkers);
    },
    [
      updateFeatureStateForRoadsRef,
      selectLinkMarkers,
      selectedLinkGroups,
      segmentsIdToIdxMap,
      deleteMarkerForSegmentAndRemoveFromMap,
    ],
  );

  /**
   * This callback handles deletion of selected link segment in the right config pane:
   * - deletes the segments from the state
   * - deletes the permanently highlighted feature from the feature state (if both directional segments were removed from the list)
   * - deletes the marker from the map adn the state (if both directional segments were removed from the list)
   */
  const handleDeleteSelectedLink = useCallback(
    (segment: SelectedSegmentConfig, segmentsGroupId: string) => {
      setSelectedLinkGroups(deleteSegmentFromGroup(selectedLinkGroups, segmentsGroupId, segment));
      updateMapOnDeleteSelectedLink(segment);
    },
    [selectedLinkGroups, updateMapOnDeleteSelectedLink],
  );

  const addSegmentFromPopup = useCallback(
    (selectedVolume: SelectedVolume, selectedRoadSegmentId: string) => {
      if (activeGroupId === "") return;

      const directionalSelectedVolume = getSelectedSegmentConfig(selectedVolume, selectedRoadSegmentId);

      // do this only if segment hasn't been already added to the list, otherwise ignore
      if (selectedRoadSegmentId && !segmentExistsInGroups(selectedLinkGroups, directionalSelectedVolume)) {
        const newGroups = addSegmentToGroups(selectedLinkGroups, activeGroupId, directionalSelectedVolume);

        setSelectedLinkGroups(newGroups);

        if (segmentsIdToIdxMap.data) {
          const fromToSelectedVolumeIdx = segmentsIdToIdxMap.data.get(directionalSelectedVolume.fromToId);

          // add permanently highlighted feature for selected links (always displayed on the map)
          if (updateFeatureStateForRoadsRef.current && fromToSelectedVolumeIdx) {
            updateFeatureStateForRoadsRef.current(fromToSelectedVolumeIdx, PERMANENT_HIGHLIGHTED_FEATURE, true);
          }
        }

        // add permanent marker to map and state
        createMarkerAndAddToMap(directionalSelectedVolume, selectedLinkGroups);
      }
    },
    [
      activeGroupId,
      selectedLinkGroups,
      updateFeatureStateForRoadsRef,
      segmentsIdToIdxMap.data,
      createMarkerAndAddToMap,
    ],
  );

  const loadingResults = selectLinkSegmentCounts.state === DataState.LOADING;
  const savingConfig = lastSavedSelectLinkConfig.state === DataState.LOADING;

  const minAllowedVolume =
    selectLinkConfigLinksModuleData?.data.minAllowedVolume ??
    selectLinkConfigZonesModuleData?.data.minAllowedVolume ??
    5; //TODO: default value should be taken from metadata

  const canRunAnalysis = useMemo(
    () => getSegments(selectedLinkGroups).length > 0 || selectedOrigins.length > 0 || selectedDestinations.length > 0,
    [selectedLinkGroups, selectedOrigins, selectedDestinations],
  );

  const handleChangeZoneSelectionMode = (newMode: ZoneSelectionMode) => {
    zoneSelectionModeRef.current = newMode;
  };

  const handleChangeActiveSegmentsGroup = (groupId: string) => {
    setActiveGroupId(groupId);
  };

  const handleDeleteSegmentsGroup = (groupId: string) => {
    const group = selectedLinkGroups.find((group) => group.groupName === groupId);
    const segments = new Set<string>();
    group?.segments.forEach((segment) => {
      updateMapOnDeleteSelectedLink(segment);
      segments.add(segment.segmentId);
    });
    setSelectLinkMarkers(selectLinkMarkers.filter(({ segmentId }) => !segments.has(segmentId)));
    setSelectedLinkGroups(selectedLinkGroups.filter((group) => group.groupName !== groupId));
  };

  const handleChangePredicateLogic = (predicateLogic: SelectLinkPredicateLogic) => {
    setSelectLinkPredicateLogic(predicateLogic);
  };

  const handleChangeOptions = (options: SelectLinkAnalysisOptions) => {
    setSelectLinkOptions(options);
  };

  const handleSaveSelectLinkConfiguration = (options: SelectLinkAnalysisOptions) => {
    if (options.minVolume >= minAllowedVolume) {
      updateSelectLinkConfiguration.current({
        segmentsGroups: selectedLinkGroups,
        segmentsGroupsOp: selectLinkPredicateLogic,
        origins: selectedOrigins,
        destinations: selectedDestinations,
        minCount: options.minVolume,
        roadFilters: buildFilters(currentRoadFilters),
      });
    }
  };

  const handleRevertSelectLinkConfiguration = useCallback(() => {
    if (lastSavedSelectLinkConfig.data) {
      const { segmentsGroups, segmentsGroupsOp, origins, destinations, minCount, roadFilters } = initConfig(
        lastSavedSelectLinkConfig.data,
      );

      const savedSegmentIds = getSegmentsFromToIds(segmentsGroups) as Set<string>;
      const segmentIdsToRemove = Array.from(getSegmentsFromToIds(selectedLinkGroups)).filter(
        (segmentId) => !savedSegmentIds.has(segmentId),
      );

      const savedOriginIds = new Set(origins.map(({ id }) => id));
      const originsToRemove = selectedOrigins.filter(({ id }) => !savedOriginIds.has(id));

      const savedDestinationIds = new Set(destinations.map(({ id }) => id));
      const destinationsToRemove = selectedDestinations.filter(({ id }) => !savedDestinationIds.has(id));

      setSelectedLinkGroups(segmentsGroups);
      setSelectLinkPredicateLogic(segmentsGroupsOp);
      setSelectedOrigins(origins);
      selectedOriginsRef.current = origins;
      setSelectedDestinations(destinations);
      selectedDestinationsRef.current = destinations;
      setSelectLinkOptions({ minVolume: minCount });

      const maxGroupId = getMaxSegmentsGroupId(segmentsGroups);
      setMaxSegmentsGroupId(maxGroupId);
      if (maxGroupId > 0) {
        setActiveGroupId(maxGroupId.toString());
      }

      setSegmentIdsToRemove(segmentIdsToRemove);
      setOriginsToRemove(originsToRemove);
      setDestinationsToRemove(destinationsToRemove);

      if (selectLinkConfigLinksModuleData?.data?.mapboxVolumesPopupRef?.current) {
        selectLinkConfigLinksModuleData.data.mapboxVolumesPopupRef.current.remove();
      }

      if (currentRoadMeasure) {
        const newRoadFilters = getFiltersByMeasure(currentRoadMeasure);

        dispatch(
          filtersActions.setRoadFilters({
            filter: filtersTypeFromFilterArguments(roadFilters ?? {}, newRoadFilters),
          }),
        );
      }

      setIsRevert(true);
    }
  }, [
    currentRoadMeasure,
    lastSavedSelectLinkConfig,
    selectLinkConfigLinksModuleData?.data,
    selectedDestinations,
    selectedLinkGroups,
    selectedOrigins,
    dispatch,
  ]);

  useEffect(() => {
    if (isRevert && lastSavedSelectLinkConfig.data) {
      const { segmentsGroups, origins, destinations } = initConfig(lastSavedSelectLinkConfig.data);

      if (selectLinkMode === SelectLinkMode.LINKS) {
        syncSelectedSegmentsState(segmentsGroups, segmentIdsToRemove, PERMANENT_HIGHLIGHTED_FEATURE);
      }

      if (selectLinkMode !== SelectLinkMode.RESULTS) {
        syncSelectedZonesState(origins, originsToRemove, ZoneSelectionMode.Origins, PERMANENT_HIGHLIGHTED_FEATURE);
        syncSelectedZonesState(
          destinations,
          destinationsToRemove,
          ZoneSelectionMode.Destinations,
          PERMANENT_HIGHLIGHTED_FEATURE,
        );
      }

      if (selectLinkMode === SelectLinkMode.RESULTS) {
        setSelectLinkMode(SelectLinkMode.LINKS);
      }

      syncMarkersWithSegments(segmentsGroups);

      setIsRevert(false);
    }
  }, [
    isRevert,
    selectLinkMode,
    lastSavedSelectLinkConfig.data,
    syncSelectedSegmentsState,
    syncSelectedZonesState,
    segmentIdsToRemove,
    originsToRemove,
    destinationsToRemove,
    syncMarkersWithSegments,
    setSelectLinkMode,
  ]);

  /**
   * Handles click on the "Run Analysis" button in the right pane.
   */
  const handleRunAnalysis = (options: SelectLinkAnalysisOptions) => {
    if (currentRoadFilters && selectedFocusArea) {
      getSelectLinkSegmentCounts.current(
        currentRoadFilters,
        selectedFocusArea,
        selectedLinkGroups,
        selectedOriginsRef.current,
        selectedDestinationsRef.current,
        selectLinkPredicateLogic,
        options,
      );

      addCustomGAEvent("select-link", "analysis", "run-analysis", user, userOrganizationName);

      setSelectLinkMode(SelectLinkMode.RESULTS);
    }
  };

  const handleConfigureAnalysis = () => {
    setSelectLinkMode(SelectLinkMode.LINKS);
  };

  const handleChangeSelectLinkMode = (index: string) => {
    setSelectLinkMode(index === "0" ? SelectLinkMode.LINKS : SelectLinkMode.RESULTS);
  };

  const handleCloseEmptyResultWarning = () => {
    setIsEmptyResultWarningOpen(false);
    setSelectLinkMode(SelectLinkMode.LINKS);
  };

  return (
    <>
      <MapController
        map={map}
        layerManagerRef={layerManagerRef}
        mode={selectLinkMode}
        selectedLinkGroups={selectedLinkGroups}
        selectLinkOptions={selectLinkOptions}
        selectedOriginsRef={selectedOriginsRef}
        selectedDestinationsRef={selectedDestinationsRef}
        selectLinkConfigLinksModuleData={selectLinkConfigLinksModuleData}
        addSegmentFromPopup={addSegmentFromPopup}
        setSelectLinkConfigZonesModuleData={setSelectLinkConfigZonesModuleData}
        setSelectLinkConfigLinksModuleData={setSelectLinkConfigLinksModuleData}
        setSelectLinkResultsModuleData={setSelectLinkResultsModuleData}
        updateRoadsModeCounts={updateRoadsModeCounts}
        updateODModeCounts={updateODModeCounts}
        zoneSelectionModeRef={zoneSelectionModeRef}
        setActiveGroupId={setActiveGroupId}
        setSelectedOrigins={setSelectedOrigins}
        setSelectedDestinations={setSelectedDestinations}
        setSelectedLinkGroups={setSelectedLinkGroups}
        setMaxSegmentsGroupId={setMaxSegmentsGroupId}
        setSelectLinkPredicateLogic={setSelectLinkPredicateLogic}
        setSelectLinkOptions={setSelectLinkOptions}
        updateFeatureStateForRoadsRef={updateFeatureStateForRoadsRef}
        updateFeatureStateForZoneRef={updateFeatureStateForZoneRef}
        updateSelectLinkConfiguration={updateSelectLinkConfiguration}
        getSelectLinkSegmentCounts={getSelectLinkSegmentCounts}
      />
      <ToggleButtons
        leftButtonLabel="Configuration"
        rightButtonLabel="Results"
        activeIndex={selectLinkMode === SelectLinkMode.RESULTS ? "1" : "0"}
        onChangeIndex={handleChangeSelectLinkMode}
        leftButtonDisabled={false}
        rightButtonDisabled={
          !areSelectLinkResultsAvailable || areConfigChangesSinceRunAnalysis || areSelectLinkResultsEmpty
        }
        leftButtonIndex={"0"}
        rightButtonIndex={"1"}
        isMapToggle
      />
      <RightSidebar isOpen animation>
        {selectLinkMode === SelectLinkMode.ZONES ? (
          <SelectLinkZonesConfigPanel
            activeZoneSelectionMode={zoneSelectionModeRef.current}
            selectedOrigins={selectedOrigins}
            selectedDestinations={selectedDestinations}
            selectedZone={selectedZone}
            setSelectedZone={setSelectedZone}
            onChangeZoneSelectionMode={handleChangeZoneSelectionMode}
            deleteSelectedZone={deleteSelectedZone}
          />
        ) : null}
        {selectLinkMode === SelectLinkMode.LINKS || (selectLinkMode === SelectLinkMode.RESULTS && !loadingResults) ? (
          <SelectLinkLinksConfigPanel
            disabled={selectLinkMode === SelectLinkMode.RESULTS || savingConfig}
            activeGroupId={activeGroupId}
            maxGroupId={maxSegmentsGroupId}
            selectedLinkGroups={selectedLinkGroups}
            predicateLogic={selectLinkPredicateLogic}
            setActiveGroupId={setActiveGroupId}
            onAddNewSegmentsGroup={handleAddNewSegmentsGroup}
            onChangeActiveGroup={handleChangeActiveSegmentsGroup}
            onDeleteSegmentsGroup={handleDeleteSegmentsGroup}
            onZoomOnCoordinate={handleZoomOnSegment}
            onDeleteSelectedLink={handleDeleteSelectedLink}
            onChangePredicateLogic={handleChangePredicateLogic}
          />
        ) : null}
        {selectLinkMode === SelectLinkMode.RESULTS && loadingResults && (
          <SegmentsContainer>
            <CircularProgress />
          </SegmentsContainer>
        )}
        <SelectLinkConfigSettings
          loadingResults={loadingResults}
          savingConfig={savingConfig}
          isResults={selectLinkMode === SelectLinkMode.RESULTS}
          minVolume={selectLinkOptions.minVolume}
          minAllowedVolume={minAllowedVolume}
          canRunAnalysis={canRunAnalysis}
          areConfigChangesSinceSave={areConfigChangesSinceSave}
          onChangeOptions={handleChangeOptions}
          onSaveConfiguration={handleSaveSelectLinkConfiguration}
          onRevertConfiguration={handleRevertSelectLinkConfiguration}
          onRunAnalysis={handleRunAnalysis}
          onConfigureAnalysis={handleConfigureAnalysis}
        />
      </RightSidebar>
      {(isEmptyResultWarningOpen || isErrorWarningOpen) && (
        <EmptyResultWarning
          warningType={isEmptyResultWarningOpen ? EmptyResultWarningType.EmptyResult : EmptyResultWarningType.Error}
          onClose={() => (isEmptyResultWarningOpen ? handleCloseEmptyResultWarning() : setIsErrorWarningOpen(false))}
          isOpen
        />
      )}
    </>
  );
};

export default SelectLinkModuleComponent;
