import { isEqual } from "lodash";

import { SegmentMarker } from "features/select-link/types";

import {
  FocusAreaItem,
  SegmentsGroup,
  SelectLinkConfig,
  SelectLinkConfigEssentials,
  SelectLinkPredicateLogic,
  SelectedArea,
  SelectedElementsPredicate,
  SelectedSegmentConfig,
} from "types";

export const deleteSegmentFromGroup = (
  groups: SegmentsGroup[],
  groupId: string,
  segment: SelectedSegmentConfig,
): SegmentsGroup[] => {
  const group = groups.find((group) => group.groupName === groupId);
  if (!group) return groups;
  const newSegments = group?.segments.filter((link) => link.segmentId !== segment.segmentId);
  const newGroup = {
    ...group,
    segments: newSegments,
  };
  const newGroups = [newGroup, ...groups.filter((group) => group.groupName !== groupId)].sort(
    ({ groupName: groupId }) => Number(groupId),
  );
  return newGroups;
};

export const addSegmentToGroups = (
  groups: SegmentsGroup[],
  groupId: string,
  segment: SelectedSegmentConfig,
): SegmentsGroup[] => {
  const group = groups.find((group) => group.groupName === groupId);
  if (!group) return groups;
  const newSegments = [...group.segments, segment];
  const newGroup = {
    ...group,
    segments: newSegments,
  };
  return [...groups.filter((group) => group.groupName !== groupId), newGroup];
};

/**
 * Builds select link request predicate for the segments.
 */
export const buildSegmentsPredicate = (
  selectedLinkGroups: SegmentsGroup[],
  predicateLogic: string,
): SelectedElementsPredicate | null => {
  const nonEmptyGroups = selectedLinkGroups.filter(({ segments }) => segments.length > 0);
  if (nonEmptyGroups.length === 0) return null;
  const isBetweenGroupsLogic = nonEmptyGroups.length > 1; // within a signle group operator shouldn't be reversed
  const isAndLogic = predicateLogic === SelectLinkPredicateLogic.And;
  const clauses = nonEmptyGroups.map((group) => {
    const parts = group.segments.map((link) => {
      return {
        isNot: false,
        elementId: link.segmentId,
      };
    });
    return {
      isAnd: isBetweenGroupsLogic ? !isAndLogic : isAndLogic,
      isNot: false,
      parts,
    };
  });
  return {
    isAnd: isBetweenGroupsLogic ? isAndLogic : !isAndLogic,
    clauses,
  };
};

export const buildZonesPredicate = (selectedZones: SelectedArea[]): SelectedElementsPredicate | null => {
  if (selectedZones.length === 0) return null;
  const clauses = selectedZones.map(({ id: zoneId }) => ({
    isAnd: true,
    isNot: false,
    parts: [
      {
        isNot: false,
        elementId: zoneId,
      },
    ],
  }));
  return {
    isAnd: false,
    clauses,
  };
};

export const segmentExistsInGroups = (groups: SegmentsGroup[], segment: SelectedSegmentConfig): boolean => {
  return groups.some((group) => group.segments.some((link) => link.segmentId === segment.segmentId));
};

/**
 * Finds the marker to delete by its coordinates and removes it from the state and from the map, if it exists.
 */
export const deleteMarkerForSegmentAndRemoveFromMap = (
  markers: SegmentMarker[],
  segment: SelectedSegmentConfig,
  setMarkers: (markers: SegmentMarker[]) => void,
): void => {
  const newMarkers = markers.filter(({ segmentId, marker }) => {
    if (segmentId === segment.segmentId) marker.remove();

    return segmentId !== segment.segmentId;
  });
  setMarkers(newMarkers);
};

export const getFocusArea = (focusAreas: FocusAreaItem[], licensedAreaId: number): FocusAreaItem | undefined => {
  return focusAreas.find(({ id }) => id === licensedAreaId.toString());
};

export const getSegments = (segments: SegmentsGroup[]) => {
  if (!segments) return [];

  return segments.flatMap((group) => group.segments);
};

export const getSegmentsIds = (segments: SegmentsGroup[]) => {
  if (!segments) return new Set();

  return new Set(getSegments(segments).map((segment) => segment.segmentId));
};

export const getSegmentsFromToIds = (segments: SegmentsGroup[], isArray?: boolean) => {
  const segmentsFromToIds = getSegments(segments).map((segment) => segment.fromToId);
  return isArray ? segmentsFromToIds : new Set(getSegments(segments).map((segment) => segment.fromToId));
};

export const getMaxSegmentsGroupId = (segmentsGroups: SegmentsGroup[]) => {
  const maxGroupId =
    segmentsGroups && segmentsGroups.length > 0
      ? Math.max(...segmentsGroups.map(({ groupName }) => (isNaN(parseInt(groupName)) ? 0 : parseInt(groupName))))
      : 0;
  return maxGroupId;
};

// Reduces full select link config from the BE to only essentials (required for export and some other feats)
export const toSelectLinkConfigEssentials = (config: SelectLinkConfig): SelectLinkConfigEssentials => {
  return {
    segmentsGroups: config.segmentsGroups,
    segmentsGroupsOp: config.segmentsGroupsOp,
    origins: config.origins,
    destinations: config.destinations,
    minCount: config.minCount,
    roadFilters: config.roadFilters,
  };
};

export const initConfig = (config: SelectLinkConfigEssentials): SelectLinkConfigEssentials => {
  return {
    ...config,
    segmentsGroups: config.segmentsGroups ?? [],
    origins: config.origins ?? [],
    destinations: config.destinations ?? [],
    segmentsGroupsOp: config.segmentsGroupsOp ?? SelectLinkPredicateLogic.And,
  };
};

const filterEmptyGroups = (config: SelectLinkConfigEssentials): SelectLinkConfigEssentials => {
  return {
    ...config,
    segmentsGroups: config.segmentsGroups?.filter((group) => group.segments.length > 0) || [],
  };
};

const normalizeConfig = (config: SelectLinkConfigEssentials): SelectLinkConfigEssentials => {
  return filterEmptyGroups(initConfig(config));
};

export const areConfigsEqual = (configA: SelectLinkConfigEssentials, configB: SelectLinkConfigEssentials): boolean => {
  return isEqual(normalizeConfig(configA), normalizeConfig(configB));
};
