import { DirectionsCar, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, styled } from "@mui/material";
import { IconButton } from "components_new";
import React, { FC, useState } from "react";

import { sequentialSchemes } from "features/map/layerColors";

import { Card, FlexContainer } from "components";

import { QueryType, ZoningLevel } from "types";

const Container = styled(Card)`
  display: flex;
  width: 280px;
  flex-direction: column;
  position: absolute;
  bottom: 10px;
  left: calc(var(--sidebar-width) + 20px);
  padding: var(--padding-lg);
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
`;

const Header = styled(FlexContainer)`
  justify-content: space-between;
  margin-bottom: 5px;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

const FlexRow = styled(FlexContainer)`
  gap: 0.5rem;
`;

const Description = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5px;
`;

const ExpandedLegend = styled(FlexContainer)`
  gap: 1rem;
`;

const Colors = styled(FlexContainer)`
  & :first-of-type {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }

  & :last-of-type {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

const ExpandedColors = styled(FlexContainer)`
  flex-direction: column;
  gap: 0.5rem;
  & > div {
    border-radius: 8px;
  }
`;

const Classes = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;
`;

const ClassBreak = styled("div")`
  text-align: left;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
`;

const Color = styled("div")((props: { length: number; color: string }) => ({
  width: `${280 / props.length}px`,
  height: "12px",
  backgroundColor: props.color,
}));

type ODLegendProps = {
  colorSchemeName: string;
  zoningLevel?: string | undefined;
  zoningLevels?: ZoningLevel[] | undefined;
  queryType?: QueryType;
  title?: string;
  subtitle: string;
  scale: any;
};

export const ODLegend: FC<ODLegendProps> = ({
  colorSchemeName,
  zoningLevel,
  zoningLevels,
  queryType,
  scale,
  title,
  subtitle,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const colorScheme = sequentialSchemes[colorSchemeName];
  const classes: number[] = scale?.clusters();
  const classBreaks: string[] = (classes ?? []).reduce((arr, classBreak, i) => {
    if (!classes?.[i + 1]) {
      return arr;
    }
    return [...arr, `${classBreak} - ${classes?.[i + 1]}`];
  }, [] as string[]);

  const classBreaksWithBorderValues: string[] = [
    `< ${classes?.[0]}`,
    ...classBreaks,
    `>= ${classes?.[classes?.length - 1]}`,
  ];

  const colorsLength = Math.min(classBreaksWithBorderValues.length, colorScheme.length);

  const handleOpen = () => setOpen(!isOpen);

  return (
    <Container>
      <Header>
        <Description>
          <FlexRow>
            <DirectionsCar fontSize="small" />
            {title ? (
              title
            ) : (
              <>
                {`${queryType === QueryType.INCOMING ? "Incoming" : "Outgoing"} ${
                  queryType === QueryType.OUTGOING ? "from" : "to"
                } ${zoningLevels?.find((l) => l.id === zoningLevel)?.longNameSingular || "zone"}`}
              </>
            )}
          </FlexRow>
          <h5>{subtitle}</h5>
        </Description>
        <IconButton onClick={handleOpen}>
          {isOpen ? <ExpandMore fontSize="small" /> : <ExpandLess fontSize="small" />}
        </IconButton>
      </Header>
      {!isOpen && (
        <Colors>
          {colorScheme.slice(0, colorsLength).map((color) => (
            <Color key={color} color={color} length={colorsLength} />
          ))}
        </Colors>
      )}
      <Collapse in={isOpen && classes !== null && classes?.length > 0}>
        <ExpandedLegend>
          <ExpandedColors>
            {colorScheme.slice(0, colorsLength).map((color) => (
              <Color key={color} color={color} length={colorScheme.length} />
            ))}
          </ExpandedColors>
          <Classes>
            {classBreaksWithBorderValues.map((classBreak) => (
              <ClassBreak key={classBreak}>{classBreak}</ClassBreak>
            ))}
          </Classes>
        </ExpandedLegend>
      </Collapse>
    </Container>
  );
};
