import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { Button } from "components_new";
import React, { FC } from "react";

import { VideoIcon } from "components/atoms/video-icon/VideoIcon";

export interface FeatureHighlightItemInfo {
  event: string;
  title: string;
  description: string;
  highlight?: string;
  videoUrl?: string;
  imageUrl: string;
  tooltip?: string;
  buttonUrl: string;
  buttonText: string;
}

export interface FeatureHighlightItemProps {
  item: FeatureHighlightItemInfo;
  triggerGAEvent: (event: string) => void;
  tryNewFeatureCallback: (item: FeatureHighlightItemInfo) => void;
}

const ComputerWindowDotContainer = styled(Box)`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
`;

const ComputerWindow = styled(Box)`
  width: 70%;
  padding: 0.5rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0) 86.58%);
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0));
  border-radius: 6px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const ComputerWindowContainer = styled(Box)`
  height: 100%;
  :after {
    content: "";
    position: absolute;
    top: 160px;
    left: 0;
    height: 40px;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0), #000);
  }
`;

const ComputerWindowDot = styled(Box)`
  width: 4px;
  height: 4px;
  background: #ffffff;
  border-radius: 30px;
`;

const FeatureHighlightsContent = styled(Box)`
  height: 18rem;
  padding-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 5rem;
  justify-items: center;
  gap: 1rem;
  overflow: hidden; /* let make clear when a feature description text is too big */
  padding-bottom: 1.5rem;
`;

const FeatureHighlightsDetails = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 40px;
`;

const FeatureHighlightsDescription = styled(Box)`
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: flex-end;
`;

const FeatureHighlightsAction = styled(Box)`
  display: flex;
  align-items: flex-end;
`;

const DiagonalLabel = styled.div`
  position: absolute;
  padding: 5px 100px;
  transform: rotate(-45deg) translate(-37%, -50%);
  background: #faa700;
  z-index: 2;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  top: -40px;
  left: -15px;
  width: 18rem;
`;

export const FeatureHighlightItem: FC<FeatureHighlightItemProps> = ({
  item,
  triggerGAEvent,
  tryNewFeatureCallback,
}) => {
  const handleTryNewFeature = (item: FeatureHighlightItemInfo) => () => {
    triggerGAEvent(`try-${item.event}`);
    tryNewFeatureCallback(item);
  };

  return (
    <FeatureHighlightsContent>
      <ComputerWindow>
        <ComputerWindowDotContainer>
          {item.highlight ? <DiagonalLabel> {item.highlight} </DiagonalLabel> : null}
          <ComputerWindowDot />
          <ComputerWindowDot />
          <ComputerWindowDot />
        </ComputerWindowDotContainer>
        <ComputerWindowContainer>
          {item.videoUrl ? (
            <VideoIcon {...item} videoUrl={item.videoUrl} fill="horizontal" triggerGAEvent={triggerGAEvent} />
          ) : (
            <Box
              sx={{ width: "auto", height: "100%", borderRadius: "1rem" }}
              component="img"
              alt={item.title}
              src={item.imageUrl}
            />
          )}
        </ComputerWindowContainer>
      </ComputerWindow>
      <FeatureHighlightsDetails>
        <FeatureHighlightsDescription>
          <Typography sx={{ textAlign: "left", fontSize: "1.1rem", marginBottom: "0.3rem", fontWeight: "800" }}>
            {item.title}
          </Typography>
          <Typography sx={{ textAlign: "start" }} variant="body2" component="p">
            {item.description}
          </Typography>
        </FeatureHighlightsDescription>
        <FeatureHighlightsAction>
          <Button size="medium" onClick={handleTryNewFeature(item)}>
            {item.buttonText}
          </Button>
        </FeatureHighlightsAction>
      </FeatureHighlightsDetails>
    </FeatureHighlightsContent>
  );
};
