import App from "App";
import { useAuth } from "ImsAuthorization";
import { FC, useCallback, useEffect } from "react";

import { useAppSelector } from "hooks";

import { reportAboutErrorState } from "utils/reports";

import { LoginErrorPage } from "./components";

const AppWrapper: FC = () => {
  const { error, loginErrorDetail, loginWithRedirect, logout } = useAuth();
  const permissions = useAppSelector((state) => state.license.permissions);

  const logoutAndRedirect = useCallback(
    (url: string) => {
      logout({ returnTo: url });
    },
    [logout],
  );

  // @TODO Should be rechecked and possible removed after IMS integration
  useEffect(() => {
    const errorCount = Number(localStorage.getItem("auth0-invalid-state-error")) || 0;
    localStorage.removeItem("auth0-invalid-state-error");

    if (error?.message === "Invalid state") {
      reportAboutErrorState({ name: error?.name, message: error?.message }, "Invalid state error in useAuth0 hook");

      if (errorCount >= 3) {
        logoutAndRedirect(window.origin);
        return;
      }

      localStorage.setItem("auth0-invalid-state-error", `${errorCount + 1}`);
      loginWithRedirect();
    }
  }, [error, loginWithRedirect, logoutAndRedirect]);

  if (permissions.error?.status === 403 && permissions.error.body.what) {
    return <LoginErrorPage errorMsg={permissions.error.body.what} logoutAndRedirect={logoutAndRedirect} />;
  }

  if (error && error.message !== "Invalid state") {
    reportAboutErrorState({ name: error?.name, message: error?.message }, "Failed useAuth0 hook");

    return <LoginErrorPage errorMsg={error.message} loginErrorDetail={loginErrorDetail} logoutAndRedirect={logoutAndRedirect} />;
  }

  return <App />;
};

export default AppWrapper;
