import memoryStore, { MemoryStoreKeys } from "api/memoryStore";
import debounce from "lodash/debounce";
import { EventData, MapboxGeoJSONFeature, Map as MapboxMap } from "mapbox-gl";
import { Dispatch, MutableRefObject, SetStateAction } from "react";

import { MIN_ZOOM_FOR_ROADS_VOLUMES } from "features/map/modules/roads/map-data/handlers/constants";
import {
  batchRemoveFeatureStates,
  batchUpdateFeatureStates,
  getMetersPerPixelAtLatitude,
  getVisibleFeaturesForLayer,
} from "features/map/modules/roads/map-data/handlers/utils";
import { ROADS_SOURCE_ID as LINKS_SOURCE_ID } from "features/map/modules/roads/map-data/sources";

import { ExtendedDirectionalRoadsTileService, ExtendedNonDirectionalRoadsTileService } from "types";

import { LINKS_HAIRLINES_LAYER_ID, LINKS_VALUES_LAYER_ID, getVolumesLineWidthExpression } from "../layers";

export const initVolumesSources = (
  map: MapboxMap,
  tileService: ExtendedDirectionalRoadsTileService | ExtendedNonDirectionalRoadsTileService,
  volumesSize: number,
  maxVolumeRef: React.RefObject<number>,
  widthFactorRef: MutableRefObject<number>,
  setVolumesLoading: Dispatch<SetStateAction<boolean>>,
) => {
  const isMapAvailable = Boolean(map);
  const areVolumesAvailable = volumesSize > 0;

  let featureStateSet = new Set<number>();
  let zoom = map.getZoom();

  const updateFeatureStatesByVolumes = (isForceUpdate: boolean) => {
    if (!isMapAvailable || !areVolumesAvailable) {
      setVolumesLoading(false);
      return;
    }

    if (map.getZoom() < MIN_ZOOM_FOR_ROADS_VOLUMES) {
      removeVolumesForSmallZoom();

      return;
    }

    // Get all visible features
    const allVisibleFeatures = getVisibleFeaturesForLayer(map, LINKS_HAIRLINES_LAYER_ID);

    if ((isForceUpdate || allVisibleFeatures.length === 0) && featureStateSet.size > 0) {
      // Clear the feature state set
      featureStateSet.clear();
    }

    // Filter out features that are already visible
    const visibleFeatures = isForceUpdate
      ? allVisibleFeatures
      : allVisibleFeatures.filter((f) => {
          if (featureStateSet.size > 0 && featureStateSet.has(f.id as number)) {
            return false;
          }

          return true;
        });

    // If there are no visible features, return
    if (visibleFeatures.length === 0) {
      setVolumesLoading(false);
      return;
    }

    const featuresToUpdate = calculateAndSetVolumesToFeatureStates(visibleFeatures);
    const newFeatureStateSet = new Set(allVisibleFeatures.map((f) => f.id as number));

    // Remove feature states that are not visible anymore
    batchRemoveFeatureStates(
      Array.from(featureStateSet).filter((id) => !newFeatureStateSet.has(id)),
      ["volumeOffset", "volumeWeight"],
      map,
      LINKS_SOURCE_ID,
      tileService.layerName,
    ).then(() => {
      // Update the feature states
      batchUpdateFeatureStates(
        Array.from(featuresToUpdate.entries()),
        ["volumeOffset", "volumeWeight"],
        map,
        LINKS_SOURCE_ID,
        tileService.layerName,
        setVolumesLoading,
      ).then(() => {
        zoom = map.getZoom();
      });

      makeLineWidthForLinksVisible();

      // Update the feature state set
      featureStateSet = newFeatureStateSet;
    });
  };

  const calculateAndSetVolumesToFeatureStates = (
    visibleFeatures: MapboxGeoJSONFeature[],
  ): Map<number, { volumeOffset: number; volumeWeight: number }> => {
    const metersPerPixelAtLatitude = getMetersPerPixelAtLatitude(map);
    const MIN_WIDTH_IN_PIXELS = 1;
    const MAX_WIDTH_IN_PIXELS = 1000;
    const features: Map<number, { volumeOffset: number; volumeWeight: number }> = new Map();
    const getWidthInPixelsByVolume = (volume: number) =>
      ((volume / (maxVolumeRef.current! * 2)) * (MAX_WIDTH_IN_PIXELS - MIN_WIDTH_IN_PIXELS)) / metersPerPixelAtLatitude;
    const volumes: Map<number, number> = memoryStore.getItem(MemoryStoreKeys.LINK_VALUES) || new Map();

    // Get the volumes for the visible segments
    visibleFeatures.forEach((feature: MapboxGeoJSONFeature) => {
      const segmentIdx = feature.properties![tileService.fromToSegmentIndexField] as number;
      const toFromSegmentIdx = (tileService as ExtendedDirectionalRoadsTileService).toFromSegmentIndexField
        ? (feature.properties![(tileService as ExtendedDirectionalRoadsTileService).toFromSegmentIndexField] as
            | number
            | undefined)
        : undefined;

      const fromToSegmentWidth = Math.round(
        (volumes.get(segmentIdx) || 0) > 0 ? getWidthInPixelsByVolume(volumes.get(segmentIdx) || 0) : 0,
      );
      const toFromVolume = toFromSegmentIdx ? volumes.get(toFromSegmentIdx) || 0 : 0;
      const toFromSegmentWidth = toFromVolume > 0 ? Math.round(getWidthInPixelsByVolume(toFromVolume)) : 0;
      const sumVolumeWidth = fromToSegmentWidth + toFromSegmentWidth;
      const signFactor = fromToSegmentWidth - toFromSegmentWidth >= 0 ? 1 : -1;

      const scalingFactor = 2;

      features.set(segmentIdx, {
        volumeOffset:
          signFactor * scalingFactor * (sumVolumeWidth / 2 - Math.min(fromToSegmentWidth, toFromSegmentWidth)),
        volumeWeight: sumVolumeWidth > 0 ? sumVolumeWidth * scalingFactor : MIN_WIDTH_IN_PIXELS,
      });
    });

    return features;
  };

  const removeVolumesForSmallZoom = () => {
    batchRemoveFeatureStates(
      Array.from(featureStateSet),
      ["volumeOffset", "volumeWeight"],
      map,
      LINKS_SOURCE_ID,
      tileService.layerName,
    ).then(() => {
      const linkLineWidth = map.getPaintProperty(LINKS_HAIRLINES_LAYER_ID, "line-width");

      if (linkLineWidth === 0) {
        map.setPaintProperty(LINKS_HAIRLINES_LAYER_ID, "line-width", 1);
        map.setPaintProperty(LINKS_VALUES_LAYER_ID, "line-width", 0);
      }

      featureStateSet.clear();
      setVolumesLoading(false);
    });
  };

  const makeLineWidthForLinksVisible = () => {
    const linkLineWidth = map.getPaintProperty(LINKS_VALUES_LAYER_ID, "line-width");

    if (linkLineWidth === 0) {
      map.setPaintProperty(LINKS_HAIRLINES_LAYER_ID, "line-width", 1);
      map.setPaintProperty(LINKS_VALUES_LAYER_ID, "line-width", getVolumesLineWidthExpression(widthFactorRef.current));
    }
  };

  const initHairlinesLayer = () => {
    if (map.getPaintProperty(LINKS_HAIRLINES_LAYER_ID, "line-width") === 0) {
      map.setPaintProperty(LINKS_HAIRLINES_LAYER_ID, "line-width", 1);
    }
  };

  const updateVolumes = (forceUpdate: boolean = false) => {
    const areVolumesSourceAndLayerAvailable = Boolean(
      map &&
        map.isSourceLoaded(LINKS_SOURCE_ID) &&
        map.getSource(LINKS_SOURCE_ID) &&
        map.getLayer(LINKS_VALUES_LAYER_ID),
    );
    const isVolumesLayerVisible =
      areVolumesSourceAndLayerAvailable && map?.getLayoutProperty(LINKS_VALUES_LAYER_ID, "visibility") !== "none";

    if (!areVolumesAvailable || !isVolumesLayerVisible) {
      return;
    }

    initHairlinesLayer();

    // Check if the zoom level has changed significantly
    const isZoomDiff = Math.abs(map.getZoom() - zoom) > 0.25;
    // Check if the update is forced
    const isForceUpdate = isZoomDiff || forceUpdate === true;

    setVolumesLoading(true);

    if (isForceUpdate) {
      batchRemoveFeatureStates(
        Array.from(featureStateSet),
        ["volumeOffset", "volumeWeight"],
        map,
        LINKS_SOURCE_ID,
        tileService.layerName,
      ).then(() => {
        updateFeatureStatesByVolumes(isForceUpdate);
      });
    } else {
      updateFeatureStatesByVolumes(isForceUpdate);
    }
  };

  const debounceUpdateVolumes = debounce(updateVolumes, 500);
  const idleDebounceUpdateVolumes = () => {
    map.once("idle", debounceUpdateVolumes);
  };

  const updateLinksVolumes = () => {
    updateVolumes(true);
  };

  const handleUpdateVolumesBySourceData = (e: EventData) => {
    if (e.sourceId !== LINKS_SOURCE_ID) {
      return;
    }

    if (!e.tile || e.tile.state !== "loaded") {
      return;
    }

    idleDebounceUpdateVolumes();
  };

  const handleUpdateVolumesByZoomEnd = () => {
    idleDebounceUpdateVolumes();
  };

  const handleInteractionEnd = () => {
    idleDebounceUpdateVolumes();
  };

  // Initial volumes update
  map.on("sourcedata", handleUpdateVolumesBySourceData);
  map.on("zoomend", handleUpdateVolumesByZoomEnd);
  map.on("dragend", handleInteractionEnd);
  map.on("moveend", handleInteractionEnd);

  return {
    cleanVolumesHandlers: () => {
      map.off("sourcedata", handleUpdateVolumesBySourceData);
      map.off("zoomend", handleUpdateVolumesByZoomEnd);
      map.off("dragend", handleInteractionEnd);
      map.off("moveend", handleInteractionEnd);
    },
    updateLinksVolumes,
  };
};
