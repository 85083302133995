import { MapLayerMouseEvent, Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, SetStateAction } from "react";

import { closeAllMapboxPopups, getFlowInfo } from "features/map/utils";

import { ODPopupProps } from "components";

import { HoveredFlow, QueryType, ZoningLevel } from "types";

import { OUT_ZONES_LAYER_FILL } from "../od/layers";
import { ZONE_DETAILS_ARROWS_HOVER_LAYER, ZONE_DETAILS_ARROWS_LAYER, ZONE_DETAILS_CENTROIDS_LAYER } from "./layers";

export const getTopFlowsHandlers = (
  map: mapboxgl.Map,
  zoningLevels: ZoningLevel[],
  idField: string,
  queryType: MutableRefObject<QueryType>,
  mapboxODZoneHoverPopupRef: MutableRefObject<Popup | null>,
  topFlowPopupRef: MutableRefObject<HTMLDivElement | null>,
  setTopFlowsPopupProps: MutableRefObject<Dispatch<SetStateAction<ODPopupProps | null>> | null>,
  setHoveredTopFlow: Dispatch<SetStateAction<HoveredFlow | null>>,
  isDemandScenario?: boolean,
) => {
  const setHighlightedTopFlow = (topFlow: { id: string | number; external: boolean; isGate: boolean } | null) => {
    if (map.getLayer(ZONE_DETAILS_ARROWS_HOVER_LAYER) === undefined) return;

    if (topFlow) {
      const { id, external, isGate } = topFlow;
      map.setFilter(ZONE_DETAILS_ARROWS_HOVER_LAYER, [
        "all",
        ["==", ["get", "id"], id],
        ["==", ["get", "external"], external],
        ["==", ["get", "isGate"], isGate],
      ]);

      if (map?.getLayer(OUT_ZONES_LAYER_FILL)) {
        map.setFilter(OUT_ZONES_LAYER_FILL, ["in", idField, id]);
      }

      setHoveredTopFlow((prev) => (prev?.id !== id ? { id, external, isGate } : prev));
    } else {
      if (map?.getLayer(OUT_ZONES_LAYER_FILL)) {
        map.setFilter(OUT_ZONES_LAYER_FILL, ["in", idField, ""]);
      }
      map.setFilter(ZONE_DETAILS_ARROWS_HOVER_LAYER, ["==", "id", ""]);
      setHoveredTopFlow((prev) => (prev ? null : prev));
    }
  };

  const handleArrowMousemove = (e: MapLayerMouseEvent) => {
    map.getCanvas().style.cursor = "pointer";

    if (mapboxODZoneHoverPopupRef.current) {
      mapboxODZoneHoverPopupRef.current.remove();
    }

    if (e.features?.length) {
      const feature = e.features[0];
      const featureId = feature?.id ?? feature?.properties?.id;
      const properties = feature.properties;
      const count = properties?.value;

      if (featureId && count !== undefined && properties && topFlowPopupRef.current && setTopFlowsPopupProps.current) {
        const { level, id, areaName, levelName, countryName, isGate, index, external } = properties;

        setHighlightedTopFlow({ id, external, isGate });

        setTopFlowsPopupProps.current({
          count,
          type: queryType.current,
          flowInfo: getFlowInfo(
            queryType.current,
            level,
            id,
            areaName,
            levelName,
            countryName,
            isGate,
            zoningLevels,
            index,
            isDemandScenario,
          ),
        });

        if (mapboxODZoneHoverPopupRef.current) {
          mapboxODZoneHoverPopupRef.current = new Popup({
            closeButton: false,
            closeOnClick: false,
            offset: 15,
          })
            .setLngLat(e.lngLat)
            .setDOMContent(topFlowPopupRef.current)
            .addTo(map);
        }
      } else {
        setHighlightedTopFlow(null);
      }
    }
  };

  const handleArrowMouseleave = () => {
    map.getCanvas().style.cursor = "";
    setHighlightedTopFlow(null);
    if (mapboxODZoneHoverPopupRef.current) {
      mapboxODZoneHoverPopupRef.current.remove();
    }
  };

  map.on("mousemove", [ZONE_DETAILS_CENTROIDS_LAYER, ZONE_DETAILS_ARROWS_LAYER], handleArrowMousemove);
  map.on("mouseleave", [ZONE_DETAILS_CENTROIDS_LAYER, ZONE_DETAILS_ARROWS_LAYER], handleArrowMouseleave);

  return {
    cleanTopFlowsHandlers: () => {
      closeAllMapboxPopups(map);

      map.off("mousemove", [ZONE_DETAILS_CENTROIDS_LAYER, ZONE_DETAILS_ARROWS_LAYER], handleArrowMousemove);
      map.off("mouseleave", [ZONE_DETAILS_CENTROIDS_LAYER, ZONE_DETAILS_ARROWS_LAYER], handleArrowMouseleave);
    },
    setHighlightedTopFlow,
  };
};
