import { CssBaseline, ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import { MemoryStoreProvider } from "api/MemoryStoreContext";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import AppWrapper from "./AppWrapper";
import { ApiContext, makeApi } from "./api";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import theme from "./theme";
import { ImsAuthorizationProvider } from "ImsAuthorization";
import { Auth0Provider } from "@auth0/auth0-react";

// In order to have better stack traces we could in the future configure
// npx @sentry/wizard@latest -i sourcemaps
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE || "1.0"), //  Capture 100% of the transactions
    profilesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_PROFILE_SAMPLE_RATE || "0.1"),
    // Session Replay disabled for now
    // replaysSessionSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE || "0.1"),
    // replaysOnErrorSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAY_ERROR_SESSION_SAMPLE_RATE || "1.0"),
  });
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const api = makeApi();

const router = createBrowserRouter([
  {
    path: "/*",
    element: <AppWrapper />,
  },
]);

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <ImsAuthorizationProvider>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
          redirectUri={window.location.origin}
          audience={
            process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_HOST : process.env.REACT_APP_AUTH0_HOST
          }
        >
          <ApiContext.Provider value={api}>
            <MemoryStoreProvider>
              <CssBaseline />
              <RouterProvider router={router} />
            </MemoryStoreProvider>
          </ApiContext.Provider>
        </Auth0Provider> 
      </ImsAuthorizationProvider>
    </Provider>
  </ThemeProvider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

const sendToSentry = (metric: any) => {
  Sentry.addBreadcrumb({
    category: "performance",
    message: metric.name,
    level: "info",
    data: metric,
  });
};

reportWebVitals(sendToSentry);
