import { Map as MapboxMap } from "mapbox-gl";
import { Dispatch, MutableRefObject, SetStateAction } from "react";

import { RoadsHoverPopupProps, VolumePopupContentProps } from "components";

import {
  ExtendedDirectionalRoadsTileService,
  ExtendedNonDirectionalRoadsTileService,
  SelectedVolume,
  Volume,
} from "types";

import { initUserInteractionsWithMap } from "./userInteractions";
import { initVolumesSources } from "./volumes";

// import { initUserInteractionsWithMap } from "./userInteractions";

export const getLinkHandlers = (
  map: MapboxMap,
  tileService: ExtendedDirectionalRoadsTileService | ExtendedNonDirectionalRoadsTileService,
  volumesSize: number,
  maxVolumeRef: React.RefObject<number>,
  widthFactorRef: MutableRefObject<number>,
  popupRoadSegmentHoverRef: any, // TODO: Fix type
  popupRoadVolumesRef: any,
  mapboxVolumesPopupRef: any,
  setRoadHoverProps: React.RefObject<Dispatch<SetStateAction<RoadsHoverPopupProps | null>>>,
  setVolumeProps: Dispatch<SetStateAction<Volume[]>>,
  setRoadsVolumesRef: React.RefObject<Dispatch<SetStateAction<VolumePopupContentProps | null>>>,
  updateRoadsPopupCounts: React.MutableRefObject<
    ((selectedVolume: SelectedVolume | null, volumesProps?: any[]) => void) | null
  >,
  setVolumesLoading: Dispatch<SetStateAction<boolean>>,
  setSelectedRoadVolume?: (selectedRoadVolume: SelectedVolume | null) => void,
  setSelectedRoadVolumeId?: (selectedRoadVolumeId: number) => void,
) => {
  const { cleanUserInteractionsHandlers } = initUserInteractionsWithMap(
    map,
    tileService,
    popupRoadSegmentHoverRef,
    popupRoadVolumesRef,
    mapboxVolumesPopupRef,
    setRoadHoverProps,
    setRoadsVolumesRef,
    updateRoadsPopupCounts,
    setVolumeProps,
    setSelectedRoadVolume,
    setSelectedRoadVolumeId,
  );

  const { cleanVolumesHandlers, updateLinksVolumes } = initVolumesSources(
    map,
    tileService,
    volumesSize,
    maxVolumeRef,
    widthFactorRef,
    setVolumesLoading,
  );

  return {
    cleanLinksHandlers: () => {
      cleanUserInteractionsHandlers();
      cleanVolumesHandlers();
    },
    updateLinksVolumes,
  };
};
