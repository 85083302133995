import { useAuth } from "ImsAuthorization";
import { Autocomplete, Grid, MenuItem, Stack, Typography } from "@mui/material";
import { Avatar, Button, Dialog, DialogProps, Divider, TextField } from "components_new";
import React, { FC, SyntheticEvent, useCallback, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "hooks";

import { licenseActions } from "store/sections/license";

import { Organization } from "types";

export interface UserSettingsDialogProps extends DialogProps {}

export const UserSettingsDialog: FC<UserSettingsDialogProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const { user, logout } = useAuth();

  const userInfo = useAppSelector((state) => state.license.user.data);
  const isOperator = useAppSelector((state) => state.license.user.data?.isOperator);
  const organizations = useAppSelector((state) => state.license.organization.data);
  const licenseTypes = useAppSelector((state) => state.license.licenseTypes.data);
  const userOrganization = useAppSelector((state) => state.license.user.data?.organization);
  const userLicenseType = useAppSelector((state) => state.license.user.data?.softwareLicense);

  const sortedOrganizations = useMemo(
    () => [...(organizations || [])].sort((a, b) => a.name.localeCompare(b.name)),
    [organizations],
  );

  const changeOrganization = useCallback(
    (code: string) => {
      if (isOperator) dispatch(licenseActions.changeUserOrganization(code));
    },
    [dispatch, isOperator],
  );

  const changeLicenseType = useCallback(
    (code: string) => {
      if (isOperator) dispatch(licenseActions.changeUserLicenseType(code));
    },
    [dispatch, isOperator],
  );

  const handleSelectOrganization = useCallback(
    (event: SyntheticEvent<Element, Event>, org: Organization | null) => {
      if (org) changeOrganization(org.code);
    },
    [changeOrganization],
  );

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      actions={
        <Button
          onClick={() =>
            logout({
              returnTo: window.location.origin,
            })
          }
        >
          Sign out
        </Button>
      }
    >
      <Grid container marginTop={2} columnSpacing={4} alignItems={"center"}>
        <Grid item xs={"auto"} container>
          <Avatar src={user?.picture} alt="avatar" sx={{ width: 80, height: 80 }} />
        </Grid>
        <Grid item xs container>
          <Stack spacing={1}>
            <Typography variant="subtitle1" fontWeight={600}>
              {userInfo?.firstName} {userInfo?.lastName}
            </Typography>
            <Typography variant="body2" color={"text.secondary"}>
              {userInfo?.email}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Stack spacing={2} marginTop={2}>
        <Divider />
        <Autocomplete
          disabled={!isOperator}
          ListboxProps={{
            sx: {
              fontSize: "14px",
              maxHeight: "350px",
            },
          }}
          options={sortedOrganizations}
          renderInput={(params) => <TextField label="Organization" {...params} />}
          getOptionLabel={(opt) => opt.name}
          isOptionEqualToValue={(opt: Organization, value: Organization) => opt.id === value.id}
          value={userOrganization}
          onChange={handleSelectOrganization}
        />
        <TextField
          select
          fullWidth
          label="License type"
          value={userLicenseType?.softwareLicenseType.id}
          disabled={!isOperator}
        >
          {licenseTypes?.length ? (
            licenseTypes?.map((license) => (
              <MenuItem key={license.id} value={license.id} onClick={() => changeLicenseType(license.code)}>
                {license.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem key={userLicenseType?.softwareLicenseType.id} value={userLicenseType?.softwareLicenseType.id}>
              {userLicenseType?.softwareLicenseType.name}
            </MenuItem>
          )}
        </TextField>
      </Stack>
    </Dialog>
  );
};
