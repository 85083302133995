import MapboxDraw from "@mapbox/mapbox-gl-draw";

const { isOfMetaType } = MapboxDraw.lib.CommonSelectors;
const Constants = MapboxDraw.constants;
const DirectSelect = MapboxDraw.modes.direct_select;
const CustomDirectSelect = { ...DirectSelect };

const isVertex = isOfMetaType(Constants.meta.VERTEX);
const isMidpoint = isOfMetaType(Constants.meta.MIDPOINT);

CustomDirectSelect.startDragging = function (state, e) {
  if (state.initialDragPanState == null) {
    state.initialDragPanState = this.map.dragPan.isEnabled();
  }

  if (isVertex(e) || isMidpoint(e)) {
    this.map.dragPan.disable();
  }
  state.canDragMove = true;
  state.dragMoveLocation = e.lngLat;
};

CustomDirectSelect.clickInactive = function (state, e) {
  if (e.featureTarget.geometry.type !== Constants.geojsonTypes.POINT) {
    // switch to direct_select mode for polygon/line features
    this.changeMode(Constants.modes.DIRECT_SELECT, {
      featureId: e.featureTarget.properties.id,
    });
  } else {
    // switch to simple_select mode for point features
    this.changeMode(Constants.modes.SIMPLE_SELECT, {
      featureIds: [e.featureTarget.properties.id],
    });
  }
};

CustomDirectSelect.dragFeature = function (state, e, delta) {
  return;
};

CustomDirectSelect.onMouseMove = function (state, e) {
  // call parent
  const result = DirectSelect.onMouseMove.call(this, state, e);

  // show pointer cursor on inactive features, move cursor on active feature vertices
  const isFeature = MapboxDraw.lib.CommonSelectors.isInactiveFeature(e);
  const onVertex = MapboxDraw.lib.CommonSelectors.isOfMetaType(Constants.meta.VERTEX)(e);
  const onMidpoint = MapboxDraw.lib.CommonSelectors.isOfMetaType(Constants.meta.MIDPOINT)(e);
  if (isFeature || onMidpoint) this.updateUIClasses({ mouse: Constants.cursors.POINTER });
  else if (onVertex) this.updateUIClasses({ mouse: Constants.cursors.MOVE });
  else this.updateUIClasses({ mouse: Constants.cursors.NONE });

  return result;
};

export default CustomDirectSelect;
