import MapboxDraw from "@mapbox/mapbox-gl-draw";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
// import esriLogo from "assets/png/ESRI_logo.png";
import mapboxgl, { LngLatBounds } from "mapbox-gl";
import React, { MutableRefObject, useEffect } from "react";

import { DirectSelect, SimpleSelect, styles } from "features/draw";

import { getMapHomeControl } from "components";

import { useAppSelector } from "hooks";

import { selectSelectedArea } from "store/sections/global";

import { getBounds } from "./utils";

/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-webpack-loader-syntax */
(mapboxgl as any).workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
/* eslint-enable @typescript-eslint/no-var-requires */
/* eslint-enable import/no-webpack-loader-syntax */

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;

interface Props {
  children?: React.ReactNode;
  map: MutableRefObject<mapboxgl.Map | null>;
  mapLoaded: boolean;
  draw?: MutableRefObject<any | null>;
  onMapLoaded: (loaded: boolean) => void;
}

const MapContainer = styled(Box)`
  width: 100%;
  height: 100%;
  grid-area: map;
`;

// const EsriLogo = styled("img")`
//   position: absolute;
//   bottom: 0px;
//   right: 105px;
// `;

export const AnalyticMap = ({ children, map, draw, mapLoaded, onMapLoaded }: Props) => {
  // Focus Areas
  const selectedArea = useAppSelector(selectSelectedArea);
  const baseMapStyle = useAppSelector((state) => state.map.baseMapStyle);
  const savedBounds = useAppSelector((state) => state.map.mapBounds);

  const mapContainer = React.useRef(null);

  // Initialize map
  useEffect(() => {
    if (map.current) {
      return; // initialize map only once
    }

    if (selectedArea) {
      const bounds = getBounds(selectedArea.geometry);
      const newBounds = savedBounds ? LngLatBounds.convert(savedBounds) : bounds;
      const padding = { top: 40, bottom: 40, left: 40, right: 40 };

      // This is for performance testing
      let startRenderTime: number;
      let endRenderTime: number;

      // Function to start a new timer
      const startTimer = () => {
        startRenderTime = performance.now();
      };

      // Function to measure and log the duration
      const logRenderTime = () => {
        requestAnimationFrame(() => {
          endRenderTime = performance.now();
          const renderDuration = endRenderTime - startRenderTime;
          startRenderTime = endRenderTime;

          if (!isNaN(renderDuration)) {
            console.log(`%c Mapbox GL render duration: ${renderDuration} ms`, "color: gray");
          }
        });
      };

      map.current = new mapboxgl.Map({
        container: mapContainer.current!,
        style: baseMapStyle,
        fitBoundsOptions: {
          padding: savedBounds ? 0 : padding,
        },
        bounds: newBounds,
        pitch: 0,
        bearing: 0,
        logoPosition: "bottom-right",
        transformRequest: (url, resourceType): any => {
          if (resourceType === "Tile" && url.startsWith(process.env.REACT_APP_API_HOST as string)) {
            return {
              headers: {
                authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
              },
              url,
            };
          }
        },
        maxTileCacheSize: 512,
      });

      // disable map rotation using right click + drag
      map.current.dragRotate.disable();

      // disable map rotation using touch rotation gesture
      map.current.touchZoomRotate.disableRotation();

      // Add home control to the map.
      map.current.addControl(
        getMapHomeControl(map.current, bounds, {
          padding,
        }),
        "top-left",
      );

      // Add zoom controls to the map.
      map.current.addControl(new mapboxgl.NavigationControl({ showCompass: false }), "top-left");

      if (draw) {
        draw.current = new MapboxDraw({
          boxSelect: false,
          styles: styles,
          modes: { ...MapboxDraw.modes, simple_select: SimpleSelect, direct_select: DirectSelect },
        });
        if (draw.current) map.current.addControl(draw.current, "top-right");
      }

      map.current.on("load", () => {
        onMapLoaded(true);
      });

      if (process.env.NODE_ENV === "development") {
        map.current.once("renderstart", () => {
          startTimer();
        });

        map.current.on("idle", () => {
          logRenderTime();
        });
      }
    }
  }, [map, draw, savedBounds, selectedArea, baseMapStyle, mapLoaded, onMapLoaded]);

  return (
    <>
      {children}
      {/* Using ref callback to assign reference in order to solve Mapbox warning about map container not being empty*/}
      <MapContainer ref={(el: any) => (mapContainer.current = el)} />
      {/* {typeof baseMapStyle === "object" && <EsriLogo src={esriLogo} alt="Esri logo" width={88} />} */}
    </>
  );
};
