export const intersectionShapefileColumns = [
  {
    column: "node_id",
    type: "Integer64",
    description:
      "OSM node id",
  },
  {
    column: "leg_count",
    type: "Integer",
    description:
      "The number of roads that join to form the intersection. Each road leading away from or towards an intersection is counted as a `leg` of the intersection.",
  },
  {
    column: "VOLTOTAL",
    type: "Long",
    description:
      "Total daily traffic of the intersection. This is determined as the sum of half the volume of each leg of the intersection.",
  },
  {
    column: "VOLDT1, VOLDT2, VOLDT3, VOLDT4",
    type: "Integer",
    description:
      "Volume breakdowns grouped by day type DT1: Mon-Thu, DT2: Fri, DT3: Sat, DT4: Sun.",
  }
];