import { createSelector } from "@reduxjs/toolkit";
import { Api } from "api";
import { produce } from "immer";
import { Reducer } from "redux";
import { all, call, getContext, put, takeLatest } from "redux-saga/effects";

import { RootState } from "store";

import {
  AggregationFunction,
  RoadIntersectionClusterIdsRequest,
  RoadIntersectionClusterVolumes,
  RoadIntersectionClusterVolumesRequest,
  RoadIntersectionNodeIdsRequest,
  RoadIntersectionVolumeDetailsRequest,
  RoadIntersectionVolumeDetailsResponse,
  RoadIntersectionVolumes,
  RoadIntersectionVolumesRequest,
} from "types";

import { reportAboutErrorState } from "utils/reports";

import { Action, ActionsUnion, createAction } from "../actionHelpers";
import { DataState, LoadingErrorData, ResponseError } from "../interfaces";
import { RoadIntersectionsActionType } from "./actionTypes";

export interface RoadIntersectionsState {
  intersectionVolumes: LoadingErrorData<RoadIntersectionVolumes>;
  intersectionIds: LoadingErrorData<boolean>;
  intersectionClusterVolumes: LoadingErrorData<RoadIntersectionClusterVolumes>;
  intersectionClusterIds: LoadingErrorData<boolean>;

  selectedIntersectionId: string | null;
  intersectionVolumeDetails: LoadingErrorData<RoadIntersectionVolumeDetailsResponse>;

  minVolumes: Record<number, number> | null;
  maxVolumes: Record<number, number> | null;

  showRoadIntersections: boolean;
  opacityFactor: number;
  widthFactor: number;

  isDrawModeActive: boolean;
  selectedIntersections: string[] | null;
  isBaseIntersectionLevel: boolean;
  aggregationFunction: AggregationFunction;
}

export type RoadIntersectionAction = ActionsUnion<typeof roadIntersectionsActions>;

export const roadIntersectionsActions = {
  // Road intersection volumes
  fetchRoadIntersectionVolumes: (request: RoadIntersectionVolumesRequest) =>
    createAction(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUMES, request),
  fetchRoadIntersectionVolumesSucceeded: (volumes: RoadIntersectionVolumes) =>
    createAction(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUMES_SUCCEEDED, {
      volumes,
    }),
  fetchRoadIntersectionVolumesFailed: (error: ResponseError) =>
    createAction(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUMES_FAILED, error),

  clearRoadIntersectionVolumes: () => createAction(RoadIntersectionsActionType.CLEAR_ROAD_INTERSECTION_VOLUMES),

  // Road intersection ids
  fetchRoadIntersectionIds: (request: RoadIntersectionNodeIdsRequest) =>
    createAction(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_IDS, request),
  fetchRoadIntersectionIdsSucceeded: (status: boolean) =>
    createAction(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_IDS_SUCCEEDED, status),
  fetchRoadIntersectionIdsFailed: (error: ResponseError) =>
    createAction(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_IDS_FAILED, error),

  // Road intersection cluster volumes
  fetchRoadIntersectionClusterVolumes: (request: RoadIntersectionClusterVolumesRequest) =>
    createAction(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_VOLUMES, request),
  fetchRoadIntersectionClusterVolumesSucceeded: (volumes: RoadIntersectionClusterVolumes) =>
    createAction(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_VOLUMES_SUCCEEDED, {
      volumes,
    }),
  fetchRoadIntersectionClusterVolumesFailed: (error: ResponseError) =>
    createAction(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_VOLUMES_FAILED, error),

  clearRoadIntersectionClusterVolumes: () =>
    createAction(RoadIntersectionsActionType.CLEAR_ROAD_INTERSECTION_CLUSTER_VOLUMES),

  // Road intersection cluster ids
  fetchRoadIntersectionClusterIds: (request: RoadIntersectionClusterIdsRequest) =>
    createAction(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_IDS, request),
  fetchRoadIntersectionClusterIdsSucceeded: (status: boolean) =>
    createAction(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_IDS_SUCCEEDED, status),
  fetchRoadIntersectionClusterIdsFailed: (error: ResponseError) =>
    createAction(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_IDS_FAILED, error),

  // Details
  fetchRoadIntersectionVolumeDetails: (request: RoadIntersectionVolumeDetailsRequest) =>
    createAction(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUME_DETAILS, request),
  fetchRoadIntersectionVolumeDetailsSucceeded: (response: RoadIntersectionVolumeDetailsResponse) =>
    createAction(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUME_DETAILS_SUCCEEDED, {
      response,
    }),
  fetchRoadIntersectionVolumeDetailsFailed: (error: ResponseError) =>
    createAction(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUME_DETAILS_FAILED, error),
  setSelectedRoadIntersectionId: (selectedIntersectionId: string | null) =>
    createAction(RoadIntersectionsActionType.SET_SELECTED_INTERSECTION_ID, selectedIntersectionId),
  clearRoadIntersectionVolumeDetails: () =>
    createAction(RoadIntersectionsActionType.CLEAR_ROAD_INTERSECTION_VOLUME_DETAILS),

  // Visuals
  setShowRoadIntersections: (showRoadIntersections: boolean) =>
    createAction(RoadIntersectionsActionType.SET_SHOW_ROAD_INTERSECTIONS, showRoadIntersections),

  setOpacityFactor: (opacityFactor: number) =>
    createAction(RoadIntersectionsActionType.SET_OPACITY_FACTOR, opacityFactor),
  setWidthFactor: (widthFactor: number) => createAction(RoadIntersectionsActionType.SET_WIDTH_FACTOR, widthFactor),

  setIsDrawModeActive: (isDrawModeActive: boolean) =>
    createAction(RoadIntersectionsActionType.SET_IS_DRAW_MODE_ACTIVE, isDrawModeActive),

  setSelectedRoadIntersections: (selectedIntersections: string[] | null) =>
    createAction(RoadIntersectionsActionType.SET_SELECTED_INTERSECTIONS, selectedIntersections),

  setIsBaseIntersectionLevel: (isBaseLevel: boolean) =>
    createAction(RoadIntersectionsActionType.SET_IS_BASE_LEVEL, isBaseLevel),

  setAggregationFunctionAndClearVolumes: (fn: AggregationFunction) =>
    createAction(RoadIntersectionsActionType.SET_AGGREGATION_FUNCTION_AND_CLEAR_VOLUMES, fn),

  resetToDefault: () => createAction(RoadIntersectionsActionType.RESET_TO_DEFAULT),
};

const initialState: RoadIntersectionsState = {
  intersectionVolumes: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  intersectionIds: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  intersectionClusterVolumes: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  intersectionClusterIds: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  intersectionVolumeDetails: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  selectedIntersectionId: null,
  selectedIntersections: null,
  minVolumes: null,
  maxVolumes: null,
  showRoadIntersections: true,
  opacityFactor: 1,
  widthFactor: 1,
  isDrawModeActive: false,
  isBaseIntersectionLevel: false,
  aggregationFunction: AggregationFunction.Average,
};

const reducer: Reducer<RoadIntersectionsState, RoadIntersectionAction> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUMES: {
        draft.intersectionVolumes = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        draft.intersectionVolumeDetails = initialState.intersectionVolumeDetails;

        return;
      }
      case RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUMES_SUCCEEDED: {
        draft.intersectionVolumes = {
          state: DataState.AVAILABLE,
          data: action.payload.volumes,
          error: null,
        };
        draft.minVolumes = {
          ...draft.minVolumes,
          0: action.payload.volumes.minVolume, // 0 for base level
        };
        draft.maxVolumes = {
          ...draft.maxVolumes,
          0: action.payload.volumes.maxVolume, // 0 for base level
        };

        return;
      }
      case RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUMES_FAILED: {
        draft.intersectionVolumes = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }
      case RoadIntersectionsActionType.CLEAR_ROAD_INTERSECTION_VOLUMES: {
        draft.intersectionVolumes = {
          state: DataState.EMPTY,
          data: null,
          error: null,
        };

        return;
      }
      case RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_IDS: {
        draft.intersectionIds = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_IDS_SUCCEEDED: {
        draft.intersectionIds = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };
        return;
      }
      case RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_IDS_FAILED: {
        draft.intersectionIds = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }
      case RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUME_DETAILS: {
        draft.intersectionVolumeDetails = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUME_DETAILS_SUCCEEDED: {
        draft.intersectionVolumeDetails = {
          state: DataState.AVAILABLE,
          data: action.payload.response,
          error: null,
        };
        return;
      }
      case RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUME_DETAILS_FAILED: {
        draft.intersectionVolumeDetails = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case RoadIntersectionsActionType.SET_SELECTED_INTERSECTION_ID: {
        draft.selectedIntersectionId = action.payload;
        return;
      }
      case RoadIntersectionsActionType.SET_SELECTED_INTERSECTIONS: {
        draft.selectedIntersections = action.payload;
        return;
      }
      case RoadIntersectionsActionType.CLEAR_ROAD_INTERSECTION_VOLUME_DETAILS: {
        draft.intersectionVolumeDetails = initialState.intersectionVolumeDetails;
        return;
      }
      case RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_VOLUMES: {
        draft.intersectionClusterVolumes = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_VOLUMES_SUCCEEDED: {
        draft.intersectionClusterVolumes = {
          state: DataState.AVAILABLE,
          data: action.payload.volumes,
          error: null,
        };

        const minVols = Object.fromEntries(action.payload.volumes.minVolumes);
        draft.minVolumes = {
          ...draft.minVolumes,
          ...minVols,
        };

        const maxVols = Object.fromEntries(action.payload.volumes.maxVolumes);
        draft.maxVolumes = {
          ...draft.maxVolumes,
          ...maxVols,
        };

        return;
      }
      case RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_VOLUMES_FAILED: {
        draft.intersectionClusterVolumes = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }
      case RoadIntersectionsActionType.CLEAR_ROAD_INTERSECTION_CLUSTER_VOLUMES: {
        draft.intersectionClusterVolumes = initialState.intersectionClusterVolumes;
        return;
      }
      case RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_IDS: {
        draft.intersectionClusterIds = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_IDS_SUCCEEDED: {
        draft.intersectionClusterIds = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };
        return;
      }
      case RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_IDS_FAILED: {
        draft.intersectionClusterIds = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }
      case RoadIntersectionsActionType.SET_SHOW_ROAD_INTERSECTIONS: {
        draft.showRoadIntersections = action.payload;
        return draft;
      }
      case RoadIntersectionsActionType.SET_IS_DRAW_MODE_ACTIVE: {
        draft.isDrawModeActive = action.payload;
        return draft;
      }
      case RoadIntersectionsActionType.SET_IS_BASE_LEVEL: {
        draft.isBaseIntersectionLevel = action.payload;
        return draft;
      }
      case RoadIntersectionsActionType.SET_AGGREGATION_FUNCTION_AND_CLEAR_VOLUMES: {
        draft.aggregationFunction = action.payload;
        draft.intersectionVolumeDetails = initialState.intersectionVolumeDetails;
        draft.intersectionClusterVolumes = initialState.intersectionClusterVolumes;
        return draft;
      }
      case RoadIntersectionsActionType.SET_OPACITY_FACTOR: {
        draft.opacityFactor = action.payload;
        return draft;
      }
      case RoadIntersectionsActionType.SET_WIDTH_FACTOR: {
        draft.widthFactor = action.payload;
        return draft;
      }
      case RoadIntersectionsActionType.RESET_TO_DEFAULT: {
        return initialState;
      }

      default:
        return state;
    }
  });

export default reducer;

function* fetchRoadIntersectionVolumes(action: Action<string, RoadIntersectionVolumesRequest>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getRoadIntersectionVolumes },
    } = api as Api;
    const volumes = (yield call(getRoadIntersectionVolumes, action.payload)) as RoadIntersectionVolumes;

    yield put({
      type: RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUMES_SUCCEEDED,
      payload: { volumes },
    });
  } catch (e: any) {
    reportAboutErrorState(e, RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUMES_FAILED);

    yield put({
      type: RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUMES_FAILED,
      payload: {
        message: e.message,
      },
    });
  }
}

function* fetchRoadIntersectionIds(action: Action<string, RoadIntersectionNodeIdsRequest>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getRoadIntersectionIds },
    } = api as Api;
    const status = yield call(getRoadIntersectionIds, action.payload);

    yield put({
      type: RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_IDS_SUCCEEDED,
      payload: status,
    });
  } catch (e: any) {
    reportAboutErrorState(e, RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_IDS_FAILED);

    yield put({
      type: RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_IDS_FAILED,
      payload: {
        message: e.message,
      },
    });
  }
}

function* fetchRoadIntersectionVolumeDetails(action: Action<string, RoadIntersectionVolumeDetailsRequest>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getRoadIntersectionVolumeDetails },
    } = api as Api;
    const response = yield call(getRoadIntersectionVolumeDetails, action.payload);

    yield put({
      type: RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUME_DETAILS_SUCCEEDED,
      payload: { response },
    });
  } catch (e: any) {
    reportAboutErrorState(e, RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUME_DETAILS_FAILED);

    yield put({
      type: RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUME_DETAILS_FAILED,
      payload: {
        message: e.message,
      },
    });
  }
}

function* fetchRoadIntersectionClusterVolumes(
  action: Action<string, RoadIntersectionClusterVolumesRequest>,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getRoadIntersectionClusterVolumes },
    } = api as Api;
    const volumes = (yield call(getRoadIntersectionClusterVolumes, action.payload)) as RoadIntersectionVolumes;

    yield put({
      type: RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_VOLUMES_SUCCEEDED,
      payload: { volumes },
    });
  } catch (e: any) {
    reportAboutErrorState(e, RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_VOLUMES_FAILED);

    yield put({
      type: RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_VOLUMES_FAILED,
      payload: {
        message: e.message,
      },
    });
  }
}

function* fetchRoadIntersectionClusterIds(action: Action<string, RoadIntersectionClusterIdsRequest>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getRoadIntersectionClusterIds },
    } = api as Api;
    const status = yield call(getRoadIntersectionClusterIds, action.payload);

    yield put({
      type: RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_IDS_SUCCEEDED,
      payload: status,
    });
  } catch (e: any) {
    reportAboutErrorState(e, RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_IDS_FAILED);

    yield put({
      type: RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_IDS_FAILED,
      payload: {
        message: e.message,
      },
    });
  }
}

export function* roadIntersectionsSaga() {
  yield all([
    takeLatest(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUMES, fetchRoadIntersectionVolumes),
    takeLatest(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_IDS, fetchRoadIntersectionIds),
    takeLatest(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_VOLUME_DETAILS, fetchRoadIntersectionVolumeDetails),
    takeLatest(
      RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_VOLUMES,
      fetchRoadIntersectionClusterVolumes,
    ),
    takeLatest(RoadIntersectionsActionType.FETCH_ROAD_INTERSECTION_CLUSTER_IDS, fetchRoadIntersectionClusterIds),
  ]);
}

export const selectRoadIntersectionsLoading = createSelector(
  [
    (state: RootState) => state.roadIntersections.intersectionVolumes.state,
    (state: RootState) => state.roadIntersections.intersectionIds.state,
    (state: RootState) => state.roadIntersections.intersectionClusterVolumes.state,
    (state: RootState) => state.roadIntersections.intersectionClusterIds.state,
  ],
  (volumesState, idsState, clusterVolumesState, clsuterIdsState) =>
    volumesState === DataState.LOADING ||
    idsState === DataState.LOADING ||
    clusterVolumesState === DataState.LOADING ||
    clsuterIdsState === DataState.LOADING,
);
