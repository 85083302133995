import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const SearchByPlace: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path d="M11.2,11.4,9.7,16H16V12.8Zm.5-1.6L16,11.1V0H14.8ZM5,4.2A1.7,1.7,0,1,1,3.3,5.9,1.7,1.7,0,0,1,5,4.2ZM0,0V16H8L13.1,0H0M6.8,9.5C6.1,10.7,5,12.4,5,12.4A28.86638,28.86638,0,0,1,3.2,9.5,7.88933,7.88933,0,0,1,1.8,5.9,3.15907,3.15907,0,0,1,5,2.7,3.15907,3.15907,0,0,1,8.2,5.9,7.88933,7.88933,0,0,1,6.8,9.5" />
    </svg>
  </SvgIcon>
);
