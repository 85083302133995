import { Box, styled } from "@mui/material";
import { Button, MapControlContainer } from "components_new";
import { isEqual } from "lodash";
import React, { ChangeEvent, FC, useDeferredValue, useEffect, useMemo, useState } from "react";

import { FiltersPanel } from "features/corridor-discovery/FiltersPanel";
import { areAllItemsUnChecked, getFiltersByMeasure } from "features/filters/Filters";

import { FlexContainer } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { roadVmtActions } from "store/sections/roadVmt";

import { FilterItems, FiltersType, MeasureType } from "types";

export interface FiltersProps {
  loading: boolean;
}

const FiltersControls = styled(FlexContainer)`
  margin-top: 0.5rem;
  justify-content: space-between;
`;

export const RoadVmtFilters: FC<FiltersProps> = ({ loading }) => {
  const dispatch = useAppDispatch();

  const [filters, setFilters] = useState<FiltersType | null>(null);
  const deferredFilters = useDeferredValue(filters);

  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);

  const roadVmtMetadata = useAppSelector((state) => state.roadVmt.roadVmtMetadata);
  const currentFilters = useAppSelector((state) => state.roadVmt.filters);

  const currentCurrentMeasure = useMemo(
    () => roadVmtMetadata.data?.measures.filter((m) => m.columnName === MeasureType.AADT_VMT)[0],
    [roadVmtMetadata.data?.measures],
  );

  const isRevertFiltersOn = useMemo(() => filters && !isEqual(filters, currentFilters), [currentFilters, filters]);
  const areInvalidFilters = useMemo(() => {
    if (filters) {
      return Object.values(filters).some((filter) => areAllItemsUnChecked(filter.items));
    }

    return false;
  }, [filters]);

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    if (filters) {
      const namesArr = event.target.name.split("-");
      const groupName = namesArr[0];
      const itemName = namesArr[1];

      setFilters({
        ...filters,
        [groupName]: {
          ...filters[groupName],
          isChecked: Object.entries(filters[groupName].items).every(([itemKey, item]) => {
            return itemKey === itemName ? !filters[groupName].items[itemName].isChecked : item.isChecked;
          }),
          items: {
            ...filters[groupName].items,
            [itemName]: {
              ...filters[groupName].items[itemName],
              isChecked: !filters[groupName].items[itemName].isChecked,
            },
          },
        },
      });
    }
  };

  const handleChangeAllFilters = (isChecked: boolean) => (groupName: string) => {
    if (filters) {
      setFilters({
        ...filters,
        [groupName]: {
          ...filters[groupName],
          isChecked: isChecked,
          items: Object.entries(filters[groupName].items).reduce((newItems, [itemKey, itemValue]) => {
            newItems[itemKey] = {
              ...itemValue,
              isChecked: isChecked,
            };
            return newItems;
          }, {} as FilterItems),
        },
      });
    }
  };

  const handleSubmit = () => {
    if (filters && selectedFocusArea) {
      if (roadVmtMetadata.state === DataState.AVAILABLE && timePeriod) {
        dispatch(roadVmtActions.updateCurrentFilters(filters));
      }
    }
  };

  const handleRevertFilters = () => {
    setFilters(currentFilters);
  };

  //Get corridor filters set redux state
  useEffect(() => {
    if (!currentFilters && currentCurrentMeasure) {
      const newFilters: FiltersType = getFiltersByMeasure(currentCurrentMeasure);
      dispatch(roadVmtActions.updateCurrentFilters(newFilters));
    }
  }, [selectedFocusArea?.datasetId, currentFilters, currentCurrentMeasure, dispatch]);

  //Set filters on first render
  useEffect(() => {
    if (
      roadVmtMetadata.state === DataState.AVAILABLE &&
      currentFilters &&
      !filters &&
      selectedFocusArea &&
      !selectedFocusArea.datasetId
    ) {
      setFilters(currentFilters);
    }
  }, [filters, currentFilters, selectedFocusArea, roadVmtMetadata]);

  useEffect(() => {
    if (selectedFocusArea) {
      setFilters(null);
    }
  }, [selectedFocusArea, timePeriod]);

  return (
    <MapControlContainer title="Filters">
      <Box padding={1} marginTop={0.5}>
        <FiltersPanel
          filters={deferredFilters}
          loading={loading}
          handleChangeFilter={handleChangeFilter}
          handleChangeAllFilters={handleChangeAllFilters}
        />

        <FiltersControls>
          {isRevertFiltersOn ? (
            <Button variant="outlined" onClick={handleRevertFilters}>
              Revert
            </Button>
          ) : (
            <div />
          )}

          <Button type="submit" disabled={areInvalidFilters || !isRevertFiltersOn} onClick={handleSubmit}>
            Apply
          </Button>
        </FiltersControls>
      </Box>
    </MapControlContainer>
  );
};
