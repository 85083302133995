import { Map as MapboxMap } from "mapbox-gl";

import { ExtendedDirectionalRoadsTileService, ExtendedNonDirectionalRoadsTileService } from "types";

import { reportAboutCustomEventInfo } from "utils/reports";

import { OPTIMAL_SIZE_OF_REMOVING_BATCHING, OPTIMAL_SIZE_OF_UPDATING_BATCHING } from "./constants";

export const getMetersPerPixelAtLatitude = (map: MapboxMap) => {
  const { lat } = map.getCenter();
  const zoom = map.getZoom();

  return (40075016.686 * Math.abs(Math.cos((lat * Math.PI) / 180))) / Math.pow(2, zoom + 8);
};

export const getVisibleFeaturesForLayer = (map: MapboxMap, layer?: string) => {
  return map.queryRenderedFeatures(undefined, {
    layers: layer ? [layer] : [],
  });
};

// Returns the toFromSegmentIdField field name from the tile service if available.
export const getToFromPropertyFromTileserviceIfAvailable = (
  tileService: ExtendedDirectionalRoadsTileService | ExtendedNonDirectionalRoadsTileService,
  property: "toFromSegmentIdField" | "toFromSegmentIndexField",
): string | undefined => {
  return (tileService as ExtendedDirectionalRoadsTileService)[property] ?? undefined;
};

// The use of batching mechanisms is necessary to prevent excessive CPU load. (also, check the comment for batchUpdateFeatureStates)
export const batchRemoveFeatureStates = (
  featureIds: (number | string)[],
  featureStateProperties: string[],
  map: MapboxMap,
  source: string,
  sourceLayer: string,
) => {
  return new Promise<void>((resolve) => {
    let index = 0;

    let isFrameScheduled = false;

    function removeNextBatch() {
      if (!map.getSource(source)) {
        resolve();
        return;
      }

      const nextBatch = featureIds.slice(index, index + OPTIMAL_SIZE_OF_REMOVING_BATCHING);

      nextBatch.forEach((id) => {
        for (const featureName of featureStateProperties) {
          try {
            removeFeatureState(id, featureName, map, source, sourceLayer);
          } catch (e) {
            reportAboutCustomEventInfo("Error removing feature state", {
              featureName,
              source,
              sourceLayer,
              error: e,
            });
          }
        }
      });

      index += nextBatch.length;

      if (index < featureIds.length) {
        if (!isFrameScheduled) {
          isFrameScheduled = true;
          requestAnimationFrame(() => {
            isFrameScheduled = false;
            removeNextBatch();
          });
        }
      } else {
        resolve();
      }
    }

    removeNextBatch();
  });
};

const removeFeatureState = (
  id: number | string,
  featureName: string,
  map: MapboxMap,
  source: string,
  sourceLayer: string,
) => {
  map.removeFeatureState(
    {
      source,
      sourceLayer,
      id,
    },
    featureName,
  );
};

// The use of batching mechanisms is necessary to prevent excessive CPU load.
// The segment update process happens asynchronously by delegating the update tasks for the map to the WebGL engine.
// The actual rendering takes more time than processing the feature state source updates from Mapbox GL.
// This ensures that performance remains stable, as rendering in WebGL can be more time-consuming than updating feature states in Mapbox GL,
// especially when dealing with large or complex datasets.
export const batchUpdateFeatureStates = (
  featureIdStatePairs: Array<[number | string, Record<string, number | string>]>,
  featureStateProperties: string[],
  map: MapboxMap,
  source: string,
  sourceLayer: string,
  setLoading: (loading: boolean) => void,
) => {
  return new Promise<void>((resolve) => {
    let index = 0;

    let isFrameScheduled = false;

    function updateNextBatch() {
      const nextBatch = featureIdStatePairs.slice(index, index + OPTIMAL_SIZE_OF_UPDATING_BATCHING);

      if (!map.getSource(source)) {
        resolve();
        setLoading(false);
        return;
      }

      nextBatch.forEach(([id, state]) => {
        const featureState = featureStateProperties.reduce((acc, prop) => {
          acc[prop] = state[prop];
          return acc;
        }, {} as Record<string, number | string>);

        try {
          map.setFeatureState(
            {
              source,
              sourceLayer,
              id,
            },
            featureState,
          );
        } catch (e) {
          reportAboutCustomEventInfo("Error updating feature state", {
            featureState,
            source,
            sourceLayer,
            error: e,
          });
        }
      });

      index += nextBatch.length;

      if (index < featureIdStatePairs.length) {
        if (!isFrameScheduled) {
          isFrameScheduled = true;
          requestAnimationFrame(() => {
            isFrameScheduled = false;
            updateNextBatch();
          });
        }
      } else {
        resolve();
        setLoading(false);
      }
    }

    updateNextBatch();
  });
};
