import { Alert, Stack } from "@mui/material";
import { ConfigDocument, CustomZoning } from "api/analytics";
import { Button, Dialog, DialogProps, TextField } from "components_new";
import React, { FC } from "react";

interface EditItemDialogProps extends Omit<DialogProps, "onSubmit"> {
  item: CustomZoning | ConfigDocument;
  errorMessage?: string | null;
  onSubmit: (name: string, description: string) => void;
}
export const EditItemDialog: FC<EditItemDialogProps> = ({ item, errorMessage, onClose, onSubmit, ...props }) => {
  const [name, setName] = React.useState(item.name);
  const [description, setDescription] = React.useState(item.description || "");

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(name, description);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      actions={
        <Button size="medium" color="secondary" disabled={!name} onClick={handleSubmit}>
          Update
        </Button>
      }
      {...props}
    >
      <Stack spacing={2} marginY={1}>
        <TextField label="Name" value={name} onChange={handleChangeName} />
        <TextField
          multiline
          maxRows={4}
          minRows={4}
          label="Description"
          value={description}
          onChange={handleChangeDescription}
        />
      </Stack>

      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
    </Dialog>
  );
};
