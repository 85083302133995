import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { MeasureBreakdownArgs } from "api/analytics";
import { MemoryStore, MemoryStoreKeys } from "api/memoryStore";
import { MutableRefObject, useCallback } from "react";
import { useDispatch } from "react-redux";

import { buildFilters, getCurrentMeasure } from "features/filters/utils";

import { analyticsActions } from "store/sections/analytics";

import {
  CustomSelectLinkResultsTileService,
  ExtendedDirectionalRoadsTileService,
  ExtendedNonDirectionalRoadsTileService,
  FiltersType,
  FocusAreaItem,
  RoadsMetadataResponse,
  SegmentIndexesForIdsMapSource,
  SelectedVolume,
} from "types";

import { getReverseSegmentIndex } from "utils/parse";
import { ProfileMode, profileTimeStart } from "utils/profile";

import { LINKS_SEGMENTS_LAYER_ID } from "../scenario/assignment/map-data/layers";
import { PERMANENT_HIGHLIGHTED_FEATURE } from "../select-link/MapController";
import {
  LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID,
  ROADS_HAIRLINES_LAYER_ID,
  ROADS_HIGHLIGHTED_VOLUMES_LAYER_ID,
  ROADS_SEGMENTS_LAYER_ID,
  ROADS_VOLUMES_LAYER_ID,
  getHighlightedVolumesLineWidthExpression,
  getLimitedAccessRoadsLineOpacityExpression,
  getRoadsHighlightedLineOpacityExpression,
  getRoadsLayers,
  getRoadsLineOpacityExpression,
  getVolumesLineWidthExpression,
  getVolumesOffsetExpression,
} from "./map-data/layers";
import { ROADS_SOURCE_ID } from "./map-data/sources";

export const useGetRoadSegmentIndexes = (selectedFocusArea: FocusAreaItem | null, timePeriod: string | null) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    if (timePeriod) {
      profileTimeStart(ProfileMode.ROADS_MODE_GETTING_SEGMENT_INDEXES);

      dispatch(
        analyticsActions.fetchSegmentIndexes({
          timePeriod,
          areaOfInterest: null,
          onlyFromTo: true,
          compression: "gzip",
          datasetId: selectedFocusArea?.datasetId ?? undefined,
        }),
      );
    }
  }, [selectedFocusArea?.datasetId, timePeriod, dispatch]);
};

export const useGetFromToNonZeroVolumeSegmentIndexes = (memoryStore: MemoryStore) => {
  return useCallback(
    (isSelectLinkSegmentCounts?: boolean) => {
      const roadSegmentFromToIndexes = memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_FROM_TO_INDEXES);
      const roadsVolumes = memoryStore.getItem(
        isSelectLinkSegmentCounts ? MemoryStoreKeys.SELECT_LINK_SEGMENT_VOLUMES : MemoryStoreKeys.ROADS_SEGMENT_VOLUMES,
      );

      if (roadSegmentFromToIndexes && roadsVolumes) {
        const fromToSegmentIndexesWithVolumes: number[] = Array.from(
          roadSegmentFromToIndexes.keys().filter((segmentIdx: number) => {
            return roadsVolumes.get(segmentIdx) || roadsVolumes.get(getReverseSegmentIndex(segmentIdx));
          }),
        );

        return fromToSegmentIndexesWithVolumes;
      } else {
        return [];
      }
    },
    [memoryStore],
  );
};

export const useGetRoadsVolumes = (selectedFocusArea: FocusAreaItem | null, timePeriod: string | null) => {
  const dispatch = useDispatch();

  return useCallback(
    (filters: FiltersType, measure: string | null) => {
      if (timePeriod && measure) {
        profileTimeStart(ProfileMode.ROADS_MODE_GETTING_SEGMENT_COUNTS);

        dispatch(
          analyticsActions.fetchRoadsVolumes({
            timePeriod,
            compression: "gzip",
            areaOfInterest: null,
            filter: buildFilters(filters),
            datasetId: selectedFocusArea?.datasetId ?? undefined,
            measure,
          }),
        );
      }
    },
    [selectedFocusArea, timePeriod, dispatch],
  );
};

export const useGetSegmentsDetails = (
  roadsMetadata: RoadsMetadataResponse | null,
  currentRoadFilters: FiltersType | null,
  selectedFocusArea: FocusAreaItem | null,
  timePeriod: string | null,
) => {
  const dispatch = useDispatch();

  return useCallback(
    (selectedVolume: SelectedVolume, measure: string | null) => {
      if (timePeriod && measure && roadsMetadata?.measures) {
        const segmentIds = [selectedVolume.ftSegmentId];
        const segmentIndexes = [selectedVolume.ftSegmentIdx];
        const currentMeasure = getCurrentMeasure(roadsMetadata.measures, measure);

        if (!currentMeasure) return;

        const dimensions = currentMeasure.dimensions?.filter((d) => d.enabled).map((d) => d.columnName) || [];
        const breakdowns: MeasureBreakdownArgs[] = dimensions.map((d) => ({
          dimensions: [d],
          includeUnfiltered: false,
        }));

        if (selectedVolume.tfSegmentId && selectedVolume.tfSegmentIdx) {
          segmentIds.push(selectedVolume.tfSegmentId);
          segmentIndexes.push(selectedVolume.tfSegmentIdx);
        }

        dispatch(
          analyticsActions.fetchSegmentsDetails({
            timePeriod,
            summary: {
              breakdowns,
              filteredTotal: true,
              unfilteredTotal: false,
            },
            measure,
            segmentIndexes,
            filter: buildFilters(currentRoadFilters),
            datasetId: selectedFocusArea?.datasetId ?? undefined,
          }),
        );
        dispatch(
          analyticsActions.fetchSegmentsFeatureDetails({
            timePeriod,
            segmentIds,
          }),
        );
      }
    },
    [roadsMetadata?.measures, selectedFocusArea?.datasetId, currentRoadFilters, timePeriod, dispatch],
  );
};

export const useGetVisibilityStatus = (showRoadVolumesRef: MutableRefObject<boolean>) => {
  return useCallback(
    (layerId: string) => {
      switch (layerId) {
        case ROADS_VOLUMES_LAYER_ID:
        case LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID:
        case ROADS_HAIRLINES_LAYER_ID:
          return showRoadVolumesRef.current ? "visible" : "none";
        default:
          return "visible";
      }
    },
    [showRoadVolumesRef],
  );
};

export const useSelectRoadVolume = (
  updateFeatureStateForRoads: (segmentIdx: number | null, stateName: string, status?: boolean | undefined) => void,
  isSelectLink: boolean,
) => {
  const dispatch = useDispatch();

  return useCallback(
    (selectedRoadVolume: SelectedVolume | null) => {
      if (selectedRoadVolume && isSelectLink) {
        dispatch(
          analyticsActions.addSegmentIdToIdxMap(
            selectedRoadVolume.ftSegmentId,
            selectedRoadVolume.ftSegmentIdx,
            SegmentIndexesForIdsMapSource.SELECT_LINK,
          ),
        );

        updateFeatureStateForRoads(selectedRoadVolume.ftSegmentIdx, PERMANENT_HIGHLIGHTED_FEATURE, true);
      }

      dispatch(analyticsActions.setSelectedRoadVolume(selectedRoadVolume));
    },
    [isSelectLink, updateFeatureStateForRoads, dispatch],
  );
};

export const useSelectRoadVolumeId = () => {
  const dispatch = useDispatch();

  return useCallback(
    (selectedRoadVolumeId: string | number | null) => {
      dispatch(analyticsActions.setSelectedRoadVolumeId(selectedRoadVolumeId));
    },
    [dispatch],
  );
};

export const useChangeRoadVolumeId = (
  selectedRoadVolumeId: string | number | null,
  selectRoadVolumeId: (selectedRoadVolumeId: string) => void,
) => {
  return useCallback(
    (id: string) => {
      if (id !== selectedRoadVolumeId) {
        selectRoadVolumeId(id);
      }
    },
    [selectedRoadVolumeId, selectRoadVolumeId],
  );
};

export const useChangeShowRoadVolumes = (
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
  updateRoadsModeCounts: MutableRefObject<(() => void) | null>,
) => {
  return useCallback(
    (showRoadVolumes: boolean) => {
      if (mapboxLayerManager.current) {
        mapboxLayerManager.current.updateLayerLayout(
          ROADS_HAIRLINES_LAYER_ID,
          "visibility",
          showRoadVolumes ? "visible" : "none",
        );

        mapboxLayerManager.current.updateLayerLayout(
          ROADS_VOLUMES_LAYER_ID,
          "visibility",
          showRoadVolumes ? "visible" : "none",
        );

        mapboxLayerManager.current.updateLayerLayout(
          LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID,
          "visibility",
          showRoadVolumes ? "visible" : "none",
        );

        if (showRoadVolumes) {
          updateRoadsModeCounts.current?.();
        }
      }
    },
    [mapboxLayerManager, updateRoadsModeCounts],
  );
};

export const useUpdateFeatureStateForRoads = (
  map: MutableRefObject<mapboxgl.Map | null>,
  layerName: string | undefined,
) => {
  return useCallback(
    (segmentIdx: number | null, stateName: string, status?: boolean) => {
      if (
        status !== undefined &&
        segmentIdx &&
        map.current &&
        layerName &&
        typeof map.current?.setFeatureState === "function"
      ) {
        map.current.setFeatureState(
          {
            source: ROADS_SOURCE_ID,
            sourceLayer: layerName,
            id: segmentIdx,
          },
          {
            [stateName]: status,
          },
        );
      } else if (segmentIdx && map.current && layerName && typeof map.current?.removeFeatureState === "function") {
        map.current.removeFeatureState(
          {
            source: ROADS_SOURCE_ID,
            sourceLayer: layerName,
            id: segmentIdx,
          },
          stateName,
        );
      }
    },
    [map, layerName],
  );
};

export const useHighlightSelectedSegment = (
  map: MutableRefObject<mapboxgl.Map | null>,
  updateFeatureStateForRoads: (segmentIdx: number | null, stateName: string, status?: boolean | undefined) => void,
) => {
  return useCallback(
    (selectedVolume: SelectedVolume | null) => {
      if (
        map.current &&
        typeof map.current?.getLayer === "function" &&
        map.current.getLayer(ROADS_SEGMENTS_LAYER_ID) &&
        selectedVolume
      ) {
        updateFeatureStateForRoads(selectedVolume.ftSegmentIdx, "selectHighlight", true);
      }
    },
    [map, updateFeatureStateForRoads],
  );
};

export const useCloseRoadsAnalyticsPanel = (
  map: MutableRefObject<mapboxgl.Map | null>,
  selectedRoadVolume: SelectedVolume | null,
  mapboxVolumesPopupRef: MutableRefObject<mapboxgl.Popup | null>,
  selectRoadVolume: (selectedRoadVolume: SelectedVolume | null) => void,
  selectRoadVolumeId: (selectedRoadVolumeId: string | null) => void,
  updateFeatureStateForRoads: (segmentIdx: number | null, stateName: string, status?: boolean | undefined) => void,
  isAssignmentScenario?: boolean,
) => {
  return useCallback(() => {
    if (selectedRoadVolume) {
      if (
        map.current &&
        typeof map.current?.getLayer === "function" &&
        map.current.getLayer(isAssignmentScenario ? LINKS_SEGMENTS_LAYER_ID : ROADS_SEGMENTS_LAYER_ID)
      ) {
        updateFeatureStateForRoads(selectedRoadVolume.ftSegmentIdx, "selectHighlight");
      }

      if (mapboxVolumesPopupRef.current && mapboxVolumesPopupRef.current.isOpen()) {
        mapboxVolumesPopupRef.current.remove();
      }

      selectRoadVolume(null);
      selectRoadVolumeId(null);
    }
  }, [
    isAssignmentScenario,
    map,
    mapboxVolumesPopupRef,
    selectedRoadVolume,
    selectRoadVolume,
    selectRoadVolumeId,
    updateFeatureStateForRoads,
  ]);
};

export const useFilterRoadSegmentsByRoadClasses = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
  tileServer?: ExtendedDirectionalRoadsTileService | ExtendedNonDirectionalRoadsTileService,
) => {
  return useCallback(
    (selectedRoadClasses: number[]) => {
      if (map.current && tileServer) {
        getRoadsLayers(tileServer.layerName).forEach((layer) => {
          if (map.current!.getLayer(layer.id)) {
            mapboxLayerManager.current?.updateLayerFilter(
              layer.id,
              ["in", tileServer.facilityTypeField, ...selectedRoadClasses],
              "road_classes",
            );
          }
        });
      }
    },
    [map, mapboxLayerManager, tileServer],
  );
};

export const useFilterRoadSegmentsByRange = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
  tileServer?:
    | ExtendedDirectionalRoadsTileService
    | ExtendedNonDirectionalRoadsTileService
    | CustomSelectLinkResultsTileService,
) => {
  return useCallback(
    (
      allFromToSegmentIndexes: number[] | null,
      limitedAccessFromToSegmentIndexes: number[],
      restAccessFromToSegmentIndexes: number[],
    ) => {
      if (map && tileServer && allFromToSegmentIndexes) {
        getRoadsLayers(tileServer.layerName).forEach((layer) => {
          if (map.current!.getLayer(layer.id)) {
            switch (layer.id) {
              case ROADS_HAIRLINES_LAYER_ID:
                mapboxLayerManager.current?.updateLayerFilter(
                  layer.id,
                  ["in", tileServer.fromToSegmentIndexField, ...allFromToSegmentIndexes],
                  "range_filter",
                );
                break;
              case ROADS_VOLUMES_LAYER_ID:
                mapboxLayerManager.current?.updateLayerFilter(
                  layer.id,
                  ["in", tileServer.fromToSegmentIndexField, ...restAccessFromToSegmentIndexes],
                  "range_filter",
                );
                break;
              case LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID:
                mapboxLayerManager.current?.updateLayerFilter(
                  layer.id,
                  ["in", tileServer.fromToSegmentIndexField, ...limitedAccessFromToSegmentIndexes],
                  "range_filter",
                );
                break;
              default:
                break;
            }
          }
        });
      } else if (map && tileServer) {
        getRoadsLayers(tileServer.layerName).forEach((layer) => {
          if (
            map.current!.getLayer(layer.id) &&
            (layer.id === ROADS_HAIRLINES_LAYER_ID ||
              layer.id === ROADS_VOLUMES_LAYER_ID ||
              layer.id === LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID)
          ) {
            mapboxLayerManager.current?.removeLayerFilter(layer.id, "range_filter");
          }
        });
      }
    },
    [map, mapboxLayerManager, tileServer],
  );
};

export const useChangeVolumesOpacity = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
) => {
  return useCallback(
    (opacityFactor: number, isSelectLinkResults?: boolean) => {
      if (map.current!.getLayer(LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID)) {
        mapboxLayerManager.current?.updateLayerPaint(
          LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID,
          "line-opacity",
          getLimitedAccessRoadsLineOpacityExpression(opacityFactor, isSelectLinkResults),
        );
      }
      if (map.current!.getLayer(ROADS_VOLUMES_LAYER_ID)) {
        mapboxLayerManager.current?.updateLayerPaint(
          ROADS_VOLUMES_LAYER_ID,
          "line-opacity",
          getRoadsLineOpacityExpression(opacityFactor, isSelectLinkResults),
        );
      }
      if (map.current!.getLayer(ROADS_HIGHLIGHTED_VOLUMES_LAYER_ID)) {
        mapboxLayerManager.current?.updateLayerPaint(
          ROADS_HIGHLIGHTED_VOLUMES_LAYER_ID,
          "line-opacity",
          getRoadsHighlightedLineOpacityExpression(opacityFactor),
        );
      }
    },
    [map, mapboxLayerManager],
  );
};

export const useChangeVolumesWidth = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
) => {
  return useCallback(
    (widthFactor: number) => {
      if (map.current!.getLayer(LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID)) {
        mapboxLayerManager.current?.updateLayerPaint(
          LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID,
          "line-width",
          getVolumesLineWidthExpression(widthFactor),
        );
        mapboxLayerManager.current?.updateLayerPaint(
          LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID,
          "line-offset",
          getVolumesOffsetExpression(widthFactor),
        );
      }
      if (map.current!.getLayer(ROADS_VOLUMES_LAYER_ID)) {
        mapboxLayerManager.current?.updateLayerPaint(
          ROADS_VOLUMES_LAYER_ID,
          "line-width",
          getVolumesLineWidthExpression(widthFactor),
        );
        mapboxLayerManager.current?.updateLayerPaint(
          ROADS_VOLUMES_LAYER_ID,
          "line-offset",
          getVolumesOffsetExpression(widthFactor),
        );
      }
      if (map.current!.getLayer(ROADS_HIGHLIGHTED_VOLUMES_LAYER_ID)) {
        mapboxLayerManager.current?.updateLayerPaint(
          ROADS_HIGHLIGHTED_VOLUMES_LAYER_ID,
          "line-width",
          getHighlightedVolumesLineWidthExpression(widthFactor),
        );
        mapboxLayerManager.current?.updateLayerPaint(
          ROADS_HIGHLIGHTED_VOLUMES_LAYER_ID,
          "line-offset",
          getVolumesOffsetExpression(widthFactor),
        );
      }
    },
    [map, mapboxLayerManager],
  );
};
