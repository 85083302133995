import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CatalogItem, ConfigDocument, CustomZoning, Dataset } from "api/analytics";
import React, { useEffect, useState } from "react";

import { Item } from "./Item";

interface SortableItemProps {
  containerId: UniqueIdentifier;
  id: UniqueIdentifier;
  index: number;
  handle: boolean;
  disabled?: boolean;
  data: Dataset;
  getIndex(id: UniqueIdentifier): number;
  onDatasetRename(dataset: Dataset): void;
  onCatalogItemDelete(catalogItem: CatalogItem): void;
  onCopyDataset(dataset: Dataset, folderId: UniqueIdentifier): void;
  onStopComputing(datasetId: UniqueIdentifier): void;
  onEditItem(item: CustomZoning | ConfigDocument): void;
  onGetZoningInfo(zoning: CustomZoning): void;
}

const useMountStatus = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
};

export const SortableItem = ({
  disabled,
  id,
  index,
  handle,
  containerId,
  data,
  getIndex,
  onDatasetRename,
  onCatalogItemDelete,
  onCopyDataset,
  onStopComputing,
  onEditItem,
  onGetZoningInfo,
}: SortableItemProps) => {
  const { setNodeRef, setActivatorNodeRef, listeners, isDragging, isSorting, transform, transition } = useSortable({
    id,
  });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      value={id}
      data={data}
      dragging={isDragging}
      sorting={isSorting}
      handleProps={{ ref: setActivatorNodeRef }}
      index={index}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      containerId={containerId}
      onDatasetRename={onDatasetRename}
      onCatalogItemDelete={onCatalogItemDelete}
      onCopyDataset={onCopyDataset}
      onStopComputing={onStopComputing}
      onEditItem={onEditItem}
      onGetZoningInfo={onGetZoningInfo}
      handle
    />
  );
};
