import { AnySourceData } from "mapbox-gl";

import { ODTileLayer } from "types";

export const OUT_ZONES_SOURCE_ID = "OUT_ZONES_SOURCE_ID";

export const getDemandSources = (zonesTileService: ODTileLayer) => {
  const demandSources: {
    id: string;
    source: AnySourceData;
  }[] = [
    {
      id: OUT_ZONES_SOURCE_ID,
      source: {
        type: "vector",
        tiles: [zonesTileService.url + "/{z}/{x}/{y}.pbf"],
        promoteId: zonesTileService.idField,
        minzoom: zonesTileService.minzoom,
        maxzoom: zonesTileService.maxzoom,
      } as AnySourceData,
    },
  ];

  return demandSources;
};
