import { useAuth } from "ImsAuthorization";
import memoryStore, { MemoryStoreKeys } from "api/memoryStore";
import { uniq } from "lodash";
import { Map as MapboxMap } from "mapbox-gl";
import { FC, MutableRefObject, useCallback, useEffect, useState } from "react";

import { RangeSlider } from "components";

import { useAppDispatch, useAppSelector, useDebounce } from "hooks";

import { DataState } from "store/interfaces";
import { corridorActions } from "store/sections/corridor";

import { MeasureRange } from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";

import { CORRIDOR_EDGE_LAYER_ID, layerIdsEndings } from "./map-data/corridor/layers";

export interface RangeFilterProps {
  mapController: MutableRefObject<null | any>;
  map: MapboxMap | null;
  label?: string;
}

export const RangeFilter: FC<RangeFilterProps> = ({ label, mapController, map }) => {
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  const [errors, setErrors] = useState({
    min: "",
    max: "",
  });

  const corridorMetadata = useAppSelector((state) => state.corridor.corridorMetadata);
  const serviceOverlayLayersState = useAppSelector((state) => state.corridor.serviceLayers.state);
  const corridorEdgeIds = useAppSelector((state) => state.corridor.corridorEdgeIds);
  const corridorEdgeCounts = useAppSelector((state) => state.corridor.corridorEdgeCounts);
  const edgesRange = useAppSelector((state) => state.corridor.corridorEdgeRange);
  const edgeCountsAvailableRange = useAppSelector((state) => state.corridor.corridorEdgeAvailabeRange);
  const corridorHeatmapConfigurationState = useAppSelector(
    (state) => state.corridor.corridorHeatmapConfiguration.state,
  );

  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);

  const validateRange = (range: [number, number], availableRange: MeasureRange) => {
    const min = range[0];
    const max = range[1];
    setErrors({ min: "", max: "" });

    if (min < availableRange.min) {
      setErrors((prevState) => ({ ...prevState, min: "Less than min value" }));
    }
    if (min > max) {
      setErrors((prevState) => ({ ...prevState, min: "Min > max" }));
    }
    if (max > availableRange.max) {
      setErrors((prevState) => ({ ...prevState, max: "Exceeds max value" }));
    }
    if (max < min) {
      setErrors((prevState) => ({ ...prevState, max: "Max < min" }));
    }
  };

  const setEdgesFilter = useCallback(
    (filteredEdgesIds: number[]) => {
      if (
        map &&
        corridorMetadata.state === DataState.AVAILABLE &&
        corridorHeatmapConfigurationState === DataState.AVAILABLE
      ) {
        const uniqPositiveEdgeIds = uniq(filteredEdgesIds.map(Math.abs));

        Object.entries(corridorMetadata.data.corridorLevels).forEach(([zoningLevelId, zoningLevel]) => {
          const filterCondition = ["in", zoningLevel.edgeTileServiceLayer.idField, ...uniqPositiveEdgeIds];

          layerIdsEndings.forEach((ending) => {
            if (
              map.getLayer(`${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}${ending}`) &&
              zoningLevel?.edgeTileServiceLayer?.name
            ) {
              map.setFilter(`${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}${ending}`, filterCondition);

              if (typeof mapController.current?.updateEdgeCounts === "function") {
                mapController.current.updateEdgeCounts(true, filteredEdgesIds);
              }
            }
          });
        });
      }
    },
    [
      corridorMetadata.data?.corridorLevels,
      corridorMetadata.state,
      corridorHeatmapConfigurationState,
      map,
      mapController,
    ],
  );

  const filterEdges = useCallback(() => {
    const filteredEdgesIds = [];

    if (
      corridorEdgeCounts.state === DataState.AVAILABLE &&
      corridorEdgeIds.state === DataState.AVAILABLE &&
      corridorHeatmapConfigurationState === DataState.AVAILABLE &&
      edgesRange
    ) {
      if (corridorEdgeIds.data) {
        const corridorEdgeIdsData = memoryStore.getItem(MemoryStoreKeys.CORRIDOR_DISCOVERY_EDGE_IDS) as Set<number>;
        const corridorEdgeCountsData = memoryStore.getItem(MemoryStoreKeys.CORRIDOR_DISCOVERY_EDGE_COUNTS) as Map<
          number,
          number
        >;

        for (let id of corridorEdgeIdsData) {
          const edgeCount = corridorEdgeCountsData.get(id);
          const oppositeEdgeCount = corridorEdgeCountsData.get(-id);

          if (edgeCount && edgeCount >= edgesRange[0] && edgeCount <= edgesRange[1]) {
            filteredEdgesIds.push(id);
          }

          if (oppositeEdgeCount && oppositeEdgeCount >= edgesRange[0] && oppositeEdgeCount <= edgesRange[1]) {
            filteredEdgesIds.push(-id);
          }
        }
      }
    }

    setEdgesFilter(filteredEdgesIds);
  }, [
    corridorEdgeCounts.state,
    corridorEdgeIds.data,
    corridorEdgeIds.state,
    corridorHeatmapConfigurationState,
    edgesRange,
    setEdgesFilter,
  ]);

  const debouncedFilterZonesByRange = useDebounce(filterEdges, 100);

  const handleSetRange = (newRange: [number, number]) => {
    if (edgeCountsAvailableRange.state === DataState.AVAILABLE) {
      validateRange(newRange, edgeCountsAvailableRange.data.range);
      dispatch(corridorActions.setCorridorEdgeRange(newRange));

      addCustomGAEvent("corridor", "edges", "change_range", user, userOrganizationName);
    }
  };

  useEffect(() => {
    if (
      edgeCountsAvailableRange.state === DataState.AVAILABLE &&
      corridorHeatmapConfigurationState === DataState.AVAILABLE &&
      serviceOverlayLayersState === DataState.AVAILABLE
    ) {
      debouncedFilterZonesByRange();
    }
  }, [
    edgesRange,
    debouncedFilterZonesByRange,
    edgeCountsAvailableRange.state,
    corridorHeatmapConfigurationState,
    serviceOverlayLayersState,
  ]);

  return (
    <RangeSlider
      label={label}
      range={edgesRange || [0, 0]}
      availableRange={edgeCountsAvailableRange.data?.range || { min: 0, max: 0 }}
      errors={errors}
      resetMin={edgeCountsAvailableRange.data?.quantile || edgeCountsAvailableRange.data?.range.min}
      loading={edgeCountsAvailableRange.state === DataState.LOADING || corridorEdgeCounts.state === DataState.LOADING}
      handleSetRange={handleSetRange}
    />
  );
};
