import { Check, Clear } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { FC } from "react";

export interface ScreenlinePlaceholderProps {
  isDrawScreenlineMode: boolean;
  isEditGeometryMode: boolean;
  screenlinesLength: number;
}

export const ScreenlinePlaceholder: FC<ScreenlinePlaceholderProps> = ({
  isDrawScreenlineMode,
  isEditGeometryMode,
  screenlinesLength,
}) => {
  return (
    <Box paddingX={2} color={"text.secondary"}>
      {isDrawScreenlineMode ? (
        isEditGeometryMode ? (
          <>
            <Typography variant="subtitle2" color={"text.primary"} marginBottom={2} marginTop={1}>
              Editing the geometry of a Screenline
            </Typography>
            <Box component={"ol"} paddingLeft={2}>
              <Typography variant="body2" component={"li"} gutterBottom>
                To edit a vertex, click on it to select it and drag it in to the desired location.
              </Typography>
              <Typography variant="body2" component={"li"} gutterBottom>
                To insert a new vertex, single-click on the line to show the midpoints between vertices. Single-click on
                a midpoint to insert a vertex, then drag it to the desired location.
              </Typography>
              <Typography variant="body2" component={"li"} gutterBottom>
                To delete a vertex, double-click on it. Press{" "}
                <Check fontSize="inherit" color="success" sx={{ marginX: 0.5, marginBottom: "-2px" }} /> to delete the
                vertex or <Clear fontSize="inherit" color="error" sx={{ marginX: 0.5, marginBottom: "-2px" }} /> to
                close the popup.
              </Typography>
              <Typography variant="body2" component={"li"} gutterBottom>
                When you're finished, click "<strong>Confirm</strong>"" to finalize your changes.
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="subtitle2" color={"text.primary"} marginBottom={2} marginTop={1}>
              Drawing Screenlines
            </Typography>
            <Box component={"ol"} paddingLeft={2}>
              <Typography variant="body2" component={"li"} gutterBottom>
                To draw a line or cordon, single-click to add vertices (points defining the Screenline geometry).
                Double-click to finish.
              </Typography>
              <Typography variant="body2" component={"li"} gutterBottom>
                In case the new geometry needs to be modified:
                <Box component={"ul"} paddingLeft={2} paddingTop={0.5}>
                  <li>To edit a vertex, click on it to select it and drag it in to the desired location.</li>
                  <li>
                    To insert a new vertex, single-click on the line to show the midpoints between vertices.
                    Single-click on a midpoint to insert a vertex, then drag it to the desired location.
                  </li>
                  <li>
                    To delete a vertex, double-click on it. Press{" "}
                    <Check fontSize="inherit" color="success" sx={{ marginX: 0.5, marginBottom: "-2px" }} /> to delete
                    the vertex or <Clear fontSize="inherit" color="error" sx={{ marginX: 0.5, marginBottom: "-2px" }} />{" "}
                    to close the popup.
                  </li>
                </Box>
              </Typography>
              <Typography variant="body2" component={"li"} gutterBottom>
                When you're finished, click "<strong>Confirm</strong>"" to finalize your changes.
              </Typography>
            </Box>
          </>
        )
      ) : screenlinesLength === 0 ? (
        <>
          <Typography variant="subtitle2" color={"text.primary"} marginBottom={2} marginTop={1}>
            Defining Screenlines
          </Typography>
          <Typography variant="body2" marginBottom={2}>
            Screenlines sum up the volumes of intersecting road segments, by direction of crossing.
          </Typography>

          <Typography variant="body2" marginBottom={2}>
            Use "<strong>Add Screenline</strong>" to draw a new screenline on the map. This will add all intersections
            with the currently <strong>selected road classes</strong>.
          </Typography>

          <Typography variant="body2" marginBottom={2}>
            You can then <strong>edit</strong> the screenline to <strong>remove individual segment</strong>{" "}
            intersections in the list, or <strong>add candidate segments</strong> by selecting them on the map.
            Candidates can be shown by including more road classes in the filter.
          </Typography>
        </>
      ) : null}
    </Box>
  );
};
