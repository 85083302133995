import { UniqueIdentifier } from "@dnd-kit/core";
import { quantile } from "d3-array";
import protobuf from "protobufjs";

import { getRoadCategoryFromFactype } from "store/utils";

import {
  AddGateArguments,
  AddGateResponse,
  AoiExportRequest,
  AreaAccuracyScatterPlotItem,
  AreaAccuracyScatterPlotRequest,
  AreaAccuracyTableRequest,
  AreaAccuracyTableResponse,
  CorridorEdgeCountStats,
  CorridorHeatmapConfiguration,
  CorridorHeatmapConfigurationRequestParams,
  CorridorMetadata,
  CorridorNodeCountStats,
  Counts,
  CountsByZoneId,
  CustomZoningSelectorItemsResponse,
  DatasetCountsArguments,
  DatasetCountsByZoneIdArguments,
  DatasetGate,
  DatasetMetadata,
  DatasetZoneDetailsArguments,
  EdgesRangeRequest,
  ExportJobs,
  FocusAreaItem,
  Gate,
  GateCoordinates,
  GateCoordinatesArguments,
  GenerateGatesArguments,
  MeasureRange,
  MeasureRangeRequest,
  MeasureRangeResponse,
  NumZonesResponse, //Export
  ODCountsArguments,
  ODCountsByZoneIdArguments,
  ODDatasetComputation,
  ODDatasetConfig,
  ODDatasetExportRequest,
  ODDatasetValidation,
  ODMeasureRange,
  ODMetadata,
  ODMetadataArguments,
  OdDatasetConfigSaveAsRequest,
  OdNumZonesRequest,
  PreparedZoningConfigRequest,
  RoadIntersectionClusterIdDetails,
  RoadIntersectionClusterIdsRequest,
  RoadIntersectionClusterVolumes,
  RoadIntersectionClusterVolumesRequest,
  RoadIntersectionNodeIdsRequest,
  RoadIntersectionVolumeDetailsRequest,
  RoadIntersectionVolumeDetailsResponse,
  RoadIntersectionVolumes,
  RoadIntersectionVolumesRequest,
  RoadMeasureRangeRequest,
  RoadSegmentIndexesByRoadClass,
  RoadSegmentIndexesWithFactype,
  RoadVmtMetadata,
  RoadVmtMetadataRequest,
  RoadVmtZoneCounts,
  RoadVmtZoneCountsRequest,
  RoadVmtZoneDetails,
  RoadVmtZoneDetailsRequest,
  RoadsMetadataArguments,
  RoadsMetadataResponse,
  RoadsVolumes,
  SearchRequest,
  SearchResponse,
  SegmentFeatureDetailsRequest,
  SegmentFeatureDetailsResponse,
  SegmentIndexesRequest,
  SegmentStatsRequest,
  SegmentStatsResponse,
  SegmentVolumeDetail,
  SegmentVolumeDetailsRequest,
  SegmentVolumesRequest,
  SelectLinkConfig,
  SelectLinkConfigCreationRequest,
  SelectLinkConfigUpdateRequest,
  SelectLinkMetadataArguments,
  SelectLinkMetadataResponse,
  SelectLinkSegmentCountsRequest,
  ServiceOverlay,
  ShapesInputFormat,
  SubareaPolygon,
  SubareaPolygonArguments,
  SubareaState,
  SubareaStateArguments,
  UpdateODDatasetConfigPayload,
  UploadZoningResponse,
  ZoneDetails,
  ZoneDetailsArguments,
  ZoneIdAreaPairs,
  ZoneIds,
  ZoneIdsArguments,
  Zoning,
} from "types";

import {
  AOIScreenlineCountsRequest,
  AOIScreenlineDetailsRequest,
  CandidateScreenlineIntersectionsRequest,
  CandidateScreenlineIntersectionsResponse,
  CatalogItem,
  CatalogItemMoveRequest,
  CatalogItemMoveResponse,
  ConfigDocument,
  ConfigDocumentCreationRequest,
  ConfigDocumentPayloadUpdateRequest,
  ConfigDocumentType,
  ConfigDocumentUpdateRequest,
  ConvertFeaturesToScreenlinesRequest,
  ConvertFeaturesToScreenlinesResponse,
  CreateScreenlineRequest,
  CreateScreenlineResponse,
  CustomDatasetRepository,
  CustomZoning,
  CustomZoningCreationRequest,
  CustomZoningUpdateRequest,
  Dataset,
  DatasetCopyRequest,
  DatasetCopyResponse,
  DatasetCreationRequest,
  DatasetFolders,
  DatasetUpdateRequest,
  Folder,
  FolderCreationRequest,
  FolderMoveRequest,
  FolderMoveResponse,
  FolderUpdateRequest,
  FoldersResponse,
  GetConfigDocumentPayloadResponse,
  ReadScreenlineShapefileResponse,
  ScreenlineCountsResponse,
  ScreenlineDetailsResponse,
  ScreenlineValidationRequest,
  ScreenlineValidationResponse,
  ScreenlineValidationSummaryRequest,
  ScreenlineValidationSummaryResponse,
  SearchConfigDocumentsResponse,
  SegmentIndexesForIdsRequest,
  UpdateScreenlineGeometryRequest,
  UpdateScreenlineGeometryResponse,
} from ".";
import RestHandler from "../RestHandler";
import {
  getDatasetVolumesProtobuf,
  getRoadIntersectionClusterVolumesProtobuf,
  getVolumesProtobuf,
  mergeLicensedAreaAndDatasetItems,
  mergeZoningLevelsCounts,
  parseCounts,
  parseNumbers,
  parseSegmentIndexes,
  parseZoneIds,
} from "../helper";
import memoryStore, { MemoryStoreKeys } from "../memoryStore";

export interface AnalyticsApiType {
  // *** Catalog API ***
  deleteConfigDocument(configDocumentId: string): Promise<ConfigDocument>;
  getConfigDocument(configDocumentId: string): Promise<ConfigDocument>;
  getConfigDocumentPayload(configDocumentId: string): Promise<GetConfigDocumentPayloadResponse>;
  updateConfigDocumentPayload(
    configDocumentId: string,
    config: ConfigDocumentPayloadUpdateRequest,
  ): Promise<ConfigDocument>;
  updateConfigDocument(configDocumentId: string, config: ConfigDocumentUpdateRequest): Promise<ConfigDocument>;
  createConfigDocument(config: ConfigDocumentCreationRequest): Promise<ConfigDocument>;
  searchConfigDocuments(
    type: ConfigDocumentType,
    filterByLicensedArea: boolean,
    excludeEmptyFolders: boolean,
    folderId: string | null,
    timePeriod: string | null,
  ): Promise<SearchConfigDocumentsResponse>;
  copyDataset(datasetId: string, config: DatasetCopyRequest): Promise<DatasetCopyResponse>;
  deleteDataset(datasetId: string): Promise<boolean>;
  getDataset(datasetId: string): Promise<Dataset>;
  updateDataset(datasetId: string, config: DatasetUpdateRequest): Promise<Dataset>;
  addDataset(config: DatasetCreationRequest): Promise<Dataset>;
  deleteFolder(folderId: string): Promise<boolean>;
  getFolder(folderId: string): Promise<Folder>;
  changeFolderIndex(folderId: string, config: FolderMoveRequest): Promise<CustomDatasetRepository>;
  updateFolder(folderId: string, config: FolderUpdateRequest): Promise<Folder>;
  createFolder(config: FolderCreationRequest): Promise<Folder>;
  getFolders(): Promise<DatasetFolders>;
  getCatalogItem(catalogItemId: string): Promise<CatalogItem>;
  changeCatalogItemIndex(catalogItemId: string, config: CatalogItemMoveRequest): Promise<CatalogItem[]>;
  deleteCustomZoning(zoningId: string): Promise<boolean>;
  getCustomZoning(zoningId: string): Promise<CustomZoning>;
  updateZoning(zoningItemId: string, config: CustomZoningUpdateRequest): Promise<CustomZoning>;
  createZoning(config: CustomZoningCreationRequest): Promise<CustomZoning>;

  // ! not refactored yet

  getDatasetZoneDetails(datasetId: string, config: DatasetZoneDetailsArguments): Promise<ZoneDetails>;
  getODZoneDetails(config: ZoneDetailsArguments): Promise<ZoneDetails>;
  getODIds(levels: string[], config: ZoneIdsArguments): Promise<ZoneIds>;
  getODCounts(levels: string[], config: ODCountsArguments): Promise<Counts>;
  getODCountsByZoneId(config: ODCountsByZoneIdArguments): Promise<CountsByZoneId>;
  getODMetadata(config: ODMetadataArguments): Promise<ODMetadata>;
  getSubareaState(config: SubareaStateArguments): Promise<SubareaState>;
  getSubareaPolygon(config: SubareaPolygonArguments): Promise<SubareaPolygon>;
  getGeneratedGates(config: GenerateGatesArguments): Promise<Gate[]>;
  getAddGate(config: AddGateArguments): Promise<AddGateResponse>;
  getGateCoordinates: (config: GateCoordinatesArguments) => Promise<GateCoordinates>;
  getRoadsMetadata(config: RoadsMetadataArguments): Promise<RoadsMetadataResponse>;
  getRoadsVolumes(config: SegmentVolumesRequest): Promise<RoadsVolumes>;
  getRoadSegmentIndexes(config: SegmentIndexesRequest): Promise<boolean>;
  getRoadSegmentStats(config: SegmentStatsRequest): Promise<SegmentStatsResponse>;
  getSegmentVolumeDetails(config: SegmentVolumeDetailsRequest): Promise<SegmentVolumeDetail[]>;
  getSegmentsFeatureDetails(config: SegmentFeatureDetailsRequest): Promise<SegmentFeatureDetailsResponse>;
  getFocusAreasAndDatasets(): Promise<FocusAreaItem[]>;
  getSegmentIndexesForIds(config: SegmentIndexesForIdsRequest): Promise<Map<string, number>>;

  // Datasets
  getDatasetCounts(datasetId: string, levels: string[], config: DatasetCountsArguments): Promise<Counts>;
  getDatasetIds(datasetId: string, levels: string[], config: ZoneIdsArguments): Promise<ZoneIds>;
  getDatasetCountsByZoneId(datasetId: string, config: DatasetCountsByZoneIdArguments): Promise<CountsByZoneId>;
  getDatasetMetadata(datasetId: string): Promise<DatasetMetadata>;
  getDatasetGates(datasetId: string): Promise<DatasetGate[]>;
  getODDatasetConfig(datasetId: UniqueIdentifier): Promise<ODDatasetConfig>;
  updateODDatasetConfig(datasetId: string, config: UpdateODDatasetConfigPayload): Promise<ODDatasetConfig>;
  saveAsODDatasetConfig(config: OdDatasetConfigSaveAsRequest): Promise<ODDatasetConfig>;
  validateODDatasetConfig(datasetConfigId: string): Promise<ODDatasetValidation>;
  computeODDataset(datasetConfigId: string, notifyByEmail: boolean): Promise<ODDatasetComputation>;
  cancelODDatasetComputation(datasetConfigId: string): Promise<ODDatasetConfig>;
  getFocusAreas(customZoningId?: string | null, forDatasetCreation?: boolean): Promise<FocusAreaItem[]>;
  getRoadsMeasureRange(request: RoadMeasureRangeRequest): Promise<MeasureRange>;
  getODMeasureRange(levels: string[], request: MeasureRangeRequest): Promise<ODMeasureRange>;
  getDatasetMeasureRange(datasetId: string, levels: string[], request: MeasureRangeRequest): Promise<ODMeasureRange>;

  // Custom zoning
  uploadZoningShapefiles(shapefiles: Blob, formats: ShapesInputFormat): Promise<UploadZoningResponse>;
  prepareZoning(config: PreparedZoningConfigRequest): Promise<UploadZoningResponse>;
  deleteZoning(zoningId: string): Promise<boolean>;
  getCustomZoningSelectorList(): Promise<CustomZoningSelectorItemsResponse>;
  getZoning(zoningId: string): Promise<Zoning>;

  //Export
  getExportJobs(): Promise<ExportJobs>;
  addDatasetExportJob(datasetId: string, request: ODDatasetExportRequest): Promise<string>;
  addAoiExportJob(request: AoiExportRequest): Promise<string>;
  getNumZones(request: OdNumZonesRequest): Promise<NumZonesResponse>;
  recreateExportJob(uuid: string): Promise<string>;

  // Mapbox Geocoding API
  getGeocoding(request: { searchText: string; token: string; proximity: string }): Promise<any>;

  // Analytics search API
  searchFeatureById(search: SearchRequest): Promise<SearchResponse>;

  // Select link analysis
  getSelectLinkSegmentCounts(config: SelectLinkSegmentCountsRequest): Promise<RoadsVolumes>;
  listSelectLinkConfigs(): Promise<SelectLinkConfig[]>;
  createSelectLinkConfig(request: SelectLinkConfigCreationRequest): Promise<SelectLinkConfig>;
  fetchSelectLinkConfig(configId: string): Promise<SelectLinkConfig>;
  updateSelectLinkConfig(configId: string, request: SelectLinkConfigUpdateRequest): Promise<SelectLinkConfig>;
  deleteSelectLinkConfig(configId: string): Promise<SelectLinkConfig>;
  getSelectLinkMetadata(config: SelectLinkMetadataArguments): Promise<SelectLinkMetadataResponse>;

  // Corridor Discovery
  fetchCorridorMetadata(config: any): Promise<CorridorMetadata>;
  fetchCorridorEdgeIds(config: any): Promise<boolean>;
  fetchCorridorEdgeCounts(config: any): Promise<CorridorEdgeCountStats>;
  fetchCorridorEdgeAvailableRange(config: EdgesRangeRequest): Promise<MeasureRangeResponse>;
  fetchCorridorEdgeDetails(config: any): Promise<any>;
  fetchCorridorNodeIds(config: any): Promise<boolean>;
  fetchCorridorNodeCounts(config: any): Promise<CorridorNodeCountStats>;
  fetchCorridorHeatmapConfiguration(
    config: CorridorHeatmapConfigurationRequestParams,
  ): Promise<CorridorHeatmapConfiguration>;
  fetchServiceOverlayLayers(): Promise<ServiceOverlay[]>;

  // Road VMT Analysis
  fetchRoadVmtMetadata(config: RoadVmtMetadataRequest): Promise<RoadVmtMetadata>;
  fetchRoadVmtZoneCounts(level: string, config: RoadVmtZoneCountsRequest): Promise<RoadVmtZoneCounts>;
  fetchRoadVmtZoneDetails(config: RoadVmtZoneDetailsRequest): Promise<RoadVmtZoneDetails>;

  // Screenlines
  // - creation / editing
  candidateScreenlineIntersections(
    config: CandidateScreenlineIntersectionsRequest,
  ): Promise<CandidateScreenlineIntersectionsResponse>;
  createScreenline(config: CreateScreenlineRequest): Promise<CreateScreenlineResponse>;
  validateScreenline(config: ScreenlineValidationRequest): Promise<ScreenlineValidationResponse>;
  validateScreenlines(config: ScreenlineValidationSummaryRequest): Promise<ScreenlineValidationSummaryResponse>;
  updateScreenlineGeometry(config: UpdateScreenlineGeometryRequest): Promise<UpdateScreenlineGeometryResponse>;
  // - importing
  readScreenlineShapefile(zippedShapefile: Blob): Promise<ReadScreenlineShapefileResponse>;
  convertFeaturesToScreenlines(
    config: ConvertFeaturesToScreenlinesRequest,
  ): Promise<ConvertFeaturesToScreenlinesResponse>;
  // - getting counts/details
  fetchAOIScreenlineCounts(config: AOIScreenlineCountsRequest): Promise<ScreenlineCountsResponse>;
  fetchAOIScreenlineDetails(config: AOIScreenlineDetailsRequest): Promise<ScreenlineDetailsResponse>;

  // Data quality
  fetchAreaAccuracyTableData(config: AreaAccuracyTableRequest): Promise<AreaAccuracyTableResponse>;
  fetchAreaAccuracyScatterPlotData(config: AreaAccuracyScatterPlotRequest): Promise<AreaAccuracyScatterPlotItem[]>;

  // Road intersections
  getRoadIntersectionVolumes(config: RoadIntersectionVolumesRequest): Promise<RoadIntersectionVolumes>;
  getRoadIntersectionIds(config: RoadIntersectionNodeIdsRequest): Promise<boolean>;
  getRoadIntersectionVolumeDetails(
    request: RoadIntersectionVolumeDetailsRequest,
  ): Promise<RoadIntersectionVolumeDetailsResponse>;
  getRoadIntersectionClusterVolumes(
    config: RoadIntersectionClusterVolumesRequest,
  ): Promise<RoadIntersectionClusterVolumes>;
  getRoadIntersectionClusterIds(config: RoadIntersectionClusterIdsRequest): Promise<boolean>;
}

export default function AnalyticsApi(restHandler: RestHandler) {
  return {
    // *** Catalog API ***

    /**
     * Deletes a configuration document.
     *
     * @param {string} configDocumentId - The configuration document id.
     * @returns {Promise<ConfigDocument>} A promise that resolves to the deleted configuration document.
     */
    async deleteConfigDocument(configDocumentId: string): Promise<ConfigDocument> {
      return (await restHandler.delete(`catalog/config-document/${configDocumentId}`)) as ConfigDocument;
    },

    /**
     * Get configuration document.
     *
     * @param {string} configDocumentId - The configuration document id.
     * @returns {Promise<ConfigDocument>} A promise that resolves to the configuration document.
     */
    async getConfigDocument(configDocumentId: string): Promise<ConfigDocument> {
      return (await restHandler.get(`catalog/config-document/${configDocumentId}`)) as ConfigDocument;
    },

    /**
     * Get configuration document payload.
     *
     * @param configDocumentId - The configuration document id.
     * @returns {Promise<GetConfigDocumentPayloadResponse>} A promise that resolves to response for getting the payload and associated information of a configuration document.
     */
    async getConfigDocumentPayload(configDocumentId: string): Promise<GetConfigDocumentPayloadResponse> {
      return (await restHandler.get(
        `catalog/config-document/${configDocumentId}/payload`,
      )) as GetConfigDocumentPayloadResponse;
    },

    /**
     * Update configuration document payload.
     *
     * @param configDocumentId - The configuration document id.
     * @param config - Request arguments to update the payload of a configuration document.
     * @returns {Promise<ConfigDocument>} A promise that resolves to the updated configuration document.
     */
    async updateConfigDocumentPayload(
      configDocumentId: string,
      config: ConfigDocumentPayloadUpdateRequest,
    ): Promise<ConfigDocument> {
      return (await restHandler.put(`catalog/config-document/${configDocumentId}/payload`, config)) as ConfigDocument;
    },

    /**
     * Update configuration document.
     *
     * @param configDocumentId - The configuration document id.
     * @param config - The updated configuration data.
     * @returns {Promise<ConfigDocument>} A promise that resolves to the updated configuration document.
     */
    async updateConfigDocument(configDocumentId: string, config: ConfigDocumentUpdateRequest): Promise<ConfigDocument> {
      return (await restHandler.put(`catalog/config-document/${configDocumentId}`, config)) as ConfigDocument;
    },

    /**
     * Create configuration document.
     *
     * @param {ConfigDocumentCreationRequest} config - The configuration document creation request object.
     * @returns {Promise<ConfigDocument>} A promise that resolves to the created configuration document.
     */
    async createConfigDocument(config: ConfigDocumentCreationRequest): Promise<ConfigDocument> {
      return (await restHandler.post(`catalog/config-document`, config)) as ConfigDocument;
    },

    /**
     * Searches for configuration documents of a specific type, and returns the result as a list of folders with
     * matching configuration documents.
     *
     * @param type The type of configuration document to search for
     * @param filterByLicensedArea If true then configuration documents that were stored with a bounding box will only be returned
     *     if the bounding box intersects with the bounding box of the licensed area. Configuration documents without a stored
     *     bounding box are always returned.
     * @param excludeEmptyFolders Indicates whether to exclude folders that do not contain any matching configuration documents.
     *     Typically: true if the list is used to opening an existing document, false for saving a new configuration document.
     * @param folderId Optional folder ID to limit the search to
     * @param timePeriod Optional time period to search configuration documents for. If a configuration document is not stored
     *     for a specific time period, it will be always be returned.
     * @returns {Promise<SearchConfigDocumentsResponse>} A promise that resolves to the list of folders with matching configuration documents
     */
    async searchConfigDocuments(
      type: ConfigDocumentType,
      filterByLicensedArea: boolean = false,
      excludeEmptyFolders: boolean = false,
      folderId: string | null = null,
      timePeriod: string | null = null,
    ): Promise<SearchConfigDocumentsResponse> {
      return (await restHandler.get(
        `catalog/config-document?type=${type}&filterByLicensedArea=${filterByLicensedArea}&excludeEmptyFolders=${excludeEmptyFolders}` +
          (folderId ? `&folderId=${folderId}` : ``) +
          (timePeriod ? `&timePeriod=${timePeriod}` : ``),
      )) as SearchConfigDocumentsResponse;
    },

    /**
     * Copies an existing dataset with the specified configuration.
     *
     * @param datasetId - The unique identifier of the dataset to be copied.
     * @param config - The configuration for the dataset copy request.
     * @returns {Promise<DatasetCopyResponse>} A promise that resolves to the response of the dataset copy operation.
     */
    async copyDataset(datasetId: string, config: DatasetCopyRequest): Promise<DatasetCopyResponse> {
      return (await restHandler.post(`catalog/dataset/${datasetId}/copy`, config)) as DatasetCopyResponse;
    },

    /**
     * Deletes a dataset with the specified ID.
     *
     * @param datasetId - The ID of the dataset to delete.
     * @returns {Promise<boolean>} A promise that resolves to a boolean indicating whether the dataset was successfully deleted.
     */
    async deleteDataset(datasetId: string): Promise<boolean> {
      const body = (await restHandler.delete(`catalog/dataset/${datasetId}`)) as Dataset;

      return Promise.resolve(body.id === datasetId);
    },

    /**
     * Fetches a dataset by its ID.
     *
     * @param datasetId - The unique identifier of the dataset to retrieve.
     * @returns {Promise<Dataset>} A promise that resolves to the dataset object.
     */
    async getDataset(datasetId: string): Promise<Dataset> {
      return await restHandler.get<Dataset>(`catalog/dataset/${datasetId}`);
    },

    /**
     * Updates the dataset with the given dataset ID using the provided configuration.
     *
     * @param datasetId - The unique identifier of the dataset to be updated.
     * @param config - The configuration object containing the updates to be applied to the dataset.
     * @returns {Promise<Dataset>} A promise that resolves to the updated dataset.
     */
    async updateDataset(datasetId: string, config: DatasetUpdateRequest): Promise<Dataset> {
      return (await restHandler.put(`catalog/dataset/${datasetId}`, config)) as Dataset;
    },

    /**
     * Adds a new dataset to the catalog.
     *
     * @param {DatasetCreationRequest} config - The configuration object for creating the dataset.
     * @returns {Promise<Dataset>} A promise that resolves to the created dataset.
     */
    async addDataset(config: DatasetCreationRequest): Promise<Dataset> {
      return (await restHandler.post(`catalog/dataset`, config)) as Dataset;
    },

    /**
     * Deletes a dataset folder by its ID.
     *
     * @param folderId - The ID of the folder to be deleted.
     * @returns A promise that resolves to a boolean indicating whether the folder was successfully deleted.
     */
    async deleteFolder(folderId: string): Promise<boolean> {
      const body = (await restHandler.delete(`catalog/folder/${folderId}`)) as Folder;

      return Promise.resolve(body.folderId === folderId);
    },

    /**
     * Retrieves a dataset folder by its ID.
     *
     * @param folderId - The unique identifier of the folder to retrieve.
     * @returns A promise that resolves to a Folder object.
     */
    async getFolder(folderId: string): Promise<Folder> {
      return (await restHandler.get(`catalog/folder/${folderId}`)) as Folder;
    },

    /**
     * Changes the index of a folder and updates its contents recursively.
     *
     * @param folderId - The ID of the folder to be moved.
     * @param config - The configuration for moving the folder, including the new index.
     * @returns {Promise<CustomDatasetRepository>} A promise that resolves to a `CustomDatasetRepository` containing the updated folder information.
     *
     * @throws Will throw an error if the request to change the folder index fails.
     */
    async changeFolderIndex(folderId: string, config: FolderMoveRequest): Promise<CustomDatasetRepository> {
      const body = (await restHandler.put(
        `catalog/folder/${folderId}/index?recursive=true`,
        config,
      )) as FolderMoveResponse;

      const folders: CustomDatasetRepository = {};

      for (const folder of body.updatedFolderList) {
        folders[folder.folderId] = {
          folderName: folder.folderName,
          items: folder?.items || [],
          permissions: folder.permissions,
        };
      }

      return Promise.resolve(folders);
    },

    /**
     * Updates the specified folder with the provided configuration.
     *
     * @param folderId - The unique identifier of the folder to be updated.
     * @param config - The configuration object containing the updates for the folder.
     * @returns {Promise<Folder>} A promise that resolves to the updated Folder object.
     */
    async updateFolder(folderId: string, config: FolderUpdateRequest): Promise<Folder> {
      return (await restHandler.put(`catalog/folder/${folderId}`, config)) as Folder;
    },

    /**
     * Creates a new folder in the catalog.
     *
     * @param {FolderCreationRequest} config - The configuration object for creating the folder.
     * @returns {Promise<Folder>} A promise that resolves to the created folder.
     */
    async createFolder(config: FolderCreationRequest): Promise<Folder> {
      return (await restHandler.post(`catalog/folder`, config)) as Folder;
    },

    /**
     * Retrieves dataset folders from the catalog.
     *
     * @returns {Promise<DatasetFolders>} A promise that resolves to an object containing the dataset folders and their permissions.
     *
     * @throws {Error} If the request to the catalog fails.
     */
    async getFolders(): Promise<DatasetFolders> {
      const body = (await restHandler.get(
        "catalog/folder-v2?recursive=true&include-permissions=true",
        undefined,
        true,
      )) as FoldersResponse;

      const folders: CustomDatasetRepository = {};

      for (const folder of body.folders) {
        folders[folder.folderId] = {
          folderName: folder.folderName,
          items: folder?.items || [],
          permissions: folder.permissions,
        };
      }

      return Promise.resolve({ folders, permissions: body.permissions });
    },

    /**
     * Fetches a catalog item by its ID.
     *
     * @param {string} catalogItemId - The ID of the catalog item to retrieve.
     * @returns {Promise<CatalogItem>} A promise that resolves to the catalog item.
     */
    async getCatalogItem(catalogItemId: string): Promise<CatalogItem> {
      return (await restHandler.get(`catalog/item/${catalogItemId}`)) as CatalogItem;
    },

    /**
     * Changes the index of a catalog item.
     *
     * @param catalogItemId - The ID of the catalog item to be moved.
     * @param config - The configuration for moving the catalog item, including the new index.
     * @returns {Promise<CatalogItem[]>} A promise that resolves to an array of updated catalog items in the target folder.
     */
    async changeCatalogItemIndex(catalogItemId: string, config: CatalogItemMoveRequest): Promise<CatalogItem[]> {
      const body = (await restHandler.put(
        `catalog/item/${catalogItemId}/index?recursive=true`,
        config,
      )) as CatalogItemMoveResponse;

      return body.updatedTargetFolderItemList;
    },

    /**
     * Deletes a custom zoning by its ID.
     *
     * @param {string} zoningId - The ID of the custom zoning to delete.
     * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating whether the deletion was successful.
     */
    async deleteCustomZoning(zoningId: string): Promise<boolean> {
      const body = (await restHandler.delete(`catalog/zoning/${zoningId}`)) as CustomZoning;

      return body?.zoningId === zoningId;
    },

    /**
     * Fetches custom zoning information based on the provided zoning ID.
     *
     * @param {string} zoningId - The unique identifier of the zoning to retrieve.
     * @returns {Promise<CustomZoning>} A promise that resolves to the custom zoning data.
     */
    async getCustomZoning(zoningId: string): Promise<CustomZoning> {
      return (await restHandler.get(`catalog/zoning/${zoningId}`)) as CustomZoning;
    },

    /**
     * Updates a zoning item with the specified configuration.
     *
     * @param zoningItemId - The ID of the zoning item to update.
     * @param config - The configuration object containing the updates for the zoning item.
     * @returns {Promise<CustomZoning>} A promise that resolves to the updated CustomZoning.
     */
    async updateZoning(zoningItemId: string, config: CustomZoningUpdateRequest): Promise<CustomZoning> {
      return (await restHandler.put(`catalog/zoning/${zoningItemId}`, config)) as CustomZoning;
    },

    /**
     * Creates a new custom zoning configuration.
     *
     * @param {CustomZoningCreationRequest} config - The configuration for the custom zoning to be created.
     * @returns {Promise<CustomZoning>} A promise that resolves to the created custom zoning.
     */
    async createZoning(config: CustomZoningCreationRequest): Promise<CustomZoning> {
      return (await restHandler.post(`/catalog/zoning`, config)) as CustomZoning;
    },

    // ! not refactored yet

    async getDatasetCounts(datasetId: string, levels: string[], config: DatasetCountsArguments): Promise<Counts> {
      const allBodies = await Promise.all(
        levels.map((level) =>
          restHandler
            .postForBinary(`od/datasets/${datasetId}/counts`, {
              ...config,
              level: level,
            })
            .then((res) => {
              return parseCounts(res, level, "OdCountWithIsGate");
            }),
        ),
      );

      return mergeZoningLevelsCounts(allBodies);
    },

    async getDatasetCountsByZoneId(datasetId: string, config: DatasetCountsByZoneIdArguments): Promise<CountsByZoneId> {
      const counts = await restHandler.postForBinary(`od/datasets/${datasetId}/counts`, config).then((res) => {
        return parseCounts(res, config.selectedId, "OdCountWithIsGate");
      });

      return {
        counts,
        selectedZoneId: config.selectedId,
      };
    },

    async getDatasetIds(datasetId: string, levels: string[], config: ZoneIdsArguments): Promise<ZoneIds> {
      const allBodies = (await Promise.all(
        levels.map((level) =>
          restHandler.post(`od/datasets/${datasetId}/zone-ids/json`, {
            ...config,
            level: level,
          }),
        ),
      )) as ZoneIdAreaPairs[];

      return allBodies.reduce((zoneIds: ZoneIds, body: ZoneIdAreaPairs, i) => {
        zoneIds.set(levels[i], parseZoneIds(body));

        return zoneIds;
      }, new Map());
    },

    async getDatasetMetadata(datasetId: string): Promise<DatasetMetadata> {
      const body = await restHandler.get<DatasetMetadata>(`od/datasets/${datasetId}/metadata`);
      return body;
    },

    async getDatasetGates(datasetId: string): Promise<DatasetGate[]> {
      const body = await restHandler.get<DatasetGate[]>(`od/datasets/${datasetId}/gates`);
      return body;
    },

    async getDatasetZoneDetails(datasetId: string, config: DatasetZoneDetailsArguments): Promise<ZoneDetails> {
      const body = await restHandler.post(`od/datasets/${datasetId}/selection-details`, config);
      return body as ZoneDetails;
    },

    /*
     Get zone details for a single zone
     Return details for a single selected zone, to be shown in an information sidebar or popup
     */
    async getODZoneDetails(config: ZoneDetailsArguments): Promise<ZoneDetails> {
      const body = await restHandler.post(`od/zone-details`, config);
      return body as ZoneDetails;
    },

    /*
     Get incoming/outgoing counts for entitled zones in the area
     For all zones in the entitlement, get the outgoing or incoming trip counts for a specified filter configuration
     */
    async getODCounts(levels: string[], config: ODCountsArguments): Promise<Counts> {
      const allBodies = await Promise.all(
        levels.map((level) =>
          restHandler
            .postForBinary(`od/zone-counts`, {
              ...config,
              level: level,
            })
            .then((res) => {
              return parseCounts(res, level, "OdCount");
            }),
        ),
      );

      return mergeZoningLevelsCounts(allBodies);
    },

    async getODCountsByZoneId(config: ODCountsByZoneIdArguments): Promise<CountsByZoneId> {
      const counts = await restHandler.postForBinary(`od/zone-counts`, config).then((res) => {
        return parseCounts(res, config.selectedZoneId, "OdCount");
      });

      return {
        counts,
        selectedZoneId: config.selectedZoneId,
      };
    },

    async getODIds(levels: string[], config: ZoneIdsArguments): Promise<ZoneIds> {
      const allBodies = (await Promise.all(
        levels.map((level) =>
          restHandler.post(`od/zone-ids/json`, {
            ...config,
            level: level,
          }),
        ),
      )) as ZoneIdAreaPairs[];

      return allBodies.reduce((zoneIds: ZoneIds, body: ZoneIdAreaPairs, i) => {
        zoneIds.set(levels[i], parseZoneIds(body));

        return zoneIds;
      }, new Map());
    },

    /*
     Return metadata needed to create the filter UI and render the OD map content
    */
    async getODMetadata(config: ODMetadataArguments): Promise<ODMetadata> {
      const body = await restHandler.post(`od/metadata`, config);
      return body as ODMetadata;
    },

    /*
     While editing the sub-area geometry, the selected zones as well as the road segments that are candidates for gates (intersecting boundary, selected facility types) should be highlighted. This endpoint returns both the zone ids and the from-two segment ids of the road features
    */
    async getSubareaState(config: SubareaStateArguments): Promise<SubareaState> {
      const body = await restHandler.post(`od/config/subarea-state`, config);
      return body as SubareaState;
    },

    /*
     Return polygon for a given subarea
    */
    async getSubareaPolygon(config: SubareaPolygonArguments): Promise<SubareaPolygon> {
      const body: any = await restHandler.post(`/od/config/subarea-polygon`, config);

      return body as SubareaPolygon;
    },

    /*
     Return possible gates for a given subarea
    */
    async getGeneratedGates(config: GenerateGatesArguments): Promise<Gate[]> {
      const body: any = await restHandler.post(`/od/config/gates/generate`, config);
      return body.gates as Gate[];
    },

    /*
     Return new Gate and list of updated, unchanged and deleted gates
    */
    async getAddGate(config: AddGateArguments): Promise<AddGateResponse> {
      const body: any = await restHandler.post(`/od/config/gates/add`, config);
      return body as AddGateResponse;
    },

    /*
     Return Gate new centroid coordinates based on his segments
    */
    async getGateCoordinates(config: GateCoordinatesArguments): Promise<GateCoordinates> {
      const body: any = await restHandler.post(`/od/config/gates/getcoordinates`, config);
      return body as GateCoordinates;
    },

    /*
     Return metadata needed to create the filter UI and render the roads map content
    */
    async getRoadsMetadata(config: RoadsMetadataArguments): Promise<RoadsMetadataResponse> {
      const body = await restHandler.post(`roads/metadata`, config);
      return body as RoadsMetadataResponse;
    },

    /*
     Return volumes for road segments
    */
    async getRoadsVolumes(config: SegmentVolumesRequest): Promise<RoadsVolumes> {
      const response = config.datasetId
        ? getDatasetVolumesProtobuf(restHandler, "roads/segment-volumes", config, "SegmentVolume")
        : getVolumesProtobuf(restHandler, "roads/segment-volumes", config, "SegmentVolume");

      return response.then((res) => {
        memoryStore.setItem(MemoryStoreKeys.ROADS_SEGMENT_VOLUMES, res?.volumes);

        return {
          measure: config.measure,
          maxVolume: res?.maxVolume,
          minVolume: res?.minVolume,
          size: res?.volumes.size,
        } as RoadsVolumes;
      });
    },

    /*
     Return road segment ids needed to filter the road segments by id
    */
    async getRoadSegmentIndexes(config: SegmentIndexesRequest): Promise<boolean> {
      return await restHandler.postForBinary(`roads/segment-indexes`, config).then((res) => {
        const segmentIndexesWithFactype: RoadSegmentIndexesWithFactype = new Map();
        const buffer = new Uint8Array(res);
        const reader = protobuf.Reader.create(buffer);

        return Promise.resolve(
          protobuf
            .load("/SegmentIndex.proto")
            .then((root: any) => {
              const segmentIndexes = root.lookupType("analytics.clickhouse.SegmentIndex");

              while (reader.pos < reader.len) {
                const msg = segmentIndexes.decodeDelimited(reader);
                segmentIndexesWithFactype.set(msg.segmentIdx, {
                  factype: msg.roadClass,
                });
              }

              const roadSegmentIndexesByRoadClass: RoadSegmentIndexesByRoadClass = {
                limitedAccess: [],
                other: [],
              };

              for (const [key, value] of segmentIndexesWithFactype) {
                roadSegmentIndexesByRoadClass[getRoadCategoryFromFactype(value.factype)].push(key);
              }

              memoryStore.setItem(MemoryStoreKeys.ROADS_SEGMENT_FROM_TO_INDEXES, segmentIndexesWithFactype);
              memoryStore.setItem(
                MemoryStoreKeys.ROADS_SEGMENT_FROM_TO_INDEXES_BY_ROAD_CLASS,
                roadSegmentIndexesByRoadClass,
              );

              return true;
            })
            .catch((err: any) => {
              throw new Error(err);
            }),
        );
      });
    },

    // Get road segment statistics for the specified area and optional zoom level.
    async getRoadSegmentStats(config: SegmentStatsRequest): Promise<SegmentStatsResponse> {
      const body: any = await restHandler.post(`roads/segment-stats`, config);
      return body as SegmentStatsResponse;
    },

    // Get road segment details for a single segment
    async getSegmentVolumeDetails(config: SegmentVolumeDetailsRequest): Promise<SegmentVolumeDetail[]> {
      const body: any = await restHandler.post(`roads/segment-volume-details`, config);
      return body?.segments as SegmentVolumeDetail[];
    },

    // Get road segment feature details for a single segment
    async getSegmentsFeatureDetails(config: SegmentFeatureDetailsRequest): Promise<SegmentFeatureDetailsResponse> {
      const body: any = await restHandler.post(`roads/segment-feature-details`, config);
      return body as SegmentFeatureDetailsResponse;
    },

    // Get OD dataset configuration
    async getODDatasetConfig(datasetId: UniqueIdentifier): Promise<ODDatasetConfig> {
      const body = (await restHandler.get(`od/config/${datasetId}`)) as ODDatasetConfig;

      return body;
    },

    // Update OD dataset configuration
    async updateODDatasetConfig(datasetId: string, config: UpdateODDatasetConfigPayload): Promise<ODDatasetConfig> {
      const body = (await restHandler.put(`od/config/${datasetId}`, config)) as ODDatasetConfig;

      return body;
    },

    // Save current editor settings for the dataset as a new OD dataset configuration
    async saveAsODDatasetConfig(config: OdDatasetConfigSaveAsRequest): Promise<ODDatasetConfig> {
      const body = (await restHandler.post("od/config/save-as", config)) as ODDatasetConfig;

      return body;
    },

    // Validate the persisted configuration
    async validateODDatasetConfig(datasetConfigId: string): Promise<ODDatasetValidation> {
      const body: any = await restHandler.post(`od/config/${datasetConfigId}/validation`, {});

      return body as ODDatasetValidation;
    },

    // Compute OD dataset
    async computeODDataset(datasetConfigId: string, notifyByEmail: boolean): Promise<ODDatasetComputation> {
      const body: any = await restHandler.post(`od/config/${datasetConfigId}/computation`, {
        disableEmailNotifications: !notifyByEmail,
      });

      return body as ODDatasetComputation;
    },

    // Cancel OD dataset computation
    async cancelODDatasetComputation(datasetConfigId: string): Promise<ODDatasetConfig> {
      const body: any = await restHandler.delete(`od/config/${datasetConfigId}/computation`);

      return body as ODDatasetConfig;
    },

    // Get measure range for roads
    async getRoadsMeasureRange(request: RoadMeasureRangeRequest): Promise<MeasureRange> {
      const body: any = await restHandler.post("/roads/measure-range", request);

      return body.range;
    },

    // Get measure range for OD
    async getODMeasureRange(levels: string[], request: MeasureRangeRequest): Promise<ODMeasureRange> {
      const allBodies: any = await Promise.all(
        levels.map((level) => restHandler.post("/od/measure-range", { ...request, level })),
      );

      return levels.reduce((obj: { [key: string]: any }, level, i: number) => {
        obj[level] = allBodies[i].range;
        return obj;
      }, {});
    },

    // Get measure range for dataset
    async getDatasetMeasureRange(
      datasetId: string,
      levels: string[],
      request: MeasureRangeRequest,
    ): Promise<ODMeasureRange> {
      const allBodies: any = await Promise.all(
        levels.map((level) => restHandler.post(`/od/datasets/${datasetId}/measure-range`, { ...request, level })),
      );

      return levels.reduce((obj: { [key: string]: any }, level, i: number) => {
        obj[level] = allBodies[i].range;
        return obj;
      }, {});
    },

    // ----- EXPORT -------

    // Get a list of export jobs for the logged in user
    async getExportJobs(): Promise<ExportJobs> {
      const response = await restHandler.post("export/list-jobs", {}, undefined, true);
      return response as ExportJobs;
    },

    // Add new dataset export job to the queue
    async addDatasetExportJob(datasetId: string, request: ODDatasetExportRequest): Promise<string> {
      const response = await restHandler.post(`export/dataset/${datasetId}`, request);
      return response as string;
    },

    // Add new aoi export job to the queue
    async addAoiExportJob(request: AoiExportRequest): Promise<string> {
      const response = await restHandler.post("export/aoi", request);
      return response as string;
    },

    // Trigger recreation of export job for the given uuid
    async recreateExportJob(uuid: string): Promise<string> {
      const response = await restHandler.post(`export/recreate/${uuid}`, {});
      return response as string;
    },

    // Get number of zones for the area of interest within entitled area of the user
    async getNumZones(request: OdNumZonesRequest): Promise<NumZonesResponse> {
      const response = await restHandler.post("od/num-zones", request);
      return response as NumZonesResponse;
    },

    async getGeocoding(request: { searchText: string; token: string; proximity: string }): Promise<any> {
      const { searchText, token, proximity } = request;
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json?access_token=${token}&autocomplete=true&country=US&language=en&types=region,place,postcode,locality,neighborhood,address&proximity=${proximity}&limit=8`,
      );

      return response.json();
    },

    async searchFeatureById(request: SearchRequest): Promise<SearchResponse> {
      const response = await restHandler.post("search", request);
      return response as SearchResponse;
    },

    async getFocusAreasAndDatasets(): Promise<FocusAreaItem[]> {
      const urls = [
        "dashboard/licensed-area-item?includeGeometry=true&includeAreaUnits=true",
        "dashboard/dataset-item?includeGeometry=true&includeAreaUnits=true",
      ];
      const focusAreaItems = await Promise.all(urls.map(async (url) => await restHandler.get(url)));

      return mergeLicensedAreaAndDatasetItems(focusAreaItems) as FocusAreaItem[];
    },

    async getFocusAreas(customZoningId?: string | null, forDatasetCreation?: boolean): Promise<FocusAreaItem[]> {
      const focusAreaItems: any = await restHandler.get(
        `dashboard/licensed-area-item?includeAreaUnits=true` +
          (customZoningId ? `&customZoningId=${customZoningId}` : ``) +
          (forDatasetCreation ? `&forDatasetCreation=true` : ``),
      );
      return mergeLicensedAreaAndDatasetItems([focusAreaItems]) as FocusAreaItem[];
    },

    async uploadZoningShapefiles(shapefiles: Blob, formats: ShapesInputFormat): Promise<UploadZoningResponse> {
      const response = await restHandler.post(`zoning/staged?format=${formats}`, shapefiles, {
        headers: { "content-type": "application/octet-stream" },
      });

      return response as UploadZoningResponse;
    },

    async prepareZoning(config: PreparedZoningConfigRequest): Promise<UploadZoningResponse> {
      const response = await restHandler.post(`zoning/prepared`, config);

      return response as UploadZoningResponse;
    },
    async deleteZoning(zoningId: string): Promise<boolean> {
      const body: any = await restHandler.delete(`zoning/${zoningId}`);

      return body?.zoningId === zoningId;
    },

    async getZoning(zoningId: string): Promise<Zoning> {
      const body: any = await restHandler.get(`zoning/${zoningId}`);

      return body as Zoning;
    },

    async getCustomZoningSelectorList(): Promise<CustomZoningSelectorItemsResponse> {
      const body: CustomZoningSelectorItemsResponse = await restHandler.get(`od/config/custom-zoning`);

      return body;
    },

    // Return segment counts for select link analysis
    async getSelectLinkSegmentCounts(config: SelectLinkSegmentCountsRequest): Promise<RoadsVolumes> {
      const response = getVolumesProtobuf(
        restHandler,
        "select-link/segment-volumes",
        config,
        "SelectLinkSegmentVolume",
      );

      return response.then((res) => {
        memoryStore.setItem(MemoryStoreKeys.SELECT_LINK_SEGMENT_VOLUMES, res?.volumes);

        return {
          measure: config.measure,
          maxVolume: res?.maxVolume,
          minVolume: res?.minVolume,
          size: res?.volumes.size,
        } as RoadsVolumes;
      });
    },

    async listSelectLinkConfigs(): Promise<SelectLinkConfig[]> {
      const response = await restHandler.get("select-link/config", {});
      return response as SelectLinkConfig[];
    },

    async createSelectLinkConfig(request: SelectLinkConfigCreationRequest): Promise<SelectLinkConfig> {
      const response = await restHandler.post("select-link/config", request);
      return response as SelectLinkConfig;
    },

    async fetchSelectLinkConfig(configId: string): Promise<SelectLinkConfig> {
      const response = await restHandler.get(`select-link/config/${configId}`);
      return response as SelectLinkConfig;
    },

    async updateSelectLinkConfig(configId: string, request: SelectLinkConfigUpdateRequest): Promise<SelectLinkConfig> {
      const response = await restHandler.put(`select-link/config/${configId}`, request);
      return response as SelectLinkConfig;
    },

    async deleteSelectLinkConfig(configId: string): Promise<SelectLinkConfig> {
      const response = await restHandler.delete(`select-link/config/${configId}`);
      return response as SelectLinkConfig;
    },

    async getSelectLinkMetadata(config: RoadsMetadataArguments): Promise<SelectLinkMetadataResponse> {
      const body = await restHandler.post(`select-link/metadata`, config);
      return body as SelectLinkMetadataResponse;
    },

    // Corridor Discovery
    async fetchCorridorMetadata(config: any): Promise<CorridorMetadata> {
      const body = await restHandler.post(`corridor/metadata`, config);
      return body as CorridorMetadata;
    },

    async fetchCorridorEdgeIds(config: any): Promise<boolean> {
      return await restHandler.postForBinary(`corridor/edge-ids`, config).then((res) => {
        const edgeIdsPromise = parseNumbers(res, "CorridorEdgeIds", "edgeId");

        return edgeIdsPromise
          .then((edgeIds) => {
            memoryStore.setItem(MemoryStoreKeys.CORRIDOR_DISCOVERY_EDGE_IDS, new Set(edgeIds));

            return true;
          })
          .catch((err: any) => {
            throw new Error(err);
          });
      });
    },

    async fetchCorridorEdgeCounts(config: any): Promise<CorridorEdgeCountStats> {
      const response = getVolumesProtobuf(restHandler, "corridor/edge-counts", config, "CorridorEdgeCount", "edgeId");

      return response.then((res) => {
        memoryStore.setItem(MemoryStoreKeys.CORRIDOR_DISCOVERY_EDGE_COUNTS, res?.volumes);

        return {
          size: (res?.volumes?.size as number) || 0,
          maxVolume: (res?.maxVolume as number) || 0,
          minVolume: res?.minVolume,
          quantile: Math.round(quantile(Array.from(res?.volumes.values()), 0.9) || res?.minVolume),
        };
      });
    },

    async fetchCorridorEdgeAvailableRange(config: EdgesRangeRequest): Promise<MeasureRangeResponse> {
      const body: any = await restHandler.post(`corridor/edge-counts-range`, config);

      return body;
    },

    async fetchCorridorNodeIds(config: any): Promise<boolean> {
      return await restHandler.postForBinary(`corridor/node-ids`, config).then((res) => {
        const nodeIdsPromise = parseNumbers(res, "CorridorNodeIds", "nodeId");

        return nodeIdsPromise
          .then((nodeIds) => {
            memoryStore.setItem(MemoryStoreKeys.CORRIDOR_DISCOVERY_NODE_IDS, new Set(nodeIds));

            return true;
          })
          .catch((err: any) => {
            throw new Error(err);
          });
      });
    },

    async fetchCorridorNodeCounts(config: any): Promise<CorridorNodeCountStats> {
      const response = getVolumesProtobuf(restHandler, "corridor/node-counts", config, "CorridorNodeCount", "nodeId");

      return response.then((res) => {
        memoryStore.setItem(MemoryStoreKeys.CORRIDOR_DISCOVERY_NODE_COUNTS, res?.volumes);

        return {
          size: (res?.volumes?.size as number) || 0,
          maxVolume: (res?.maxVolume as number) || 0,
        };
      });
    },

    async fetchCorridorHeatmapConfiguration(
      config: CorridorHeatmapConfigurationRequestParams,
    ): Promise<CorridorHeatmapConfiguration> {
      const body = await restHandler.post(`corridor/heatmap-configuration`, config);

      return body as CorridorHeatmapConfiguration;
    },

    async fetchServiceOverlayLayers(): Promise<ServiceOverlay[]> {
      const body: any = await restHandler.get("overlays/service-layer");
      return body?.featureServiceLayers as ServiceOverlay[];
    },

    async fetchCorridorEdgeDetails(config: any): Promise<any> {
      const body = await restHandler.post(`corridor/edge-details`, config);
      return body as any;
    },

    // Road VMT Analysis
    async fetchRoadVmtMetadata(config: RoadVmtMetadataRequest): Promise<RoadVmtMetadata> {
      const body = await restHandler.post(`road-vmt/metadata`, config);
      return body as RoadVmtMetadata;
    },

    async fetchRoadVmtZoneCounts(level: string, config: RoadVmtZoneCountsRequest): Promise<RoadVmtZoneCounts> {
      const { zones, availableRange } = await restHandler.postForBinary(`road-vmt/zone-counts`, config).then((res) => {
        return parseCounts(res, level, "RoadVmtZoneCount");
      });

      return {
        zones,
        availableRange,
      } as RoadVmtZoneCounts;
    },
    async fetchRoadVmtZoneDetails(config: RoadVmtZoneDetailsRequest): Promise<RoadVmtZoneDetails> {
      const body = await restHandler.post(`road-vmt/zone-details`, config);
      return body as RoadVmtZoneDetails;
    },

    // Screenlines

    /**
     * Get candidate intersection points/segment geometries for a screenline geometry that is being created
     *
     * @note If this request fails due to an invalid geometry, the editor should show the error message and keep the invalid geometry,
     * so that it can be further modified to fix the reason for the error (e.g. move a vertex to remove a self-intersection)
     */
    async candidateScreenlineIntersections(
      config: CandidateScreenlineIntersectionsRequest,
    ): Promise<CandidateScreenlineIntersectionsResponse> {
      const body = await restHandler.post(`screenlines/candidate-intersections`, config);
      return body as CandidateScreenlineIntersectionsResponse;
    },

    // create a new screenline
    async createScreenline(config: CreateScreenlineRequest): Promise<CreateScreenlineResponse> {
      const body = await restHandler.post(`screenlines/create`, config);
      return body as CreateScreenlineResponse;
    },

    // validate a screenline
    async validateScreenline(config: ScreenlineValidationRequest): Promise<ScreenlineValidationResponse> {
      const body = await restHandler.post(`screenlines/validation`, config);
      return body as ScreenlineValidationResponse;
    },

    // validate a list of screenlines to get summary results
    async validateScreenlines(
      config: ScreenlineValidationSummaryRequest,
    ): Promise<ScreenlineValidationSummaryResponse> {
      const body = await restHandler.post(`screenlines/validation-summary`, config);
      return body as ScreenlineValidationSummaryResponse;
    },

    // applies an updated geometry to a screenline, returning the updated screenline
    async updateScreenlineGeometry(config: UpdateScreenlineGeometryRequest): Promise<UpdateScreenlineGeometryResponse> {
      const body = await restHandler.post(`screenlines/updated-geometry`, config);
      return body as UpdateScreenlineGeometryResponse;
    },

    /** Upload zipped shapefile with screenline features and return validated/prepared features, field candidates/default
     *  fields for id, name and description, and validation messages */
    async readScreenlineShapefile(zippedShapefile: Blob): Promise<ReadScreenlineShapefileResponse> {
      const body = await restHandler.post(`screenlines/import/shapefile`, zippedShapefile, {
        headers: { "content-type": "application/octet-stream" },
      });
      return body as ReadScreenlineShapefileResponse;
    },

    /** Convert validated/prepared screenline features to screenlines */
    async convertFeaturesToScreenlines(
      config: ConvertFeaturesToScreenlinesRequest,
    ): Promise<ConvertFeaturesToScreenlinesResponse> {
      const body = await restHandler.post(`screenlines/import/convert`, config);
      return body as ConvertFeaturesToScreenlinesResponse;
    },

    async fetchAOIScreenlineCounts(config: AOIScreenlineCountsRequest): Promise<ScreenlineCountsResponse> {
      const body = await restHandler.post(`screenlines/aoi/screenline-counts`, config);
      return body as ScreenlineCountsResponse;
    },

    async fetchAOIScreenlineDetails(config: AOIScreenlineDetailsRequest): Promise<ScreenlineDetailsResponse> {
      const body = await restHandler.post(`screenlines/aoi/screenline-details`, config);
      return body as ScreenlineDetailsResponse;
    },

    // Get road segment indexes for a list of segment ids
    async getSegmentIndexesForIds(config: SegmentIndexesForIdsRequest): Promise<Map<string, number>> {
      const body = await restHandler.postForBinary("roads/segment-indexes-for-ids", config).then((res) => {
        return parseSegmentIndexes(res);
      });
      return body as Map<string, number>;
    },

    // Data quality
    async fetchAreaAccuracyTableData(config: AreaAccuracyTableRequest): Promise<AreaAccuracyTableResponse> {
      const body = await restHandler.post(`data-quality/area-accuracy/by-aadt-group`, config);
      return body as AreaAccuracyTableResponse;
    },

    async fetchAreaAccuracyScatterPlotData(
      config: AreaAccuracyScatterPlotRequest,
    ): Promise<AreaAccuracyScatterPlotItem[]> {
      return await restHandler.postForBinary(`data-quality/area-accuracy/aadt-vs-ref-counts`, config).then((res) => {
        const aadtVsRefCounts: AreaAccuracyScatterPlotItem[] = [];
        const buffer = new Uint8Array(res);
        const reader = protobuf.Reader.create(buffer);

        return Promise.resolve(
          protobuf
            .load("/AadtVsRefCount.proto")
            .then((root: any) => {
              const AadtVsRefCount = root.lookupType("analytics.clickhouse.AadtVsRefCount");

              while (reader.pos < reader.len) {
                const msg = AadtVsRefCount.decodeDelimited(reader);
                aadtVsRefCounts.push({
                  aadt: msg.aadt,
                  refCount: msg.refCount,
                  roadClassGroup: msg.roadClassGroup,
                });
              }

              return aadtVsRefCounts;
            })
            .catch((err: any) => {
              throw new Error(err);
            }),
        );
      });
    },

    // Road intersections
    async getRoadIntersectionVolumes(request: RoadIntersectionVolumesRequest): Promise<RoadIntersectionVolumes> {
      const response = getVolumesProtobuf(
        restHandler,
        "roads/intersection-volumes",
        request,
        "IntersectionVolume",
        "nodeId",
        "volume",
      );

      return response.then((res) => {
        memoryStore.setItem(MemoryStoreKeys.ROAD_INTERSECTION_VOLUMES, res?.volumes);

        return {
          measure: request.measure,
          maxVolume: res?.maxVolume,
          minVolume: res?.minVolume,
          size: res?.volumes.size,
        };
      });
    },

    async getRoadIntersectionIds(request: RoadIntersectionNodeIdsRequest): Promise<boolean> {
      return await restHandler.postForBinary(`roads/intersection-ids`, request).then((res) => {
        const nodeIds = new Array<number>();
        const buffer = new Uint8Array(res);
        const reader = protobuf.Reader.create(buffer);

        return Promise.resolve(
          protobuf
            .load("/IntersectionNodeIds.proto")
            .then((root: any) => {
              const IntersectionIdType = root.lookupType("analytics.clickhouse.IntersectionNodeIds");

              while (reader.pos < reader.len) {
                const msg = IntersectionIdType.decodeDelimited(reader);
                nodeIds.push(msg.nodeId);
              }

              memoryStore.setItem(MemoryStoreKeys.ROAD_INTERSECTION_IDS, nodeIds);

              return true;
            })
            .catch((err: any) => {
              throw new Error(err);
            }),
        );
      });
    },

    async getRoadIntersectionVolumeDetails(
      request: RoadIntersectionVolumeDetailsRequest,
    ): Promise<RoadIntersectionVolumeDetailsResponse> {
      const body: any = await restHandler.post(`roads/intersection-volume-details`, request);
      return body as RoadIntersectionVolumeDetailsResponse;
    },

    async getRoadIntersectionClusterVolumes(
      request: RoadIntersectionClusterVolumesRequest,
    ): Promise<RoadIntersectionClusterVolumes> {
      const response = getRoadIntersectionClusterVolumesProtobuf(
        restHandler,
        "roads/intersection-cluster-volumes",
        request,
        "IntersectionClusterVolume",
      );

      return response.then((res) => {
        memoryStore.setItem(MemoryStoreKeys.ROAD_INTERSECTION_CLUSTER_VOLUMES, res?.volumes);

        return {
          measure: request.measure,
          maxVolumes: res?.maxVolumes,
          minVolumes: res?.minVolumes,
          size: res?.volumes.size,
        };
      });
    },

    async getRoadIntersectionClusterIds(config: RoadIntersectionClusterIdsRequest): Promise<boolean> {
      return await restHandler.postForBinary(`roads/intersection-cluster-ids`, config).then((res) => {
        const clusterIdDetails: RoadIntersectionClusterIdDetails = new Map<number, Map<number, number>>();
        const buffer = new Uint8Array(res);
        const reader = protobuf.Reader.create(buffer);

        return Promise.resolve(
          protobuf
            .load("/IntersectionClusterIds.proto")
            .then((root: any) => {
              const ClusterIdType = root.lookupType("analytics.clickhouse.IntersectionClusterIds");

              while (reader.pos < reader.len) {
                const msg = ClusterIdType.decodeDelimited(reader);
                if (!clusterIdDetails.has(msg.level)) {
                  clusterIdDetails.set(msg.level, new Map<number, number>());
                }
                clusterIdDetails.get(msg.level)!.set(msg.clusterId, msg.clusterSize);
              }

              memoryStore.setItem(MemoryStoreKeys.ROAD_INTERSECTION_CLUSTER_IDS, clusterIdDetails);

              return true;
            })
            .catch((err: any) => {
              throw new Error(err);
            }),
        );
      });
    },
  };
}
