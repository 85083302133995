import { FilterArguments, FiltersType, Measure, MeasureRange } from "types";

export const buildFilters = (filters: FiltersType | null): FilterArguments => {
  const filter: Record<string, string[]> = {};

  if (filters) {
    for (let [filterKey, filterValues] of Object.entries(filters)) {
      if (!filterValues.isChecked) {
        filter[filterKey] = Object.entries(filterValues.items)
          .filter(([, itemValue]) => itemValue.isChecked)
          .map(([, itemValue]) => itemValue.value);
      }
    }
  }

  return filter;
};

export const filtersTypeFromFilterArguments = (
  filterArguments: FilterArguments,
  allFilters: FiltersType,
): FiltersType => {
  const filters: FiltersType = { ...allFilters };

  for (let [filterKey, filterValues] of Object.entries(filterArguments)) {
    if (filters[filterKey]) {
      filters[filterKey].isChecked = false;
      for (let itemKey of Object.keys(filters[filterKey].items)) {
        filters[filterKey].items[itemKey].isChecked = filterValues.includes(filters[filterKey].items[itemKey].value);
      }
    }
  }

  return filters;
};

export const isInAvailableRange = (range: [number, number], availableRange: MeasureRange) =>
  availableRange.min === range[0] && availableRange.max === range[1];

export const getCurrentMeasure = <T extends Measure>(measures: T[] | undefined, measure: string | null): T | null =>
  measures?.find((m) => m.columnName === measure) ?? null;
