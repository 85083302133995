import { useAuth } from "ImsAuthorization";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Autocomplete, Box, InputAdornment, Tooltip, styled } from "@mui/material";
import { IconButton, Menu, MenuItem, SearchByPlace, TextField, TextInputNumbers } from "components_new";
import { AnyLayer, Map, Marker } from "mapbox-gl";
import React, { FC, MutableRefObject, useCallback, useEffect, useRef, useState } from "react";

import { ROADS_SEGMENTS_LAYER_ID } from "features/map/modules/roads/map-data/layers";
import { ROADS_SOURCE_ID } from "features/map/modules/roads/map-data/sources";


import { useAppDispatch, useAppSelector, useDebounce } from "hooks";

import { analyticsActions } from "store/sections/analytics";

import { GeocodingSearchResultsFeature, SearchType } from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";
import { getFromToSegmentIndex } from "utils/parse";
import {
  convertFeatureResultToGeocodingSearchResultsFeature,
  convertPlaceSearchResultToGeocodingSearchResultsFeature,
} from "utils/search";

const MIN_SEARCH_TEXT_LENGTH = 5;

interface GeocodingSearchBoxProps {
  map: MutableRefObject<Map | null>;
}

const SearchOption = styled(MenuItem)`
  display: flex;
  gap: 5px;
`;

const SearchPlaceIcon = styled(SearchByPlace)`
  width: 15px;
  height: 15px;
`;

const SearchSegmentIcon = styled(TextInputNumbers)`
  width: 15px;
  height: 15px;
  margin-top: 1px;
`;

const GeocodingSearchBox: FC<GeocodingSearchBoxProps> = ({ map }) => {
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  const geocodingSearchResults = useAppSelector((state) => state.analytics.geocodingSearch);
  const geocodingSearchByFeatureIdResults = useAppSelector((state) => state.analytics.geocodingSearchByFeatureId);
  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);

  const [value, setValue] = useState<GeocodingSearchResultsFeature | null>(null);
  const [searchText, setSearchText] = useState("");
  const [isSearchByPlaceName, setIsSearchByPlaceName] = useState(true);
  const [higlightedSegmentId, setHighlightedSegmentId] = useState<number | null>(null);

  const searchMarker = useRef(new Marker({ scale: 0.8, offset: [0, -22], color: "blue" }));
  const handleCloseSearchByMenu = useRef<() => void>();

  const handleRemoveSearchMarker = useCallback(() => {
    searchMarker.current.remove();
  }, []);

  const handleRemoveHighlightedSegment = useCallback(() => {
    if (map.current && higlightedSegmentId !== null) {
      const layerName = (map.current.getLayer(ROADS_SEGMENTS_LAYER_ID) as AnyLayer & { sourceLayer?: string })?.[
        "sourceLayer"
      ];

      map.current.setFeatureState(
        { source: ROADS_SOURCE_ID, sourceLayer: layerName, id: higlightedSegmentId },
        { searchResult: false },
      );
    }
  }, [map, higlightedSegmentId]);

  const handleClearGeocodingSearch = useCallback(() => {
    dispatch(analyticsActions.clearGeocodingSearchResults());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      handleClearGeocodingSearch();
      handleRemoveSearchMarker();
    };
  }, [handleClearGeocodingSearch, handleRemoveSearchMarker]);

  // Highlight the road segment on the map when a search result is selected
  // @TODO We need to apply the searchResult feature state after the map is loaded, revise the logic how to read all such states for different map modes
  useEffect(() => {
    if (!isSearchByPlaceName && geocodingSearchByFeatureIdResults.data?.features && value) {
      const feature = geocodingSearchByFeatureIdResults.data.features.find((f) => f.Segment.id === value.id);

      if (feature && map.current?.getLayer(ROADS_SEGMENTS_LAYER_ID)) {
        const segmentIndex = getFromToSegmentIndex(feature.Segment.segment_idx);

        if (segmentIndex === higlightedSegmentId) return;

        const layerName = (map.current.getLayer(ROADS_SEGMENTS_LAYER_ID) as AnyLayer & { sourceLayer?: string })?.[
          "sourceLayer"
        ];

        if (higlightedSegmentId !== null) {
          map.current.setFeatureState(
            { source: ROADS_SOURCE_ID, sourceLayer: layerName, id: higlightedSegmentId },
            { searchResult: false },
          );
        }

        map.current.setFeatureState(
          { source: ROADS_SOURCE_ID, sourceLayer: layerName, id: segmentIndex },
          { searchResult: true },
        );

        setHighlightedSegmentId(segmentIndex);
      }
    }
  }, [isSearchByPlaceName, geocodingSearchByFeatureIdResults.data?.features, value, higlightedSegmentId, map]);

  const flyToLocation = useCallback(
    (coordinates: [number, number], placeName: string) => {
      if (map.current) {
        map.current.flyTo({ center: coordinates, zoom: 14 });
        handleRemoveSearchMarker();
        searchMarker.current.setLngLat(coordinates).addTo(map.current);
      }
      addCustomGAEvent("geocoding-search", "search", placeName, user, userOrganizationName);
    },
    [map, user, userOrganizationName, handleRemoveSearchMarker],
  );

  const executeGeocodingSearch = useCallback(
    (text: string) => {
      if (!map.current) return;
      if (isSearchByPlaceName) {
        const proximity = map.current.getCenter().toArray().join(",");
        dispatch(
          analyticsActions.fetchGeocodingSearchResults(
            text,
            process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string,
            proximity,
          ),
        );
      } else if (timePeriod) {
        dispatch(
          analyticsActions.fetchSearchResultsByFeatureId({
            searchMode: SearchType.SEGMENT_ID,
            searchText: text,
            timePeriod,
          }),
        );
      }
    },
    [map, dispatch, timePeriod, isSearchByPlaceName],
  );

  const handleSearchTextChange = (newValue: string) => {
    setSearchText(newValue);
    debouncedSearch();
  };

  const debouncedSearch = useDebounce(() => {
    if (searchText.length >= MIN_SEARCH_TEXT_LENGTH) {
      executeGeocodingSearch(searchText);
    } else {
      handleClearGeocodingSearch();
    }
  }, 250);

  const toggleSearchByPlaceName = () => {
    if (!isSearchByPlaceName) {
      setValue(null);
      handleCloseSearchByMenu.current?.();
      handleClearGeocodingSearch();
      handleRemoveSearchMarker();
      handleRemoveHighlightedSegment();
      setIsSearchByPlaceName(true);
    }
  };

  const toggleSearchBySegmentId = () => {
    if (isSearchByPlaceName) {
      setValue(null);
      handleCloseSearchByMenu.current?.();
      handleClearGeocodingSearch();
      handleRemoveSearchMarker();
      handleRemoveHighlightedSegment();
      setIsSearchByPlaceName(false);
    }
  };

  return (
    <Box sx={{ position: "absolute", top: "2px", left: "338px", width: "250px", zIndex: 2 }}>
      <Autocomplete
        disablePortal
        popupIcon={null}
        options={
          isSearchByPlaceName
            ? geocodingSearchResults.data?.features.map(convertPlaceSearchResultToGeocodingSearchResultsFeature) || []
            : geocodingSearchByFeatureIdResults.data?.features.map(
                convertFeatureResultToGeocodingSearchResultsFeature,
              ) || []
        }
        noOptionsText={`Search for a ${isSearchByPlaceName ? "location" : "segment"}...`}
        slotProps={{
          paper: {
            sx: (theme) => ({
              fontSize: "14px",
              "& .MuiAutocomplete-noOptions": { padding: theme.spacing(1) },
            }),
          },
        }}
        getOptionLabel={(option) => option.place_name}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        value={value}
        onChange={(event: React.SyntheticEvent, newValue: GeocodingSearchResultsFeature | null, reason: string) => {
          setValue(newValue);

          if (newValue) {
            flyToLocation(newValue.center, newValue.place_name);
          }

          if (reason === "clear") {
            handleClearGeocodingSearch();
            handleRemoveSearchMarker();
            handleRemoveHighlightedSegment();
          }
        }}
        inputValue={searchText}
        onInputChange={(event, inputVal) => {
          handleSearchTextChange(inputVal);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search..."
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: 0, ml: 1 }}>
                  <Menu
                    control={(handleOpen, handleClose) => {
                      handleCloseSearchByMenu.current = handleClose;

                      return (
                        <Tooltip title="Search by">
                          <IconButton onClick={handleOpen}>
                            {isSearchByPlaceName ? <SearchPlaceIcon /> : <SearchSegmentIcon />}
                            <ArrowDropDownIcon fontSize="small" sx={{ ml: "-2px" }} />
                          </IconButton>
                        </Tooltip>
                      );
                    }}
                  >
                    <SearchOption onClick={toggleSearchByPlaceName}>
                      <SearchPlaceIcon />
                      Search place by name
                    </SearchOption>
                    <SearchOption onClick={toggleSearchBySegmentId}>
                      <SearchSegmentIcon />
                      Search road segment by segment ID
                    </SearchOption>
                  </Menu>
                </InputAdornment>
              ),
            }}
            sx={{
              "& fieldset": {
                boxShadow: "0 1px 2px rgba(60,64,67,0.3),0 2px 6px 2px rgba(60,64,67,0.15)",
                border: "0 !important",
              },
              "& .MuiInputBase-root": {
                height: "29px",
                py: "0 !important",
                paddingRight: "35px !important",
                "&:after": {
                  content: '""',
                  display: "inline-block",
                  width: "15px",
                  background: "linear-gradient(to right, transparent, rgba(255, 255, 255, 1))",
                  height: "24px",
                  position: "absolute",
                  right: "35px",
                },
              },
              "& .MuiInputBase-input": {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                "&:hover": {
                  textOverflow: "clip",
                },
                "&:focus": {
                  textOverflow: "clip",
                },
              },
            }}
          />
        )}
      />
    </Box>
  );
};

export const GeocodingSearch = React.memo(GeocodingSearchBox);
