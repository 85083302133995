import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { DemandScenarioMetadataResponse, DemandSelectedArea, ZoneIdsRequest } from "api/analytics/publications";
import { MutableRefObject, useCallback } from "react";
import { useDispatch } from "react-redux";

import { buildFilters, getCurrentMeasure } from "features/filters/utils";

import { analyticsActions } from "store/sections/analytics";

import { FiltersType, FlowsSettings, MeasureBreakdownArgs, ODTileLayer, QueryType, SelectedArea } from "types";

import { ZONES_LAYER_FILL, getODFillColorExpression, getODFillOpacityExpression } from "./map-data/layers";
import { OUT_ZONES_SOURCE_ID } from "./map-data/sources";

export const useGetDemandScenarioZoneIds = (demandScenarioId?: string) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    if (!demandScenarioId) {
      return;
    }

    const config: ZoneIdsRequest = {
      compression: "gzip",
      format: "protobuf",
    };

    dispatch(analyticsActions.fetchDemandZoneIds(demandScenarioId, config));
  }, [demandScenarioId, dispatch]);
};

export const useGetZoneCounts = (measure: string | null, queryType: QueryType, demandScenarioId?: string) => {
  const dispatch = useDispatch();

  return useCallback(
    (filters: FiltersType, selectedZoneId?: number) => {
      if (!demandScenarioId || !measure) {
        return;
      }

      dispatch(
        analyticsActions.fetchDemandZoneCounts(demandScenarioId, {
          measure,
          queryType,
          filter: buildFilters(filters),
          compression: "gzip",
          format: "protobuf",
          selectedZoneId,
        }),
      );
    },
    [measure, queryType, demandScenarioId, dispatch],
  );
};

export const useHandleSelectZone = () => {
  const dispatch = useDispatch();

  return useCallback(
    (selectedZone: DemandSelectedArea | null) => {
      dispatch(analyticsActions.setSelectedZone(selectedZone as unknown as SelectedArea | null));
    },
    [dispatch],
  );
};

export const useChangeShowZoneCounts = (mapboxLayerManager: MutableRefObject<LayerManager | null>) => {
  return useCallback(
    (showZoneCounts: boolean) => {
      if (mapboxLayerManager.current) {
        const map = mapboxLayerManager.current.getMapInstance()!;

        if (
          map.getLayer(ZONES_LAYER_FILL) &&
          map.getLayoutProperty(ZONES_LAYER_FILL, "visibility") === "visible" &&
          !showZoneCounts
        ) {
          mapboxLayerManager.current?.updateLayerLayout(ZONES_LAYER_FILL, "visibility", "none");
        } else if (
          map.getLayer(ZONES_LAYER_FILL) &&
          map.getLayoutProperty(ZONES_LAYER_FILL, "visibility") === "none" &&
          showZoneCounts
        ) {
          mapboxLayerManager.current?.updateLayerLayout(ZONES_LAYER_FILL, "visibility", "visible");
        }
      }
    },
    [mapboxLayerManager],
  );
};

export const useChangeZonesFillOpacity = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
) => {
  return useCallback(
    (range: [number, number], opacityFactor: number) => {
      if (map.current!.getLayer(ZONES_LAYER_FILL)) {
        mapboxLayerManager.current?.updateLayerPaint(
          ZONES_LAYER_FILL,
          "fill-opacity",
          getODFillOpacityExpression(range, opacityFactor),
        );
      }
    },
    [map, mapboxLayerManager],
  );
};

export const useGetSelectedZoneDetails = (
  queryType: QueryType,
  demandScenarioMetadata: DemandScenarioMetadataResponse | null,
  demandScenarioId?: string,
) => {
  const dispatch = useDispatch();

  return useCallback(
    (selectedZoneId: number, measure: string | null, flowsSettings: FlowsSettings, filters: FiltersType | null) => {
      if (!demandScenarioId || !demandScenarioMetadata || !measure) {
        return;
      }

      const currentMeasure = getCurrentMeasure(demandScenarioMetadata.measures, measure);

      if (!currentMeasure) return;

      const dimensions = currentMeasure.dimensions?.filter((d) => d.enabled).map((d) => d.columnName) || [];
      const breakdowns: MeasureBreakdownArgs[] = dimensions.map((d) => ({
        dimensions: [d],
        includeUnfiltered: false,
      }));

      const config = {
        zoneId: selectedZoneId,
        measure,
        summaries: {
          [queryType]: {
            filteredTotal: true,
            unfilteredTotal: false,
            breakdowns,
          },
        },
        topFlows: {
          [queryType]: flowsSettings,
        },
        filter: buildFilters(filters),
      };

      dispatch(analyticsActions.fetchDemandZoneDetails(demandScenarioId, config));
      dispatch(analyticsActions.fetchDemandZoneAttributes(demandScenarioId, { zoneIds: [selectedZoneId] }));
    },
    [queryType, demandScenarioId, demandScenarioMetadata, dispatch],
  );
};

export const useCloseODAnalyticsPanel = (
  map: MutableRefObject<mapboxgl.Map | null>,
  handleSelectZone: (selectedZone: DemandSelectedArea | null) => void,
) => {
  return useCallback(
    (selectedZone: DemandSelectedArea | null) => {
      if (selectedZone) {
        handleSelectZone(null);

        if (map.current && map.current.getLayer(ZONES_LAYER_FILL)) {
          if (
            map.current.getFeatureState({
              source: OUT_ZONES_SOURCE_ID,
              sourceLayer: "default",
              id: selectedZone.id,
            })
          ) {
            map.current.setFeatureState(
              {
                source: OUT_ZONES_SOURCE_ID,
                sourceLayer: "default",
                id: selectedZone.id,
              },
              { click: false },
            );
          }
        }
      }
    },
    [map, handleSelectZone],
  );
};

export const useFilterZonesByRange = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
) => {
  return useCallback(
    // TODO layer should be removed from here, it is needed for backward compatibility
    (range: [number, number], layer: ODTileLayer, opacityFactor: number) => {
      if (map.current!.getLayer(ZONES_LAYER_FILL)) {
        mapboxLayerManager.current?.updateLayerPaint(
          ZONES_LAYER_FILL,
          "fill-opacity",
          getODFillOpacityExpression(range, opacityFactor),
        );
        mapboxLayerManager.current?.updateLayerPaint(ZONES_LAYER_FILL, "fill-color", getODFillColorExpression(range));
      }
    },
    [map, mapboxLayerManager],
  );
};
