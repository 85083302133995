import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { AssignmentScenarioMetadataResponse, LinkIdsRequest } from "api/analytics/publications";
import { MutableRefObject, useCallback } from "react";
import { useDispatch } from "react-redux";

import { buildFilters, getCurrentMeasure } from "features/filters/utils";

import { analyticsActions } from "store/sections/analytics";

import { DirectionalRoadsTileService, FiltersType, MeasureBreakdownArgs, MeasureType } from "types";

import { getRoadsLineOpacityExpression } from "../../roads/map-data/layers";
import {
  LINKS_HAIRLINES_LAYER_ID,
  LINKS_VALUES_LAYER_ID,
  getLinksLayers,
  getVolumesLineWidthExpression,
  getVolumesOffsetExpression,
} from "./map-data/layers";

export const useGetAssignmentLinkIds = (assignmentScenarioId?: string) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    if (!assignmentScenarioId) {
      return;
    }

    const config: LinkIdsRequest = {
      onlyFromTo: false,
      compression: "gzip",
      format: "protobuf",
    };

    dispatch(analyticsActions.fetchAssignmentLinksIds(assignmentScenarioId, config));
  }, [assignmentScenarioId, dispatch]);
};

export const useGetAssignmentLinkValues = (assignmentScenarioId?: string) => {
  const dispatch = useDispatch();

  return useCallback(
    (measure: string | null, filters: FiltersType | null) => {
      if (!assignmentScenarioId || !measure) {
        return;
      }

      dispatch(
        analyticsActions.fetchAssignmentLinksValues(assignmentScenarioId, {
          measure,
          filter: buildFilters(filters),
          compression: "gzip",
          format: "protobuf",
        }),
      );
    },
    [assignmentScenarioId, dispatch],
  );
};

export const useGetLinkDetails = (
  assignmentScenarioMetadata: AssignmentScenarioMetadataResponse | null,
  assignmentScenarioId?: string,
) => {
  const dispatch = useDispatch();

  return useCallback(
    (linkIds: number[], measure: string | null, filters: FiltersType | null) => {
      if (!assignmentScenarioId || !assignmentScenarioMetadata || !measure) {
        return;
      }

      const currentMeasure = getCurrentMeasure(assignmentScenarioMetadata.measures, measure as MeasureType);

      if (!currentMeasure) return;

      const dimensions = currentMeasure.dimensions?.filter((d) => d.enabled).map((d) => d.columnName) || [];
      const breakdowns: MeasureBreakdownArgs[] = dimensions.map((d) => ({
        dimensions: [d],
        includeUnfiltered: false,
      }));

      const config = {
        linkIds,
        measure,
        summary: {
          breakdowns,
          filteredTotal: true,
          unfilteredTotal: false,
        },
        filter: buildFilters(filters),
      };

      dispatch(analyticsActions.fetchAssignmentLinkDetails(assignmentScenarioId, config));
      dispatch(analyticsActions.fetchAssignmentLinkAttributes(assignmentScenarioId, { linkIds }));
    },
    [assignmentScenarioId, assignmentScenarioMetadata, dispatch],
  );
};

export const useGetVisibilityStatus = (showRoadVolumesRef: MutableRefObject<boolean>) => {
  return useCallback(
    (layerId: string) => {
      switch (layerId) {
        case LINKS_VALUES_LAYER_ID:
        case LINKS_HAIRLINES_LAYER_ID:
          return showRoadVolumesRef.current ? "visible" : "none";
        default:
          return "visible";
      }
    },
    [showRoadVolumesRef],
  );
};

export const useFilterLinksByRoadClasses = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
  tileServer?: DirectionalRoadsTileService,
) => {
  return useCallback(
    (selectedRoadClasses: number[]) => {
      if (map.current && tileServer) {
        getLinksLayers(tileServer.layerName).forEach((layer) => {
          if (map.current!.getLayer(layer.id)) {
            mapboxLayerManager.current?.updateLayerFilter(
              layer.id,
              ["in", tileServer.facilityTypeField, ...selectedRoadClasses],
              "road_classes",
            );
          }
        });
      }
    },
    [map, mapboxLayerManager, tileServer],
  );
};

export const useFilterLinksByRange = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
  tileServer?: DirectionalRoadsTileService,
) => {
  return useCallback(
    (allFromToLinkIndexes: number[] | null) => {
      if (map && tileServer && allFromToLinkIndexes) {
        getLinksLayers(tileServer.layerName).forEach((layer) => {
          if (map.current!.getLayer(layer.id)) {
            switch (layer.id) {
              case LINKS_HAIRLINES_LAYER_ID:
                mapboxLayerManager.current?.updateLayerFilter(
                  layer.id,
                  ["in", tileServer.fromToSegmentIndexField, ...allFromToLinkIndexes],
                  "range_filter",
                );
                break;
              case LINKS_VALUES_LAYER_ID:
                mapboxLayerManager.current?.updateLayerFilter(
                  layer.id,
                  ["in", tileServer.fromToSegmentIndexField, ...allFromToLinkIndexes],
                  "range_filter",
                );
                break;
              default:
                break;
            }
          }
        });
      } else if (map && tileServer) {
        getLinksLayers(tileServer.layerName).forEach((layer) => {
          if (
            map.current!.getLayer(layer.id) &&
            (layer.id === LINKS_HAIRLINES_LAYER_ID || layer.id === LINKS_VALUES_LAYER_ID)
          ) {
            mapboxLayerManager.current?.removeLayerFilter(layer.id, "range_filter");
          }
        });
      }
    },
    [map, mapboxLayerManager, tileServer],
  );
};

export const useChangeVolumesOpacity = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
) => {
  return useCallback(
    (opacityFactor: number) => {
      if (map.current!.getLayer(LINKS_VALUES_LAYER_ID)) {
        mapboxLayerManager.current?.updateLayerPaint(
          LINKS_VALUES_LAYER_ID,
          "line-opacity",
          getRoadsLineOpacityExpression(opacityFactor, false),
        );
      }
    },
    [map, mapboxLayerManager],
  );
};

export const useChangeVolumesWidth = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
) => {
  return useCallback(
    (widthFactor: number) => {
      if (map.current!.getLayer(LINKS_VALUES_LAYER_ID)) {
        mapboxLayerManager.current?.updateLayerPaint(
          LINKS_VALUES_LAYER_ID,
          "line-width",
          getVolumesLineWidthExpression(widthFactor),
        );
        mapboxLayerManager.current?.updateLayerPaint(
          LINKS_VALUES_LAYER_ID,
          "line-offset",
          getVolumesOffsetExpression(widthFactor),
        );
      }
    },
    [map, mapboxLayerManager],
  );
};

export const useChangeShowRoadVolumes = (
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
  updateRoadsModeCounts: MutableRefObject<(() => void) | null>,
) => {
  return useCallback(
    (showRoadVolumes: boolean) => {
      if (mapboxLayerManager.current) {
        mapboxLayerManager.current.updateLayerLayout(
          LINKS_HAIRLINES_LAYER_ID,
          "visibility",
          showRoadVolumes ? "visible" : "none",
        );

        mapboxLayerManager.current.updateLayerLayout(
          LINKS_VALUES_LAYER_ID,
          "visibility",
          showRoadVolumes ? "visible" : "none",
        );

        if (showRoadVolumes) {
          updateRoadsModeCounts.current?.();
        }
      }
    },
    [mapboxLayerManager, updateRoadsModeCounts],
  );
};
