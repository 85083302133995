import { Layer } from "mapbox-gl";
import { MutableRefObject } from "react";

import {
  ROAD_HAIRLINE_COLOR,
  ROAD_NETWORK_VOLUME_OPACITY,
  ROAD_SEGMENT_HIGHLIGHT_COLOR,
  ROAD_SEGMENT_PERMANENT_HIGHLIGHT_COLOR,
  ROAD_SEGMENT_SUPER_HIGHLIGHT_COLOR,
  ROAD_VOLUME_COLOR,
  ROAD_VOLUME_HOVER_COLOR,
} from "features/map/layerColors";
import { ROADS_SOURCE_ID as LINKS_SOURCE_ID } from "features/map/modules/roads/map-data/sources";
import { calculateWidthFactor } from "features/map/utils";

export const LINKS_HAIRLINES_LAYER_ID = "LINKS_HAIRLINES_LAYER_ID";
export const LINKS_VALUES_LAYER_ID = "LINKS_VALUES_LAYER_ID";
export const LINKS_SEGMENTS_LAYER_ID = "LINKS_SEGMENTS_LAYER_ID";

export const getLineOpacityExpression = (opacityFactor: number) => ["*", ROAD_NETWORK_VOLUME_OPACITY, opacityFactor];

export const getHighlightedVolumesLineWidthExpression = (widthFactor: number) => [
  "*",
  ["case", ["boolean", ["feature-state", "hover"], false], ["number", ["feature-state", "volumeWeight"], 0], 0],
  calculateWidthFactor(widthFactor),
];

export const getVolumesLineWidthExpression = (widthFactor: number) => [
  "*",
  ["number", ["feature-state", "volumeWeight"], 0],
  calculateWidthFactor(widthFactor),
];

export const getVolumesOffsetExpression = (offsetFactor: number) => [
  "*",
  ["number", ["feature-state", "volumeOffset"], 0],
  calculateWidthFactor(offsetFactor),
];

export const getLinksLayers = (
  layerName: string,
  opacityFactorRef?: MutableRefObject<number>,
  widthFactorRef?: MutableRefObject<number>,
): Layer[] => {
  const widthFactor = widthFactorRef?.current ?? 1;
  const opacityFactor = opacityFactorRef?.current ?? 1;

  const linksLayers = [
    // Add links hairlines layer
    {
      id: LINKS_HAIRLINES_LAYER_ID,
      type: "line",
      source: LINKS_SOURCE_ID,
      "source-layer": layerName,
      paint: {
        "line-color": ROAD_HAIRLINE_COLOR,
        "line-width": 0,
      },
    },
    // Add links volume layer
    {
      id: LINKS_VALUES_LAYER_ID,
      type: "line",
      source: LINKS_SOURCE_ID,
      "source-layer": layerName,
      paint: {
        "line-color": ROAD_VOLUME_COLOR,
        "line-width": getVolumesLineWidthExpression(widthFactor),
        "line-offset": getVolumesOffsetExpression(widthFactor),
        "line-opacity": getLineOpacityExpression(opacityFactor),
      },
    },
    // Add links segments layer
    {
      id: LINKS_SEGMENTS_LAYER_ID,
      type: "line",
      source: LINKS_SOURCE_ID,
      "source-layer": layerName,
      paint: {
        "line-color": [
          "case",
          ["boolean", ["feature-state", "searchResult"], false],
          "blue", // Blue color for searchResult
          ["boolean", ["feature-state", "permanentSelectHighlight"], false],
          ROAD_SEGMENT_PERMANENT_HIGHLIGHT_COLOR,
          ["boolean", ["feature-state", "hoverHighlight"], false],
          ROAD_VOLUME_HOVER_COLOR,
          ["boolean", ["feature-state", "selectHighlight"], false],
          ROAD_SEGMENT_SUPER_HIGHLIGHT_COLOR,
          ROAD_SEGMENT_HIGHLIGHT_COLOR,
        ],
        "line-width": [
          "interpolate",
          ["exponential", 1.6],
          ["zoom"],
          6,
          ["case", ["boolean", ["feature-state", "hover"], false], 2, 0.5],
          20,
          ["case", ["boolean", ["feature-state", "hover"], false], 35, 30],
        ],
        "line-offset": 0,
        "line-opacity": [
          "case",
          ["boolean", ["feature-state", "searchResult"], false],
          1,
          ["boolean", ["feature-state", "hover"], false],
          1,
          ["boolean", ["feature-state", "permanentSelectHighlight"], false],
          1,
          0,
        ],
      },
    },
  ] as Layer[];

  return linksLayers;
};
