import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { DemandSelectedArea } from "api/analytics/publications";
import React, { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react";

import { ModuleData } from "features/map/ModuleManager";
import { initialFlowsSettings } from "features/map/utils";

import { HoveredFlow } from "types";

import { ODAnalyticsPanel } from "../../od/ODAnalyticsPanel";
import { MapController } from "./MapController";

interface DemandScenarioModuleComponentProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  isModuleLoaded: boolean;
  updateODModeCounts: MutableRefObject<(() => void) | null>;
  hoveredTopFlow: HoveredFlow | null;
  setHoveredTopFlow: (hoveredTopFlow: HoveredFlow | null) => void;
  setDemandModuleData: Dispatch<SetStateAction<ModuleData | null>>;
}

const DemandScenarioModuleComponent: React.FC<DemandScenarioModuleComponentProps> = ({
  map,
  layerManagerRef,
  isModuleLoaded,
  hoveredTopFlow,
  updateODModeCounts,
  setHoveredTopFlow,
  setDemandModuleData,
}) => {
  const [flowsSettings, setFlowsSettings] = useState(initialFlowsSettings);

  const closeODAnalyticsPanelRef = useRef<() => void>(null);

  useEffect(() => {
    const closeODAnalyticsPanel = closeODAnalyticsPanelRef.current;

    return () => {
      if (closeODAnalyticsPanel) {
        closeODAnalyticsPanel();
      }

      setDemandModuleData(null);
    };
  }, [setDemandModuleData]);

  return (
    <>
      <MapController
        map={map}
        layerManagerRef={layerManagerRef}
        isModuleLoaded={isModuleLoaded}
        setDemandModuleData={setDemandModuleData}
        flowsSettings={flowsSettings}
        closeODAnalyticsPanelRef={
          closeODAnalyticsPanelRef as React.MutableRefObject<(selectedZone: DemandSelectedArea | null) => void>
        }
        updateODModeCounts={updateODModeCounts}
      />
      <ODAnalyticsPanel
        flowsSettings={flowsSettings}
        closeODAnalyticsPanelRef={closeODAnalyticsPanelRef}
        hoveredTopFlow={hoveredTopFlow}
        setHoveredTopFlow={setHoveredTopFlow}
        setFlowsSettings={setFlowsSettings}
        isDemandScenario
      />
    </>
  );
};

export default DemandScenarioModuleComponent;
