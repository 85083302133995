import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { styled } from "@mui/material";
import { useMemoryStore } from "api/MemoryStoreContext";
import { MemoryStoreKeys } from "api/memoryStore";
import pinImage from "assets/png/pin.png";
import { DeleteVertexPopupContent, DeleteVertexPopupContentProps } from "components_new";
import { MultiPolygon, Polygon } from "geojson";
import { isEqual } from "lodash";
import mapboxgl, { Map, Popup } from "mapbox-gl";
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { GeocodingSearch } from "features";

import {
  ComputeDatasetDialog,
  GEN_GATES_LAYER_ID,
  GatesEditor,
  LOCKED_GEN_GATES_LAYER_ID,
  MainPanel,
} from "features/dataset-editor";
import { DirectSelect, DrawPolygon, SimpleSelect, Static, styles } from "features/draw";
import {
  EditorGateCandidates,
  EditorOD,
  EditorRoads,
  addBoundariesLayer,
  initEditorGateCandidates,
  initEditorOD,
  initEditorRoads,
} from "features/map-visualization";
import { getAvailableZoomLevels, getBounds } from "features/map/utils";

import { LeftSidebar, PopupWrapper, SpinnerOverlay, getMapHomeControl } from "components";

import { useAppDispatch, useAppSelector, usePageTracking, usePrevious, useStateRef } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";

import { DatasetEditorMode, FocusAreaItem, ODDatasetConfig, ODTileLayer, RoadClass } from "types";

import { getDatasetEditorHandlers } from "./handlers";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";

/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-webpack-loader-syntax */
(mapboxgl as any).workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

/* eslint-enable @typescript-eslint/no-var-requires */
/* eslint-enable import/no-webpack-loader-syntax */

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;

const DEFAULT_SIMPLIFICATION_DISTANCE_M = 0.5;

const MapPageContainer = styled("div")`
  position: relative;
  display: grid;
  grid-template-columns: 280px 1fr;
  grid-template-rows: 100%;
  grid-template-areas: "left-sidebar map";
  height: 100%;
  overflow: hidden;
`;

const Mapbox = styled("div")`
  height: 100%;
`;

interface DatasetEditorProps {
  ODDatasetConfiguration: ODDatasetConfig;
  datasetLicensedArea: FocusAreaItem;
}

export const DatasetEditor: FC<DatasetEditorProps> = ({ ODDatasetConfiguration, datasetLicensedArea }) => {
  usePageTracking();

  const { timePeriod, gateRoadClasses, gates, subAreaGeometry, areaIds, customZoningId } = ODDatasetConfiguration;

  const memoryStore = useMemoryStore();
  const dispatch = useAppDispatch();

  const [mapLoaded, setMapLoaded] = useState(false);
  const [baseStylesUpdated, setBaseStylesUpdated] = useState<boolean>(false);
  const [isGateEditorOpen, setIsGateEditorOpen] = useState(false);
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
  const [editorDrawMode, setEditorDrawMode] = useState<DatasetEditorMode>(DatasetEditorMode.SimpleSelect);
  const editorDrawModeRef = useStateRef(editorDrawMode);

  // Roads
  const [showRoads, setShowRoads] = useState<boolean>(true);
  const [selectedRoadClasses, setSelectedRoadClasses] = useState<RoadClass[] | null>(null);
  const [isRoadsSourceInitialized, setRoadsSourceInitialized] = useState<boolean>(false);
  const [showGates, setShowGates] = useState<boolean>(true);

  // OD
  const [showZones, setShowZones] = useState<boolean>(true);
  const selectedZoning = useAppSelector((state) => state.analytics.ODDatasetConfig.data?.zoningLevel);
  const selectedZoningRef = useStateRef(selectedZoning);

  // General
  const subareaState = useAppSelector((state) => state.analytics.subareaState);
  const customZoningSelectorFolders = useAppSelector((state) => state.datasetFolders.customZoningSelectorFolders.data);

  //Map
  const baseMapStyle = useAppSelector((state) => state.map.baseMapStyle);

  // Roads
  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const roadSegmentIndexes = useAppSelector((state) => state.analytics.roadSegmentIndexes);

  // OD
  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const ODIds = useAppSelector((state) => state.analytics.ODIds);

  // Refs
  const mapContainer: any = useRef(null);
  const map = useRef<Map | null>(null);
  const draw = useRef<any>(null);

  const generatedGatesDataRef = useStateRef(gates);

  const ODClearFunction = useRef<() => void>();
  const roadsClearFunction = useRef<() => void>();
  const gateCandidatesClearFunction = useRef<() => void>();

  const vertexPopupRef = useRef<HTMLDivElement | null>(null);
  const setVertexPopupPropsRef = useRef<Dispatch<SetStateAction<DeleteVertexPopupContentProps | null>>>(null);
  const mapboxVertexPopupRef = useRef<Popup | null>(null);

  const previousSelectedRoadClasses = usePrevious(selectedRoadClasses);

  const layers = useMemo(() => ODMetadata.data?.tileService.layers || [], [ODMetadata.data?.tileService.layers]);

  const roadsLayerName = useMemo(
    () => roadsMetadata.data?.vehicularRoadsTileService.layerName,
    [roadsMetadata.data?.vehicularRoadsTileService.layerName],
  );

  const currentLayer = useMemo(() => {
    if (selectedZoning !== "Custom") {
      return layers.find(({ level }) => level === selectedZoning);
    }

    let customZoningTileInfo: (ODTileLayer & { type?: string }) | null = null;

    customZoningSelectorFolders?.folders.forEach((folder) => {
      folder.customZonings.forEach((customZoning) => {
        if (customZoning.customZoningId === customZoningId) {
          customZoningTileInfo = { ...customZoning.tileService, type: "CustomZoning" };
          customZoningTileInfo.url = `${process.env.REACT_APP_API_HOST}${customZoning.tileService.url}`;
          return;
        }
      });
    });

    if (customZoningTileInfo) {
      return customZoningTileInfo as ODTileLayer;
    }
  }, [layers, selectedZoning, customZoningSelectorFolders, customZoningId]);

  const getRoadSegmentIndexes = useCallback(() => {
    dispatch(
      analyticsActions.fetchSegmentIndexes({
        timePeriod,
        areaOfInterest: null,
        onlyFromTo: true,
        compression: "gzip",
      }),
    );
  }, [timePeriod, dispatch]);

  const setSegmentFeatureState = useCallback(
    (id: number, state: { [key: string]: boolean }) => {
      if (map.current && mapLoaded && id && map.current.getSource(EditorRoads.SourceId) && roadsLayerName) {
        map.current?.setFeatureState(
          {
            source: EditorRoads.SourceId,
            sourceLayer: roadsLayerName,
            id,
          },
          state,
        );
      }
    },
    [mapLoaded, roadsLayerName],
  );

  const onChangeZoning = useCallback(
    (zoningLevel: string, customZoningId?: string) => {
      dispatch(
        analyticsActions.updateODDatasetConfigSucceeded({
          ...ODDatasetConfiguration,
          zoningLevel,
          customZoningId,
        }),
      );
    },

    [ODDatasetConfiguration, dispatch],
  );

  const changeShowZones = () => {
    if (currentLayer) {
      const isVisible = !showZones;
      setShowZones(isVisible);

      map.current?.setLayoutProperty(EditorOD.ZonesFillHighlighted, "visibility", isVisible ? "visible" : "none");

      map.current?.setLayoutProperty(currentLayer.name, "visibility", isVisible ? "visible" : "none");

      map.current?.setLayoutProperty(
        `${EditorOD.ZonesBounds}-${currentLayer.name}`,
        "visibility",
        isVisible ? "visible" : "none",
      );
    }
  };

  const changeShowRoads = () => {
    const isVisible = !showRoads;
    setShowRoads(isVisible);

    map.current?.setLayoutProperty(EditorRoads.SegmentsLayerId, "visibility", isVisible ? "visible" : "none");
    map.current?.setLayoutProperty(
      EditorRoads.HighlightedSegmentsLayerId,
      "visibility",
      isVisible ? "visible" : "none",
    );
  };

  const changeShowGates = () => {
    const isVisible = !showGates;
    setShowGates(isVisible);

    if (map.current?.getLayer(GEN_GATES_LAYER_ID)) {
      map.current?.setLayoutProperty(GEN_GATES_LAYER_ID, "visibility", isVisible ? "visible" : "none");
    }
    if (map.current?.getLayer(LOCKED_GEN_GATES_LAYER_ID)) {
      map.current?.setLayoutProperty(LOCKED_GEN_GATES_LAYER_ID, "visibility", isVisible ? "visible" : "none");
    }
  };

  const onAddFullEntireAreaPolygon = useCallback(() => {
    if (ODIds.data && selectedZoning) {
      draw.current.deleteAll();

      dispatch(
        analyticsActions.fetchSubareaPolygon({
          timePeriod: timePeriod,
          simplification: {
            distanceM: DEFAULT_SIMPLIFICATION_DISTANCE_M,
          },
          customZoningId: selectedZoning === "Custom" ? customZoningId : undefined,
        }),
      );
    }
  }, [selectedZoning, ODIds.data, customZoningId, timePeriod, dispatch]);

  const handleOpenValidationModal = useCallback(() => {
    if (!isValidationModalOpen) {
      setIsValidationModalOpen(true);
    }
  }, [isValidationModalOpen]);

  const handleCloseValidationModal = useCallback(() => {
    if (isValidationModalOpen) {
      setIsValidationModalOpen(false);
    }
  }, [isValidationModalOpen]);

  const handleDeleteSubarea = useCallback(() => {
    draw.current.deleteAll();
    dispatch(analyticsActions.setSubAreaPolygon(null));
  }, [dispatch]);

  const handleSetIsGateEditorOpen = useCallback((isOpen: boolean) => {
    if (!isOpen) {
      setEditorDrawMode(DatasetEditorMode.SimpleSelect);
      draw.current.changeMode("simple_select");
    }
    setIsGateEditorOpen(isOpen);
  }, []);

  useEffect(() => {
    if (map.current) return; // initialize map only once

    const bounds = getBounds(subAreaGeometry || datasetLicensedArea.geometry);
    const padding = { top: 40, bottom: 40, left: 40, right: 40 };

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: baseMapStyle,
      bounds: bounds,
      fitBoundsOptions: {
        padding,
      },
      pitch: 0,
      bearing: 0,
      logoPosition: "bottom-right",
      transformRequest: (url, resourceType): any => {
        if (resourceType === "Tile" && url.startsWith(process.env.REACT_APP_API_HOST as string)) {
          return {
            headers: {
              authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
            },
            url,
          };
        }
      },
    });

    // disable map rotation using right click + drag
    map.current.dragRotate.disable();

    // disable map rotation using touch rotation gesture
    map.current.touchZoomRotate.disableRotation();

    // Add full extent control to the map.
    map.current.addControl(
      getMapHomeControl(map.current, bounds, {
        padding,
      }),
      "top-left",
    );

    // Add zoom controls to the map.
    map.current.addControl(new mapboxgl.NavigationControl({ showCompass: false }), "top-left");

    draw.current = new MapboxDraw({
      displayControlsDefault: false,
      touchEnabled: false,
      boxSelect: false,
      controls: {
        polygon: true,
        trash: true,
      },
      styles: styles,
      modes: {
        ...MapboxDraw.modes,
        static: Static,
        simple_select: SimpleSelect,
        direct_select: DirectSelect,
        draw_polygon: DrawPolygon,
      },
      userProperties: true,
      snap: true,
      snapOptions: {
        snapPx: 10,
        snapToMidPoints: false,
        snapVertexPriorityDistance: 0.01, // 10 meters
      },
      guides: false,
    } as any);
    if (draw.current) map.current.addControl(draw.current, "top-right");

    map.current.on("load", () => {
      map.current?.loadImage(pinImage, (error, image) => {
        if (error) throw error;
        if (image) map.current?.addImage("pin", image);
      });

      if (subAreaGeometry) draw.current.add(subAreaGeometry);

      getDatasetEditorHandlers(
        map.current as Map,
        draw.current,
        vertexPopupRef,
        setVertexPopupPropsRef,
        mapboxVertexPopupRef,
        selectedZoningRef,
        editorDrawModeRef,
        setEditorDrawMode,
        (subareaPolygon: Polygon | MultiPolygon | null) => dispatch(analyticsActions.setSubAreaPolygon(subareaPolygon)),
      );
      setMapLoaded(true);
    });

    map.current.on("style.load", () => {
      if (map.current) {
        addBoundariesLayer({
          map: map.current,
          selectedArea: {
            id: "editor",
            geometry: datasetLicensedArea.geometry,
          } as any,
        });

        setBaseStylesUpdated(true);
      }
    });

    const sourceCallback = () => {
      if (
        map.current &&
        map.current.getSource(EditorRoads.SourceId) &&
        map.current.isSourceLoaded(EditorRoads.SourceId)
      ) {
        setRoadsSourceInitialized(true);
      }
    };

    map.current.on("sourcedata", sourceCallback);

    return () => {
      dispatch(analyticsActions.clearSubareaState());
    };
  }, [subAreaGeometry, datasetLicensedArea, baseMapStyle, editorDrawModeRef, selectedZoningRef, dispatch]);

  useEffect(() => {
    if (isRoadsSourceInitialized && timePeriod && gateRoadClasses && selectedZoning) {
      if (subAreaGeometry) {
        dispatch(
          analyticsActions.fetchSubareaState({
            polygon: subAreaGeometry,
            timePeriod: timePeriod,
            gateRoadClasses: gateRoadClasses,
            level: selectedZoning,
            customZoningId: customZoningId,
            includeSegmentGeometry: true,
          }),
        );
      } else {
        dispatch(analyticsActions.clearSubareaState());
      }
    }
  }, [
    subAreaGeometry,
    timePeriod,
    gateRoadClasses,
    customZoningId,
    isRoadsSourceInitialized,
    selectedZoning,
    dispatch,
  ]);

  // Set selectedZoning to the minimum granularity available on first render
  useEffect(() => {
    if (mapLoaded && !selectedZoning && ODMetadata.state === DataState.AVAILABLE) {
      onChangeZoning(ODMetadata.data.level, customZoningId);
    }
  }, [mapLoaded, selectedZoning, ODMetadata, customZoningId, onChangeZoning]);

  //Fetch Segment IDs
  useEffect(() => {
    if (mapLoaded && roadSegmentIndexes.state === DataState.EMPTY) {
      getRoadSegmentIndexes();
    }
  }, [mapLoaded, roadSegmentIndexes.state, getRoadSegmentIndexes]);

  //Fetch OD Zones Ids
  useEffect(() => {
    if (areaIds && timePeriod && ODIds.state === DataState.EMPTY && ODMetadata.state === DataState.AVAILABLE) {
      dispatch(
        analyticsActions.fetchODIds(getAvailableZoomLevels(ODMetadata.data.tileService.layers), {
          timePeriod: timePeriod,
          areaOfInterest: areaIds,
        }),
      );
    }
  }, [areaIds, timePeriod, ODIds.state, ODMetadata, dispatch]);

  //Initialize map for OD
  useEffect(() => {
    if (
      map.current &&
      mapLoaded &&
      currentLayer &&
      ODIds.state === DataState.AVAILABLE &&
      ODMetadata.state === DataState.AVAILABLE &&
      isRoadsSourceInitialized
    ) {
      if (ODClearFunction.current) {
        ODClearFunction.current();
      }

      const { clear } = initEditorOD(map.current, {
        ids: ODIds.data,
        layer: currentLayer,
        outerLayer: ODMetadata.data.tileService.layers[0],
      });

      ODClearFunction.current = clear;
    }
  }, [currentLayer, ODIds, ODMetadata, mapLoaded, isRoadsSourceInitialized]);

  //Set highlighted zones filter on subarea state change
  useEffect(() => {
    if (
      map.current &&
      mapLoaded &&
      currentLayer &&
      baseStylesUpdated &&
      map.current.getLayer(EditorOD.ZonesFillHighlighted)
    ) {
      map.current.setFilter(EditorOD.ZonesFillHighlighted, [
        "in",
        ["get", currentLayer.idField],
        ["literal", subareaState.data?.zoneIds || []],
      ]);
    }
  }, [currentLayer, subareaState.data?.zoneIds, mapLoaded, baseStylesUpdated]);

  //Initialize map for Roads
  useEffect(() => {
    if (
      map.current &&
      mapLoaded &&
      roadSegmentIndexes.state === DataState.AVAILABLE &&
      roadsMetadata.state === DataState.AVAILABLE &&
      baseStylesUpdated
    ) {
      if (roadsClearFunction.current) {
        roadsClearFunction.current();
      }

      const segmentFromToIndexesData = memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_FROM_TO_INDEXES);

      const { clear } = initEditorRoads(map.current, {
        tileService: roadsMetadata.data.vehicularRoadsTileService,
        roadSegmentIndexes: Array.from(segmentFromToIndexesData.keys()),
      });

      roadsClearFunction.current = clear;
    }
  }, [mapLoaded, memoryStore, roadSegmentIndexes.state, baseStylesUpdated, roadsMetadata, dispatch]);

  // Initialize gate candidates
  useEffect(() => {
    if (map.current && mapLoaded) {
      if (gateCandidatesClearFunction.current) {
        gateCandidatesClearFunction.current();
      }

      const { clear } = initEditorGateCandidates(map.current, {
        candidates: subareaState.data?.gateCandidates || [],
        generatedGates: generatedGatesDataRef.current || [],
      });

      gateCandidatesClearFunction.current = clear;
    }
  }, [mapLoaded, subareaState.data?.gateCandidates, generatedGatesDataRef]);

  // Filtering out gate segments candidates that are already part of an existing gate
  useEffect(() => {
    if (map.current && mapLoaded && map.current.getLayer(EditorGateCandidates.LayerId)) {
      map.current.setFilter(EditorGateCandidates.LayerId, [
        "match",
        ["get", "fromToSegId"],
        ["in", ...(gates?.map((gate) => gate.segments.map((s) => s.id)).flat(1) || [])],
        false,
        true,
      ]);
    }
  }, [mapLoaded, gates]);

  // Set filter for showing segments just for the selected road classes
  useEffect(() => {
    if (
      mapLoaded &&
      roadSegmentIndexes.state === DataState.AVAILABLE &&
      roadsMetadata.state === DataState.AVAILABLE &&
      map.current &&
      map.current?.getLayer(EditorRoads.SegmentsLayerId) &&
      map.current?.getLayer(EditorRoads.HighlightedSegmentsLayerId) &&
      selectedRoadClasses
    ) {
      const segmentFromToIndexesData = memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_FROM_TO_INDEXES);

      if (segmentFromToIndexesData) {
        const filter = [
          "all",
          [
            "in",
            roadsMetadata.data?.vehicularRoadsTileService.facilityTypeField,
            ...selectedRoadClasses.map((rc) => rc.id),
          ],
          [
            "in",
            roadsMetadata.data?.vehicularRoadsTileService.fromToSegmentIndexField,
            ...Array.from(segmentFromToIndexesData.keys()),
          ],
        ];
        map.current!.setFilter(EditorRoads.SegmentsLayerId, filter);
        map.current!.setFilter(EditorRoads.HighlightedSegmentsLayerId, filter);
      }
    }
  }, [memoryStore, mapLoaded, roadsMetadata, roadSegmentIndexes, selectedRoadClasses]);

  // Update gate roadclasses in the dataset configuration
  useEffect(() => {
    if (
      previousSelectedRoadClasses &&
      selectedRoadClasses &&
      !isEqual(previousSelectedRoadClasses, selectedRoadClasses)
    ) {
      dispatch(
        analyticsActions.updateODDatasetConfigSucceeded({
          ...ODDatasetConfiguration,
          gateRoadClasses: selectedRoadClasses.map((rc) => rc.id),
        }),
      );
    }
  }, [selectedRoadClasses, previousSelectedRoadClasses, ODDatasetConfiguration, dispatch]);

  // Replace polygon in the draw instance with the one fetched from the endpoint (when selecting the whole AOI)
  useEffect(() => {
    if (subAreaGeometry && mapLoaded && draw.current && draw.current.getAll().features.length === 0) {
      draw.current.add(subAreaGeometry);
    }
  }, [subAreaGeometry, mapLoaded]);

  return (
    <MapPageContainer>
      {mapLoaded && subareaState.state === DataState.LOADING && <SpinnerOverlay />}

      <LeftSidebar>
        <MainPanel
          draw={draw.current}
          zoningOptions={layers.map((l) => l.level)}
          datasetLicensedArea={datasetLicensedArea}
          selectedZoning={selectedZoning}
          selectedRoadClasses={selectedRoadClasses}
          showZones={showZones}
          showRoads={showRoads}
          showGates={showGates}
          isGateEditorOpen={isGateEditorOpen}
          editorDrawMode={editorDrawMode}
          setEditorDrawMode={setEditorDrawMode}
          setSelectedZoning={onChangeZoning}
          changeShowZones={changeShowZones}
          changeShowRoads={changeShowRoads}
          changeShowGates={changeShowGates}
          setSelectedRoadClasses={setSelectedRoadClasses}
          setIsGateEditorOpen={handleSetIsGateEditorOpen}
          onAddFullEntireAreaPolygon={onAddFullEntireAreaPolygon}
          openValidationModal={handleOpenValidationModal}
          handleDeleteSubarea={handleDeleteSubarea}
        />
      </LeftSidebar>
      <PopupWrapper
        popupRef={vertexPopupRef}
        setPopupRef={setVertexPopupPropsRef}
        renderPopupContent={({ draw, onClose }) => <DeleteVertexPopupContent draw={draw} onClose={onClose} />}
      />
      <Mapbox ref={mapContainer} style={{ display: "block" }} />

      <GatesEditor
        map={map.current}
        draw={draw.current}
        mapLoaded={mapLoaded}
        selectedRoadClasses={selectedRoadClasses}
        isRoadsSourceInitialized={isRoadsSourceInitialized}
        isGateEditorOpen={isGateEditorOpen}
        editorDrawMode={editorDrawMode}
        setEditorDrawMode={setEditorDrawMode}
        setSegmentFeatureState={setSegmentFeatureState}
        setIsGateEditorOpen={handleSetIsGateEditorOpen}
      />

      <ComputeDatasetDialog open={isValidationModalOpen} onClose={handleCloseValidationModal} />
      <GeocodingSearch map={map} />
    </MapPageContainer>
  );
};
