import { getKeyValuesIntIntProtobuf } from "api/helper";
import memoryStore, { MemoryStoreKeys } from "api/memoryStore";
import protobuf from "protobufjs";

import { Flow } from "types";

// !!!TODO we need to create a separate type for this (perhaps common one)
import {
  AssignmentScenarioMetadataResponse,
  DemandScenarioMetadataResponse,
  LinkAttributesRequest,
  LinkAttributesResponse,
  LinkIdsRequest,
  LinkIdsWithClass,
  LinkMeasureDetailsRequest,
  LinkMeasureDetailsResponse,
  LinkMeasureValuesRequest,
  OdZoneIdsResponse,
  RangeValues,
  ScenarioItem,
  StudyArea,
  ZoneAttributesRequest,
  ZoneAttributesResponse,
  ZoneIdsRequest,
  ZoneMeasureDetailsRequest,
  ZoneMeasureDetailsResponse,
  ZoneMeasureValuesRequest,
} from ".";
import RestHandler from "../../RestHandler";

export interface PublicationsApiType {
  getStudyAreas(): Promise<StudyArea[]>;
  getScenarioItems(studyAreaId: string): Promise<ScenarioItem[]>;
  // assignment
  getAssignmentScenarioMetadata(assignmentScenarioId: string): Promise<AssignmentScenarioMetadataResponse>;
  getLinkIds(assignmentScenarioId: string, config: LinkIdsRequest): Promise<boolean>;
  getLinkValues(assignmentScenarioId: string, config: LinkMeasureValuesRequest): Promise<RangeValues>;
  getLinkDetails(assignmentScenarioId: string, config: LinkMeasureDetailsRequest): Promise<LinkMeasureDetailsResponse>;
  getLinkAttributes(assignmentScenarioId: string, config: LinkAttributesRequest): Promise<LinkAttributesResponse>;
  // demand
  getDemandScenarioMetadata(demandScenarioId: string): Promise<DemandScenarioMetadataResponse>;
  getZoneIds(demandScenarioId: string, config: ZoneIdsRequest): Promise<boolean>;
  getZoneValues(demandScenarioId: string, config: ZoneMeasureValuesRequest): Promise<RangeValues>;
  getZoneDetails(demandScenarioId: string, config: ZoneMeasureDetailsRequest): Promise<ZoneMeasureDetailsResponse>;
  getZoneAttributes(demandScenarioId: string, config: ZoneAttributesRequest): Promise<ZoneAttributesResponse>;
}

export default function PublicationsApi(restHandler: RestHandler): PublicationsApiType {
  return {
    async getStudyAreas(): Promise<StudyArea[]> {
      return await restHandler.get<StudyArea[]>("publications/study-area?includeScenarios=true");
    },
    async getScenarioItems(studyAreaId: string): Promise<ScenarioItem[]> {
      return await restHandler.get<ScenarioItem[]>(`publications/study-area/${studyAreaId}/scenario`);
    },

    // *** Assignment ***
    async getAssignmentScenarioMetadata(assignmentScenarioId: string): Promise<AssignmentScenarioMetadataResponse> {
      return await restHandler.get<AssignmentScenarioMetadataResponse>(
        `publications/assignment-scenario/${assignmentScenarioId}/metadata`,
      );
    },
    async getLinkIds(assignmentScenarioId: string, config: LinkIdsRequest): Promise<boolean> {
      return await restHandler
        .postForBinary(`publications/assignment-scenario/${assignmentScenarioId}/link-ids`, config)
        .then((response) => {
          const buffer = new Uint8Array(response);
          const reader = protobuf.Reader.create(buffer);
          const linkIdsWithClass: LinkIdsWithClass = new Map();

          return Promise.resolve(
            protobuf
              .load("/KeyValueIntInt.proto")
              .then((root) => {
                const ids = root.lookupType("analytics.clickhouse.KeyValueIntInt");

                while (reader.pos < reader.len) {
                  const msg = ids.decodeDelimited(reader) as unknown as { key: number; value: number };
                  linkIdsWithClass.set(msg.key, { linkClass: msg.value });
                }

                memoryStore.setItem(MemoryStoreKeys.LINK_IDS_WITH_LINK_CLASSES, linkIdsWithClass);

                return true;
              })
              .catch((err) => {
                throw new Error(err);
              }),
          );
        });
    },
    async getLinkValues(assignmentScenarioId: string, config: LinkMeasureValuesRequest): Promise<RangeValues> {
      return getKeyValuesIntIntProtobuf(
        restHandler,
        `publications/assignment-scenario/${assignmentScenarioId}/link-values`,
        config,
      )
        .then((response) => {
          memoryStore.setItem(MemoryStoreKeys.LINK_VALUES, response?.volumes as Map<number, number>);

          return {
            max: response.maxVolume,
            min: response.minVolume,
            size: response?.volumes.size,
          };
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
    async getLinkDetails(
      assignmentScenarioId: string,
      config: LinkMeasureDetailsRequest,
    ): Promise<LinkMeasureDetailsResponse> {
      return (await restHandler.post(
        `publications/assignment-scenario/${assignmentScenarioId}/link-details`,
        config,
      )) as LinkMeasureDetailsResponse;
    },
    async getLinkAttributes(
      assignmentScenarioId: string,
      config: LinkAttributesRequest,
    ): Promise<LinkAttributesResponse> {
      return (await restHandler.post(
        `publications/assignment-scenario/${assignmentScenarioId}/link-attributes`,
        config,
      )) as LinkAttributesResponse;
    },

    // *** Demand ***
    async getDemandScenarioMetadata(demandScenarioId: string): Promise<DemandScenarioMetadataResponse> {
      return await restHandler.get<DemandScenarioMetadataResponse>(
        `publications/demand-scenario/${demandScenarioId}/metadata`,
      );
    },
    async getZoneIds(demandScenarioId: string, config: ZoneIdsRequest): Promise<boolean> {
      try {
        const response = (await restHandler.post(
          `publications/demand-scenario/${demandScenarioId}/zone-ids/json`,
          config,
        )) as OdZoneIdsResponse;

        const zoneIds = new Map<number, number>();

        response.zoneIds.forEach((zone) => {
          zoneIds.set(Number(zone.id), zone.sqKm);
        });

        memoryStore.setItem(MemoryStoreKeys.DEMAND_ZONE_IDS, zoneIds);

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getZoneValues(demandScenarioId: string, config: ZoneMeasureValuesRequest): Promise<RangeValues> {
      return getKeyValuesIntIntProtobuf(
        restHandler,
        `publications/demand-scenario/${demandScenarioId}/zone-values`,
        config,
      )
        .then((response) => {
          memoryStore.setItem(MemoryStoreKeys.DEMAND_ZONE_COUNTS, response?.volumes as Map<number, number>);

          return {
            max: response.maxVolume,
            min: response.minVolume,
            size: response?.volumes.size,
          };
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
    async getZoneDetails(
      demandScenarioId: string,
      config: ZoneMeasureDetailsRequest,
    ): Promise<ZoneMeasureDetailsResponse> {
      const response = (await restHandler.post(
        `publications/demand-scenario/${demandScenarioId}/zone-details`,
        config,
      )) as ZoneMeasureDetailsResponse;

      // We need to convert topFlows ids to numbers
      response.topFlows = {
        incoming: {
          flows:
            (response.topFlows?.incoming?.flows?.map((flow) => ({
              ...flow,
              id: Number(flow.id),
            })) as unknown as Flow[]) || [],
        },
        outgoing: {
          flows:
            (response.topFlows?.outgoing?.flows?.map((flow) => ({
              ...flow,
              id: Number(flow.id),
            })) as unknown as Flow[]) || [],
        },
      };

      return response as ZoneMeasureDetailsResponse;
    },
    async getZoneAttributes(demandScenarioId: string, config: ZoneAttributesRequest): Promise<ZoneAttributesResponse> {
      return (await restHandler.post(
        `publications/demand-scenario/${demandScenarioId}/zone-attributes`,
        config,
      )) as ZoneAttributesResponse;
    },
  };
}
