import { withAuthenticationRequired } from "@auth0/auth0-react";
import { styled } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { imsEnabled, useAuth } from "ImsAuthorization";
import Routes from "Routes";
import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { LoadingPage, Navbar, ReleaseAvailable } from "components";

import { useAppDispatch, useAppSelector, useMapSearchParams, useRedirect, useToastMessage } from "hooks";

import { DataState } from "store/interfaces";
import { selectHasODDetail } from "store/permissionsSelectors";
import { analyticsActions } from "store/sections/analytics";
import { licenseActions } from "store/sections/license";

import { reportAboutErrorState } from "utils/reports";

import "react-toastify/dist/ReactToastify.css";

const AppContainer = styled("div")`
  height: 100%;
  display: grid;
  grid-template-rows: 64px auto;
  grid-template-columns: auto;
  overflow: hidden;
`;

const Main = styled("main")`
  overflow-y: auto;
`;

const App: FC = () => {
  // Navigates to a url set in a redux action
  useRedirect();

  // Displays toast messages from a redux action
  useToastMessage();

  // Sets map context in search params
  useMapSearchParams();

  const dispatch = useAppDispatch();
  const { isLoading, isAuthenticated, getAccessTokenSilently, logout } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);

  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const studyAreas = useAppSelector((state) => state.analytics.studyAreas);
  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const datasetMetadataState = useAppSelector((state) => state.analytics.datasetMetadata.state);
  const datasetGatesState = useAppSelector((state) => state.analytics.datasetGates.state);
  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);

  const isTokenLoaded = useAppSelector((state) => state.analytics.authorizationTokenLoaded);
  const user = useAppSelector((state) => state.license.user);
  const permissions = useAppSelector((state) => state.license.permissions);

  const isODAllowed = useAppSelector((state) => selectHasODDetail(state, selectedFocusArea?.licensedAreaId));

  const mode = useAppSelector((state) => state.analytics.mapVisualizationMode);

  const [prevOrganizationId, setPrevOrganizationId] = useState<number | null>(null);

  //Fetch token from Auth0
  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently();

          // @TODO Remove this when we migrate to the IMS completely
          if (sessionStorage.getItem("accessToken") !== accessToken) {
            sessionStorage.setItem("accessToken", accessToken);
          }

          dispatch(analyticsActions.setAutorizationTokenLoaded(true));

          if (window.location.pathname !== pathname) {
            // Sync the URL if it doesn't match React Router's state
            navigate(window.location.pathname + window.location.search, { replace: true });
          }
        } catch (error: any) {
          reportAboutErrorState({ name: error?.name, message: error?.message }, "Failed to get access token");
          console.log(error);
        }
      })();
    }
  }, [isAuthenticated, pathname, getAccessTokenSilently, dispatch, navigate]);

  useEffect(() => {
    if (imsEnabled()) {
      // If the user has changed organization, log them out
      if (prevOrganizationId && user.data?.organization && prevOrganizationId !== user.data.organization.id) {
        logout();
      } else if (!prevOrganizationId && user.data?.organization) {
        setPrevOrganizationId(user.data.organization.id);
      }
    }
  }, [user.data?.organization, prevOrganizationId, logout]);

  useEffect(() => {
    return () => {
      sessionStorage.clear();
    };
  }, []);

  //Fetch permissions
  useEffect(() => {
    if (isTokenLoaded) {
      dispatch(licenseActions.fetchPermissions());
    }
  }, [isTokenLoaded, dispatch]);

  // Fetch user info
  useEffect(() => {
    if (isTokenLoaded) {
      dispatch(licenseActions.fetchUser());
    }
  }, [isTokenLoaded, dispatch]);

  // Setup sentry information effect
  useEffect(() => {
    if (user.state === DataState.AVAILABLE) {
      Sentry.setUser({ id: user.data.id });
      Sentry.setTag("isOperator", user.data.isOperator);
      Sentry.setTag("organizationId", user.data.organization.id);
    }
  }, [user]);

  // Fetch licensed area and dataset items (focus areas)
  useEffect(() => {
    if (isTokenLoaded && focusAreas.state === DataState.EMPTY) {
      dispatch(analyticsActions.fetchFocusAreasAndDatasets());
    }
  }, [isTokenLoaded, focusAreas.state, dispatch]);

  // Fetch study areas
  useEffect(() => {
    if (isTokenLoaded && studyAreas.state === DataState.EMPTY) {
      dispatch(analyticsActions.fetchStudyAreas());
    }
  }, [isTokenLoaded, studyAreas.state, dispatch]);

  // Fetch OD metadata
  useEffect(() => {
    if (isTokenLoaded && ODMetadata.state === DataState.EMPTY && timePeriod) {
      if (isODAllowed) {
        dispatch(
          analyticsActions.fetchODMetadata({
            timePeriod,
            includeDisabled: false,
          }),
        );
      } else {
        dispatch(
          analyticsActions.fetchODMetadataFailed({
            status: 403,
            body: {
              // This error message can be received from the backend
              what: "No Origin/destination data access: This license doesn't support access to OD data",
            },
          }),
        );
      }
    }
  }, [isTokenLoaded, isODAllowed, ODMetadata.state, timePeriod, mode, dispatch]);

  //Fetch Dataset Metadata
  useEffect(() => {
    if (selectedFocusArea?.datasetId && datasetMetadataState === DataState.EMPTY) {
      dispatch(analyticsActions.fetchDatasetMetadata(selectedFocusArea.id));
    }
  }, [selectedFocusArea, datasetMetadataState, dispatch]);

  //Fetch Dataset Gates
  useEffect(() => {
    if (selectedFocusArea?.datasetId && datasetGatesState === DataState.EMPTY && timePeriod) {
      dispatch(analyticsActions.fetchDatasetGates(selectedFocusArea.id, timePeriod));
    }
  }, [selectedFocusArea, datasetGatesState, timePeriod, dispatch]);

  // Fetch Roads metadata
  useEffect(() => {
    if (isTokenLoaded && roadsMetadata.state === DataState.EMPTY && selectedFocusArea && timePeriod) {
      // @TODO When we have the case of no access for roads, we should handle it here in the same way as OD
      dispatch(
        analyticsActions.fetchRoadsMetadata({
          timePeriod,
          includeDisabled: false,
        }),
      );
    }
  }, [isTokenLoaded, roadsMetadata.state, focusAreas.state, selectedFocusArea, timePeriod, dispatch]);

  if (isLoading || user.state !== DataState.AVAILABLE) return <LoadingPage />;

  return (
    <AppContainer>
      <Navbar permissions={permissions.data} />
      {permissions && (
        // If we render the Routes before the permissions, even than we may have access to the feature
        // since permissions is still null we will be re-directed to dashboard
        // So we can only render the Routes after the "permissions" being set
        <Main>
          <Routes />
          <ToastContainer limit={1} />
          <ReleaseAvailable />
        </Main>
      )}
    </AppContainer>
  );
};

export default imsEnabled()
  ? App
  : withAuthenticationRequired(App, {
      onRedirecting: () => <LoadingPage />,
    });
