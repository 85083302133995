import { isEqual } from "lodash";
import React, { FC, useEffect, useState } from "react";

import { RoadClassSelector } from "components";

import { useAppDispatch, useAppSelector, usePrevious } from "hooks";

import { filtersActions } from "store/sections/filters";

import { RoadCategory, RoadClass } from "types";

export interface RoadClassFilterProps {
  roadClasses: RoadClass[] | undefined;
  roadCategories: RoadCategory[] | undefined;
  loading: boolean;
  filterRoadSegmentsByRoadClasses: (roadClasses: number[]) => void;
}

export const RoadClassFilter: FC<RoadClassFilterProps> = ({
  roadClasses,
  roadCategories,
  loading,
  filterRoadSegmentsByRoadClasses,
}) => {
  const dispatch = useAppDispatch();

  const savedRoadClasses = useAppSelector((state) => state.filters.roadClasses);

  const [selectedRoadClasses, setSelectedRoadClasses] = useState<RoadClass[] | null>(null);
  const previousSelectedRoadClasses = usePrevious(selectedRoadClasses);

  useEffect(() => {
    if (roadClasses && savedRoadClasses === null)
      setSelectedRoadClasses((savedRoadClasses) =>
        roadClasses.filter((roadClass) =>
          savedRoadClasses ? savedRoadClasses?.find((rc) => rc.id === roadClass.id) : true,
        ),
      );
  }, [roadClasses, savedRoadClasses]);

  useEffect(() => {
    if (
      selectedRoadClasses &&
      previousSelectedRoadClasses &&
      !isEqual(previousSelectedRoadClasses, selectedRoadClasses) &&
      !isEqual(selectedRoadClasses, savedRoadClasses)
    ) {
      const selectedRoadClassesIds = selectedRoadClasses.map((roadClass) => roadClass.id);
      dispatch(filtersActions.setRoadClasses(selectedRoadClassesIds));
      filterRoadSegmentsByRoadClasses?.(selectedRoadClassesIds);
    }
  }, [selectedRoadClasses, previousSelectedRoadClasses, savedRoadClasses, filterRoadSegmentsByRoadClasses, dispatch]);

  return (
    <RoadClassSelector
      roadClasses={roadClasses}
      roadCategories={roadCategories}
      loading={loading}
      selectedRoadClasses={selectedRoadClasses}
      savedRoadClasses={savedRoadClasses}
      setSelectedRoadClasses={setSelectedRoadClasses}
    />
  );
};
