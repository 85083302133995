import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const DrawPolygon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor">
      <path d="M3,2V3H2V2H3M4,1H1V4H4V1Z" />
      <path d="M14,2V3H13V2h1m1-1H12V4h3V1Z" />
      <path d="M3,13v1H2V13H3m1-1H1v3H4V12Z" />
      <path d="M14,13v1H13V13h1m1-1H12v3h3V12Z" />
      <polygon points="6 2 4 2 4 3 6 3 6 2 6 2" />
      <polygon points="9 2 7 2 7 3 9 3 9 2 9 2" />
      <polygon points="12 2 10 2 10 3 12 3 12 2 12 2" />
      <polygon points="3 4 2 4 2 5 2 5 2 6 3 6 3 4 3 4" />
      <polygon points="3 7 2 7 2 8 2 8 2 9 3 9 3 7 3 7" />
      <polygon points="3 10 2 10 2 11 2 11 2 12 3 12 3 10 3 10" />
      <polygon points="14 4 13 4 13 5 13 5 13 6 14 6 14 4 14 4" />
      <polygon points="14 7 13 7 13 8 13 8 13 9 14 9 14 7 14 7" />
      <polygon points="14 10 13 10 13 11 13 11 13 12 14 12 14 10 14 10" />
      <polygon points="6 13 4 13 4 14 6 14 6 13 6 13" />
      <polygon points="9 13 7 13 7 14 9 14 9 13 9 13" />
      <polygon points="12 13 10 13 10 14 12 14 12 13 12 13" />
    </svg>
  </SvgIcon>
);
