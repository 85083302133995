import {
  Add,
  AutoAwesome,
  CenterFocusStrong,
  Delete,
  InfoOutlined,
  PushPin,
  RemoveCircleOutline,
} from "@mui/icons-material";
import {
  Box,
  Card,
  Chip,
  ChipProps,
  Grid,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import gateSegmentsPlaceholderSVG from "assets/svg/gate-segments-placeholder.svg";
import { Button, DirectionIcon, Divider, IconButton, MapControlContainer, Popover, TextField } from "components_new";
import { Geometry } from "geojson";
import uniq from "lodash/uniq";
import { LngLatLike, Map, MapLayerMouseEvent, Marker } from "mapbox-gl";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { EditorRoads, EditorRoadsHighlightStatus } from "features";

import { useHighlightGateSegments } from "features/dataset-editor";

import { RightSidebarPanel } from "components";

import { useAppDispatch, useAppSelector, useDebounce, useStateRef } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions, selectSortedDraftDatasetGates } from "store/sections/analytics";

import {
  DatasetEditorMode,
  Gate,
  GateSegment,
  GateSegmentProps,
  ODDatasetConfig,
  RoadClass,
  SegmentIndexesForIdsMapSource,
} from "types";

import { getDirectionLabel } from "utils/ui";

import { DrawIconButton } from "./drawingTools";
import {
  findGate,
  findGateFromSegmentId,
  getRoadClassLabel,
  normalizeFromToSegment,
  normalizeToFromSegment,
  parseGateSegments,
  unParseSegment,
} from "./utils";

export const GEN_GATES_LAYER_ID = "generated-gates";
export const LOCKED_GEN_GATES_LAYER_ID = "generated-gates-locked";

export interface GatesEditorProps {
  map: Map | null;
  draw: any;
  mapLoaded: boolean;
  selectedRoadClasses: RoadClass[] | null;
  isRoadsSourceInitialized: boolean;
  isGateEditorOpen: boolean;
  editorDrawMode: DatasetEditorMode;
  setEditorDrawMode: Dispatch<SetStateAction<DatasetEditorMode>>;
  setSegmentFeatureState: (
    id: number,
    state: {
      [key: string]: boolean;
    },
  ) => void;
  setIsGateEditorOpen: (isOpen: boolean) => void;
}

const Container = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 2),
  display: "grid",
  height: "calc(100% - 68px)",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "auto minmax(200px, 1fr) auto auto  minmax(100px, 1fr)",
  rowGap: theme.spacing(1),
}));

const TitlePanel = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const EllipsisText = styled("div")`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const GateId = styled("div")`
  display: flex;
  align-items: center;
  margin: 5px 0;
  width: 60px;
  font-size: 13px;
  color: var(--color-textSecondary);
  max-height: 80px;
`;

const GateStreetName = styled(EllipsisText)`
  font-size: 14px;
  font-weight: 700;
  max-width: 225px;
  margin-bottom: 5px;
`;

const ManageGatePlaceHolderSVG = styled("img")`
  margin-bottom: 1rem;
  width: 150px;
  align-self: center;
`;

const GateDetailTools = styled("div")`
  width: 106px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledChip = styled((props: ChipProps) => <Chip color="secondary" size="small" variant="outlined" {...props} />)(
  ({ theme }) => ({
    color: "text.secondary",
    width: "100px",
    backgroundColor: "#fff",
  }),
);

const GatesEditorComponent: FC<GatesEditorProps> = ({
  map,
  draw,
  mapLoaded,
  selectedRoadClasses,
  isRoadsSourceInitialized,
  isGateEditorOpen,
  editorDrawMode,
  setEditorDrawMode,
  setSegmentFeatureState,
  setIsGateEditorOpen,
}) => {
  const dispatch = useAppDispatch();

  const [gateDescription, setGateDescription] = useState("");

  const editorDrawModeRef = useStateRef(editorDrawMode);

  const hoveredGateRef = useRef<Gate | null>(null);
  const selectedGateRef = useRef<Gate | null>(null);
  const gateMarker = useRef(new Marker({ scale: 0.8, offset: [0, -22], color: "purple" }));
  const gatesLayerEventsFirstLoaded = useRef(false);

  const roadClasses = useAppSelector((state) => state.analytics.roadsMetadata.data?.roadClasses);

  const ODDatasetConfiguration = useAppSelector((state) => state.analytics.ODDatasetConfig);
  const ODDatasetConfigurationDataRef = useStateRef<ODDatasetConfig | null>(ODDatasetConfiguration.data);

  const roadsTileService = useAppSelector((state) => state.analytics.roadsMetadata.data?.vehicularRoadsTileService);
  const segmentsIdToIdxMap = useAppSelector((state) => state.analytics.datasetSegmentsIdToIdxMap);
  const segmentsIdToIdxMapDataRef = useStateRef<typeof segmentsIdToIdxMap.data>(segmentsIdToIdxMap.data);

  const subareaState = useAppSelector((state) => state.analytics.subareaState);

  const datasetGates = useAppSelector(selectSortedDraftDatasetGates);
  const generatedGatesDataRef = useRef<Gate[] | null>(null);

  const allGatesSegmentsIds = useMemo(() => {
    const segmentsIds: { [key: string]: boolean } = {};
    ODDatasetConfiguration.data?.gates?.forEach((gate) => {
      gate.segments?.forEach((s) => (segmentsIds[s.id] = true));
    });
    return segmentsIds;
  }, [ODDatasetConfiguration.data?.gates]);

  const allGatesSegmentsIdsRef = useStateRef(allGatesSegmentsIds);

  const newGate = useAppSelector((state) => state.analytics.newGate);
  const loadingGates = useAppSelector((state) => state.analytics.loadingGeneratedGates);

  const [selectedGateId, setSelectedGateId] = useState<string | null>(null);
  const [selectedSegment, setSelectedSegment] = useState<GateSegment | null>(null);

  const gateItemsRefs = useMemo(
    () =>
      ODDatasetConfiguration.data?.gates?.reduce((gates: { [key: string]: React.RefObject<any> }, gate) => {
        gates[gate.identifier] = React.createRef();
        return gates;
      }, {}) || {},
    [ODDatasetConfiguration.data?.gates],
  );

  const polygonRef = useStateRef<Geometry | null>(ODDatasetConfiguration.data?.subAreaGeometry || null);

  useEffect(() => {
    if (ODDatasetConfiguration.data?.gates && selectedGateId !== null) {
      setGateDescription(findGate(selectedGateId, ODDatasetConfiguration.data.gates)?.description || "");
    }
  }, [ODDatasetConfiguration.data?.gates, selectedGateId]);

  const selectedGate = useMemo(
    () =>
      selectedGateId !== null && ODDatasetConfiguration.data?.gates
        ? findGate(selectedGateId, ODDatasetConfiguration.data.gates)
        : null,
    [selectedGateId, ODDatasetConfiguration.data?.gates],
  );

  const selectedGateParsedSegments = useMemo(() => parseGateSegments(selectedGate?.segments), [selectedGate?.segments]);

  const isRoadsSourceLoaded = map?.getSource("roads");

  const setGateSegmentsFeatureState = useCallback(
    (segments: GateSegment[], state: { [key: string]: boolean }) => {
      segments.forEach((segment) => {
        const idx = segmentsIdToIdxMapDataRef.current?.get(segment.id);

        if (idx) {
          setSegmentFeatureState(idx, state);
        }
      });
    },
    [setSegmentFeatureState, segmentsIdToIdxMapDataRef],
  );

  useHighlightGateSegments(
    selectedSegment,
    selectedGate?.segments || null,
    segmentsIdToIdxMap.data,
    setSegmentFeatureState,
    setGateSegmentsFeatureState,
  );

  const handleZoomOnGate = useCallback(
    (lngLat: LngLatLike, zoom: number = 18) => {
      if (map) {
        map.flyTo({
          center: lngLat,
          zoom,
        });
      }
    },
    [map],
  );

  const handleSelectGate = useCallback(
    (gateId: string | null, coords?: LngLatLike) => {
      setSelectedGateId(gateId);
      setSelectedSegment(null);
      if (gateId) {
        setIsGateEditorOpen(true);
        if (coords) handleZoomOnGate(coords, map?.getZoom());
      }

      if (draw.getMode() === "draw_line_string") draw.changeMode("simple_select");
    },
    [map, draw, setIsGateEditorOpen, handleZoomOnGate],
  );

  const handleGenerateGates = () => {
    if (
      ODDatasetConfiguration.data?.subAreaGeometry &&
      ODDatasetConfiguration.data?.timePeriod &&
      subareaState.state === DataState.AVAILABLE &&
      ODDatasetConfiguration.state === DataState.AVAILABLE &&
      selectedRoadClasses
    ) {
      dispatch(
        analyticsActions.fetchGeneratedGates({
          polygon: ODDatasetConfiguration.data?.subAreaGeometry,
          timePeriod: ODDatasetConfiguration.data?.timePeriod,
          gateRoadClasses: selectedRoadClasses.map((rc) => rc.id),
          existingGates: ODDatasetConfiguration.data?.gates?.filter((gate) => gate.pinned) || [],
        }),
      );
    }
  };

  const handleToggleLockGate = (gateId: string) => {
    dispatch(analyticsActions.toggleLockGate(gateId));
  };

  const debouncedGateDescriptionUpdate = useDebounce(() => {
    if (selectedGateId) {
      dispatch(analyticsActions.updateGateDescription(selectedGateId, gateDescription));
    }
  }, 500);

  const handleDescriptionUpdate = (gateId: string, description: string) => {
    setGateDescription(description);
    debouncedGateDescriptionUpdate();
  };

  const handleHoverOnGate = useCallback(
    (e: MapLayerMouseEvent) => {
      const feature = e.features?.[0];
      if (map && feature?.id && generatedGatesDataRef.current) {
        map.getCanvas().style.cursor = "default";

        const gate = findGate(feature.id, generatedGatesDataRef.current);

        if (hoveredGateRef.current) {
          map.setFeatureState(
            {
              source: GEN_GATES_LAYER_ID,
              id: hoveredGateRef.current.identifier,
            },
            { hover: false },
          );
          setGateSegmentsFeatureState(hoveredGateRef.current.segments, {
            [EditorRoadsHighlightStatus.GateHover]: false,
          });
        }

        if (gate) {
          map.setFeatureState(
            {
              source: GEN_GATES_LAYER_ID,
              id: gate.identifier,
            },
            { hover: true },
          );
          setGateSegmentsFeatureState(gate.segments, {
            [EditorRoadsHighlightStatus.GateHover]: true,
          });
        }

        hoveredGateRef.current = gate;
      }
    },
    [map, setGateSegmentsFeatureState],
  );

  const handleHoverOffGate = useCallback(() => {
    if (map && hoveredGateRef.current) {
      map.getCanvas().style.cursor = "";

      map.setFeatureState(
        {
          source: GEN_GATES_LAYER_ID,
          id: hoveredGateRef.current.identifier,
        },
        { hover: false },
      );
      setGateSegmentsFeatureState(hoveredGateRef.current.segments, {
        [EditorRoadsHighlightStatus.GateHover]: false,
      });
      hoveredGateRef.current = null;
    }
  }, [map, setGateSegmentsFeatureState]);

  const handleClick = useCallback(
    (e: MapLayerMouseEvent) => {
      if (map) {
        const features = map.queryRenderedFeatures(e.point, {
          layers: [GEN_GATES_LAYER_ID],
        });
        const feature = features?.[0];
        const featureId = feature?.id as string;
        if (featureId) {
          handleSelectGate(featureId);
        } else if (editorDrawModeRef.current !== DatasetEditorMode.AddSegmentsToGate) {
          handleSelectGate(null);
        }
      }
    },
    [map, editorDrawModeRef, handleSelectGate],
  );

  const handleDoubleClickOnGate = useCallback(
    (e: MapLayerMouseEvent) => {
      handleZoomOnGate(e.lngLat);
    },
    [handleZoomOnGate],
  );

  const toggleAddMode = (mode: DatasetEditorMode.DrawGate | DatasetEditorMode.AddSegmentsToGate) => {
    if (map) {
      if (editorDrawMode === mode) {
        removeHighlightedSegmentsLayerEvents();
        addGatesLayerEvents();
        draw.changeMode("simple_select");
        map.off("draw.create", handleAddGate);
        setEditorDrawMode(DatasetEditorMode.SimpleSelect);
      } else {
        if (mode === DatasetEditorMode.DrawGate) {
          map.on("draw.create", handleAddGate);
          draw.changeMode("draw_line_string");
          setEditorDrawMode(DatasetEditorMode.DrawGate);
          removeGatesLayerEvents();
          removeHighlightedSegmentsLayerEvents();
        }
        if (mode === DatasetEditorMode.AddSegmentsToGate) {
          addHighlightedSegmentsLayerEvents();
          addGatesLayerEvents();
          draw.changeMode("static");
          setEditorDrawMode(DatasetEditorMode.AddSegmentsToGate);
        }
      }
    }
  };

  const addGatesLayerEvents = useCallback(() => {
    if (map) {
      map.on("mousemove", GEN_GATES_LAYER_ID, handleHoverOnGate);
      map.on("mouseleave", GEN_GATES_LAYER_ID, handleHoverOffGate);
      map.on("click", handleClick);
      map.on("dblclick", GEN_GATES_LAYER_ID, handleDoubleClickOnGate);
    }
  }, [map, handleHoverOnGate, handleHoverOffGate, handleClick, handleDoubleClickOnGate]);

  const removeGatesLayerEvents = useCallback(() => {
    if (map) {
      map.off("mousemove", GEN_GATES_LAYER_ID, handleHoverOnGate);
      map.off("mouseleave", GEN_GATES_LAYER_ID, handleHoverOffGate);
      map.off("click", handleClick);
      map.off("dblclick", GEN_GATES_LAYER_ID, handleDoubleClickOnGate);
    }
  }, [map, handleHoverOnGate, handleHoverOffGate, handleClick, handleDoubleClickOnGate]);

  const handleAddGate = useCallback(
    (e: MapLayerMouseEvent) => {
      const feature = e.features?.[0];
      if (
        feature &&
        feature.geometry.type === "LineString" &&
        editorDrawModeRef.current === DatasetEditorMode.DrawGate &&
        ODDatasetConfigurationDataRef.current?.timePeriod
      ) {
        dispatch(
          analyticsActions.fetchAddedGate({
            intersectingLine: feature.geometry,
            timePeriod: ODDatasetConfigurationDataRef.current?.timePeriod,
            gateRoadClasses: ODDatasetConfigurationDataRef.current?.gateRoadClasses || [],
            existingGates: generatedGatesDataRef.current || [],
            includeUnchanged: true,
            polygon: ODDatasetConfigurationDataRef.current?.subAreaGeometry || null,
          }),
        );

        draw.delete(feature.id);
        setEditorDrawMode(DatasetEditorMode.SimpleSelect);
        addGatesLayerEvents();
      }
    },
    [draw, editorDrawModeRef, ODDatasetConfigurationDataRef, setEditorDrawMode, addGatesLayerEvents, dispatch],
  );

  const handleAddSegments = useCallback(
    (segments: GateSegmentProps[]) => {
      if (selectedGateRef.current && ODDatasetConfigurationDataRef.current?.timePeriod) {
        dispatch(
          analyticsActions.addGateSegments(selectedGateRef.current.identifier, segments, {
            segments: [...selectedGateRef.current.segments, ...segments],
            polygon: polygonRef.current,
            timePeriod: ODDatasetConfigurationDataRef.current?.timePeriod,
          }),
        );
      }
    },
    [ODDatasetConfigurationDataRef, polygonRef, dispatch],
  );

  const handleDeleteSegments = useCallback(
    (segments: GateSegmentProps[] | GateSegment[]) => {
      if (selectedGateRef.current && ODDatasetConfigurationDataRef.current?.timePeriod) {
        const ids = segments.map((s) => s.id);
        const firstSegmentIdx = segmentsIdToIdxMapDataRef.current?.get(segments[0].id);

        if (firstSegmentIdx) {
          setSegmentFeatureState(firstSegmentIdx, {});
        }

        dispatch(
          analyticsActions.deleteGateSegments(selectedGateRef.current.identifier, ids, {
            segments: selectedGateRef.current.segments.filter((s) => !ids.includes(s.id)),
            polygon: polygonRef.current,
            timePeriod: ODDatasetConfigurationDataRef.current?.timePeriod,
          }),
        );
      }
    },
    [segmentsIdToIdxMapDataRef, ODDatasetConfigurationDataRef, polygonRef, setSegmentFeatureState, dispatch],
  );

  const hoveredSegmentIdx = useRef<number | null>(null);

  const handleHoverOnSegment = useCallback(
    (e: MapLayerMouseEvent) => {
      if (hoveredSegmentIdx.current) {
        setSegmentFeatureState(hoveredSegmentIdx.current, {
          [EditorRoadsHighlightStatus.HoverAdd]: false,
          [EditorRoadsHighlightStatus.HoverDelete]: false,
        });
      }

      if (!roadsTileService) return;

      const feature = e.features?.[0];
      const featureId = feature?.properties?.[roadsTileService.fromToSegmentIdField];
      const featureIdx = feature?.properties?.[roadsTileService.fromToSegmentIndexField];

      dispatch(analyticsActions.addSegmentIdToIdxMap(featureId, featureIdx, SegmentIndexesForIdsMapSource.DATASET));

      if (map && featureIdx) {
        map.getCanvas().style.cursor = "default";
        if (
          allGatesSegmentsIdsRef.current?.[featureId] &&
          selectedGateRef.current?.segments.find((s) => s.id === featureId)
        ) {
          setSegmentFeatureState(featureIdx, {
            [EditorRoadsHighlightStatus.HoverDelete]: true,
          });
        } else if (!allGatesSegmentsIdsRef.current?.[featureId]) {
          setSegmentFeatureState(featureIdx, {
            [EditorRoadsHighlightStatus.HoverAdd]: true,
          });
        }
        hoveredSegmentIdx.current = featureIdx;
      }
    },
    [map, roadsTileService, allGatesSegmentsIdsRef, setSegmentFeatureState, dispatch],
  );

  const handleHoverOffSegment = useCallback(() => {
    if (map && hoveredSegmentIdx.current) {
      map.getCanvas().style.cursor = "";
      setSegmentFeatureState(hoveredSegmentIdx.current, {
        [EditorRoadsHighlightStatus.HoverAdd]: false,
        [EditorRoadsHighlightStatus.HoverDelete]: false,
      });
    }
  }, [map, setSegmentFeatureState]);

  const handleClickOnSegment = useCallback(
    (e: MapLayerMouseEvent) => {
      if (map) {
        const feature = e.features?.[0];
        if (feature && roadsTileService) {
          const ftSegment = normalizeFromToSegment(feature.properties, roadsTileService);
          const tfSegment = normalizeToFromSegment(feature.properties, roadsTileService);

          dispatch(
            analyticsActions.addSegmentIdToIdxMap(
              ftSegment.id,
              feature.properties?.[roadsTileService.fromToSegmentIndexField],
              SegmentIndexesForIdsMapSource.DATASET,
            ),
          );

          const segments = [ftSegment, ...(feature.properties?.seg_pair ? [tfSegment] : [])];

          if (
            editorDrawModeRef.current === DatasetEditorMode.AddSegmentsToGate &&
            allGatesSegmentsIdsRef.current &&
            selectedGateRef.current
          ) {
            const isSegmentTaken = segments.find((s) => allGatesSegmentsIdsRef.current?.[s.id]);
            const segmentOwnerGate = findGateFromSegmentId(ftSegment.id, generatedGatesDataRef.current || []);
            const gateOwnesSegment = segmentOwnerGate?.identifier === selectedGateRef.current.identifier;

            if (!isSegmentTaken) {
              handleAddSegments(segments);
            } else if (gateOwnesSegment) {
              setSelectedSegment(ftSegment as any);
            }
          }
        }
      }
    },
    [map, roadsTileService, editorDrawModeRef, allGatesSegmentsIdsRef, handleAddSegments, dispatch],
  );

  const addHighlightedSegmentsLayerEvents = useCallback(() => {
    if (map) {
      map.on("click", EditorRoads.HighlightedSegmentsLayerId, handleClickOnSegment);
      map.on("mousemove", EditorRoads.HighlightedSegmentsLayerId, handleHoverOnSegment);
      map.on("mouseleave", EditorRoads.HighlightedSegmentsLayerId, handleHoverOffSegment);
    }
  }, [map, handleClickOnSegment, handleHoverOnSegment, handleHoverOffSegment]);

  const removeHighlightedSegmentsLayerEvents = useCallback(() => {
    if (map) {
      map.off("click", EditorRoads.HighlightedSegmentsLayerId, handleClickOnSegment);
      map.off("mousemove", EditorRoads.HighlightedSegmentsLayerId, handleHoverOnSegment);
      map.off("mouseleave", EditorRoads.HighlightedSegmentsLayerId, handleHoverOffSegment);
    }
  }, [map, handleClickOnSegment, handleHoverOnSegment, handleHoverOffSegment]);

  //Cleanup
  useEffect(() => {
    return () => {
      dispatch(analyticsActions.clearNewGate());
    };
  }, [dispatch]);

  // Keeping updated generatedGatesDataRef with current state. The ref is used to pass the generated gates to the event listener.
  useEffect(() => {
    if (isRoadsSourceLoaded && segmentsIdToIdxMap.state === DataState.AVAILABLE) {
      generatedGatesDataRef.current?.forEach((gate) => {
        gate.segments?.forEach((s) => {
          const segmentIdx = segmentsIdToIdxMapDataRef.current?.get(s.id);

          if (segmentIdx) {
            setSegmentFeatureState(segmentIdx, {
              [EditorRoadsHighlightStatus.AllGateSegments]: false,
            });
          }
        });
      });
      ODDatasetConfiguration.data?.gates?.forEach((gate) => {
        gate.segments?.forEach((s) => {
          const segmentIdx = segmentsIdToIdxMapDataRef.current?.get(s.id);

          if (segmentIdx) {
            setSegmentFeatureState(segmentIdx, {
              [EditorRoadsHighlightStatus.AllGateSegments]: true,
            });
          }
        });
      });

      generatedGatesDataRef.current = ODDatasetConfiguration.data?.gates || null;
      setSelectedSegment(null);
    }
  }, [
    isRoadsSourceLoaded,
    segmentsIdToIdxMap.state,
    segmentsIdToIdxMapDataRef,
    ODDatasetConfiguration.data?.gates,
    setSegmentFeatureState,
  ]);

  useEffect(() => {
    if (isRoadsSourceLoaded) {
      const allLockedGates =
        ODDatasetConfiguration.data?.gates?.filter((gate) => gate.pinned).map((gate) => gate.identifier) || [];

      map?.setFilter(LOCKED_GEN_GATES_LAYER_ID, ["in", "identifier", ...allLockedGates]);
    }
  }, [map, isRoadsSourceLoaded, ODDatasetConfiguration.data?.gates]);

  useEffect(() => {
    if (newGate.data && segmentsIdToIdxMap.state === DataState.AVAILABLE) {
      setSelectedGateId(newGate.data.identifier);
    }
  }, [newGate, segmentsIdToIdxMap.state]);

  useEffect(() => {
    if (selectedGateRef.current) {
      map?.setFeatureState(
        {
          source: GEN_GATES_LAYER_ID,
          id: selectedGateRef.current.identifier,
        },
        {
          selected: false,
        },
      );
    }

    if (selectedGate) {
      map?.setFeatureState(
        {
          source: GEN_GATES_LAYER_ID,
          id: selectedGate.identifier,
        },
        {
          selected: true,
        },
      );
    }

    selectedGateRef.current = selectedGate;
  }, [map, selectedGate]);

  useEffect(() => {
    if (
      selectedGate?.identifier &&
      gateItemsRefs[selectedGate.identifier] &&
      gateItemsRefs[selectedGate.identifier].current
    ) {
      setTimeout(() => {
        gateItemsRefs[selectedGate.identifier].current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }, 500);
    }
  }, [selectedGate, gateItemsRefs]);

  useEffect(() => {
    gateMarker.current.remove();
    if (map && selectedGate) {
      gateMarker.current.setLngLat([selectedGate.lon, selectedGate.lat]).addTo(map);
    }
  }, [map, selectedGate]);

  useLayoutEffect(() => {
    if (map && mapLoaded && isRoadsSourceInitialized && ODDatasetConfiguration.state === DataState.AVAILABLE) {
      if (map.getLayer(LOCKED_GEN_GATES_LAYER_ID)) {
        map.removeLayer(LOCKED_GEN_GATES_LAYER_ID);
      }
      if (map.getLayer(GEN_GATES_LAYER_ID)) {
        map.removeLayer(GEN_GATES_LAYER_ID);
        map.removeSource(GEN_GATES_LAYER_ID);
      }

      map.addSource(GEN_GATES_LAYER_ID, {
        type: "geojson",
        promoteId: "identifier",
        data: {
          type: "FeatureCollection",
          features:
            ODDatasetConfiguration.data?.gates?.map((gate) => ({
              type: "Feature",
              properties: {
                identifier: gate.identifier,
                lat: gate.lat,
                lon: gate.lon,
              },
              geometry: {
                type: "Point",
                coordinates: [gate.lon, gate.lat],
              },
            })) || [],
        },
      });

      map.addLayer({
        id: GEN_GATES_LAYER_ID,
        type: "circle",
        source: GEN_GATES_LAYER_ID,
        paint: {
          "circle-color": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            "#1e40af",
            ["boolean", ["feature-state", "selected"], false],
            "purple",
            "#139eec",
          ],
          "circle-radius": ["interpolate", ["exponential", 1.6], ["zoom"], 6, 4, 10, 7, 16, 8, 20, 10],
          "circle-stroke-width": ["case", ["boolean", ["feature-state", "selected"], false], 1.5, 1],
          "circle-stroke-color": "#ffffff",
        },
      });

      map.addLayer({
        id: LOCKED_GEN_GATES_LAYER_ID,
        type: "symbol",
        source: GEN_GATES_LAYER_ID,
        layout: {
          "icon-image": "pin",
          "icon-size": 0.8,
          "icon-allow-overlap": true,
          "icon-offset": [14, -14],
        },
        filter: ["in", "identifier", ""],
      });

      if (!gatesLayerEventsFirstLoaded.current) {
        addGatesLayerEvents();
        gatesLayerEventsFirstLoaded.current = true;
      }
    }
  }, [
    map,
    mapLoaded,
    isRoadsSourceInitialized,
    ODDatasetConfiguration.state,
    ODDatasetConfiguration.data?.gates,
    addGatesLayerEvents,
    setSegmentFeatureState,
  ]);

  const getGateTitle = (gate: Gate | null) =>
    gate
      ? `${
          uniq(
            gate.segments
              ?.map((segment) => segment.name)
              .filter((v) => v)
              .sort(),
          ).join(", ") || "(no street name)"
        }`
      : "";

  const handleDeleteAllGates = () => {
    if (editorDrawMode === DatasetEditorMode.AddSegmentsToGate) setEditorDrawMode(DatasetEditorMode.SimpleSelect);
    dispatch(analyticsActions.deleteAllGates());
  };

  const handleDeleteGate = (gateId: string) => {
    if (editorDrawMode === DatasetEditorMode.AddSegmentsToGate) setEditorDrawMode(DatasetEditorMode.SimpleSelect);
    dispatch(analyticsActions.deleteGate(gateId));
  };

  return (
    <RightSidebarPanel
      isOpen={isGateEditorOpen}
      onClose={() => setIsGateEditorOpen(false)}
      title="Gates Editor"
      subtitle={
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Typography
            fontSize={11}
            color={"text.secondary"}
          >{`${selectedRoadClasses?.length} of ${roadClasses?.length} road classes selected for gate definition`}</Typography>
          <Popover
            hover
            control={(handleOpen, handleClose, open) => (
              <IconButton
                size="small"
                color="secondary"
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
                sx={{ marginRight: 0.5 }}
              >
                <InfoOutlined fontSize="inherit" />
              </IconButton>
            )}
          >
            <Box padding={2} maxWidth={600}>
              <Stack spacing={2}>
                <div>
                  <Grid container alignItems={"center"}>
                    <AutoAwesome fontSize="small" color="secondary" sx={{ marginBottom: "4px" }} />
                    <Typography variant="subtitle2" fontWeight={700} marginLeft={1}>
                      Generate / Regenerate
                    </Typography>
                  </Grid>
                  <Typography variant="body2" color={"text.secondary"} component={"div"} paddingLeft={2}>
                    <ul>
                      <li>
                        Automatically generates gates where <strong>selected road classes</strong> intersect the{" "}
                        <strong>subarea boundary</strong>.
                      </li>
                      <li>
                        One-way roads that intersect the boundary close to each other are grouped into a single gate.
                        Segment pairs for bidirectional roads are grouped into one gate.
                      </li>
                      <li>
                        When regenerating gates (e.g. after changing the boundary or road class selection), pinned gates
                        (
                        <PushPin
                          fontSize="inherit"
                          sx={{ marginX: 0.5, marginBottom: "-2px", color: "secondary.main" }}
                        />
                        ) are not changed. Other gates are deleted prior to regeneration.
                      </li>
                    </ul>
                  </Typography>
                </div>
                <div>
                  <Grid container alignItems={"center"}>
                    <Add fontSize="small" color="secondary" sx={{ marginBottom: "2px" }} />
                    <Typography variant="subtitle2" fontWeight={700} marginLeft={1}>
                      Add Gate
                    </Typography>
                  </Grid>
                  <Typography variant="body2" color={"text.secondary"} component={"div"} paddingLeft={2}>
                    <ul>
                      <li>
                        Click on the map to define a line that intersects the roads for which a new gate should be
                        added. Double-click or press <code>Enter</code> to finish; press <code>ESC</code> to cancel.
                      </li>
                      <li>
                        This tool allows to quickly reorganize gates: if one of the intersected roads is already part of
                        another gate, it will be reassigned to the new gate. If the other gate has no remaining roads
                        assigned then it will be deleted.
                      </li>
                      <ul style={{ marginLeft: "2rem" }}>
                        <li>
                          Pinned gates are not modified by this tool. To reassign roads from a pinned gate, first unpin
                          it.
                        </li>
                      </ul>
                      <li>
                        Manually added gates are created as pinned (
                        <PushPin
                          fontSize="inherit"
                          sx={{ marginX: 0.5, marginBottom: "-2px", color: "secondary.main" }}
                        />
                        ) and will be maintained when regenerating gates.
                      </li>
                    </ul>
                  </Typography>
                </div>
                <div>
                  <Typography variant="subtitle1" fontWeight={700} marginLeft={0}>
                    Selected gate
                  </Typography>
                  <Divider />
                </div>
                <div>
                  <Grid container alignItems={"center"}>
                    <Typography variant="subtitle2" fontWeight={700} marginLeft={1}>
                      Gate description
                    </Typography>
                  </Grid>
                  <Typography variant="body2" color={"text.secondary"} component={"div"} paddingLeft={2}>
                    <ul>
                      <li>Descriptions can optionally be added to gates.</li>
                      <li>When exporting a computed dataset, gate descriptions are included.</li>
                    </ul>
                  </Typography>
                </div>
                <div>
                  <Grid container alignItems={"center"}>
                    <Add fontSize="small" color="secondary" sx={{ marginBottom: "2px" }} />
                    <Typography variant="subtitle2" fontWeight={700} marginLeft={1}>
                      Add Segment
                    </Typography>
                  </Grid>
                  <Typography variant="body2" color={"text.secondary"} component={"div"} paddingLeft={2}>
                    <ul>
                      <li>Select a road on the map to include it in the selected gate.</li>
                      <li>
                        When clicking on a road that is already part of the selected gate, then the road is selected in
                        the list.
                      </li>
                      <li>
                        Roads already assigned to <i>another</i> gate cannot be selected.
                      </li>
                    </ul>
                  </Typography>
                </div>
                <div>
                  <Grid container alignItems={"center"}>
                    <RemoveCircleOutline fontSize="small" color="secondary" sx={{ marginBottom: "2px" }} />
                    <Typography variant="subtitle2" fontWeight={700} marginLeft={1}>
                      Remove segment
                    </Typography>
                  </Grid>
                  <Typography variant="body2" color={"text.secondary"} component={"div"} paddingLeft={2}>
                    <ul>
                      <li>
                        If a gate includes more than one road, then a road selected in the list can be removed from the
                        gate.
                      </li>
                      <li>
                        The button is not available if the gate contains only a single road. To delete a gate, use the
                        delete button (
                        <Delete
                          fontSize="inherit"
                          sx={{ marginX: 0.5, marginBottom: "-2px", color: "secondary.main" }}
                        />
                        ) on the selected gate or in the gates list.
                      </li>
                    </ul>
                  </Typography>
                </div>
              </Stack>
            </Box>
          </Popover>
        </Grid>
      }
    >
      <Container>
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <div>
            <Button
              sx={{ marginRight: 1 }}
              variant="outlined"
              color="secondary"
              onClick={handleGenerateGates}
              loading={loadingGates}
              disabled={subareaState.state === DataState.EMPTY || !ODDatasetConfiguration.data?.subAreaGeometry}
              startIcon={<AutoAwesome />}
            >
              {ODDatasetConfiguration.data?.gates?.length ? "Regenerate" : "Generate"}
            </Button>
            <Tooltip
              placement="left"
              title="Add or redefine gate by drawing line across road segments (finish line with double-click)"
            >
              <span>
                <DrawIconButton
                  selected={editorDrawMode === DatasetEditorMode.DrawGate}
                  onClick={() => toggleAddMode(DatasetEditorMode.DrawGate)}
                >
                  <Add fontSize="inherit" />
                  <span>Add Gate</span>
                </DrawIconButton>
              </span>
            </Tooltip>
          </div>

          <Button
            variant="outlined"
            color="secondary"
            disabled={!ODDatasetConfiguration.data?.gates?.length}
            onClick={handleDeleteAllGates}
          >
            Delete All
          </Button>
        </Grid>
        {datasetGates.length ? (
          <>
            <Card variant="outlined" sx={{ height: "100%" }}>
              <List
                sx={{
                  overflow: "auto",
                  height: "100%",
                  padding: 0,
                }}
              >
                {datasetGates.map((gate, i) => {
                  const isSelected = selectedGate?.identifier === gate.identifier;
                  return (
                    <ListItem
                      key={gate.identifier}
                      ref={gateItemsRefs[gate.identifier]}
                      onClick={() => handleSelectGate(gate.identifier, [gate.lon, gate.lat])}
                      onDoubleClick={() => handleZoomOnGate([gate.lon, gate.lat])}
                      disablePadding
                      disableGutters
                      secondaryAction={
                        <Stack direction="row" spacing={1} marginRight={2}>
                          {(isSelected || gate.pinned) && (
                            <IconButton onClick={() => handleToggleLockGate(gate.identifier)} edge="end" size="small">
                              <PushPin
                                fontSize="inherit"
                                sx={{
                                  color: gate.pinned ? "secondary.main" : "text.disabled",
                                  rotate: gate.pinned ? "0deg" : "45deg",
                                }}
                              />
                            </IconButton>
                          )}

                          <IconButton
                            sx={{ color: isSelected ? "secondary.main" : "transparent" }}
                            edge="end"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleZoomOnGate([gate.lon, gate.lat]);
                            }}
                          >
                            <CenterFocusStrong fontSize="inherit" />
                          </IconButton>
                          <IconButton onClick={() => handleDeleteGate(gate.identifier)} edge="end" size="small">
                            <Delete fontSize="inherit" sx={{ color: isSelected ? "secondary.main" : "transparent" }} />
                          </IconButton>
                        </Stack>
                      }
                    >
                      <ListItemButton selected={isSelected}>
                        <Typography fontSize={12} noWrap color={"text.secondary"} maxWidth={230}>
                          {`${gate.identifier}. ${getGateTitle(gate)}`}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Card>
            <Divider />
          </>
        ) : (
          <Stack padding={2} fontSize={14}>
            <Typography fontSize={"inherit"} fontWeight={500} marginBottom={1}>
              Defining Gates
            </Typography>
            <Typography fontSize={"inherit"} color="text.secondary" gutterBottom>
              Gates capture trips that exit or enter the sub-area via selected roads.
            </Typography>

            <Typography fontSize={"inherit"} color="text.secondary" gutterBottom>
              You can <strong>generate</strong> gates for selected road classes that cross the sub-area, and{" "}
              <strong>add</strong>, <strong>modify</strong> and <strong>remove</strong> individual gates.
            </Typography>

            <Typography fontSize={"inherit"} color="text.secondary" gutterBottom>
              Gates can be <strong>pinned</strong> to keep them when re-generating gates, e.g. after changing the
              sub-area.
            </Typography>
          </Stack>
        )}

        <>
          {selectedGate ? (
            <>
              <div>
                <TitlePanel>
                  <GateStreetName>{getGateTitle(selectedGate)}</GateStreetName>
                </TitlePanel>
                <TitlePanel>
                  <GateId>{selectedGate?.identifier ? `Gate ${selectedGate.identifier}` : ""}</GateId>
                  <GateDetailTools>
                    <Tooltip
                      placement="bottom"
                      title={selectedGate.pinned ? "Unpin gate" : "Pin gate"}
                      disableHoverListener={!selectedGate}
                    >
                      <span>
                        <DrawIconButton
                          disabled={!selectedGate}
                          onClick={() => handleToggleLockGate(selectedGate?.identifier as string)}
                        >
                          <PushPin
                            fontSize="inherit"
                            sx={{
                              rotate: selectedGate.pinned ? "0deg" : "45deg",
                              color: selectedGate.pinned ? "primary" : "divider",
                            }}
                          />
                        </DrawIconButton>
                      </span>
                    </Tooltip>
                    <Tooltip placement="bottom" title="Zoom to gate" disableHoverListener={!selectedGate}>
                      <span>
                        <DrawIconButton
                          disabled={!selectedGate}
                          onClick={() =>
                            selectedGate ? handleZoomOnGate([selectedGate.lon, selectedGate.lat]) : undefined
                          }
                        >
                          <CenterFocusStrong fontSize="inherit" />
                        </DrawIconButton>
                      </span>
                    </Tooltip>
                    <Tooltip placement="bottom" title="Delete gate" disableHoverListener={!selectedGate}>
                      <span>
                        <DrawIconButton
                          disabled={!selectedGate}
                          onClick={() =>
                            selectedGate?.identifier ? handleDeleteGate(selectedGate.identifier) : undefined
                          }
                        >
                          <Delete fontSize="inherit" />
                        </DrawIconButton>
                      </span>
                    </Tooltip>
                  </GateDetailTools>
                </TitlePanel>
                <TextField
                  fullWidth
                  multiline
                  placeholder="Gate description..."
                  rows={1}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    selectedGate ? handleDescriptionUpdate(selectedGate.identifier, e.target.value) : undefined
                  }
                  value={gateDescription || ""}
                />
              </div>

              <MapControlContainer
                title={`Road segments (${selectedGate?.segments?.length || 0})`}
                secondaryAction={
                  <Stack direction={"row"} spacing={1}>
                    <Tooltip placement="bottom" title="Select a segment on the map to add it to the gate">
                      <DrawIconButton
                        height={24}
                        selected={editorDrawMode === DatasetEditorMode.AddSegmentsToGate}
                        onClick={() => toggleAddMode(DatasetEditorMode.AddSegmentsToGate)}
                      >
                        <Add fontSize="inherit" />
                        <span>Add Segment</span>
                      </DrawIconButton>
                    </Tooltip>
                  </Stack>
                }
              >
                <List
                  sx={{
                    overflowY: "auto",
                    height: "calc(100% - 40px)",
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  {selectedGateParsedSegments.map((segment) => (
                    <ListItem key={segment.id} disableGutters disablePadding>
                      <ListItemButton
                        divider
                        sx={{ padding: 0 }}
                        selected={selectedSegment?.id === segment.id}
                        onClick={() =>
                          setSelectedSegment((selectedSegment) => (selectedSegment?.id === segment.id ? null : segment))
                        }
                      >
                        <Grid container alignItems={"center"} padding={"4px 4px 8px 4px"}>
                          <Grid item xs={11}>
                            <Typography variant="body2" noWrap maxWidth={320} marginLeft={0.5}>
                              {segment.name || "No name"}{" "}
                              <Typography variant="caption" fontStyle={"italic"} color={"text.secondary"} noWrap>
                                - {getRoadClassLabel(roadClasses, segment.roadClass)}
                              </Typography>
                            </Typography>

                            <Stack spacing={0.5} direction={"row"}>
                              <StyledChip
                                icon={<DirectionIcon direction={segment.direction} fontSize="inherit" />}
                                label={
                                  <Typography noWrap fontSize={12} color={"text.primary"}>
                                    {getDirectionLabel(segment.direction)}
                                  </Typography>
                                }
                              />
                              {segment.toFromSeg && (
                                <StyledChip
                                  icon={<DirectionIcon direction={segment.toFromSeg.direction} fontSize="inherit" />}
                                  label={
                                    <Typography noWrap fontSize={12} color={"text.primary"}>
                                      {getDirectionLabel(segment.toFromSeg.direction)}
                                    </Typography>
                                  }
                                />
                              )}
                            </Stack>
                          </Grid>
                          {selectedSegment?.id === segment.id && selectedGateParsedSegments.length > 1 && (
                            <Tooltip title="Remove segment">
                              <IconButton onClick={() => handleDeleteSegments(unParseSegment(segment))}>
                                <RemoveCircleOutline fontSize="inherit" color="secondary" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Grid>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </MapControlContainer>
            </>
          ) : ODDatasetConfiguration.data?.gates?.length ? (
            <Card variant="outlined">
              <Stack padding={2} alignItems={"center"}>
                <ManageGatePlaceHolderSVG src={gateSegmentsPlaceholderSVG} alt="Gate Segments Placeholder" />
                <Typography variant="subtitle1" fontWeight={500}>
                  No gate selected
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Select</strong> a gate on the map or in the Gates list.
                </Typography>
              </Stack>
            </Card>
          ) : null}
        </>
      </Container>
    </RightSidebarPanel>
  );
};

export const GatesEditor = React.memo(GatesEditorComponent);
