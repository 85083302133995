import { Api } from "api";
import { produce } from "immer";
import { Reducer } from "redux";
import { all, call, getContext, put, takeLatest } from "redux-saga/effects";

import {
  FeedbackPayload,
  LicenseType,
  NotificationsInboxResponse,
  NotificationsInboxState,
  Organization,
  SessionResponse,
  User,
  UserPermissions,
} from "types";

import { reportAboutErrorState } from "utils/reports";

import { Action, ActionsUnion, createAction } from "../actionHelpers";
import { DataState, LoadingErrorData, ResponseError } from "../interfaces";
import { LicenseActionType } from "./actionTypes";

export interface LicenseState {
  user: LoadingErrorData<User>;
  organization: LoadingErrorData<Organization[]>;
  notifications: LoadingErrorData<NotificationsInboxResponse>;
  notificationsState: LoadingErrorData<NotificationsInboxState>;
  feedback: LoadingErrorData<true>;
  licenseTypes: LoadingErrorData<LicenseType[]>;
  permissions: LoadingErrorData<UserPermissions>;
  session: LoadingErrorData<SessionResponse>;
}

export type LicenseAction = ActionsUnion<typeof licenseActions>;

export const licenseActions = {
  fetchUser: () => createAction(LicenseActionType.FETCH_USER),
  fetchUserSucceeded: (user: User) => createAction(LicenseActionType.FETCH_USER_SUCCEEDED, { user }),
  fetchUserFailed: (error: ResponseError) => createAction(LicenseActionType.FETCH_USER_FAILED, error),

  fetchOrganization: () => createAction(LicenseActionType.FETCH_ORGANIZATION),
  fetchOrganizationSucceeded: (organization: Organization[]) =>
    createAction(LicenseActionType.FETCH_ORGANIZATION_SUCCEEDED, {
      organization,
    }),
  fetchOrganizationFailed: (error: ResponseError) => createAction(LicenseActionType.FETCH_ORGANIZATION_FAILED, error),

  changeUserOrganization: (code: string) => createAction(LicenseActionType.CHANGE_USER_ORGANIZATION, code),

  // Notifications
  fetchNotificationsInboxState: () => createAction(LicenseActionType.FETCH_NOTIFICATIONS_INBOX_STATE),
  fetchNotificationsInboxStateSucceeded: (state: NotificationsInboxState) =>
    createAction(LicenseActionType.FETCH_NOTIFICATIONS_INBOX_STATE_SUCCEEDED, {
      state,
    }),
  fetchNotificationsInboxStateFailed: (error: ResponseError) =>
    createAction(LicenseActionType.FETCH_NOTIFICATIONS_INBOX_STATE_FAILED, error),
  readAllNotificationsFromInbox: () => createAction(LicenseActionType.READ_ALL_NOTIFICATIONS_FROM_INBOX),

  updateNotificationsInboxState: (lastAccessed: string) =>
    createAction(LicenseActionType.UPDATE_NOTIFICATIONS_INBOX_STATE, {
      lastAccessed,
    }),
  updateNotificationsInboxStateSucceeded: () =>
    createAction(LicenseActionType.UPDATE_NOTIFICATIONS_INBOX_STATE_SUCCEEDED, {}),
  updateNotificationsInboxStateFailed: (error: ResponseError) =>
    createAction(LicenseActionType.UPDATE_NOTIFICATIONS_INBOX_STATE_FAILED, error),

  fetchNotificationsInbox: () => createAction(LicenseActionType.FETCH_NOTIFICATIONS_INBOX),
  fetchNotificationsInboxSucceeded: (notifications: NotificationsInboxResponse) =>
    createAction(LicenseActionType.FETCH_NOTIFICATIONS_INBOX_SUCCEEDED, {
      notifications,
    }),
  fetchNotificationsInboxFailed: (error: ResponseError) =>
    createAction(LicenseActionType.FETCH_NOTIFICATIONS_INBOX_FAILED, error),

  sendFeedback: (feedbackPayload: FeedbackPayload) => createAction(LicenseActionType.SEND_FEEDBACK, feedbackPayload),
  sendFeedbackSucceeded: () => createAction(LicenseActionType.SEND_FEEDBACK_SUCCEEDED),
  sendFeedbackFailed: (error: ResponseError) => createAction(LicenseActionType.SEND_FEEDBACK_FAILED, error),
  clearFeedback: () => createAction(LicenseActionType.CLEAR_FEEDBACK),

  fetchLicenseTypes: () => createAction(LicenseActionType.FETCH_LICENSE_TYPES),
  fetchLicenseTypesSucceeded: (licenseTypes: LicenseType[]) =>
    createAction(LicenseActionType.FETCH_LICENSE_TYPES_SUCCEEDED, { licenseTypes }),
  fetchLicenseTypesFailed: (error: ResponseError) => createAction(LicenseActionType.FETCH_LICENSE_TYPES_FAILED, error),

  changeUserLicenseType: (code: string) => createAction(LicenseActionType.CHANGE_USER_LICENSE_TYPE, code),

  fetchPermissions: () => createAction(LicenseActionType.FETCH_PERMISSIONS),
  fetchPermissionsSucceeded: (permissions: any) =>
    createAction(LicenseActionType.FETCH_PERMISSIONS_SUCCEEDED, { permissions }),
  fetchPermissionsFailed: (error: ResponseError) => createAction(LicenseActionType.FETCH_PERMISSIONS_FAILED, error),

  triggerStartSession: () => createAction(LicenseActionType.TRIGGER_START_SESSION),
  triggerStartSessionSucceeded: (sessionResponse: any) =>
    createAction(LicenseActionType.TRIGGER_START_SESSION_SUCCEEDED, { sessionResponse }),
  triggerStartSessionFailed: (error: ResponseError) => createAction(LicenseActionType.TRIGGER_START_SESSION_FAILED, error),
};

const initialState: LicenseState = {
  user: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  organization: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  notifications: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  notificationsState: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  feedback: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  licenseTypes: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  permissions: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  session: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
};

const reducer: Reducer<LicenseState, LicenseAction> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LicenseActionType.FETCH_USER: {
        draft.user = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case LicenseActionType.FETCH_USER_SUCCEEDED: {
        draft.user = {
          state: DataState.AVAILABLE,
          data: action.payload.user,
          error: null,
        };
        return;
      }
      case LicenseActionType.FETCH_USER_FAILED: {
        draft.user = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case LicenseActionType.FETCH_ORGANIZATION: {
        draft.organization = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case LicenseActionType.FETCH_ORGANIZATION_SUCCEEDED: {
        draft.organization = {
          state: DataState.AVAILABLE,
          data: action.payload.organization,
          error: null,
        };
        return;
      }
      case LicenseActionType.FETCH_ORGANIZATION_FAILED: {
        draft.organization = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }

      case LicenseActionType.FETCH_NOTIFICATIONS_INBOX_STATE: {
        draft.notificationsState = {
          state: DataState.LOADING,
          data: state.notificationsState.data,
          error: null,
        };
        return;
      }
      case LicenseActionType.FETCH_NOTIFICATIONS_INBOX_STATE_SUCCEEDED: {
        draft.notificationsState = {
          state: DataState.AVAILABLE,
          data: action.payload.state,
          error: null,
        };
        return;
      }
      case LicenseActionType.FETCH_NOTIFICATIONS_INBOX_STATE_FAILED: {
        draft.notificationsState = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case LicenseActionType.READ_ALL_NOTIFICATIONS_FROM_INBOX: {
        draft.notificationsState.data = {
          unseenNotificationCount: 0,
          waitMilli: state.notificationsState.data?.waitMilli || 15000, // default to 15 seconds
        };
        return;
      }

      case LicenseActionType.FETCH_NOTIFICATIONS_INBOX: {
        draft.notifications = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case LicenseActionType.FETCH_NOTIFICATIONS_INBOX_SUCCEEDED: {
        draft.notifications = {
          state: DataState.AVAILABLE,
          data: action.payload.notifications,
          error: null,
        };
        return;
      }
      case LicenseActionType.FETCH_NOTIFICATIONS_INBOX_FAILED: {
        draft.notifications = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }

      case LicenseActionType.SEND_FEEDBACK: {
        draft.feedback = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case LicenseActionType.SEND_FEEDBACK_SUCCEEDED: {
        draft.feedback = {
          state: DataState.AVAILABLE,
          data: true,
          error: null,
        };
        return;
      }
      case LicenseActionType.SEND_FEEDBACK_FAILED: {
        draft.feedback = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case LicenseActionType.CLEAR_FEEDBACK: {
        draft.feedback = {
          state: DataState.EMPTY,
          data: null,
          error: null,
        };
        return;
      }

      case LicenseActionType.FETCH_LICENSE_TYPES: {
        draft.licenseTypes = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case LicenseActionType.FETCH_LICENSE_TYPES_SUCCEEDED: {
        draft.licenseTypes = {
          state: DataState.AVAILABLE,
          data: action.payload.licenseTypes,
          error: null,
        };
        return;
      }
      case LicenseActionType.FETCH_LICENSE_TYPES_FAILED: {
        draft.licenseTypes = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case LicenseActionType.FETCH_PERMISSIONS: {
        draft.permissions = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case LicenseActionType.FETCH_PERMISSIONS_SUCCEEDED: {
        draft.permissions = {
          state: DataState.AVAILABLE,
          data: action.payload.permissions,
          error: null,
        };
        return;
      }
      case LicenseActionType.FETCH_PERMISSIONS_FAILED: {
        draft.permissions = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case LicenseActionType.TRIGGER_START_SESSION: {
        draft.session = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case LicenseActionType.TRIGGER_START_SESSION_SUCCEEDED: {
        draft.session = {
          state: DataState.AVAILABLE,
          data: action.payload.sessionResponse,
          error: null,
        };
        return;
      }
      case LicenseActionType.TRIGGER_START_SESSION_FAILED: {
        draft.session = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }

      default:
        return state;
    }
  });

export default reducer;

function* fetchUser(): Generator {
  try {
    const api = yield getContext("api");
    const {
      accountApi: { getUser },
    } = api as Api;
    const user = yield call(getUser);

    yield put({
      type: LicenseActionType.FETCH_USER_SUCCEEDED,
      payload: { user },
    });
  } catch (error) {
    reportAboutErrorState(error, LicenseActionType.FETCH_USER_FAILED);

    yield put({
      type: LicenseActionType.FETCH_USER_FAILED,
      payload: error,
    });
  }
}

function* fetchOrganization(): Generator {
  try {
    const api = yield getContext("api");
    const {
      accountApi: { getOrganization },
    } = api as Api;
    const organization = yield call(getOrganization);

    yield put({
      type: LicenseActionType.FETCH_ORGANIZATION_SUCCEEDED,
      payload: { organization },
    });
  } catch (error) {
    reportAboutErrorState(error, LicenseActionType.FETCH_ORGANIZATION_FAILED);

    yield put({
      type: LicenseActionType.FETCH_ORGANIZATION_FAILED,
      payload: error,
    });
  }
}

function* fetchNotificationState(): Generator {
  try {
    const api = yield getContext("api");
    const {
      accountApi: { getNotificationsInboxState },
    } = api as Api;
    const state = yield call(getNotificationsInboxState);

    yield put({
      type: LicenseActionType.FETCH_NOTIFICATIONS_INBOX_STATE_SUCCEEDED,
      payload: { state },
    });
  } catch (error) {
    yield put({
      type: LicenseActionType.FETCH_NOTIFICATIONS_INBOX_STATE_FAILED,
      payload: error,
    });
  }
}

function* fetchNotifications(): Generator {
  try {
    const api = yield getContext("api");
    const {
      accountApi: { getNotificationsInbox },
    } = api as Api;
    const notifications = yield call(getNotificationsInbox);

    yield put({
      type: LicenseActionType.FETCH_NOTIFICATIONS_INBOX_SUCCEEDED,
      payload: { notifications },
    });
  } catch (error) {
    reportAboutErrorState(error, LicenseActionType.FETCH_NOTIFICATIONS_INBOX_FAILED);

    yield put({
      type: LicenseActionType.FETCH_NOTIFICATIONS_INBOX_FAILED,
      payload: error,
    });
  }
}

function* updateNotificationsInboxState(action: Action<string, { lastAccessed: string }>): Generator {
  try {
    const api = yield getContext("api");
    const {
      accountApi: { updateNotificationsInboxState },
    } = api as Api;
    yield call(updateNotificationsInboxState, action.payload.lastAccessed);

    yield put({
      type: LicenseActionType.UPDATE_NOTIFICATIONS_INBOX_STATE_SUCCEEDED,
      payload: {},
    });
  } catch (error) {
    reportAboutErrorState(error, LicenseActionType.UPDATE_NOTIFICATIONS_INBOX_STATE_FAILED);

    yield put({
      type: LicenseActionType.UPDATE_NOTIFICATIONS_INBOX_STATE_FAILED,
      payload: error,
    });
  }
}

function* changeUserOrganization(action: Action<string, string>): Generator {
  try {
    const api = yield getContext("api");
    const {
      accountApi: { changeUserOrganization },
    } = api as Api;
    const user = yield call(changeUserOrganization, action.payload);

    yield put({
      type: LicenseActionType.FETCH_USER_SUCCEEDED,
      payload: { user },
    });
  } catch (error) {
    reportAboutErrorState(error, LicenseActionType.FETCH_USER_FAILED);

    yield put({
      type: LicenseActionType.FETCH_USER_FAILED,
      payload: error,
    });
  }
}

function* sendUserFeedback(action: Action<string, FeedbackPayload>): Generator {
  try {
    const api = yield getContext("api");
    const {
      accountApi: { sendFeedback },
    } = api as Api;
    yield call(sendFeedback, action.payload);

    yield put({
      type: LicenseActionType.SEND_FEEDBACK_SUCCEEDED,
    });
  } catch (error) {
    reportAboutErrorState(error, LicenseActionType.SEND_FEEDBACK_FAILED);

    yield put({
      type: LicenseActionType.SEND_FEEDBACK_FAILED,
      payload: error,
    });
  }
}

function* fetchLicenseTypes(): Generator {
  try {
    const api = yield getContext("api");
    const {
      licenseApi: { getSoftwareLicenseTypes },
    } = api as Api;
    const licenseTypes = yield call(getSoftwareLicenseTypes);

    yield put({
      type: LicenseActionType.FETCH_LICENSE_TYPES_SUCCEEDED,
      payload: { licenseTypes },
    });
  } catch (error) {
    reportAboutErrorState(error, LicenseActionType.FETCH_LICENSE_TYPES_FAILED);

    yield put({
      type: LicenseActionType.FETCH_LICENSE_TYPES_FAILED,
      payload: error,
    });
  }
}

function* changeUserLicenseType(action: Action<string, string>): Generator {
  try {
    const api = yield getContext("api");
    const {
      accountApi: { changeUserLicenseType },
    } = api as Api;
    const user = yield call(changeUserLicenseType, action.payload);

    yield put({
      type: LicenseActionType.FETCH_USER_SUCCEEDED,
      payload: { user },
    });
  } catch (error) {
    reportAboutErrorState(error, LicenseActionType.FETCH_USER_FAILED);

    yield put({
      type: LicenseActionType.FETCH_USER_FAILED,
      payload: error,
    });
  }
}

function* fetchPermissions(): Generator {
  try {
    const api = yield getContext("api");
    const {
      accountApi: { getPermissions },
    } = api as Api;
    const permissions = yield call(getPermissions);

    yield put({
      type: LicenseActionType.FETCH_PERMISSIONS_SUCCEEDED,
      payload: { permissions },
    });
  } catch (error) {
    reportAboutErrorState(error, LicenseActionType.FETCH_PERMISSIONS_FAILED);

    yield put({
      type: LicenseActionType.FETCH_PERMISSIONS_FAILED,
      payload: error,
    });
  }
}

function* triggerStartSession(): Generator {
  try {
    const api = yield getContext("api");
    const {
      accountApi: { startSession },
    } = api as Api;
    const sessionResponse = yield call(startSession);

    yield put({
      type: LicenseActionType.TRIGGER_START_SESSION_SUCCEEDED,
      payload: { sessionResponse },
    });
  } catch (error) {
    reportAboutErrorState(error, LicenseActionType.TRIGGER_START_SESSION_FAILED);

    yield put({
      type: LicenseActionType.TRIGGER_START_SESSION_FAILED,
      payload: error,
    });
  }
}

export function* licenseSaga() {
  yield all([
    takeLatest(LicenseActionType.FETCH_USER, fetchUser),
    takeLatest(LicenseActionType.FETCH_ORGANIZATION, fetchOrganization),
    takeLatest(LicenseActionType.CHANGE_USER_ORGANIZATION, changeUserOrganization),
    takeLatest(LicenseActionType.FETCH_NOTIFICATIONS_INBOX_STATE, fetchNotificationState),
    takeLatest(LicenseActionType.FETCH_NOTIFICATIONS_INBOX, fetchNotifications),
    takeLatest(LicenseActionType.UPDATE_NOTIFICATIONS_INBOX_STATE, updateNotificationsInboxState),
    takeLatest(LicenseActionType.SEND_FEEDBACK, sendUserFeedback),
    takeLatest(LicenseActionType.FETCH_LICENSE_TYPES, fetchLicenseTypes),
    takeLatest(LicenseActionType.CHANGE_USER_LICENSE_TYPE, changeUserLicenseType),
    takeLatest(LicenseActionType.FETCH_PERMISSIONS, fetchPermissions),
    takeLatest(LicenseActionType.TRIGGER_START_SESSION, triggerStartSession),
  ]);
}
