import { Card, Grid, List, ListItem, Skeleton, Stack, Typography } from "@mui/material";
import { Screenline, ScreenlineGeometry } from "api/analytics/index.d";
import { PaddingOptions } from "mapbox-gl";
import { FC, useMemo } from "react";

import { useAppSelector } from "hooks";

import { RoadNetworkType } from "types";

import { ScreenlineItem } from "./ScreenlineItem";

export interface ScreenlineListProps {
  loading: boolean;
  editMode: boolean;
  drawMode: boolean;
  screenlines: Screenline[];
  selectedScreenline: Screenline | null;
  networkType: RoadNetworkType | undefined;
  filterByRoadNetworkType: boolean;
  setSelectedScreenlineId: ((screenlineId: string | null) => void) | null;
  handleZoomOnBounds: (geometry: ScreenlineGeometry, padding?: number | PaddingOptions) => void;
  handleDeleteScreenline: (screenline: Screenline) => void;
  handleToggleScreenlineVisibility: (screenline: Screenline) => void;
}

export const ScreenlineList: FC<ScreenlineListProps> = ({
  loading,
  editMode,
  drawMode,
  screenlines,
  selectedScreenline,
  networkType,
  filterByRoadNetworkType,
  setSelectedScreenlineId,
  handleZoomOnBounds,
  handleDeleteScreenline,
  handleToggleScreenlineVisibility,
}) => {
  const loadingCreateScreenline = useAppSelector((state) => state.screenlines.loading);
  const screenlinesValidationSummaryData = useAppSelector(
    (state) => state.screenlines.screenlinesValidationSummary.data,
  );

  const filteredScreenlines = useMemo(
    () => screenlines.filter((s) => (filterByRoadNetworkType ? s.network === networkType : true)),
    [screenlines, filterByRoadNetworkType, networkType],
  );

  return (
    <Card variant="outlined" sx={{ height: "100%" }}>
      <List
        sx={{
          overflow: "auto",
          height: "100%",
          padding: 0,
        }}
      >
        {loadingCreateScreenline ? (
          Array.from({ length: screenlines.length + 1 }, (_, index) => 0 + index).map((i) => (
            <ListItem
              key={i}
              disablePadding
              disableGutters
              secondaryAction={
                <Stack direction={"row"}>
                  <Skeleton variant="rectangular" height={12} width={12} sx={{ marginRight: 1 }} />
                  <Skeleton variant="rectangular" height={12} width={12} sx={{ marginRight: 1 }} />
                  <Skeleton variant="rectangular" height={12} width={12} sx={{ marginRight: 1 }} />
                </Stack>
              }
              sx={{ padding: "6px 10px" }}
            >
              <Skeleton variant="text" width={40} height={12} sx={{ marginX: 1 }} />{" "}
              <Skeleton variant="text" width={60} height={12} />
            </ListItem>
          ))
        ) : filteredScreenlines.length === 0 ? (
          <Grid container direction="column" justifyContent="center" alignItems="center" height={"100%"} padding={2}>
            <Typography fontSize={12}>
              {`${screenlines.length} screenline${screenlines.length === 1 ? "" : "s"} hidden`}
            </Typography>
            <Typography fontSize={11} color={"text.secondary"}>
              {`since ${screenlines.length === 1 ? "it is" : "they are"} defined for a different road network`}
            </Typography>
          </Grid>
        ) : (
          filteredScreenlines.map((screenline) => (
            <ScreenlineItem
              key={screenline.id}
              screenlineId={screenline.id}
              name={screenline.name}
              selected={selectedScreenline?.id === screenline.id}
              disabled={editMode || drawMode || loading}
              visible={screenline.visible}
              validationSummary={
                screenlinesValidationSummaryData?.find((sv) => sv.screenlineId === screenline.id)?.summary
              }
              roadNetworkType={screenline.network}
              onClick={() => setSelectedScreenlineId?.(screenline.id)}
              handleZoomOnBounds={() => handleZoomOnBounds(screenline.geometry)}
              handleDeleteScreenline={() => handleDeleteScreenline(screenline)}
              handleToggleScreenlineVisibility={() => handleToggleScreenlineVisibility(screenline)}
            />
          ))
        )}
      </List>
    </Card>
  );
};
