import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import React, { Dispatch, MutableRefObject, SetStateAction, useEffect } from "react";

import { ModuleData } from "features/map/ModuleManager";

import { SelectedVolume } from "types";

import { RoadsAnalyticsPanel } from "../../roads/RoadsAnalyticsPanel";
import { MapController } from "./MapController";

interface AssignmentScenarioModuleComponentProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  updateRoadsModeCounts: MutableRefObject<(() => void) | null>;
  closeRoadsAnalyticsPanelRef: MutableRefObject<(() => void) | null>;
  updateRoadsPopupCounts: MutableRefObject<
    ((selectedVolume: SelectedVolume | null, volumesProps?: any[]) => void) | null
  >;
  setAssignmentModuleData: Dispatch<SetStateAction<ModuleData | null>>;
}
const AssignmentScenarioModuleComponent: React.FC<AssignmentScenarioModuleComponentProps> = ({
  map,
  layerManagerRef,
  updateRoadsModeCounts,
  closeRoadsAnalyticsPanelRef,
  updateRoadsPopupCounts,
  setAssignmentModuleData,
}) => {
  useEffect(() => {
    const closeRoadsAnalyticsPanel = closeRoadsAnalyticsPanelRef.current;

    return () => {
      if (closeRoadsAnalyticsPanel) {
        closeRoadsAnalyticsPanel();
      }

      setAssignmentModuleData(null);
    };
  }, [setAssignmentModuleData, closeRoadsAnalyticsPanelRef]);

  return (
    <>
      <MapController
        map={map}
        layerManagerRef={layerManagerRef}
        closeRoadsAnalyticsPanelRef={closeRoadsAnalyticsPanelRef}
        updateRoadsModeCounts={updateRoadsModeCounts}
        updateRoadsPopupCounts={updateRoadsPopupCounts}
        setAssignmentModuleData={setAssignmentModuleData}
      />
      <RoadsAnalyticsPanel
        isPedestriansMode={false}
        closeRoadsAnalyticsPanelRef={closeRoadsAnalyticsPanelRef}
        isAssignmentScenario
      />
    </>
  );
};

export default AssignmentScenarioModuleComponent;
