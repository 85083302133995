import {
  FeedbackPayload,
  NotificationsInboxResponse,
  NotificationsInboxState,
  Organization,
  OrganizationResponse,
  SessionRequest,
  SessionResponse,
  User,
  UserPermissions,
} from "types";

import RestHandler from "./RestHandler";
import { GetCurrentReleaseResponse, GetReleasesResponse } from "./account";

export interface AccountApiType {
  startSession():Promise<SessionResponse>;
  getNotificationsInboxState(): Promise<NotificationsInboxState>;
  updateNotificationsInboxState(lastAccessed: string): Promise<void>;
  getNotificationsInbox(): Promise<NotificationsInboxResponse>;
  sendFeedback(payload: FeedbackPayload): Promise<any>;
  getUser(): Promise<User>;
  changeUserOrganization(code: string): Promise<User>;
  changeUserLicenseType(code: string): Promise<User>;
  getPermissions(): Promise<UserPermissions>;
  getOrganization(): Promise<Organization[]>;
  getReleases(offset: number, limit: number): Promise<GetReleasesResponse>;
  getCurrentRelease(): Promise<GetCurrentReleaseResponse>;
}

export default function AccountApi(restHandler: RestHandler) {
  return {
    async startSession() {
      const body = await restHandler.post("session", {} as SessionRequest);
      return body as SessionResponse;
    },

    async getNotificationsInboxState() {
      /*
        The suppressEvents `true` parameter is used to indicate that the request is
        a frequent background request and should be ignored by event trackers.
      */
      const body = await restHandler.get<NotificationsInboxState>("my-inbox", undefined, true);
      return body;
    },

    async updateNotificationsInboxState(lastAccessed: string) {
      await restHandler.put("my-inbox", {
        lastAccessed,
      });
    },

    async getNotificationsInbox() {
      const body = await restHandler.get<NotificationsInboxResponse>("my-inbox/notification");
      return body;
    },

    async sendFeedback(payload: FeedbackPayload) {
      const body = await restHandler.post("feedback", payload);
      return body;
    },

    async getUser() {
      const body = await restHandler.get<User>("user/me");
      return body;
    },

    async changeUserOrganization(code: string) {
      const body = await restHandler.put("user/me", {
        organization: {
          code: code,
        },
      });
      return body as User;
    },

    async changeUserLicenseType(code: string) {
      const body = await restHandler.put("user/me", {
        softwareLicense: {
          softwareLicenseType: code,
        },
      });
      return body as User;
    },

    async getPermissions() {
      const body = await restHandler.get("user/me/permissions");
      return body as UserPermissions;
    },

    async getOrganization() {
      const body = await restHandler.get<OrganizationResponse>("organization?status-in=active");
      return body.data;
    },

    async getReleases(offset: number, limit: number) {
      const body = await restHandler.get(`release?offset=${offset}&limit=${limit}`);
      return body as GetReleasesResponse;
    },

    async getCurrentRelease() {
      const body = await restHandler.get("release/current");
      return body as GetCurrentReleaseResponse;
    },
  };
}
