import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const PolygonByPoints: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor">
      <g>
        <path
          d="M8.64,7.4238c-0.17-0.14-0.4-0.23-0.64-0.23s-0.47,0.09-0.64,0.23C7.14,7.6138,7,7.8838,7,8.1938
		c0,0.3101,0.14,0.58,0.36,0.76c0.17,0.15,0.4,0.24,0.64,0.24s0.47-0.09,0.64-0.24c0.22-0.18,0.36-0.45,0.36-0.76
		C9,7.8838,8.86,7.6138,8.64,7.4238z"
        />
      </g>
      <g>
        <path
          d="M1.64,5.7076c-0.17-0.14-0.4-0.23-0.64-0.23s-0.47,0.09-0.64,0.23C0.14,5.8976,0,6.1675,0,6.4775
		c0,0.3101,0.14,0.58,0.36,0.76c0.17,0.15,0.4,0.24,0.64,0.24s0.47-0.09,0.64-0.24c0.22-0.18,0.36-0.45,0.36-0.76
		C2,6.1675,1.86,5.8976,1.64,5.7076z"
        />
      </g>
      <g>
        <path
          d="M8,0L1.86,4.69c0.15,0.06,0.3,0.15,0.43,0.26C2.56,5.18,2.76,5.48,2.88,5.8L8,1.89l6.26,4.78l-2.42,7.83H4.16L2.18,8.08
		C1.84,8.34,1.43,8.48,1,8.48c-0.09,0-0.18-0.01-0.27-0.02L3.06,16h9.88L16,6.11L8,0z"
        />
      </g>
    </svg>
  </SvgIcon>
);
