import { Layer } from "mapbox-gl";
import { MutableRefObject } from "react";

import { CorridorHeatmapConfiguration } from "types";

import { CORRIDOR_EDGE_SOURCE_ID, CORRIDOR_NODE_SOURCE_ID } from "./sources";

export const CORRIDOR_EDGE_LAYER_ID = "CORRIDOR_EDGE_LAYER_ID";
export const CORRIDOR_NODE_LAYER_ID = "CORRIDOR_NODE_LAYER_ID";

export const layerIdsEndings = ["", "_hairline", "_hightlighted_volume", "_hightlighted_hairline"];

export function calculateHeatmapIntensityFactor(value: number) {
  return value ** 2;
}

export function calculateHeatmapRadiusFactor(value: number) {
  return value ** 2;
}

export function calculateEdgeWidthFactor(value: number) {
  return value ** 3;
}

export const getHeatmapIntensityExpression = (intensityFactor: number, config: CorridorHeatmapConfiguration) => [
  "interpolate",
  ["exponential", config.intensityExponentialBase],
  ["zoom"],
  config.intensityStartZoomScale,
  config.intensityMin * calculateHeatmapIntensityFactor(intensityFactor),
  config.intensityEndZoomScale,
  config.intensityMax * calculateHeatmapIntensityFactor(intensityFactor),
];

export const getHeatmapRadiusExpression = (radiusFactor: number, config: CorridorHeatmapConfiguration) => [
  "interpolate",
  ["exponential", config.radiusExponentialBase],
  ["zoom"],
  config.radiusStartZoomScale,
  config.radiusMin * calculateHeatmapRadiusFactor(radiusFactor),
  config.radiusEndZoomScale,
  config.radiusMax * calculateHeatmapRadiusFactor(radiusFactor),
];

export const getHeatmapOpacityExpression = (opacityFactor: number) => [
  "interpolate",
  ["linear"],
  ["zoom"],
  0,
  ["*", 0.4, opacityFactor],
  22,
  ["*", 1, opacityFactor],
];

export const getEdgesWidthExpression = (widthFactor: number) => [
  "*",
  ["number", ["feature-state", "volumeWeight"], 0],
  calculateEdgeWidthFactor(widthFactor),
];

export const getEdgesOffsetExpression = (offsetFactor: number) => [
  "*",
  ["number", ["feature-state", "volumeOffset"], 0],
  calculateEdgeWidthFactor(offsetFactor),
];

export const getEdgesOpacityExpression = (opacityFactor: number) => ["*", 0.7, opacityFactor];

export const getCorridorLayers = (
  corridorLevels: any,
  heatmapConfiguration: CorridorHeatmapConfiguration,
  edgesWidthFactor: MutableRefObject<number>,
  edgesOpacityFactor: MutableRefObject<number>,
  heatmapIntensityFactor: MutableRefObject<number>,
  heatmapRadiusFactor: MutableRefObject<number>,
  heatmapOpacityFactor: MutableRefObject<number>,
  heatmapVisibility: MutableRefObject<boolean>,
  edgesVisibility: MutableRefObject<boolean>,
): Layer[] => {
  const corridorLayers: Layer[] = [];

  Object.keys(corridorLevels).forEach((zoningLevelId) => {
    const zoningLevel = corridorLevels[zoningLevelId];

    corridorLayers.push({
      id: `${CORRIDOR_NODE_LAYER_ID}_${zoningLevelId}`,
      type: "heatmap",
      source: `${CORRIDOR_NODE_SOURCE_ID}_${zoningLevelId}`,
      "source-layer": zoningLevel.nodeTileServiceLayer.name,
      layout: {
        visibility: heatmapVisibility.current ? "visible" : "none",
      },
      paint: {
        "heatmap-weight": 0,
        "heatmap-intensity": getHeatmapIntensityExpression(heatmapIntensityFactor.current, heatmapConfiguration),
        "heatmap-radius": getHeatmapRadiusExpression(heatmapRadiusFactor.current, heatmapConfiguration),
        "heatmap-color": [
          "interpolate",
          ["linear"],
          ["heatmap-density"],
          0,
          "rgba(0, 0, 255, 0)",
          0.1,
          "rgba(65, 105, 225, 0.5)",
          0.3,
          "rgba(0, 255, 255, 0.93)",
          0.5,
          "lime",
          0.7,
          "yellow",
          1,
          "red",
        ],
        "heatmap-opacity": getHeatmapOpacityExpression(heatmapOpacityFactor.current),
      },
      level: zoningLevelId,
    } as Layer);

    corridorLayers.push({
      id: `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}`,
      type: "line",
      source: `${CORRIDOR_EDGE_SOURCE_ID}_${zoningLevelId}`,
      "source-layer": zoningLevel.edgeTileServiceLayer.name,
      layout: {
        "line-cap": "round",
        visibility: edgesVisibility.current ? "visible" : "none",
      },
      paint: {
        "line-color": "#0799ec", // logo color with slightly increased saturation and reduced value, to counteract transparency
        "line-width": getEdgesWidthExpression(edgesWidthFactor.current),
        "line-offset": getEdgesOffsetExpression(edgesWidthFactor.current),
        "line-opacity": getEdgesOpacityExpression(edgesOpacityFactor.current),
      },
      level: zoningLevelId,
    } as Layer);

    corridorLayers.push({
      id: `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}_hairline`,
      type: "line",
      source: `${CORRIDOR_EDGE_SOURCE_ID}_${zoningLevelId}`,
      "source-layer": zoningLevel.edgeTileServiceLayer.name,
      layout: {
        visibility: edgesVisibility.current ? "visible" : "none",
      },
      paint: {
        "line-color": "#ffffff",
        "line-width": 1,
        "line-opacity": 0.2,
      },
      level: zoningLevelId,
    } as Layer);

    corridorLayers.push({
      id: `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}_hightlighted_volume`,
      type: "line",
      source: `${CORRIDOR_EDGE_SOURCE_ID}_${zoningLevelId}`,
      "source-layer": zoningLevel.edgeTileServiceLayer.name,
      paint: {
        "line-color": "#3c6699",
        "line-width": getEdgesWidthExpression(edgesWidthFactor.current),
        "line-offset": getEdgesOffsetExpression(edgesWidthFactor.current),
        "line-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 1, 0],
      },
      layout: {
        visibility: edgesVisibility.current ? "visible" : "none",
      },
      level: zoningLevelId,
    } as Layer);

    corridorLayers.push({
      id: `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}_hightlighted_hairline`,
      type: "line",
      source: `${CORRIDOR_EDGE_SOURCE_ID}_${zoningLevelId}`,
      "source-layer": zoningLevel.edgeTileServiceLayer.name,
      layout: {
        "line-cap": "round",
        visibility: edgesVisibility.current ? "visible" : "none",
      },
      paint: {
        "line-color": ["case", ["boolean", ["feature-state", "selected"], false], "purple", "#3c5599"],
        "line-width": [
          "interpolate",
          ["exponential", 1.6],
          ["zoom"],
          6,
          ["case", ["boolean", ["feature-state", "hover"], false], 2.5, 2],
          20,
          ["case", ["boolean", ["feature-state", "hover"], false], 35, 30],
        ],
        "line-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          1,
          ["boolean", ["feature-state", "selected"], false],
          1,
          0,
        ],
      },
      level: zoningLevelId,
    } as Layer);
  });

  return corridorLayers;
};
