import { GeoJsonProperties } from "geojson";

import { ExtendedDirectionalRoadsTileService, Gate, GateSegment, ParsedGateSegment, RoadClass } from "types";

export const findGate = (id: string | number, gates: Gate[]) => gates.find((gate) => gate.identifier === id) || null;

export const findGateFromSegmentId = (segmentId: string, gates: Gate[]) =>
  gates.find((gate) => gate.segments.find((s) => s.id === segmentId));

export const getRoadClassLabel = (roadClasses: RoadClass[] | undefined, id: number) =>
  roadClasses?.find((roadClass) => roadClass.id === id)?.label || "";

export const parseGateSegments = (segments: GateSegment[] | undefined) => {
  const toFromSegments =
    segments?.filter((seg) => seg.from > seg.to && segments.find((s) => seg.from === s.to && seg.to === s.from)) || [];
  const parsedSegments: ParsedGateSegment[] = [];
  segments?.forEach((segment) => {
    const toFromSeg = toFromSegments?.find((s) => segment.from === s.to && segment.to === s.from);

    if (!toFromSegments.find((s) => s.id === segment.id)) {
      parsedSegments.push({
        ...segment,
        toFromSeg,
      });
    }
  });
  return parsedSegments;
};

export const unParseSegment = (segment: ParsedGateSegment) => {
  return [segment, ...(segment.toFromSeg ? [segment.toFromSeg] : [])];
};

export const normalizeFromToSegment = (
  props: GeoJsonProperties | undefined,
  roadsTileService: ExtendedDirectionalRoadsTileService,
) => ({
  from: undefined,
  to: undefined,
  id: props?.[roadsTileService.fromToSegmentIdField],
  roadClass: props?.[roadsTileService.facilityTypeField],
  direction: props?.[roadsTileService.fromToDirectionField],
  name: props?.[roadsTileService.nameField],
});

export const normalizeToFromSegment = (
  props: GeoJsonProperties | undefined,
  roadsTileService: ExtendedDirectionalRoadsTileService,
) => ({
  from: undefined,
  to: undefined,
  id: props?.[roadsTileService.toFromSegmentIdField],
  roadClass: props?.[roadsTileService.facilityTypeField],
  direction: props?.[roadsTileService.toFromDirectionField],
  name: props?.[roadsTileService.nameField],
});
