import RestartAlt from "@mui/icons-material/RestartAlt";
import { Box, Grid, Skeleton, Slider, SxProps, Typography, styled } from "@mui/material";
import { IconButton, TextField } from "components_new";
import { ChangeEvent, FC } from "react";

import { MeasureRange } from "types";

export interface RangeSliderProps {
  label?: string;
  loading: boolean;
  range: [number, number];
  availableRange: MeasureRange;
  errors: { min: string; max: string };
  disabled?: boolean;
  resetMin?: number;
  resetMax?: number;
  sx?: SxProps;
  handleSetRange: (newRange: [number, number]) => void;
}

const RangeTextField = styled(TextField)(({ theme }) => ({
  "& .MuiFormHelperText-root": {
    fontSize: "10px",
    margin: "0px",
    marginLeft: "2px",
    color: theme.palette.warning.main,
  },
}));

export const InlineGridItem = styled(Grid)(({ theme }) => ({
  "&.MuiGrid-item": {
    paddingTop: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

export const RangeSlider: FC<RangeSliderProps> = ({
  label,
  loading,
  range,
  availableRange,
  errors,
  disabled,
  resetMin = availableRange?.min,
  resetMax = availableRange?.max,
  sx,
  handleSetRange,
}) => {
  const handleChangeSlider = (event: Event, newValue: number | number[]) => {
    handleSetRange(newValue as [number, number]);
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (range) {
      const { name, value } = event.target;
      const parsedValue = value.replaceAll(",", "");
      if (/^[0-9]*$/.test(parsedValue) === false) return;

      if (name === "min") handleSetRange([+parsedValue, range[1]]);
      if (name === "max") handleSetRange([range[0], +parsedValue]);
    }
  };

  const handleResetRange = () => {
    handleSetRange([resetMin, resetMax]);
  };

  return (
    <Box sx={sx}>
      <Grid container columnGap={1} alignItems="center">
        {label ? (
          <InlineGridItem minWidth={55} item>
            <Typography fontSize={12} fontWeight={500} color="text.secondary">
              {label}
            </Typography>
          </InlineGridItem>
        ) : null}
        <InlineGridItem item xs>
          <Slider
            color="secondary"
            size="small"
            value={range}
            disabled={loading || disabled}
            onChange={handleChangeSlider}
            valueLabelDisplay="off"
            min={availableRange?.min}
            max={availableRange?.max}
          />
        </InlineGridItem>
        <InlineGridItem item>
          <IconButton disabled={loading} size="small" onClick={handleResetRange}>
            <RestartAlt fontSize={"small"} />
          </IconButton>
        </InlineGridItem>
      </Grid>
      <Grid container columnGap={1} marginTop={0.5}>
        <Grid item xs>
          {loading ? (
            <Skeleton variant="rounded" height={37} sx={{ marginTop: "7px", marginBottom: "5px" }} />
          ) : (
            <RangeTextField
              name="min"
              size="small"
              label="Min"
              value={range[0].toLocaleString()}
              disabled={loading || disabled}
              onChange={handleChangeInput}
              helperText={errors.min}
              color={errors.min !== "" ? "warning" : "secondary"}
            />
          )}
        </Grid>
        <Grid item xs>
          {loading ? (
            <Skeleton variant="rounded" height={37} sx={{ marginTop: "7px", marginBottom: "5px" }} />
          ) : (
            <RangeTextField
              name="max"
              size="small"
              label="Max"
              value={range[1].toLocaleString()}
              disabled={loading || disabled}
              onChange={handleChangeInput}
              helperText={errors.max}
              color={errors.max !== "" ? "warning" : "secondary"}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
