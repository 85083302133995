import styled from "@emotion/styled";
import { CircularProgress } from "components_new";
import React, { FC, memo, useMemo, useState } from "react";

import { ODAnalytics } from "features/analytics/ODAnalytics";
import { RoadAttributeProps } from "features/analytics/RoadAnalytics";

import { RightSidebarPanel } from "components";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";

import { FlowsSettings, HoveredFlow, SelectedArea } from "types";

import { createSegmentAttributeMap } from "../roads/RoadsAnalyticsPanel";

interface ODAnalyticsPanelProps {
  flowsSettings: FlowsSettings;
  closeODAnalyticsPanelRef: React.RefObject<(selectedZone: SelectedArea | null) => void>;
  hoveredTopFlow: HoveredFlow | null;
  setHoveredTopFlow: (hoveredTopFlow: HoveredFlow | null) => void;
  setFlowsSettings: (settings: FlowsSettings) => void;
  isDemandScenario?: boolean;
}

const RightSidebarLoaderWrapper = styled.div`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ODAnalyticsPanel: FC<ODAnalyticsPanelProps> = memo(
  ({
    flowsSettings,
    closeODAnalyticsPanelRef,
    hoveredTopFlow,
    isDemandScenario,
    setHoveredTopFlow,
    setFlowsSettings,
  }) => {
    const [zoneAttributeOpen, setZoneAttributeOpen] = useState(false);

    const zoneDetails = useAppSelector((state) => state.analytics.zoneDetails);
    const isCustomDataset = useAppSelector((state) => Boolean(state.global.selectedFocusArea?.datasetId));
    const selectedFocusAreaZoningLevel = useAppSelector((state) => state.global.selectedFocusArea?.zoningLevel);
    const selectedZone = useAppSelector((state) => state.analytics.selectedZone);
    const datasetMetadataZoningLevels = useAppSelector((state) => state.analytics.datasetMetadata.data?.zoningLevels);
    const datasetMetadataDimensions = useAppSelector(
      (state) => state.analytics.datasetMetadata.data?.measures[0].dimensions, // TODO: Use a selector for this
    );
    const odMetadataZoningLevels = useAppSelector((state) => state.analytics.ODMetadata.data?.zoningLevels);
    const odMetadataDimensions = useAppSelector((state) => state.analytics.ODMetadata.data?.measures[0].dimensions); // TODO: Use a selector for this
    const datasetCountsState = useAppSelector((state) => state.analytics.datasetCountsByZoneId.state);
    const odCountsByZoneIdState = useAppSelector((state) => state.analytics.ODCountsByZoneId.state);
    const demandOdCountsByZoneIdState = useAppSelector((state) => state.analytics.demandScenarioZoneCounts.state);
    const queryType = useAppSelector((state) => state.filters.queryType);

    const demandScenarioMetadataDimensions = useAppSelector(
      (state) => state.analytics.demandScenarioMetadata.data?.measures[0].dimensions,
    );
    const zoneAttributesData = useAppSelector((state) => state.analytics.demandScenarioZoneAttributes.data);
    const demandScenarioMetadataAttributes = useAppSelector(
      (state) => state.analytics.demandScenarioMetadata.data?.zoneAttributes,
    );

    const handleSetFlowsSettings = (newSettings: FlowsSettings) => {
      setFlowsSettings(newSettings);
    };

    const isODDetailsLoading = useMemo(() => zoneDetails.state === DataState.LOADING, [zoneDetails.state]);

    const datasetZoningLevels = useMemo(() => {
      if (datasetMetadataZoningLevels) {
        return selectedFocusAreaZoningLevel === "Custom" && odMetadataZoningLevels?.[0]
          ? [...datasetMetadataZoningLevels, odMetadataZoningLevels?.[0]]
          : datasetMetadataZoningLevels;
      }
    }, [odMetadataZoningLevels, datasetMetadataZoningLevels, selectedFocusAreaZoningLevel]);

    const zoningLevels = useMemo(
      () => (isCustomDataset ? datasetZoningLevels : odMetadataZoningLevels),
      [isCustomDataset, datasetZoningLevels, odMetadataZoningLevels],
    );

    const isShowRightSidebarForOD = useMemo(
      () =>
        selectedZone &&
        zoneDetails.state !== DataState.EMPTY &&
        (isCustomDataset
          ? datasetCountsState !== DataState.EMPTY
          : isDemandScenario
          ? demandOdCountsByZoneIdState !== DataState.EMPTY
          : odCountsByZoneIdState !== DataState.EMPTY) &&
        zoneDetails,
      [
        isDemandScenario,
        isCustomDataset,
        datasetCountsState,
        odCountsByZoneIdState,
        demandOdCountsByZoneIdState,
        selectedZone,
        zoneDetails,
      ],
    );

    const odMetadataDimensionsValues = useMemo(
      () => (isCustomDataset ? datasetMetadataDimensions : odMetadataDimensions),
      [isCustomDataset, datasetMetadataDimensions, odMetadataDimensions],
    );

    const isShowODAnalytics = useMemo(
      () => !isODDetailsLoading && selectedZone && zoneDetails.data && flowsSettings,
      [isODDetailsLoading, selectedZone, zoneDetails.data, flowsSettings],
    );

    const isOpen = Boolean(isShowRightSidebarForOD || isODDetailsLoading);

    const demandZoneDetailsMap = useMemo(() => {
      if (!isDemandScenario || !zoneAttributesData) {
        return new Map();
      }

      const labelMap = new Map(
        demandScenarioMetadataAttributes?.map((attribute) => [
          attribute.columnName,
          { label: attribute.label, type: attribute.attributeType },
        ]),
      );

      return new Map(
        zoneAttributesData.zones?.map((zoneDetail) => [
          zoneDetail.zoneId,
          createSegmentAttributeMap(zoneDetail.attributes, labelMap),
        ]),
      );
    }, [isDemandScenario, zoneAttributesData, demandScenarioMetadataAttributes]);

    const zoneAttributeProps: RoadAttributeProps | undefined = useMemo(
      () =>
        zoneAttributesData && selectedZone?.id
          ? {
              values: demandZoneDetailsMap.get(selectedZone.id),
              openState: zoneAttributeOpen,
              setOpenState: setZoneAttributeOpen,
            }
          : undefined,
      [demandZoneDetailsMap, zoneAttributesData, selectedZone?.id, zoneAttributeOpen, setZoneAttributeOpen],
    );

    const handleClosePanel = () => {
      if (typeof closeODAnalyticsPanelRef.current === "function") {
        closeODAnalyticsPanelRef.current(selectedZone);
      }
    };

    return (
      <RightSidebarPanel
        title={
          isODDetailsLoading
            ? "Loading..."
            : `Selected ${zoneDetails.data?.isGate ? "Gate" : "Zone"} (${zoneDetails.data?.zoneId})`
        }
        subtitle={`Annual average daily trips ${
          zoneDetails.data?.summaries?.[queryType]?.filteredTotal?.toLocaleString("en-US") || "..."
        }`}
        cardType="od"
        onClose={handleClosePanel}
        isOpen={isOpen}
      >
        {isShowODAnalytics ? (
          <ODAnalytics
            isCustomDataset={isCustomDataset}
            isDemandScenario={!!isDemandScenario}
            selectedZone={selectedZone!}
            zoneDetails={zoneDetails.data!}
            flowsDirection={queryType}
            flowsSettings={flowsSettings}
            zoningLevels={zoningLevels}
            hoveredFlow={hoveredTopFlow}
            odMetadataDimensions={isDemandScenario ? demandScenarioMetadataDimensions : odMetadataDimensionsValues}
            zoneAttributeProps={zoneAttributeProps}
            setFlowsSettings={handleSetFlowsSettings}
            setHoveredFlow={setHoveredTopFlow}
          />
        ) : null}
        {isODDetailsLoading && (
          <RightSidebarLoaderWrapper>
            <CircularProgress />
          </RightSidebarLoaderWrapper>
        )}
      </RightSidebarPanel>
    );
  },
);
