import { Dispatch, SetStateAction } from "react";

import { ModuleData } from "features/map/ModuleManager";

import { MapController } from "./MapController";

interface CommonModuleComponentProps {
  setCommonModuleData: Dispatch<SetStateAction<ModuleData | null>>;
}

const CommonModuleComponent = ({ setCommonModuleData }: CommonModuleComponentProps) => {
  return (
    <>
      <MapController setCommonModuleData={setCommonModuleData} />
    </>
  );
};

export default CommonModuleComponent;
