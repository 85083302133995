import { Expression, Layer } from "mapbox-gl";
import { MutableRefObject } from "react";

import {
  CHOROPLETH_FILL_COLOR,
  CHOROPLETH_FILL_OPACITY,
  CHOROPLETH_FILL_OPACITY_HOVER,
  CHOROPLETH_FILL_OPACITY_SELECTED,
  CHOROPLETH_LINE_COLOR,
  CHOROPLETH_LINE_OPACITY,
  ZONE_HOVER_HIGHLIGHT_COLOR,
  ZONE_PERMANENT_HIGHLIGHT_COLOR_DESTINATION,
  ZONE_PERMANENT_HIGHLIGHT_COLOR_ORIGIN,
  ZONE_PERMANENT_HIGHLIGHT_FILL_OPACITY,
} from "features/map/layerColors";

import { OUT_ZONES_SOURCE_ID } from "./sources";

export const ZONES_LAYER_FILL = "ZONES_LAYER_FILL";
export const ZONE_BORDERS_LAYER = "ZONE_BORDERS_LAYER";

export const CHOROPLETH_LINE_WIDTH = 0.6;

export const getODFillOpacityExpression = (
  range: [number, number] = [0, Infinity],
  opacityFactor: number,
): Expression => [
  "case",
  [
    "!",
    [
      "all",
      [">=", ["coalesce", ["feature-state", "count"], 0], range[0]],
      ["<=", ["coalesce", ["feature-state", "count"], 0], range[1]],
    ],
  ],
  ["*", 0.4, opacityFactor],
  ["boolean", ["feature-state", "permanentSelectHighlightOrigin"], false],
  ["*", ZONE_PERMANENT_HIGHLIGHT_FILL_OPACITY, opacityFactor],
  ["boolean", ["feature-state", "permanentSelectHighlightDestination"], false],
  ["*", ZONE_PERMANENT_HIGHLIGHT_FILL_OPACITY, opacityFactor],
  ["==", ["feature-state", "color"], null],
  0,
  ["boolean", ["feature-state", "hover"], false],
  ["*", CHOROPLETH_FILL_OPACITY_HOVER, opacityFactor],
  ["boolean", ["feature-state", "click"], false],
  ["*", CHOROPLETH_FILL_OPACITY_SELECTED, opacityFactor],
  ["*", CHOROPLETH_FILL_OPACITY, opacityFactor],
];

export const getODFillColorExpression = (range: [number, number] = [0, Infinity]): Expression => [
  "case",
  [
    "!",
    [
      "all",
      [">=", ["coalesce", ["feature-state", "count"], 0], range[0]],
      ["<=", ["coalesce", ["feature-state", "count"], 0], range[1]],
    ],
  ],
  "#bdbdbd",
  ["boolean", ["feature-state", "permanentSelectHighlightOrigin"], false],
  ZONE_PERMANENT_HIGHLIGHT_COLOR_ORIGIN,
  ["boolean", ["feature-state", "permanentSelectHighlightDestination"], false],
  ZONE_PERMANENT_HIGHLIGHT_COLOR_DESTINATION,
  ["==", ["feature-state", "color"], null],
  CHOROPLETH_FILL_COLOR,
  ["feature-state", "color"],
];

export const getODLayers = (
  opacityFactorRef: MutableRefObject<number>,
  idField: string,
): {
  zoningLayers: Layer[];
} => {
  const zoningLayers: Layer[] = [
    // Add choropleth fill layer
    {
      id: ZONES_LAYER_FILL,
      type: "fill",
      source: OUT_ZONES_SOURCE_ID,
      "source-layer": "default",
      layout: {
        visibility: "visible",
      },
      paint: {
        "fill-color": getODFillColorExpression([0, Infinity]),
        "fill-opacity": getODFillOpacityExpression([0, Infinity], opacityFactorRef.current),
      },
    },
    // Add choropleth line layer
    {
      id: ZONE_BORDERS_LAYER,
      type: "line",
      source: OUT_ZONES_SOURCE_ID,
      "source-layer": "default",
      layout: {
        visibility: "visible",
      },
      paint: {
        "line-color": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          ZONE_HOVER_HIGHLIGHT_COLOR,
          CHOROPLETH_LINE_COLOR,
        ],
        "line-opacity": CHOROPLETH_LINE_OPACITY,
        "line-width": ["case", ["boolean", ["feature-state", "hover"], false], 2, CHOROPLETH_LINE_WIDTH],
      },
    },
  ];

  return { zoningLayers };
};
