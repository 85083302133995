import AutoAwesomeOutlined from "@mui/icons-material/AutoAwesomeOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import { SxProps, styled } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { Divider, TextField } from "components_new";
import React, { useMemo } from "react";

import { AoiSelectorOption } from "types";

const REGION_FLAG = "REGION_FLAG";

interface Props {
  selectedOptionId: string | undefined;
  options: AoiSelectorOption[];
  loading: boolean;
  disabled: boolean;
  onChange: (focusAreaId?: string, isStudyArea?: boolean) => void;
  noLabel?: boolean;
  showStudyAreas?: boolean;
  sx?: SxProps;
}

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  display: "flex",
  padding: "5px 10px 0px 27px",
  color: "var(--color-textSecondary)",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  fontSize: "var(--font-size-sm)",
  fontWeight: "bold",
}));

const RegionGroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  padding: "0 10px",
  backgroundColor: "white",
  fontSize: "var(--font-size-sm)",
  fontWeight: "bold",
}));

const GroupItems = styled("ul")({
  padding: 0,
  margin: "0 10px",
  fontSize: "var(--font-size-sm)",
  fontWeight: "bold",
});

export const FocusAreaDropdown = ({
  selectedOptionId,
  options,
  loading,
  disabled,
  noLabel,
  showStudyAreas,
  onChange,
  ...props
}: Props) => {
  const selectedOption = useMemo(
    () => options.find((option) => option.id === selectedOptionId) ?? null,
    [options, selectedOptionId],
  );

  return (
    <Autocomplete
      loading={loading}
      disabled={disabled}
      options={options}
      componentsProps={{
        popper: {
          placement: "bottom-start",
          style: {
            width: "412px", // 412px so that is aligned with "open button in the dashboard"
          },
        },
      }}
      groupBy={(option) => (option.folderName ? option.folderName : `${REGION_FLAG}${option.region}`)}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderOption={(props, option) => (
        <Box {...props} key={props.key} component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
          {option.datasetId ? (
            <InsertDriveFileOutlinedIcon
              fontSize="small"
              style={{ marginLeft: "22px", marginRight: "5px", color: "#374151" }}
            />
          ) : option.isStudyArea ? (
            <SchoolOutlinedIcon fontSize="small" style={{ marginLeft: "22px", marginRight: "5px", color: "#374151" }} />
          ) : option.isDemo ? (
            <AutoAwesomeOutlined fontSize="small" style={{ marginRight: "5px", color: "#0067b0" }} />
          ) : (
            <PublicOutlinedIcon fontSize="small" style={{ marginRight: "5px", color: "#0067b0" }} />
          )}
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField {...params} size="small" label={noLabel === true ? undefined : "Area of interest"} />
      )}
      renderGroup={(params) => {
        const isRegion = params.group.startsWith(REGION_FLAG);

        return (
          <ul key={params.key}>
            {isRegion ? (
              <>
                {Boolean(params.key) && (
                  <Divider variant="middle" style={{ marginTop: "5px", marginBottom: "5px" }} light />
                )}
                <RegionGroupHeader>{params.children}</RegionGroupHeader>
              </>
            ) : (
              <GroupHeader>
                <FolderOpenOutlinedIcon fontSize="small" style={{ marginRight: "4px" }} />
                {params.group}
              </GroupHeader>
            )}

            {!isRegion && <GroupItems>{params.children}</GroupItems>}
          </ul>
        );
      }}
      value={selectedOption}
      onChange={(event, value) => onChange(value?.id, !!value?.isStudyArea)}
      {...props}
    />
  );
};
