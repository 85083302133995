import { Stack, styled } from "@mui/material";
import { Tab, TabPanel, Tabs } from "components_new";
import { ChangeEvent, FC, useMemo } from "react";

import { CheckboxDropdown, InfoCard, MeasureIcon } from "components";

import { FiltersType, Measure, MeasureType } from "types";

import { areAllItemsUnChecked, getIconByDimension } from "./Filters";

export interface RoadsFiltersPanelProps {
  filters: FiltersType | null;
  roadMeasures: Measure[] | undefined;
  currentRoadMeasure: Measure | null;
  measure: string | null;
  loading: boolean;
  disabled?: boolean;
  setMeasure: (measure: MeasureType) => void;
  handleChangeFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeAllFilters: (isChecked: boolean) => (groupName: string) => void;
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  maxWidth: "100%",
  marginRight: "-16px",
  minHeight: 24,
  borderBottom: `1px solid ${theme.palette.divider}`,

  "& .MuiTabs-indicator": {
    bottom: "0px",
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  padding: 0,

  "& .MuiTabs-indicator": {
    bottom: "2px",
    color: theme.palette.secondary.main,
  },
}));

export const RoadsFiltersPanel: FC<RoadsFiltersPanelProps> = ({
  filters,
  roadMeasures,
  currentRoadMeasure,
  measure,
  loading,
  disabled,
  setMeasure,
  handleChangeFilter,
  handleChangeAllFilters,
}) => {
  const selectedMeasureIndex = useMemo(() => {
    const index = roadMeasures?.findIndex((roadMeasure) => roadMeasure.columnName === measure) || 0;
    return index !== -1 ? index : 0;
  }, [measure, roadMeasures]);

  const handleTabChange = (newMeasure: MeasureType) => {
    setMeasure(newMeasure);
  };

  return (
    <>
      <StyledTabs
        centered
        indicatorColor="secondary"
        value={selectedMeasureIndex}
        onChange={(e, measureIndex) => {
          const newMeasure = roadMeasures?.[measureIndex].columnName;
          if (newMeasure) handleTabChange(newMeasure as MeasureType);
        }}
      >
        {Object.values(roadMeasures || {}).map((roadMeasure) => (
          <StyledTab
            key={roadMeasure.columnName}
            id={roadMeasure.columnName}
            disabled={disabled || loading}
            icon={<MeasureIcon measure={roadMeasure.columnName} fontSize="small" color="secondary" />}
            label={roadMeasure.label}
          />
        ))}
      </StyledTabs>
      {roadMeasures?.map((roadMeasure, index) => (
        <TabPanel key={roadMeasure.columnName} value={selectedMeasureIndex} index={index}>
          {filters && Object.keys(filters).length > 0 ? (
            <Stack spacing={2} maxWidth={248}>
              {Object.entries(filters).map(([filterKey, filterValues]) => (
                <CheckboxDropdown
                  key={filterKey}
                  isGroupChecked={filterValues.isChecked}
                  groupName={filterKey}
                  groupLabel={filterValues.label}
                  groupIcon={filterValues.icon}
                  items={filterValues.items}
                  placement="top"
                  disabled={loading || disabled}
                  error={areAllItemsUnChecked(filterValues.items)}
                  onChange={handleChangeFilter}
                  selectAll={handleChangeAllFilters(true)}
                  clearAll={handleChangeAllFilters(false)}
                />
              ))}
            </Stack>
          ) : currentRoadMeasure ? (
            <InfoCard icon={getIconByDimension(currentRoadMeasure.columnName)} text={currentRoadMeasure.help} />
          ) : null}
        </TabPanel>
      ))}
    </>
  );
};
