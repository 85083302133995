import styled from "@emotion/styled";
// import { Typography } from "@mui/material";
import { FC, Fragment, MutableRefObject, ReactNode, RefObject, createRef, useEffect, useRef, useState } from "react";

import { AoiTripsTable } from "./AoiTripsTable";
import { DatasetTripsTable } from "./DatasetTripsTable";
import { FileTable } from "./FileTable";
import {
  Description,
  ElevatedTable,
  FooterTableCell,
  Footnote,
  HeadTableCell,
  SubTitle,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "./Table";
import {
  ODShapeFileColumns,
  ODVolumesFileColumns,
  gatesFileColums,
  gatesShapeFileColumns,
  roadShapeFileColumns,
  roadVolumesFileColumns,
  subareaShapeFileColumns,
} from "./data";
import { commonODShapeFileColumns } from "./data/CommonODShapeFileColumns";
import { screenlineCsvFileColumns } from "./data/ScreenlineCsvFileColumns";
import { intersectionShapefileColumns } from "./data/intersectionShapefileColumns";
import { roadAdtVmtFileColumns } from "./data/roadAdtVmtFileColumns";
import { roadMileageFileColumns } from "./data/roadMileageFileColumns";
import { screenlineShapeFileInfo, screenlineShapefileColumns } from "./data/screenlineShapefileColumns";
import { selectLinkODVolumesFileColumns } from "./data/selectLinkODVolumesFileColumns";

type ContentRefs = Map<string, RefObject<HTMLElement>>;

interface ContentData {
  id: string;
  label: string;
  title: string;
  data?: any[];
  description: string;
  fileIntroduction?: ReactNode;
}

const TableLink = styled.p`
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  margin: 0;
`;

const exportContentData: ContentData[] = [
  {
    id: "gates-csv",
    label: "gates.csv",
    title: "Gates (only present in dataset export, “gates.csv”)",
    data: gatesFileColums,
    description: "List of gate segments.",
  },
  {
    id: "od-data-csv",
    label: "od_data.csv",
    title: "OD volumes (“od_data.csv”)",
    data: ODVolumesFileColumns,
    description: "OD flows within the subarea.",
  },
  {
    id: "zones-shp",
    label: "zones.shp (+.dbf/.prj/.shx)",
    title: "OD zone shape files (“zones.shp”)",
    data: ODShapeFileColumns,
    description: "Zones inside the dataset sub-area, with incoming/outgoing counts and breakdowns.",
  },
  {
    id: "road-volumes-csv",
    label: "volumes.csv",
    title: "Road volumes (“volumes.csv” or “select_link_volumes.csv”)",
    data: roadVolumesFileColumns,
    description:
      "Volumes for selected road classes within the dataset sub area (does not include gate segments volumes).",
  },
  {
    id: "road-segments-shp",
    label: "road_segments.shp (+.dbf/.prj/.shx)",
    title: "Road network shape file (“road_segments.shp”)",
    data: roadShapeFileColumns,
    description:
      "Segment features (linestrings with attributes) intersecting the subarea, including all gate segments (even if they are outside the subarea). Also including generated gate connectors from gate segments to the gate location.",
  },
  {
    id: "subarea-shp",
    label: "subarea.shp (+.dbf/.prj/.shx)",
    title: "Subarea shape file (“subarea.shp”)",
    data: subareaShapeFileColumns,
    description: "Subarea polygon with attributes describing the dataset.",
  },
  {
    id: "gates-shp",
    label: "gates.shp (+.dbf/.prj/.shx)",
    title: "Gates shape files (“gates.shp”)",
    data: gatesShapeFileColumns,
    description:
      "Contains gates, their volume and breakdowns in the same structure as zones shapefile. Only provided for datasets with gates.",
  },
  {
    id: "screenlines-shp",
    label: "screenlines.shp (+.dbf/.prj/.shx)",
    title: "Screenlines shape file (“screenlines.shp”)",
    data: screenlineShapefileColumns,
    description: "Screenlines with aggregated volumes for the selected volume metric (vehicles or pedestrians)",
    fileIntroduction: screenlineShapeFileInfo,
  },
  {
    id: "screenlines-csv",
    label: "screenlines.csv",
    title: "Screenlines csv file (“screenline_segments.csv”)",
    data: screenlineCsvFileColumns,
    description: "Screenlines with intersecting segments and their attributes",
  },
  {
    id: "road-intersections-shp",
    label: "road_intersections.shp (+.dbf/.prj/.shx)",
    title: "Road intersections shapefile (“road_intersections.shp”)",
    data: intersectionShapefileColumns,
    description: "Intersection nodes with volume and breakdowns",
  },
];

const selectLinkExportContentData: ContentData[] = [
  {
    id: "road-segments-shp", // re-use the same table as in the regular export
    label: "road_segments.shp (+.dbf/.prj/.shx)",
    title: "Select link analysis road network shape file",
    description:
      "Segment features (linestrings with attributes) of the results of the select link analysis being exported. Attributes are the same as those of the map road export segment features.",
  },
  {
    id: "road-volumes-csv", // re-use the same table as in the regular export
    label: "select_link_volumes.csv",
    title: "Select link analysis road volumes",
    description:
      "Volumes for the resulting road segments of the select link analysis being exported. This file has the same structure as the road volumes CSV file for the regular export.",
  },
  {
    id: "select-link-od-data-csv",
    label: "select_link_od.csv",
    title: "Select link analysis OD volumes",
    data: selectLinkODVolumesFileColumns,
    description: "OD flows of the results of the select link analysis being exported.",
  },
  {
    id: "select-link-zones-shp",
    label: "zones.shp (+.dbf/.prj/.shx)",
    title: "OD zone shape files for select link export",
    data: commonODShapeFileColumns,
    description: "Zones inside the dataset sub-area.",
  },
];

const vmtExportContentData: ContentData[] = [
  {
    id: "road-aadt-vmt-zone-counts-csv",
    label: "road_adt_vmt_zone_counts.csv",
    title: "ADT VMT zone counts (road_adt_vmt_zone_counts.csv)",
    data: roadAdtVmtFileColumns,
    description: "Average daily vehicle miles travelled per zone id",
  },
  {
    id: "road-mileage-zone-counts-csv",
    label: "road_mileage_zone_counts.csv",
    title: "VMT mileage (“road_mileage_zone_counts.csv”)",
    data: roadMileageFileColumns,
    description: "VMT mileage",
  },
];

function listFileColumns(contentData: ContentData[], refs: MutableRefObject<ContentRefs>) {
  return contentData.map((file, i) =>
    file.data ? (
      <Fragment key={`${file.id}-${i}`}>
        <SubTitle ref={refs.current.get(file.id) as RefObject<HTMLHeadingElement>}>{file.title}</SubTitle>
        <Description style={{ marginBottom: "2rem" }}>{file.fileIntroduction}</Description>
        <FileTable fileColumns={file.data} />
      </Fragment>
    ) : null,
  );
}

function listFileNames(
  contentData: ContentData[],
  scrollToRef: (ref: RefObject<HTMLElement>) => void,
  refs: MutableRefObject<ContentRefs>,
) {
  return contentData.map((file, i) => (
    <TableRow key={i}>
      <TableCell width={"280px"}>
        <TableLink onClick={() => scrollToRef(refs.current.get(file.id)!)}>{file.label}</TableLink>
      </TableCell>
      <TableCell>{file.description}</TableCell>
    </TableRow>
  ));
}

export const ExportContent: FC = () => {
  const [areRefsSet, setAreRefsSet] = useState(false);
  const refs = useRef<ContentRefs>(new Map());

  const scrollToRef = (ref: RefObject<HTMLElement>) => {
    if (!ref.current) return;

    ref.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (!refs.current.size) {
      for (const { id } of [...exportContentData, ...selectLinkExportContentData, ...vmtExportContentData]) {
        refs.current.set(id, createRef());
      }
      setAreRefsSet(true);
    }
  }, []);

  return areRefsSet ? (
    <>
      {/* Page header used for pdf generation */}
      {/* <Typography fontSize={24} fontWeight={700} gutterBottom>
        OpenPaths Patterns Export Content
      </Typography> */}
      <Description>
        A core capability of OpenPaths Patterns is the export function, allowing users to work with OD and road datasets
        in other GIS and transport modeling tools. The following data sets and fields are being provided as part of an
        export.
      </Description>

      <SubTitle style={{ marginTop: "2rem" }}>
        Correspondence between data shown in dashboard and data exported
      </SubTitle>
      <Description style={{ marginBottom: "2rem" }}>
        The driving design principle behind our map display is to show all mobility in the entire area of interest or
        dataset. This includes trips that start or end outside of the area of interest or dataset. In contrast to that,
        the export content is restricted to trips that start and end inside of the area of interest and external gates
        (in the case of datasets).
      </Description>

      <AoiTripsTable />

      <DatasetTripsTable />

      <SubTitle>Files contained in the export </SubTitle>
      <ElevatedTable>
        <TableHead>
          <TableRow>
            <HeadTableCell>File name</HeadTableCell>
            <HeadTableCell>Content</HeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listFileNames(exportContentData, scrollToRef, refs)}

          <TableRow>
            <FooterTableCell colSpan={7}>&nbsp;</FooterTableCell>
          </TableRow>
        </TableBody>
      </ElevatedTable>
      {listFileColumns(exportContentData, refs)}

      <SubTitle>Files contained in the select link analysis export</SubTitle>
      <ElevatedTable>
        <TableHead>
          <TableRow>
            <HeadTableCell>File name</HeadTableCell>
            <HeadTableCell>Content</HeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listFileNames(selectLinkExportContentData, scrollToRef, refs)}
          <TableRow>
            <FooterTableCell colSpan={7}>&nbsp;</FooterTableCell>
          </TableRow>
        </TableBody>
      </ElevatedTable>
      {listFileColumns(selectLinkExportContentData, refs)}

      <SubTitle>Files contained in the road VMT export</SubTitle>
      <ElevatedTable>
        <TableHead>
          <TableRow>
            <HeadTableCell>File name</HeadTableCell>
            <HeadTableCell>Content</HeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listFileNames(vmtExportContentData, scrollToRef, refs)}
          <TableRow>
            <FooterTableCell colSpan={7}>&nbsp;</FooterTableCell>
          </TableRow>
        </TableBody>
      </ElevatedTable>
      {listFileColumns(vmtExportContentData, refs)}

      <Footnote> 1. Based on US Census 2020 Urban Areas</Footnote>
      <Footnote>
        2. Source: https://geo.dot.gov/server/rest/services/Hosted. OpenPaths Patterns has tried to accurately transfer
        Highway Performance Monitoring System (HPMS) data to the OSM network. Although our data will not exactly match
        State and Federal reports, we have validated the reasonableness of the transfer process. Some differences are
        attributable to variations in geometric representation of highway infrastructure and estimated traffic volumes.
      </Footnote>
      <Footnote>
        3. State DOT road mileage reports provide centerline miles, while OpenPaths Patterns provides road miles.
        Centerline miles summarizes the one direction length of a highway, regardless of how simple or complex the
        cross-section is represented in mapping geometry. Road miles summarizes the length of road segments as
        represented in the mapping geometry including directionality.
      </Footnote>
      <Footnote>
        4. The counts for segments which are positioned in two zones are included proportional to the length that is in
        each zone.
      </Footnote>
    </>
  ) : null;
};
