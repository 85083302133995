import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const MergeUnion: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor">
      <path d="m10 6v-4h-8v8h4v4h8v-8h-4" />
    </svg>
  </SvgIcon>
);
