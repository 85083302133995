import { BarChart } from "@mui/icons-material";
import { Box, Grid, Tooltip, styled } from "@mui/material";
import { centroid } from "@turf/turf";
import { Button, CircularProgress, IconButton } from "components_new";
import { Geometry } from "geojson";
import mapboxgl, { Map } from "mapbox-gl";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { MapBoxStyles } from "features/map/baseMapStyles";
import { CHOROPLETH_LINE_WIDTH } from "features/map/modules/od/map-data/od/layers";
import { getBounds, zoomOnArea } from "features/map/utils";

import { FlexContainer, FocusAreaDropdown, MapErrorPage } from "components";

import { useAppDispatch, useAppSelector, usePrevious } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions, selectActiveStudyArea, selectAoiSelectorOptions } from "store/sections/analytics";
import { globalActions } from "store/sections/global";

import { DOCS_ROUTES, ROUTES } from "types";

import { convertSquareKmToSquareMiles } from "utils/format";
import { reportAboutErrorState } from "utils/reports";

export interface FocusAreaPreviewProps {
  triggerGAEvent: (event: string) => void;
}

const MapLoadingContainer = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  margin-left: -25px;
  margin-top: -25px;
`;

const MapErrorContainer = styled("div")`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
`;

const Mapbox = styled("div")`
  width: 100%;
  height: 100%;
`;

const MapPreviewPanel = styled("div")`
  position: relative;
  height: 100%;
  border-radius: 8px;
  display: grid;
  grid-template-rows: auto min-content;
`;

const MapContainer = styled("div")`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const DiagonalLabel = styled("div")`
  position: absolute;
  padding: 5px 100px;
  transform: rotate(-45deg) translate(-37%, -50%);
  background: var(--color-green-400);
  z-index: 2;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  top: -20px;
`;

const InfoLabel = styled("div")`
  display: flex;
  background: white;
  gap: 1rem;
  position: absolute;
  z-index: 2;
  font-size: 12px;
  top: 1rem;
  right: 1rem;
  padding: 0.3rem;
  border-radius: 4px;
`;

const ModeControlsContainer = styled(FlexContainer)`
  border-radius: 0 0 8px 8px;
  padding: 1.5rem 1.5rem;
  background-color: var(--color-text);
`;

const addAreaSource = (map: Map, id: string, geometry: Geometry, properties: {}) => {
  map.addSource(`area-${id}`, {
    type: "geojson",
    promoteId: "id",
    data: {
      type: "Feature",
      properties,
      geometry,
    },
  });
};

const addAreaLayers = (map: Map, id: string) => {
  map.addLayer({
    id: `area-fill-${id}`,
    type: "fill",
    source: `area-${id}`,
    paint: {
      "fill-color": "#3b82f6",
      "fill-opacity": ["case", ["boolean", ["feature-state", "selected"], false], 0.2, 0],
    },
  });

  map.addLayer({
    id: `area-boundaries-${id}`,
    type: "line",
    source: `area-${id}`,
    paint: {
      "line-color": "#0067b0",
      "line-width": CHOROPLETH_LINE_WIDTH,
    },
  });
};

export const FocusAreaPreview: FC<FocusAreaPreviewProps> = ({ triggerGAEvent }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<Map | null>(null);

  const [mapLoaded, setMapLoaded] = useState(false);

  const isTokenLoaded = useAppSelector((state) => state.analytics.authorizationTokenLoaded);

  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const datasetMetadata = useAppSelector((state) => state.analytics.datasetMetadata);

  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const previousSelectedFocusArea = usePrevious(selectedFocusArea);

  const studyAreas = useAppSelector((state) => state.analytics.studyAreas);
  const selectedStudyArea = useAppSelector(selectActiveStudyArea);
  const previousSelectedStudyArea = usePrevious(selectedStudyArea);

  const aoiSelectorOptions = useAppSelector((state) => selectAoiSelectorOptions(state, true));

  const error = useMemo(() => focusAreas.state === DataState.ERROR, [focusAreas.state]);

  const loading = useMemo(
    () =>
      focusAreas.state === DataState.LOADING ||
      ODMetadata.state === DataState.LOADING ||
      datasetMetadata.state === DataState.LOADING ||
      roadsMetadata.state === DataState.LOADING,
    [focusAreas.state, ODMetadata.state, datasetMetadata.state, roadsMetadata.state],
  );

  useEffect(() => {
    if (isTokenLoaded) {
      dispatch(analyticsActions.fetchFocusAreasAndDatasets());
    }
  }, [isTokenLoaded, dispatch]);

  useEffect(() => {
    if (map.current) return;

    if (
      focusAreas.state === DataState.AVAILABLE &&
      studyAreas.state === DataState.AVAILABLE &&
      (selectedFocusArea || selectedStudyArea)
    ) {
      let centerCoords;

      if (selectedStudyArea) {
        const studyAreaCentroid = centroid(selectedStudyArea.geometry);
        centerCoords = {
          lon: studyAreaCentroid.geometry.coordinates[0],
          lat: studyAreaCentroid.geometry.coordinates[1],
        };
      } else if (selectedFocusArea) {
        centerCoords = { lon: selectedFocusArea.centroidLon, lat: selectedFocusArea.centroidLat };
      }

      map.current = new mapboxgl.Map({
        accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
        container: mapContainer.current as HTMLElement,
        style: MapBoxStyles.Default,
        center: centerCoords,
        zoom: 7,
        interactive: false,
      });

      map.current.on("load", () => {
        focusAreas.data?.forEach((area) => {
          addAreaSource(map.current as Map, area.id, area.geometry, {
            id: area.id,
            isDataset: Boolean(area.datasetId),
          });
          addAreaLayers(map.current as Map, area.id);
        });

        studyAreas.data?.forEach((area) => {
          addAreaSource(map.current as Map, area.studyAreaId, area.geometry, {
            id: area.studyAreaId,
            isDataset: false,
          });
          addAreaLayers(map.current as Map, area.studyAreaId);
        });

        setMapLoaded(true);
      });
    }
  }, [focusAreas, selectedFocusArea, studyAreas, selectedStudyArea]);

  useEffect(() => {
    if (map.current && mapLoaded && (selectedFocusArea || selectedStudyArea)) {
      if (previousSelectedFocusArea) {
        const previousId = previousSelectedStudyArea
          ? previousSelectedStudyArea.studyAreaId
          : previousSelectedFocusArea.id;

        map.current?.setFeatureState(
          {
            source: `area-${previousId}`,
            id: previousId,
          },
          {
            selected: false,
          },
        );
      }

      const id = selectedStudyArea ? selectedStudyArea.studyAreaId : selectedFocusArea?.id;

      map.current?.setFeatureState(
        {
          source: `area-${id}`,
          id: id,
        },
        {
          selected: true,
        },
      );

      zoomOnArea(map.current, getBounds(selectedStudyArea?.geometry ?? (selectedFocusArea?.geometry as Geometry)));
    }
  }, [
    selectedFocusArea,
    previousSelectedFocusArea,
    selectedStudyArea,
    previousSelectedStudyArea,
    mapLoaded,
    searchParams,
    setSearchParams,
  ]);

  // Catching errors
  useEffect(() => {
    if (error) {
      reportAboutErrorState(
        {
          extraData: `Error statutes: focus areas: ${focusAreas.state === DataState.ERROR} (status: ${
            focusAreas.error?.status
          }, body: ${focusAreas.error?.body})`,
        },
        "The map failed to show the data on the dashboard page",
      );
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenMapPage = () => {
    triggerGAEvent("map-open");
    navigate("/map");
  };

  const handleChangeFocusArea = (areaId?: string, isStudyArea?: boolean) => {
    if (areaId && !isStudyArea) {
      dispatch(globalActions.setSelectedFocusAreaId({ focusAreaId: areaId }));
    } else if (areaId) {
      dispatch(globalActions.setSelectedStudyAreaId(areaId));
    }
  };

  return (
    <MapPreviewPanel>
      <MapContainer>
        {!selectedStudyArea && selectedFocusArea?.isDemo ? <DiagonalLabel> Feature Preview </DiagonalLabel> : null}
        {!selectedStudyArea && selectedFocusArea ? (
          <InfoLabel>
            <Box>
              <b>Area:</b> {convertSquareKmToSquareMiles(selectedFocusArea.areaSqKm).toFixed(2)} sq/mi
            </Box>
            {selectedFocusArea?.population ? (
              <Box>
                <b>Pop:</b> {selectedFocusArea?.population?.toLocaleString("en-US") || ""}
              </Box>
            ) : undefined}
          </InfoLabel>
        ) : undefined}
        <Mapbox ref={mapContainer} style={{ visibility: error || loading ? "hidden" : "visible" }} />
        {error ? (
          <MapErrorContainer>
            <MapErrorPage size="sm" />
          </MapErrorContainer>
        ) : null}
        {loading ? (
          <MapLoadingContainer>
            <CircularProgress />
          </MapLoadingContainer>
        ) : null}
      </MapContainer>
      <ModeControlsContainer>
        <Grid container columnGap={1} alignItems={"center"}>
          <Grid item xs container alignItems={"center"}>
            <FocusAreaDropdown
              selectedOptionId={(selectedStudyArea?.studyAreaId ?? selectedFocusArea?.id) || ""}
              options={aoiSelectorOptions}
              loading={loading}
              disabled={focusAreas.state === DataState.EMPTY || focusAreas.state === DataState.ERROR}
              noLabel={true}
              onChange={handleChangeFocusArea}
              sx={{ width: "100%", "& .MuiFormControl-root": { margin: 0 } }}
              showStudyAreas
            />
          </Grid>
          <Button size="medium" variant="outlined" onClick={() => handleOpenMapPage()} disabled={error || loading}>
            Open
          </Button>
          <Tooltip title="Data quality">
            <IconButton
              onClick={() => navigate(`${ROUTES.Documentation}/${DOCS_ROUTES.DataQuality}`)}
              sx={{ width: "36.5px", height: "36.5px", borderRadius: "6px", backgroundColor: "#fff !important" }}
            >
              <BarChart fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </ModeControlsContainer>
    </MapPreviewPanel>
  );
};
