import { createTheme, responsiveFontSizes } from "@mui/material/styles";

import { themeColors, themePaddings } from "./themeConstants";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    elevated: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    dialogTitle: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    dialogTitle?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    dialogTitle: true;
  }
}

interface LevelPalette {
  // base style (no hover, no selection)
  minVolumeColor: string;
  maxVolumeColor: string;
  opacity: number;
  strokeColor: string;
  strokeWidth: number;

  // hover style
  hoverColor: string;
  hoverOpacity: number;
  hoverStrokeColor: string;
  hoverStrokeWidth: number;

  // selection style
  selectionColor: string;
  selectionOpacity: number;
  selectionStrokeColor: string;
  selectionStrokeWidth: number;

  // circle size parameters
  hoverSizeFactorAtMinZoom: number;
  hoverSizeFactorAtMaxZoom: number;
  minimumSizeAtMinZoom: number;
  maximumSizeAtMinZoom: number;
  minimumSizeAtMaxZoom: number;
  maximumSizeAtMaxZoom: number;
}

declare module "@mui/material/styles" {
  interface Palette {
    screenline: {
      circle: string;
      line: string;
      volume: string;
      candidate: string;
      unresolved: string;
    };
    roadIntersections: {
      nodes: LevelPalette;
      clusters: LevelPalette;
    };
  }

  interface PaletteOptions {
    screenline?: {
      circle: string;
      line: string;
      volume: string;
      candidate: string;
      unresolved: string;
    };
    roadIntersections?: {
      nodes: LevelPalette;
      clusters: LevelPalette;
    };
  }
}

// see discussion in teams
// https://teams.microsoft.com/l/message/19:c79a485d69e9496b89bf33861bd16911@thread.tacv2/1739465723470?tenantId=067e9632-ea4c-4ed9-9e6d-e294956e284b&groupId=f61c8fd6-59c8-4e75-bf95-e2085620500f&parentMessageId=1739381543211&teamName=OpenPaths%20Patterns&channelName=Frontend&createdTime=1739465723470
const defaultLevelPalette: LevelPalette = {
  minVolumeColor: "#00b4d8",
  maxVolumeColor: "#0077b6",
  opacity: 0.7,
  strokeColor: "white",
  strokeWidth: 1,

  hoverColor: "#0077b6",
  hoverOpacity: 0.8,
  hoverStrokeColor: "white",
  hoverStrokeWidth: 2,

  selectionColor: "purple",
  selectionOpacity: 1,
  selectionStrokeColor: "white",
  selectionStrokeWidth: 2,

  hoverSizeFactorAtMinZoom: 1.4,
  hoverSizeFactorAtMaxZoom: 1.6,
  minimumSizeAtMinZoom: 2,
  maximumSizeAtMinZoom: 14,
  minimumSizeAtMaxZoom: 4,
  maximumSizeAtMaxZoom: 20,
};

let theme = createTheme({
  palette: {
    primary: {
      main: themeColors.primary,
    },
    secondary: {
      main: themeColors.primaryLight,
      contrastText: themeColors.textContrast,
    },

    screenline: {
      circle: "#004d20",
      line: "#008035",
      volume: "#00c853",
      candidate: "#ba68c8",
      unresolved: "#ed6c02",
    },

    roadIntersections: {
      nodes: {
        ...defaultLevelPalette,
        // override as needed
      },
      clusters: {
        ...defaultLevelPalette,
        // override as needed
        opacity: 0.6, // clusters are stlightly less opaque
      },
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiMenu: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
        disableRipple: true,
      },
    },

    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          dialogTitle: "span",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: themePaddings.xl,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: themePaddings.xl,
          paddingBottom: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: themePaddings.xl,
        },
      },
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),

    dialogTitle: {
      display: "block",
      fontSize: 16,
      marginBottom: themePaddings.md,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
