import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const TextInputNumbers: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path d="m15 4v7h-14v-7h14m0-1h-14c-.552 0-1 .448-1 1v7c0 .552.448 1 1 1h14c.552 0 1-.448 1-1v-7c0-.552-.448-1-1-1z" />
      <path d="m5.405 9.934h-.539v-3.466c0-.288.009-.561.027-.818-.047.047-.099.095-.156.146s-.322.267-.792.649l-.293-.379 1.287-.995h.466v4.864" />
      <path d="m10.452 9.934h-3.197v-.476l1.281-1.287c.39-.395.648-.676.772-.845s.217-.333.279-.492c.062-.16.093-.332.093-.516 0-.26-.079-.465-.236-.617-.157-.152-.376-.228-.655-.228-.202 0-.393.033-.574.1-.181.066-.382.187-.604.363l-.293-.376c.448-.373.936-.559 1.464-.559.457 0 .815.117 1.075.351.259.234.389.548.389.943 0 .308-.086.613-.259.915-.173.302-.497.683-.971 1.144l-1.065 1.041v.027h2.502v.512" />
      <path d="m14.164 6.214c0 .311-.087.564-.261.762s-.421.329-.74.396v.027c.39.049.68.173.868.373s.283.461.283.785c0 .463-.161.82-.482 1.069s-.779.374-1.371.374c-.257 0-.493-.019-.707-.058-.214-.039-.422-.107-.624-.205v-.526c.211.104.435.184.674.238s.464.082.677.082c.841 0 1.261-.329 1.261-.988 0-.59-.463-.885-1.391-.885h-.479v-.476h.486c.379 0 .68-.084.902-.251.222-.167.333-.4.333-.697 0-.237-.082-.424-.245-.559-.163-.135-.384-.203-.664-.203-.213 0-.414.029-.602.087s-.404.164-.645.319l-.279-.373c.2-.157.43-.281.69-.371.261-.09.535-.135.823-.135.472 0 .839.108 1.101.324s.393.513.393.89" />
    </svg>
  </SvgIcon>
);
