import { Stack, Typography, styled } from "@mui/material";
import logo from "assets/png/BentleyLOGO_WHITE_type.png";
import { Divider } from "components_new";
import React, { FC } from "react";

import { StatePageContainer } from "components";
import { LoginError } from "types/authorization";

interface ErrorPageProps {
  errorMsg?: string;
  loginErrorDetail?:LoginError[];
  errorDetails?: React.ReactNode;
  logoutAndRedirect: (url: string) => void;
}

const LoginErrorPageContainer = styled(StatePageContainer)`
  background-color: #000000;
`;

const ErrorContainer = styled("div")`
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  height: 150px;
  display: grid;
  grid-template-rows: auto auto 24px;
  justify-items: center;

  & img {
    width: 180px;
  }

  & a,
  p {
    color: #ffffff;
    cursor: pointer;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const LoginErrorPage: FC<ErrorPageProps> = ({ errorMsg, loginErrorDetail, logoutAndRedirect }) => {
  
  return (
    <LoginErrorPageContainer>
      <ErrorContainer>
        <img src={logo} alt="Bentley Logo" />
        <h4>{errorMsg || "Access error"}</h4>
        { loginErrorDetail !== undefined ? (
          loginErrorDetail.map((error,idx) => <h5 key={idx}>{error.message}</h5>)
        ) : null }
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography component={"a"} href="mailto:openpaths-patterns@bentley.com">
            Contact support
          </Typography>
          <Divider orientation="vertical" flexItem sx={{ borderColor: "#ffffff" }} />
          <Typography onClick={() => logoutAndRedirect(window.origin)}>Log out</Typography>
        </Stack>
      </ErrorContainer>
    </LoginErrorPageContainer>
  );
};
