import * as Sentry from "@sentry/react";
import React, { FC } from "react";
import { Navigate, Route, Routes as RouterRoutes } from "react-router-dom";

import { useAppSelector } from "hooks";

import {
  selectAtLeastOneCorridorDataPermission,
  selectAtLeastOneRoadVmtDataPermission,
  selectAtLeastOneSelectLinkPermission,
} from "store/permissionsSelectors";

import { ROUTES } from "types";

import {
  AnalyticsPage,
  CorridorDiscoveryPage,
  DashboardPage,
  DatasetEditorPage,
  DatasetsPage,
  DocsPage,
  ExportsPage,
  MapPage,
  RoadVmtPage,
  SelectLinkPage,
} from "./components";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(RouterRoutes);

const Routes: FC = () => {
  const isCorridorAllowed = useAppSelector(selectAtLeastOneCorridorDataPermission);
  const isRoadVmtAllowed = useAppSelector(selectAtLeastOneRoadVmtDataPermission);
  const isSelectLinkAllowed = useAppSelector(selectAtLeastOneSelectLinkPermission);

  return (
    <SentryRoutes>
      <Route path={ROUTES.Dashboard} element={<DashboardPage />} />
      <Route path={ROUTES.Map} element={<MapPage />} />
      <Route path={`${ROUTES.Documentation}/*`} element={<DocsPage />} />
      <Route path={ROUTES.DatasetEditor} element={<DatasetEditorPage />} />
      <Route path={ROUTES.Datasets} element={<DatasetsPage />} />
      <Route path={ROUTES.Exports} element={<ExportsPage />} />
      <Route path={`${ROUTES.Analytics}`}>
        <Route
          path={ROUTES.SelectLink}
          element={isSelectLinkAllowed ? <AnalyticsPage /> : <Navigate to={ROUTES.Dashboard} />}
        />
        <Route
          path={ROUTES.SelectLinkEditor}
          element={isSelectLinkAllowed ? <SelectLinkPage /> : <Navigate to={ROUTES.Dashboard} />}
        />
        <Route
          path={ROUTES.CorridorDiscovery}
          element={isCorridorAllowed ? <CorridorDiscoveryPage /> : <Navigate to={ROUTES.Dashboard} />}
        />
        <Route
          path={ROUTES.RoadVmt}
          element={isRoadVmtAllowed ? <RoadVmtPage /> : <Navigate to={ROUTES.Dashboard} />}
        />
      </Route>

      <Route path="*" element={<Navigate to={ROUTES.Dashboard} />} />
    </SentryRoutes>
  );
};

export default Routes;
