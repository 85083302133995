import { Stack, Typography } from "@mui/material";
import { AnalyticsContainer, DirectionGroup, Divider, MapControlContainer } from "components_new";
import React, { FC, Fragment } from "react";

import { BarChart } from "components";

import { BarChartEmptyState } from "components/molecules/bar-chart/BarChartEmptyState";

import { useAppDispatch, useAppSelector } from "hooks";

import { globalActions } from "store/sections/global";

import { Dimension, RoadVmtZoneDetails } from "types";

import { getLabelByCategories, getLabelByDimension } from "./ODAnalytics";

interface RoadVmtAnalyticsProps {
  zoneDetails: RoadVmtZoneDetails[];
  roadVmtDimensions: Dimension[] | undefined;
}

const RoadVmtAnalytics: FC<RoadVmtAnalyticsProps> = ({ zoneDetails, roadVmtDimensions }) => {
  const dispatch = useAppDispatch();
  const collapsedDimensionContainers = useAppSelector((state) => state.global.collapsedDimensionContainers);

  return (
    <Stack sx={{ height: "calc(100% - 62px)" }} divider={<Divider />}>
      {zoneDetails.map((detail) => (
        <Fragment key={`${detail.measure}_${detail.id}`}>
          <DirectionGroup>
            <Typography variant="subtitle2">{detail?.name || "Attributes"}</Typography>
          </DirectionGroup>
          <AnalyticsContainer heightOperand={40}>
            {detail?.summary?.breakdowns?.map((breakdown, index) => {
              const dimension = breakdown.dimensions[0];
              const data = breakdown.rows.map((row) => ({
                name: getLabelByCategories(row.categories[0], dimension, roadVmtDimensions || []),
                value: row.value,
              }));

              const title = getLabelByDimension(dimension, roadVmtDimensions || []);

              return (
                <MapControlContainer
                  key={dimension}
                  title={title}
                  collapse
                  expanded={!collapsedDimensionContainers.includes(`vmt_${detail.measure}_${dimension}`)}
                  onChange={() =>
                    dispatch(globalActions.toggleDimensionContainerCollapsedState(`vmt_${detail.measure}_${dimension}`))
                  }
                >
                  {data.length ? (
                    <BarChart key={index} data={data} layout="vertical" />
                  ) : (
                    <BarChartEmptyState title={title} message="No data available" />
                  )}
                </MapControlContainer>
              );
            })}
          </AnalyticsContainer>
        </Fragment>
      ))}
    </Stack>
  );
};

export { RoadVmtAnalytics };
