import { Box } from "@mui/material";
import { Divider, MapControlContainer, SliderControl, VisibilityIconButton } from "components_new";
import { FC, MutableRefObject, useCallback, useEffect, useMemo, useState } from "react";

import { ColorSchemeSelector } from "features/filters/ColorSchemeSelector";
import { RangeFilter } from "features/filters/RangeFilter";
import { isInAvailableRange } from "features/filters/utils";
import { ModuleData } from "features/map/ModuleManager";
import { sequentialSchemes } from "features/map/layerColors";
import { getLayerFromZoom, getQueryType } from "features/map/utils";

import { ODLegend } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";
import { filtersActions } from "store/sections/filters";
import { globalActions } from "store/sections/global";
import { mapActions } from "store/sections/map";

import { MapLayerContainerId, MapVisualizationType, MeasureRange } from "types";

interface ODMapLayersProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  ODModuleData: ModuleData;
  isSelectLinkMode: boolean;
  disabled?: boolean;
  isDemandMode?: boolean;
}

export const ODMapLayers: FC<ODMapLayersProps> = ({ map, ODModuleData, isSelectLinkMode, disabled, isDemandMode }) => {
  const dispatch = useAppDispatch();

  const {
    zoningLevels,
    ODZoningLevelBlockedZoom,
    filterZonesByRange,
    filterGatesByRange,
    updateODModeCounts,
    changeZonesFillOpacity,
  } = ODModuleData.data;

  const collapsedMapLayerContainers = useAppSelector((state) => state.global.collapsedMapLayerContainers);

  const mapMode = useAppSelector((state) => state.analytics.mapVisualizationMode);

  const showZoneCounts = useAppSelector((state) => state.map.showZoneCounts);
  const colorScheme = useAppSelector((state) => state.map.colorScheme);
  const colorScale = useAppSelector((state) => state.map.colorScale);
  const odOpacityFactor = useAppSelector((state) => state.analytics.ODOpacityFactor);

  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const selectedZone = useAppSelector((state) => state.analytics.selectedZone);

  const queryType = useAppSelector((state) => state.filters.queryType);
  const zoningLevel = useAppSelector((state) => state.filters.zoningLevel);

  const rangeByZone = useAppSelector((state) => state.filters.rangeByZone);
  const availableRangeByZone = useAppSelector((state) => state.filters.availableRangeByZone);

  //OD
  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const ODCounts = useAppSelector((state) => state.analytics.ODCounts);
  const ODCountsByZone = useAppSelector((state) => state.analytics.ODCountsByZoneId);
  const ODRange = useAppSelector((state) => state.filters.ODRange);
  const ODAvailableRange = useAppSelector((state) => state.filters.ODAvailableRange);
  // @TODO replace with Select Link mode later
  const isOD =
    (mapMode === MapVisualizationType.OD || mapMode === null) && selectedFocusArea && !selectedFocusArea?.datasetId;

  //Dataset
  const datasetMetadata = useAppSelector((state) => state.analytics.datasetMetadata);
  const datasetCounts = useAppSelector((state) => state.analytics.datasetCounts);
  const datasetCountsByZone = useAppSelector((state) => state.analytics.datasetCountsByZoneId);
  const datasetRange = useAppSelector((state) => state.filters.datasetRange);
  const datasetAvailableRange = useAppSelector((state) => state.filters.datasetAvailableRange);
  // @TODO replace with Select Link mode later
  const isDataset = (mapMode === MapVisualizationType.OD || mapMode === null) && selectedFocusArea?.datasetId;

  const demandScenarioZoneCounts = useAppSelector((state) => state.analytics.demandScenarioZoneCounts);
  const demandAvailableRange = useAppSelector((state) => state.filters.demandAvailableRange);
  const demandRange = useAppSelector((state) => state.filters.demandRange);

  const assignmentLinkValues = useAppSelector((state) => state.analytics.assignmentLinkValues);

  const [opacityFactor, setOpacityFactor] = useState(odOpacityFactor);

  const layers = isOD ? ODMetadata.data?.tileService.layers : datasetMetadata.data?.tileService.layers;

  const availableRange = useMemo(() => {
    if (selectedZone && availableRangeByZone) return availableRangeByZone;
    if (isOD && ODAvailableRange.data && zoningLevel) return ODAvailableRange.data?.[zoningLevel?.level];
    if (isDataset && datasetAvailableRange.data && zoningLevel) return datasetAvailableRange.data?.[zoningLevel?.level];
    if (isDemandMode && demandScenarioZoneCounts.state === DataState.AVAILABLE && demandAvailableRange)
      return demandAvailableRange;
  }, [
    isDemandMode,
    isOD,
    isDataset,
    selectedZone,
    availableRangeByZone,
    ODAvailableRange.data,
    zoningLevel,
    datasetAvailableRange.data,
    demandScenarioZoneCounts.state,
    demandAvailableRange,
  ]);

  const range = useMemo(() => {
    const availableRangeTouple: [number, number] = availableRange ? [availableRange.min, availableRange.max] : [0, 0];
    if (selectedZone) return rangeByZone || availableRangeTouple;
    if (isOD && zoningLevel)
      return ODRange?.[zoningLevel?.level] ? ODRange?.[zoningLevel?.level] : availableRangeTouple;
    if (isDataset && zoningLevel)
      return datasetRange?.[zoningLevel?.level] ? datasetRange?.[zoningLevel?.level] : availableRangeTouple;
    if (isDemandMode) {
      return demandRange ? demandRange : availableRangeTouple;
    }
  }, [
    selectedZone,
    rangeByZone,
    availableRange,
    isOD,
    zoningLevel,
    ODRange,
    demandRange,
    isDataset,
    isDemandMode,
    datasetRange,
  ]);

  const loading = useMemo(
    () =>
      ODCounts.state === DataState.LOADING ||
      ODCountsByZone.state === DataState.LOADING ||
      datasetCounts.state === DataState.LOADING ||
      datasetCountsByZone.state === DataState.LOADING ||
      demandScenarioZoneCounts.state === DataState.LOADING ||
      assignmentLinkValues.state === DataState.LOADING,
    [
      ODCounts.state,
      ODCountsByZone.state,
      datasetCounts.state,
      datasetCountsByZone.state,
      demandScenarioZoneCounts.state,
      assignmentLinkValues.state,
    ],
  );

  useEffect(() => {
    const zoom = map.current?.getZoom();
    const isZoningLevelBlocked =
      (!isDemandMode &&
        range &&
        availableRange &&
        (range[0] !== availableRange.min || range[1] !== availableRange.max)) ||
      selectedZone;

    if (!isDemandMode && isZoningLevelBlocked && !ODZoningLevelBlockedZoom?.current && zoom !== undefined) {
      ODZoningLevelBlockedZoom.current = zoom;
    } else if (!isDemandMode && !isZoningLevelBlocked && ODZoningLevelBlockedZoom?.current) {
      ODZoningLevelBlockedZoom.current = null;
      const layer = getLayerFromZoom(zoom, layers);
      if (layer) dispatch(filtersActions.setZoningLevel(layer));
      updateODModeCounts.current?.();
    }
  }, [
    map,
    isDemandMode,
    range,
    availableRange,
    selectedZone,
    layers,
    zoningLevel,
    ODZoningLevelBlockedZoom,
    updateODModeCounts,
    dispatch,
  ]);

  const handleSetRange = (newRange: [number, number], availableRange: MeasureRange) => {
    if (selectedZone) {
      if (isDemandMode) {
        dispatch(filtersActions.setDemandRange(isInAvailableRange(newRange, availableRange) ? null : newRange));
      } else {
        dispatch(filtersActions.setRangeByZone(isInAvailableRange(newRange, availableRange) ? null : newRange));
      }
    } else if (isOD && zoningLevel) {
      dispatch(
        filtersActions.setODRange(
          isInAvailableRange(newRange, availableRange) ? null : { [zoningLevel?.level]: newRange },
        ),
      );
    } else if (isDataset && zoningLevel) {
      dispatch(
        filtersActions.setDatasetRange(
          isInAvailableRange(newRange, availableRange) ? null : { [zoningLevel?.level]: newRange },
        ),
      );
    } else if (isDemandMode) {
      dispatch(filtersActions.setDemandRange(isInAvailableRange(newRange, availableRange) ? null : newRange));
    }
  };

  const filterZones = useCallback(() => {
    if (map.current && range && availableRange && (zoningLevel || isDemandMode)) {
      if (range[0] > availableRange.min || range[1] < availableRange.max) {
        filterZonesByRange(range, zoningLevel, opacityFactor, isSelectLinkMode);
        if (isDataset) filterGatesByRange(range);
      } else {
        filterZonesByRange([availableRange.min, availableRange.max], zoningLevel, opacityFactor, isSelectLinkMode);
        if (isDataset) filterGatesByRange([availableRange.min, availableRange.max]);
      }
    }
  }, [
    isDemandMode,
    availableRange,
    filterZonesByRange,
    filterGatesByRange,
    isDataset,
    map,
    range,
    zoningLevel,
    isSelectLinkMode,
    opacityFactor,
  ]);

  const handleChangeShowZoneCounts = () => {
    dispatch(mapActions.setShowZoneCounts(!showZoneCounts));
    ODModuleData.data.changeShowZoneCounts(!showZoneCounts);
  };

  const handleChangeColorScheme = useCallback(
    (colorScheme: string) => {
      dispatch(mapActions.setColorScheme(colorScheme));
    },
    [dispatch],
  );

  const handleChangeFillOpacity = (opacityFactor: number) => {
    if (isDemandMode) {
      changeZonesFillOpacity(range, opacityFactor);
    } else {
      changeZonesFillOpacity(layers, range, opacityFactor, isSelectLinkMode);
    }

    dispatch(analyticsActions.setODOpacityFactor(opacityFactor));
  };

  return (
    <>
      <ODLegend
        colorSchemeName={colorScheme}
        scale={colorScale}
        queryType={selectedZone ? getQueryType(queryType) : queryType}
        zoningLevel={zoningLevel?.level}
        zoningLevels={zoningLevels}
        subtitle="Counts per square mile"
      />

      <MapControlContainer
        title="OD Counts"
        primaryAction={
          <VisibilityIconButton
            visible={showZoneCounts}
            onClick={handleChangeShowZoneCounts}
            disabled={loading || disabled}
          />
        }
        collapse
        expanded={!collapsedMapLayerContainers.includes(MapLayerContainerId.OD)}
        onChange={() => dispatch(globalActions.toggleLayerContainerCollapsedState(MapLayerContainerId.OD))}
      >
        <Box padding={1}>
          <RangeFilter
            label={"Filter"}
            range={range}
            availableRange={availableRange}
            loading={loading}
            disabled={disabled}
            filterByRange={filterZones}
            setRange={handleSetRange}
          />
          <Divider sx={{ marginY: 0.5 }} />
          <SliderControl
            label="Opacity"
            disabled={loading || disabled}
            value={opacityFactor}
            defaultValue={1}
            min={0}
            max={1}
            step={0.1}
            marks={[
              {
                value: 1,
                label: "",
              },
            ]}
            valueLabelDisplay="auto"
            onChange={(e, value) => setOpacityFactor(value as number)}
            onChangeCommitted={(e, value) => handleChangeFillOpacity(value as number)}
          />
          <Divider sx={{ marginY: 0.5 }} />
          <ColorSchemeSelector
            availableSchemes={sequentialSchemes}
            selectedColorScheme={colorScheme}
            disabled={loading || disabled}
            onChange={handleChangeColorScheme}
          />
        </Box>
      </MapControlContainer>
    </>
  );
};
