import { useAuth } from "ImsAuthorization";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppSelector } from "hooks";

export const usePageTracking = () => {
  const { user } = useAuth();
  const location = useLocation();

  const userOrganization = useAppSelector((state) => state.license.user.data?.organization);

  useEffect(() => {
    if (user) {
      const splittedEmail = user?.email?.split("@");

      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search + location.hash,
        page_search: location.search,
        page_hash: location.hash,
        // !!! user__nickname was removed from the event for privacy reasons
        // user_nickname: splittedEmail?.[0] || "anonymous",
        user_auth0_id: user?.sub || "anonymous",
        user_organization: userOrganization?.name || "anonymous",
        email_domain: splittedEmail?.[1] || "anonymous",
      });
    }
  }, [location, user, userOrganization?.name]);
};
