import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { MutableRefObject, useCallback } from "react";

import { buildFilters, getCurrentMeasure } from "features/filters/utils";

import { useAppDispatch } from "hooks";

import { roadIntersectionsActions } from "store/sections/roadIntersections";

import {
  AggregationFunction,
  FiltersType,
  MeasureBreakdownArgs,
  RoadIntersectionLevel,
  RoadsMetadataResponse,
} from "types";

import {
  ROAD_INTERSECTIONS_LAYER_ID,
  getRoadIntersectionLayerCircleOpacityExpression,
  getRoadIntersectionLayerNameForLevel,
} from "./map-data/layers";
import { ROAD_INTERSECTIONS_SOURCE_ID } from "./map-data/sources";

export const useChangeShowRoadIntersections = (
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
  levels: RoadIntersectionLevel[],
) => {
  return useCallback(
    (showRoadIntersections: boolean) => {
      if (mapboxLayerManager.current) {
        levels.forEach((level) => {
          mapboxLayerManager.current!.updateLayerLayout(
            getRoadIntersectionLayerNameForLevel(level),
            "visibility",
            showRoadIntersections ? "visible" : "none",
          );
        });
      }
    },
    [mapboxLayerManager, levels],
  );
};

export const useFetchRoadIntersectionIds = (timePeriod: string | null, datasetId?: string) => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    if (timePeriod) {
      dispatch(
        roadIntersectionsActions.fetchRoadIntersectionIds({
          timePeriod,
          datasetId,
          compression: "gzip",
          format: "protobuf",
        }),
      );
    }
  }, [dispatch, timePeriod, datasetId]);
};

export const useFetchRoadIntersectionVolumes = (
  timePeriod: string | null,
  measure: string | null,
  roadFilters: FiltersType | null,
) => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    if (timePeriod && measure) {
      dispatch(
        roadIntersectionsActions.fetchRoadIntersectionVolumes({
          timePeriod,
          measure,
          filter: buildFilters(roadFilters),
          compression: "gzip",
          format: "protobuf",
        }),
      );
    }
  }, [dispatch, timePeriod, measure, roadFilters]);
};

export const useFetchRoadIntersectionClusterIds = (timePeriod: string | null, datasetId?: string) => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    if (timePeriod) {
      dispatch(
        roadIntersectionsActions.fetchRoadIntersectionClusterIds({
          timePeriod,
          datasetId,
          compression: "gzip",
          format: "protobuf",
        }),
      );
    }
  }, [dispatch, timePeriod, datasetId]);
};

export const useFetchRoadIntersectionClusterVolumes = (
  timePeriod: string | null,
  measure: string | null,
  roadFilters: FiltersType | null,
) => {
  const dispatch = useAppDispatch();

  return useCallback(
    (aggregationFunction: AggregationFunction) => {
      if (timePeriod && measure) {
        dispatch(
          roadIntersectionsActions.fetchRoadIntersectionClusterVolumes({
            timePeriod,
            measure,
            filter: buildFilters(roadFilters),
            compression: "gzip",
            format: "protobuf",
            aggregationFunction,
          }),
        );
      }
    },
    [dispatch, timePeriod, measure, roadFilters],
  );
};

export const useChangeRoadIntersectionsOpacity = (
  map: MutableRefObject<mapboxgl.Map | null>,
  levels: RoadIntersectionLevel[],
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
) => {
  return useCallback(
    (opacityFactor: number) => {
      levels.forEach((level) => {
        const layerName = getRoadIntersectionLayerNameForLevel(level);
        if (map.current!.getLayer(layerName)) {
          mapboxLayerManager.current?.updateLayerPaint(
            layerName,
            "circle-opacity",
            getRoadIntersectionLayerCircleOpacityExpression(level, opacityFactor),
          );
        }
      });
    },
    [map, mapboxLayerManager, levels],
  );
};

export const useFetchRoadIntersectionVolumeDetails = (
  roadsMetadata: RoadsMetadataResponse | null,
  currentRoadFilters: FiltersType | null,
  timePeriod: string | null,
) => {
  const dispatch = useAppDispatch();

  return useCallback(
    (nodeIds: number[], measure: string | null, aggregationFunction: AggregationFunction) => {
      if (timePeriod && measure && roadsMetadata?.pedestrianRoadsTileService) {
        const currentMeasure = getCurrentMeasure(roadsMetadata?.measures, measure);

        if (!currentMeasure) return;

        const dimensions = currentMeasure.dimensions?.filter((d) => d.enabled).map((d) => d.columnName) || [];
        const breakdowns: MeasureBreakdownArgs[] = dimensions.map((d) => ({
          dimensions: [d],
          includeUnfiltered: false,
        }));

        dispatch(
          roadIntersectionsActions.fetchRoadIntersectionVolumeDetails({
            nodeIds,
            timePeriod,
            summary: {
              breakdowns,
              filteredTotal: true,
              unfilteredTotal: false,
            },
            measure,
            filter: buildFilters(currentRoadFilters),
            aggregationFunction,
          }),
        );
      }
    },
    [roadsMetadata?.measures, roadsMetadata?.pedestrianRoadsTileService, currentRoadFilters, timePeriod, dispatch],
  );
};

export const useSetSelectedIntersectionId = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (selectedIntersectionId: string | null) => {
      dispatch(roadIntersectionsActions.setSelectedRoadIntersectionId(selectedIntersectionId));
    },
    [dispatch],
  );
};

export const useSetSelectedIntersections = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (selectedIntersectionIds: string[] | null) => {
      dispatch(roadIntersectionsActions.setSelectedRoadIntersections(selectedIntersectionIds));
    },
    [dispatch],
  );
};

export const useUpdateFeatureStateForRoadIntersections = (
  map: MutableRefObject<mapboxgl.Map | null>,
  layerName: string | undefined,
) => {
  return useCallback(
    (nodeId: number | null, stateName: string, status?: boolean) => {
      if (
        status !== undefined &&
        nodeId &&
        map.current &&
        layerName &&
        typeof map.current?.setFeatureState === "function"
      ) {
        map.current.setFeatureState(
          {
            source: ROAD_INTERSECTIONS_SOURCE_ID,
            sourceLayer: layerName,
            id: nodeId,
          },
          {
            [stateName]: status,
          },
        );
      } else if (nodeId && map.current && layerName && typeof map.current?.removeFeatureState === "function") {
        map.current.removeFeatureState(
          {
            source: ROAD_INTERSECTIONS_SOURCE_ID,
            sourceLayer: layerName,
            id: nodeId,
          },
          stateName,
        );
      }
    },
    [map, layerName],
  );
};

export const useCloseRoadIntersectionsAnalyticsPanel = (
  map: MutableRefObject<mapboxgl.Map | null>,
  selectedIntersectionId: string | null,
  selectIntersectionId: (selectedRoadVolumeId: string | null) => void,
  updateFeatureStateForRoadIntersections: (
    nodeId: number | null,
    stateName: string,
    status?: boolean | undefined,
  ) => void,
  clearSelectedIntersections: () => void,
) => {
  return useCallback(() => {
    if (selectedIntersectionId) {
      if (
        map.current &&
        typeof map.current?.getLayer === "function" &&
        map.current.getLayer(ROAD_INTERSECTIONS_LAYER_ID)
      ) {
        updateFeatureStateForRoadIntersections(Number(selectedIntersectionId), "selectHighlight");
      }

      selectIntersectionId(null);
    }

    clearSelectedIntersections();
  }, [
    map,
    selectedIntersectionId,
    selectIntersectionId,
    updateFeatureStateForRoadIntersections,
    clearSelectedIntersections,
  ]);
};

export const useClearRoadIntersectionVolumeDetails = () => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(roadIntersectionsActions.clearRoadIntersectionVolumeDetails());
  }, [dispatch]);
};

export const useSetIsDrawModeActive = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (active: boolean) => {
      dispatch(roadIntersectionsActions.setIsDrawModeActive(active));
    },
    [dispatch],
  );
};

export const useSetIsBaseIntersectionLevel = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (isBaseLevel: boolean) => {
      dispatch(roadIntersectionsActions.setIsBaseIntersectionLevel(isBaseLevel));
    },
    [dispatch],
  );
};

export const useClearSelectedIntersections = (
  map: MutableRefObject<mapboxgl.Map | null>,
  selectedIntersections: string[] | null,
  setSelectedIntersections: (selectedIntersections: string[] | null) => void,
  setIsDrawModeActive: (active: boolean) => void,
  updateFeatureStateForRoadIntersections: (
    nodeId: number | null,
    stateName: string,
    status?: boolean | undefined,
  ) => void,
) => {
  return useCallback(() => {
    if (selectedIntersections) {
      selectedIntersections.forEach((selectedIntersectionId) => {
        if (
          map.current &&
          typeof map.current?.getLayer === "function" &&
          map.current.getLayer(ROAD_INTERSECTIONS_LAYER_ID)
        ) {
          updateFeatureStateForRoadIntersections(Number(selectedIntersectionId), "selectHighlight");
        }
      });

      setSelectedIntersections(null);
    }

    setIsDrawModeActive(false);
  }, [
    map,
    selectedIntersections,
    setSelectedIntersections,
    setIsDrawModeActive,
    updateFeatureStateForRoadIntersections,
  ]);
};
