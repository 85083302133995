import { useAuth } from "ImsAuthorization";
import { ExpandLess, ExpandMore, HelpCenter } from "@mui/icons-material";
import { ButtonBase, ListItemText, MenuItem, styled } from "@mui/material";
import { Avatar, IconButton, Menu, UserSettingsDialog } from "components_new";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import { FlexContainer, HelpForm, Notifications } from "components";

import { useAppDispatch, useAppSelector, usePrevious } from "hooks";

import { licenseActions } from "store/sections/license";


const UserButton = styled(ButtonBase)`
  min-width: 62px;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
`;

const UserControlsContainer = styled(FlexContainer)`
  gap: 24px;
`;

export const UserControls: FC = () => {
  const dispatch = useAppDispatch();
  const { user, logout } = useAuth();

  const isOperator = useAppSelector((state) => state.license.user.data?.isOperator);
  const userOrganization = useAppSelector((state) => state.license.user.data?.organization);
  const userLicenseType = useAppSelector((state) => state.license.user.data?.softwareLicense);
  const previousUserOrganization = usePrevious(userOrganization);
  const previousUserLicenseType = usePrevious(userLicenseType);

  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);
  const [isUserSettingsDialogOpen, setIsUserSettingsDialogOpen] = useState<boolean>(false);

  const handleCloseUserMenu = useRef<() => void>();

  const isUserOrganisationChanged = useMemo(
    () => userOrganization && previousUserOrganization && previousUserOrganization !== userOrganization,
    [userOrganization, previousUserOrganization],
  );

  const isUserLicenseTypeChanged = useMemo(
    () => userLicenseType && previousUserLicenseType && previousUserLicenseType !== userLicenseType,
    [userLicenseType, previousUserLicenseType],
  );

  useEffect(() => {
    if (isOperator) dispatch(licenseActions.fetchOrganization());
  }, [isOperator, dispatch]);

  useEffect(() => {
    if (isOperator) dispatch(licenseActions.fetchLicenseTypes());
  }, [isOperator, dispatch]);

  useEffect(() => {
    if (isUserOrganisationChanged || isUserLicenseTypeChanged) {
      window.location.replace("/");
    }
  }, [isUserOrganisationChanged, isUserLicenseTypeChanged]);

  const userMenuItems = useMemo(
    () => [
      ...(isOperator
        ? [
            {
              text: "Copy token",
              onClick: () => {
                navigator.clipboard.writeText(sessionStorage.getItem("accessToken") || "");
                handleCloseUserMenu.current?.();
              },
            },
          ]
        : []),
      {
        text: "Settings",
        onClick: () => {
          setIsUserSettingsDialogOpen(true);
          handleCloseUserMenu.current?.();
        },
        divider: true,
      },
      {
        text: "Sign out",
        onClick: () =>
          logout({
            returnTo: window.location.origin,
          }),
      },
    ],
    [isOperator, logout],
  );

  return (
    <>
      <UserSettingsDialog open={isUserSettingsDialogOpen} onClose={() => setIsUserSettingsDialogOpen(false)} />
      <UserControlsContainer>
        <Notifications />
        <IconButton onClick={() => setIsHelpFormOpen(true)}>
          <HelpCenter sx={{ color: "#fff" }} />
        </IconButton>
        <HelpForm isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} />
        <Menu
          control={(handleOpen, handleClose, open) => {
            handleCloseUserMenu.current = handleClose;
            return (
              <UserButton onClick={handleOpen}>
                <Avatar src={user?.picture} alt="avatar" sx={{ width: 40, height: 40 }} />
                {open ? <ExpandLess sx={{ color: "#fff" }} /> : <ExpandMore sx={{ color: "#fff" }} />}
              </UserButton>
            );
          }}
        >
          {userMenuItems.map((item, index) => (
            <MenuItem dense key={index} divider={item.divider} onClick={item.onClick} sx={{ minWidth: 150 }}>
              <ListItemText primary={item.text} />
            </MenuItem>
          ))}
        </Menu>
      </UserControlsContainer>
    </>
  );
};
