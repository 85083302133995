
export interface SessionRequest {
  // currrently the request to start a session is empty,
  // we may add information in the future
}

export type SessionErrorType = "userdisabled" | "seatdisabled" | "organizationdisabled" | "missingsoftwarelicense" | "organizationnotfound" | "seatnotfound" 

export interface SessionResponse {
  errors: SessionErrorType[]
}


export interface Audit {
  createdAt: string;
  updatedAt: string;
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  organization: Organization;
  audit: Audit;
  isOperator: boolean;
  softwareLicense: SoftwareLicense;
}

export interface SoftwareLicense {
  id: number;
  numberOfSeats: number;
  expiresAt: string;
  softwareLicenseType: SoftwareLicenseType;
  organization: SoftwareLicenseOrganization;
  audit: Audit;
}

export type SoftwareLicenseType = Omit<LicenseType, "softwarePermissions" | "audit">;

export type SoftwareLicenseOrganization = Omit<Organization, "orgType" | "invoiceAddress" | "audit">;

export interface OrganizationResponse {
  limit: number;
  offset: number;
  totalCount: number;
  data: Organization[];
}

export interface Organization {
  id: number;
  code: string;
  name: string;
  orgType: string;
  invoiceAddress: string;
  audit: Audit;
}

export enum NotificationUrlType {
  APP_PAGE = "apppage",
  DOWNLOAD = "download",
}

export enum NotificationLevel {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
}

export interface NotificationUrl {
  value: string;
  urlType: NotificationUrlType;
}

export type Notification = {
  id: number;
  content: {
    title: string;
    detailsText: string;
    detailsFooter: string;
    url?: NotificationUrl;
    downloadUrl: string;
    appUrl: string;
    level: NotificationLevel;
  };
  seen: boolean;
  audit: {
    createdAt: string;
    updatedAt: string;
  };
};

export interface NotificationsInboxState {
  unseenNotificationCount: number;
  waitMilli: number;
}

export interface NotificationsInboxResponse {
  limit: number;
  offset: number;
  totalCount: number;
  data: Notification[];
}

export interface FeedbackPayload {
  subject: string;
  body: string;
  url: string;
  images?: Image[];
}

export interface Image {
  content: string;
  contentType: string;
  fileName: string;
}

export interface LicenseTypeResponse {
  limit: number;
  offset: number;
  totalCount: number;
  data: LicenseType[];
}

export interface LicenseType {
  id: number;
  name: string;
  code: string;
  softwarePermissions: SoftwarePermissions;
  audit: Audit;
}

export interface UserPermissions {
  softwarePermissions: SoftwarePermissions;
  licensedAreas: LicensedArea[];
}

export interface LicensedArea {
  licensedAreaId: number;
  softwarePermissions: SoftwarePermissions;
  dataDetail: DataDetail;
}

export interface DataDetail {
  roadDetail: RoadDetail;
  odDetail: Detail;
  corridorDetail: Detail;
  roadVmtDetail: Detail;
}

export interface DataPermission {
  measures: Measures;
}

export interface Detail extends DataPermission {
  zoningLevel: string;
  measures: Measures;
}

export interface Measures {
  [key: string]: string[];
}

export interface RoadDetail extends DataPermission {
  includeModelingAttributes: boolean;
  roadClasses: number[];
  measures: Measures;
}

export interface SoftwarePermissions {
  folder: Folder;
  dataset: Dataset;
  exports: Exports;
  zoning: Zoning;
  selectLink: SelectLink;
  screenlines: Screenlines;
  roadIntersections: RoadIntersections;
  user: User;
}

export interface Dataset {
  allowCreateDataset: IsAllowed;
  allowEditAllDatasets: IsAllowed;
  allowUseCustomZoning: IsAllowed;
  allowReadAllDraftDatasets: IsAllowed;
  allowReadAllComputedDatasets: IsAllowed;
  allowComputeAllDatasets: IsAllowed;
  allowDeleteAllDatasets: IsAllowed;
  computePriority: number;
}

export interface Reason {
  reason?: string;
}

export interface IsAllowed {
  Allowed?: Reason;
  NotAllowed?: Reason;
}

export interface Exports {
  allowExport: IsAllowed;
}

export interface Folder {
  allowManageFolders: IsAllowed;
}

export interface SelectLink {
  allowSelectLink: IsAllowed;
}

export interface Screenlines {
  allowUseScreenlines: IsAllowed;
}

export interface RoadIntersections {
  allowRoadIntersections: IsAllowed;
}

export interface User {
  allowManagingUsers: IsAllowed;
}

export interface Zoning {
  allowZoningUpload: IsAllowed;
}
