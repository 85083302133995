export enum MemoryStoreKeys {
  ROADS_SEGMENT_VOLUMES = "roadsSegmentVolumes",
  ROADS_SEGMENT_FROM_TO_INDEXES = "roadsSegmentFromToIndexes",
  SELECT_LINK_SEGMENT_VOLUMES = "selectLinkSegmentVolumes",
  ROADS_SEGMENT_FROM_TO_INDEXES_BY_ROAD_CLASS = "roadsSegmentFromToIndexesByRoadClass",
  CORRIDOR_DISCOVERY_EDGE_IDS = "corridorDiscoveryEdgeIds",
  CORRIDOR_DISCOVERY_EDGE_COUNTS = "corridorDiscoveryEdgeCounts",
  CORRIDOR_DISCOVERY_NODE_IDS = "corridorDiscoveryNodeIds",
  CORRIDOR_DISCOVERY_NODE_COUNTS = "corridorDiscoveryNodeCounts",

  // Road intersection and cluster volumes
  ROAD_INTERSECTION_VOLUMES = "roadIntersectionVolumes",
  ROAD_INTERSECTION_IDS = "roadIntersectionIds",
  ROAD_INTERSECTION_CLUSTER_VOLUMES = "roadIntersectionClusterVolumes",
  ROAD_INTERSECTION_CLUSTER_IDS = "roadIntersectionClusterIds",

  // Publications
  LINK_IDS_WITH_LINK_CLASSES = "linkIdsWithLinkClasses",
  LINK_VALUES = "linkValues",
  DEMAND_ZONE_IDS = "demandZoneIds",
  DEMAND_ZONE_COUNTS = "demandZoneCounts",
}

export interface MemoryStore {
  setItem: (key: string, value: any) => void;
  getItem: (key: string) => any;
  hasItem: (key: string) => boolean;
  deleteItem: (key: string) => void;
  clear: () => void;
  size: () => number;
  keys: () => any[];
  values: () => any[];
  getMap: () => Map<string, any>;
}

const memoryStore = ((): MemoryStore => {
  const dataMap = new Map(); // In-memory Map to store data in key-value pairs for the current session

  return {
    setItem: (key: string, value: any) => {
      dataMap.set(key, value);
    },

    getItem: (key: string) => {
      return dataMap.get(key);
    },

    hasItem: (key: string) => {
      return dataMap.has(key);
    },

    deleteItem: (key: string) => {
      dataMap.delete(key);
    },

    clear: () => {
      dataMap.clear();
    },

    size: () => {
      return dataMap.size;
    },

    keys: () => {
      return Array.from(dataMap.keys());
    },

    values: () => {
      return Array.from(dataMap.values());
    },

    getMap: () => {
      return dataMap;
    },
  };
})();

export default memoryStore;
