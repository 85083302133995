import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { useMemoryStore } from "api/MemoryStoreContext";
import { Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useMemo, useRef, useState } from "react";

import { ModuleData } from "features/map/ModuleManager";

import { getMaxSegmentsGroupId } from "components/pages/analytics/select-link/utils";

import { useAppDispatch, useAppSelector, useStateRef } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";
import { filtersActions } from "store/sections/filters";

import {
  Counts,
  FiltersType,
  FocusAreaItem,
  SegmentsGroup,
  SelectLinkAnalysisOptions,
  SelectLinkConfigEssentials,
  SelectLinkMode,
  SelectLinkPredicateLogic,
  SelectedArea,
  SelectedVolume,
  Volume,
  ZoneIds,
  ZoneSelectionMode,
} from "types";

import {
  useChangeShowZoneCounts,
  useChangeZonesFillOpacity,
  useFilterZonesByRange,
  useGetODCounts,
  useGetODIds,
  useHandleSetColorScale,
  useHandleSetZoningLevel,
} from "../od/ControllerCallbacks";
import { getODLayers } from "../od/map-data/od/layers";
import { getODSources } from "../od/map-data/od/sources";
import {
  useChangeShowRoadVolumes,
  useChangeVolumesOpacity,
  useChangeVolumesWidth,
  useFilterRoadSegmentsByRange,
  useFilterRoadSegmentsByRoadClasses,
  useGetFromToNonZeroVolumeSegmentIndexes,
  useGetRoadSegmentIndexes,
  useGetRoadsVolumes,
  useGetVisibilityStatus,
  useSelectRoadVolume,
  useSelectRoadVolumeId,
  useUpdateFeatureStateForRoads,
} from "../roads/RoadsControllerCallbacks";
import { getRoadsLayers } from "../roads/map-data/layers";
import { getRoadsSources } from "../roads/map-data/sources";
import {
  useDeleteSelectedZone,
  useDeleteSelectedZonesFromFeatureState,
  useGetSelectLinkMetadata,
  useGetSelectLinkSegmentCounts,
  useSelectZoneInternal,
  useSelectZoning,
  useUpdateFeatureStateForZone,
  useUpdateFeatureStateForZoneInAllLayers,
  useUpdateFeatureStateWithSelectedLinks,
  useUpdateFeatureStateWithSelectedODs,
  useUpdateFeatureStateWithSelectedZones,
  useUpdateSelectLinkConfiguration,
} from "./ControllerCallbacks";

export const PERMANENT_HIGHLIGHTED_FEATURE = "permanentSelectHighlight";

interface Props {
  map: MutableRefObject<mapboxgl.Map | null>;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  zoneSelectionModeRef: MutableRefObject<ZoneSelectionMode>;
  mode: SelectLinkMode;
  selectedLinkGroups: SegmentsGroup[];
  selectLinkOptions: SelectLinkAnalysisOptions;
  selectedOriginsRef: MutableRefObject<SelectedArea[]>;
  selectedDestinationsRef: MutableRefObject<SelectedArea[]>;
  selectLinkConfigLinksModuleData: ModuleData | null;
  addSegmentFromPopup: (selectedVolume: SelectedVolume, selectedRoadSegmentId: string) => void;
  setSelectLinkConfigZonesModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  setSelectLinkConfigLinksModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  setSelectLinkResultsModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  updateODModeCounts: MutableRefObject<(() => void) | null>;
  updateRoadsModeCounts: MutableRefObject<(() => void) | null>;
  setActiveGroupId: Dispatch<SetStateAction<string>>;
  setSelectedOrigins: Dispatch<SetStateAction<SelectedArea[]>>;
  setSelectedDestinations: Dispatch<SetStateAction<SelectedArea[]>>;
  setSelectedLinkGroups: Dispatch<SetStateAction<SegmentsGroup[]>>;
  setMaxSegmentsGroupId: Dispatch<SetStateAction<number>>;
  setSelectLinkPredicateLogic: Dispatch<SetStateAction<SelectLinkPredicateLogic>>;
  setSelectLinkOptions: Dispatch<SetStateAction<SelectLinkAnalysisOptions>>;
  updateFeatureStateForRoadsRef: MutableRefObject<
    ((segmentIdx: number | null, stateName: string, status?: boolean | undefined) => void) | null
  >;
  updateFeatureStateForZoneRef: MutableRefObject<
    ((zone: SelectedArea, zoneMode: string, stateName: string, status?: boolean) => void) | null
  >;
  updateSelectLinkConfiguration: MutableRefObject<(update: SelectLinkConfigEssentials) => void>;
  getSelectLinkSegmentCounts: MutableRefObject<
    (
      filters: FiltersType,
      selectedFocusArea: FocusAreaItem,
      segmentGroups: SegmentsGroup[],
      origins: SelectedArea[],
      destinations: SelectedArea[],
      predicateLogic: string,
      { minVolume }: SelectLinkAnalysisOptions,
    ) => void
  >;
}

export const MapController = ({
  map,
  layerManagerRef,
  zoneSelectionModeRef,
  mode,
  selectedLinkGroups,
  selectLinkOptions,
  selectedOriginsRef,
  selectedDestinationsRef,
  addSegmentFromPopup,
  setSelectLinkConfigZonesModuleData,
  setSelectLinkConfigLinksModuleData,
  setSelectLinkResultsModuleData,
  updateODModeCounts,
  updateRoadsModeCounts,
  setActiveGroupId,
  setSelectedOrigins,
  setSelectedDestinations,
  setSelectedLinkGroups,
  setMaxSegmentsGroupId,
  setSelectLinkPredicateLogic,
  setSelectLinkOptions,
  updateFeatureStateForRoadsRef,
  updateFeatureStateForZoneRef,
  updateSelectLinkConfiguration,
  getSelectLinkSegmentCounts,
}: Props) => {
  const memoryStore = useMemoryStore();
  const dispatch = useAppDispatch();

  const [, setVolumeProps] = useState<Volume[]>([]);

  // Global
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);

  // OD
  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const ODIds = useAppSelector((state) => state.analytics.ODIds);
  const ODCounts = useAppSelector((state) => state.analytics.ODCounts);
  const ODCountsByZoneId = useAppSelector((state) => state.analytics.ODCountsByZoneId);
  const selectedZone = useAppSelector((state) => state.analytics.selectedZone);
  const queryType = useAppSelector((state) => state.filters.queryType);
  const currentODFilters = useAppSelector((state) => state.filters.ODFilters);
  const colorScheme = useAppSelector((state) => state.map.colorScheme);
  const showZoneCounts = useAppSelector((state) => state.map.showZoneCounts);
  const odOpacityFactor = useAppSelector((state) => state.analytics.ODOpacityFactor);
  const odOpacityFactorRef = useStateRef(odOpacityFactor);

  // Roads and road volumes
  const measure = useAppSelector((state) => state.filters.measure);
  const roadSegmentIndexes = useAppSelector((state) => state.analytics.roadSegmentIndexes);
  const roadsVolumes = useAppSelector((state) => state.analytics.roadsVolumes);
  const currentRoadFilters = useAppSelector((state) => state.filters.roadFilters);
  const showRoadVolumes = useAppSelector((state) => state.map.showRoadVolumes);
  const roadClasses = useAppSelector((state) => state.filters.roadClasses);
  const segmentsIdToIdxMap = useAppSelector((state) => state.analytics.selectLinkSegmentsIdToIdxMap);
  const roadsOpacityFactor = useAppSelector((state) => state.analytics.roadsOpacityFactor);
  const roadsOpacityFactorRef = useStateRef(roadsOpacityFactor);
  const roadsWidthFactor = useAppSelector((state) => state.analytics.roadsWidthFactor);
  const roadsWidthFactorRef = useStateRef(roadsWidthFactor);

  // Select link analysis
  const selectLinkSegmentCounts = useAppSelector((state) => state.selectLink.selectLinkSegmentCounts);
  const selectLinkMetadata = useAppSelector((state) => state.analytics.selectLinkMetadata);
  const savedSelectLinkConfig = useAppSelector((state) => state.selectLink.savedSelectLinkConfig);
  const currentSelectLinkConfig = useAppSelector((state) => state.selectLink.currentSelectLinkConfig);
  const resultsSelectLinkConfig = useAppSelector((state) => state.selectLink.resultsSelectLinkConfig);
  const timePeriod = useMemo(
    () => savedSelectLinkConfig.data?.timePeriod || null,
    [savedSelectLinkConfig.data?.timePeriod],
  );

  // Refs
  const ODCountsRef = useRef<Counts | null>(null);
  const ODZoningLevelBlockedZoom = useRef<number | null>(null);
  const queryTypeRef = useStateRef(queryType);
  const showZoneCountsRef = useRef<boolean>(showZoneCounts);
  const colorSchemeRef = useStateRef(colorScheme);

  const mapboxSegmentHoverPopupRef = useRef<Popup>(null);
  const mapboxVolumesPopupRef = useRef<Popup>(null);
  const maxSegmentVolumeRef = useRef<number>(1);
  const showRoadVolumesRef = useRef<boolean>(showRoadVolumes);

  const isODDataAvailable = useMemo(
    () =>
      Boolean(
        ODMetadata.state === DataState.AVAILABLE &&
          ODMetadata.data?.tileService &&
          ODIds.state === DataState.AVAILABLE &&
          ODIds.data &&
          ODCounts.state === DataState.AVAILABLE &&
          ODCounts.data,
      ),
    [ODIds, ODCounts, ODMetadata],
  );

  const isODZonesDataAvailable = Boolean(
    ODMetadata.state === DataState.AVAILABLE &&
      ODMetadata.data?.tileService &&
      ODIds.state === DataState.AVAILABLE &&
      ODIds.data,
  );

  const isRoadsDataAvailable = Boolean(
    selectLinkMetadata.state === DataState.AVAILABLE &&
      selectLinkMetadata.data?.roadsTileService &&
      roadSegmentIndexes.state === DataState.AVAILABLE &&
      roadsVolumes.state === DataState.AVAILABLE,
  );

  const zoningLevels = useMemo(() => ODMetadata.data?.zoningLevels, [ODMetadata.data?.zoningLevels]);
  const zoneLayers = useMemo(() => ODMetadata.data?.tileService.layers, [ODMetadata.data?.tileService.layers]);
  const ODLayers = useMemo(() => ODMetadata.data?.tileService.layers, [ODMetadata.data?.tileService.layers]);

  const getSelectLinkMetadata = useGetSelectLinkMetadata(timePeriod);

  // OD callbacks
  const getODIds = useGetODIds(ODMetadata.data, selectedFocusArea, timePeriod);
  const getODCounts = useGetODCounts(queryType, ODMetadata.data, selectedFocusArea, timePeriod);
  const changeShowZoneCounts = useChangeShowZoneCounts(layerManagerRef, ODLayers, ODZoningLevelBlockedZoom);
  const handleSetColorScale = useHandleSetColorScale();
  const handleSetZoningLevel = useHandleSetZoningLevel();
  const updateFeatureStateForZone = useUpdateFeatureStateForZone(map, zoneLayers);
  updateFeatureStateForZoneRef.current = useUpdateFeatureStateForZoneInAllLayers(
    map,
    zoneLayers,
    updateFeatureStateForZone,
  );
  const updateFeatureStateWithSelectedZones = useUpdateFeatureStateWithSelectedZones(map, updateFeatureStateForZone);
  const updateFeatureStateWithSelectedODs = useUpdateFeatureStateWithSelectedODs(
    zoneLayers,
    selectedOriginsRef,
    selectedDestinationsRef,
    updateFeatureStateWithSelectedZones,
  );
  const deleteSelectedZonesFromFeatureState = useDeleteSelectedZonesFromFeatureState(
    zoneLayers,
    updateFeatureStateForZone,
  );
  const deleteSelectedZone = useDeleteSelectedZone(
    zoneSelectionModeRef,
    selectedOriginsRef,
    selectedDestinationsRef,
    setSelectedOrigins,
    setSelectedDestinations,
    deleteSelectedZonesFromFeatureState,
  );

  const selectZoneInternal = useSelectZoneInternal(
    selectedOriginsRef,
    selectedDestinationsRef,
    setSelectedOrigins,
    setSelectedDestinations,
    updateFeatureStateForZone,
  );
  const setSelectedZone = useSelectZoning(
    selectedOriginsRef,
    selectedDestinationsRef,
    zoneSelectionModeRef,
    deleteSelectedZone,
    selectZoneInternal,
  );
  const changeZonesFillOpacity = useChangeZonesFillOpacity(map, layerManagerRef);

  // Roads callbacks
  const getRoadSegmentIndexes = useGetRoadSegmentIndexes(selectedFocusArea, timePeriod);
  const getFromToNonZeroVolumeSegmentIndexes = useGetFromToNonZeroVolumeSegmentIndexes(memoryStore);
  const getRoadsVolumes = useGetRoadsVolumes(selectedFocusArea, timePeriod);
  const selectRoadVolumeId = useSelectRoadVolumeId();
  const getVisibilityStatus = useGetVisibilityStatus(showRoadVolumesRef);
  const changeShowRoadVolumes = useChangeShowRoadVolumes(layerManagerRef, updateRoadsModeCounts);
  const filterRoadSegmentsByRoadClasses = useFilterRoadSegmentsByRoadClasses(
    map,
    layerManagerRef,
    selectLinkMetadata.data?.roadsTileService,
  );
  const filterRoadSegmentsByRange = useFilterRoadSegmentsByRange(
    map,
    layerManagerRef,
    selectLinkMetadata.data?.roadsTileService,
  );
  updateFeatureStateForRoadsRef.current = useUpdateFeatureStateForRoads(
    map,
    selectLinkMetadata.data?.roadsTileService?.layerName,
  );
  const selectRoadVolume = useSelectRoadVolume(updateFeatureStateForRoadsRef.current, true);
  const updateFeatureStateWithSelectedLinks = useUpdateFeatureStateWithSelectedLinks(
    segmentsIdToIdxMap.data,
    updateFeatureStateForRoadsRef,
  );
  const changeVolumesOpacity = useChangeVolumesOpacity(map, layerManagerRef);
  const changeVolumesWidth = useChangeVolumesWidth(map, layerManagerRef);

  // Configuration callbacks
  updateSelectLinkConfiguration.current = useUpdateSelectLinkConfiguration(
    savedSelectLinkConfig.data?.configId,
    selectLinkOptions.minVolume,
  );

  getSelectLinkSegmentCounts.current = useGetSelectLinkSegmentCounts(measure, timePeriod);

  const filterZonesByRange = useFilterZonesByRange(map, layerManagerRef);

  const selectRoadLink = useRef((selectedRoadVolume: SelectedVolume, selectedRoadVolumeId: string) => {});

  // Get selectlink metadata
  useEffect(() => {
    if (
      selectLinkMetadata.state === DataState.EMPTY &&
      (mode === SelectLinkMode.LINKS || mode === SelectLinkMode.RESULTS)
    ) {
      getSelectLinkMetadata();
    }
  }, [mode, selectLinkMetadata.state, getSelectLinkMetadata]);

  useEffect(() => {
    return () => {
      dispatch(analyticsActions.clearSelectLinkMetadata());
    };
  }, [dispatch]);

  // Set select link measure
  useEffect(() => {
    if (selectLinkMetadata.state === DataState.AVAILABLE) {
      const selectLinkMeasures = selectLinkMetadata.data?.measures;
      const selectLinkFirstMeasure = selectLinkMeasures?.[0]?.columnName;
      if (measure === null || selectLinkMeasures.find((m) => m.columnName === measure) === undefined) {
        dispatch(filtersActions.setMeasure(selectLinkFirstMeasure));
      }
    }
  }, [dispatch, measure, selectLinkMetadata]);

  // Update setRoadLink method after getting selectedLinkGroups
  useEffect(() => {
    selectRoadLink.current = (selectedRoadVolume: SelectedVolume, selectedRoadVolumeId: string) => {
      if (selectLinkMetadata.data?.roadsTileService) {
        addSegmentFromPopup(selectedRoadVolume, selectedRoadVolumeId);
      }
    };
  }, [addSegmentFromPopup, selectedLinkGroups, selectLinkMetadata.data?.roadsTileService]);

  // Set select link data from config
  useEffect(() => {
    if (savedSelectLinkConfig.data) {
      const { segmentsGroups, segmentsGroupsOp, origins, destinations, minCount } =
        currentSelectLinkConfig ?? savedSelectLinkConfig.data;
      setSelectedLinkGroups(segmentsGroups || []);
      setSelectLinkPredicateLogic(segmentsGroupsOp || SelectLinkPredicateLogic.And);
      setSelectedOrigins(origins || []);
      selectedOriginsRef.current = origins || [];
      setSelectedDestinations(destinations || []);
      selectedDestinationsRef.current = destinations || [];
      setSelectLinkOptions({ minVolume: minCount ?? selectLinkMetadata.data?.minAllowedVolume });

      const maxGroupId = getMaxSegmentsGroupId(segmentsGroups);
      setMaxSegmentsGroupId(maxGroupId);
      if (maxGroupId > 0) {
        setActiveGroupId(maxGroupId.toString());
      }
    }
  }, [
    selectLinkMetadata.data?.minAllowedVolume,
    selectedOriginsRef,
    selectedDestinationsRef,
    savedSelectLinkConfig.data,
    currentSelectLinkConfig,
    setActiveGroupId,
    setSelectedDestinations,
    setSelectedOrigins,
    setSelectedLinkGroups,
    setMaxSegmentsGroupId,
    setSelectLinkOptions,
    setSelectLinkPredicateLogic,
  ]);

  // Fetch OD ids
  useEffect(() => {
    if (ODIds.state === DataState.EMPTY) {
      getODIds();
    }
  }, [ODIds.state, getODIds]);

  // Fetch OD counts
  useEffect(() => {
    if (
      ODCounts.state !== DataState.LOADING &&
      ODCountsByZoneId.state !== DataState.LOADING &&
      selectedFocusArea &&
      currentODFilters
    ) {
      if (ODCounts.state === DataState.EMPTY) {
        getODCounts(currentODFilters);
      }

      if (ODCounts.state === DataState.AVAILABLE) {
        ODCountsRef.current = ODCounts.data;

        if (typeof updateODModeCounts.current === "function") {
          updateODModeCounts.current();
        }
      }

      if (ODCountsByZoneId.state === DataState.AVAILABLE && selectedZone) {
        ODCountsRef.current = ODCountsByZoneId.data.counts;

        if (typeof updateODModeCounts.current === "function") {
          updateODModeCounts.current();
        }
      }
    }
  }, [ODCounts, ODCountsByZoneId, selectedFocusArea, currentODFilters, selectedZone, getODCounts, updateODModeCounts]);

  // Update query showZoneCounts ref
  useEffect(() => {
    showZoneCountsRef.current = showZoneCounts;
  }, [showZoneCounts]);

  // Fetch Segment IDs
  useEffect(() => {
    if (roadSegmentIndexes.state === DataState.EMPTY) {
      getRoadSegmentIndexes();
    }
  }, [roadSegmentIndexes.state, getRoadSegmentIndexes]);

  // Fetch roads volumes
  useEffect(() => {
    if (roadsVolumes.state !== DataState.LOADING && selectedFocusArea && currentRoadFilters) {
      if (roadsVolumes.state === DataState.EMPTY) {
        getRoadsVolumes(currentRoadFilters, measure);
      }

      if (roadsVolumes.state === DataState.AVAILABLE) {
        maxSegmentVolumeRef.current = roadsVolumes.data?.maxVolume || 1;
      }
    }
  }, [map, measure, currentRoadFilters, roadsVolumes.state, roadsVolumes.data, selectedFocusArea, getRoadsVolumes]);

  // Update showRoadVolumes ref
  useEffect(() => {
    showRoadVolumesRef.current = showRoadVolumes;
  }, [showRoadVolumes]);

  // Set zones module data
  useEffect(() => {
    if (mode === SelectLinkMode.ZONES && currentSelectLinkConfig) {
      if (selectedFocusArea && isODDataAvailable) {
        setSelectLinkConfigLinksModuleData(null);
        setSelectLinkResultsModuleData(null);

        const ODTileservice: any = ODMetadata.data?.tileService;
        const ids: ZoneIds | null = ODIds.data;

        const ODSources = getODSources(ODTileservice);
        const ODLayers = getODLayers(ODTileservice, odOpacityFactorRef).zoningLayers;

        setSelectLinkConfigZonesModuleData((configData) =>
          configData
            ? configData
            : {
                sources: ODSources,
                layers: ODLayers,
                data: {
                  ODTileservice,
                  ids,
                  ODCountsRef,
                  queryTypeRef,
                  ODZoningLevelBlockedZoom,
                  zoningLevels,
                  showZoneCountsRef,
                  colorSchemeRef,
                  isExportPermitted: selectLinkMetadata.data?.permissions.Export.allow, // TODO should be replaced with permissions from according endpoint
                  minAllowedVolume: selectLinkMetadata.data?.minAllowedVolume,
                  updateODModeCounts,
                  odOpacityFactorRef,
                  handleSetColorScale,
                  handleSetZoningLevel,
                  filterZonesByRange,
                  setSelectedZone,
                  changeShowZoneCounts,
                  deleteSelectedZone,
                  updateFeatureStateWithSelectedODs,
                  changeZonesFillOpacity,
                },
              },
        );
      }
    }
  }, [
    mode,
    currentSelectLinkConfig,
    isODDataAvailable,
    selectedFocusArea,
    ODMetadata.data,
    ODIds.data,
    selectLinkMetadata.data,
    colorSchemeRef,
    queryTypeRef,
    ODCountsRef,
    ODZoningLevelBlockedZoom,
    zoningLevels,
    showZoneCountsRef,
    updateODModeCounts,
    odOpacityFactorRef,
    setSelectLinkConfigZonesModuleData,
    setSelectLinkConfigLinksModuleData,
    setSelectLinkResultsModuleData,
    handleSetColorScale,
    handleSetZoningLevel,
    filterZonesByRange,
    setSelectedZone,
    changeShowZoneCounts,
    updateFeatureStateWithSelectedODs,
    deleteSelectedZone,
    changeZonesFillOpacity,
  ]);

  // Set links module data
  useEffect(() => {
    if (mode === SelectLinkMode.LINKS && currentSelectLinkConfig) {
      const isAllDataAvailable = ODMetadata.state === DataState.AVAILABLE && isRoadsDataAvailable;

      if (selectedFocusArea && isAllDataAvailable && roadsVolumes.data?.size) {
        setSelectLinkConfigZonesModuleData(null);
        setSelectLinkResultsModuleData(null);

        maxSegmentVolumeRef.current = roadsVolumes.data?.maxVolume || 1;

        const roadsSources = getRoadsSources(selectLinkMetadata.data!.roadsTileService);
        const roadsLayers = getRoadsLayers(
          selectLinkMetadata.data!.roadsTileService.layerName,
          roadsOpacityFactorRef,
          roadsWidthFactorRef,
        );
        const ODSources = getODSources(ODMetadata.data!.tileService);
        const selectLinkSelectedZonesLayers = getODLayers(
          ODMetadata.data!.tileService,
          odOpacityFactorRef,
          true,
        ).selectLinkSelectedZonesLayers;
        const zoneIds: ZoneIds | null = ODIds.data;

        setSelectLinkConfigLinksModuleData((data) =>
          data
            ? data
            : {
                sources: [...roadsSources, ...ODSources],
                layers: [...roadsLayers, ...selectLinkSelectedZonesLayers],
                data: {
                  segmentGroups: currentSelectLinkConfig.segmentsGroups,
                  roadsTileService: selectLinkMetadata.data!.roadsTileService,
                  ODTileservice: ODMetadata.data!.tileService,
                  maxSegmentVolumeRef,
                  mapboxSegmentHoverPopupRef,
                  mapboxVolumesPopupRef,
                  showRoadVolumesRef,
                  setVolumeProps,
                  selectRoadVolume,
                  selectRoadLink,
                  ids: getFromToNonZeroVolumeSegmentIndexes(),
                  zoneIds,
                  roadClasses,
                  minAllowedVolume: selectLinkMetadata.data?.minAllowedVolume,
                  isExportPermitted: selectLinkMetadata.data?.permissions.Export.allow, // TODO should be replaced with permissions from according endpoint
                  getVisibilityStatus,
                  changeShowRoadVolumes,
                  filterByRoadClasses: filterRoadSegmentsByRoadClasses,
                  filterRoadSegmentsByRange,
                  updateFeatureStateWithSelectedLinks,
                  updateFeatureStateWithSelectedODs,
                  volumesSize: roadsVolumes.data?.size ?? 0,
                  changeVolumesOpacity,
                  changeVolumesWidth,
                  roadsOpacityFactorRef,
                  roadsWidthFactorRef,
                  odOpacityFactorRef,
                },
              },
        );
      }
    }
  }, [
    mode,
    currentSelectLinkConfig,
    selectLinkMetadata.data,
    ODMetadata,
    ODIds.data,
    roadsVolumes.data?.maxVolume,
    roadsVolumes.data?.size,
    selectedFocusArea,
    isRoadsDataAvailable,
    maxSegmentVolumeRef,
    mapboxSegmentHoverPopupRef,
    mapboxVolumesPopupRef,
    showRoadVolumesRef,
    roadClasses,
    odOpacityFactorRef,
    roadsOpacityFactorRef,
    roadsWidthFactorRef,
    setSelectLinkConfigZonesModuleData,
    setSelectLinkConfigLinksModuleData,
    setSelectLinkResultsModuleData,
    setVolumeProps,
    selectRoadVolume,
    selectRoadVolumeId,
    getFromToNonZeroVolumeSegmentIndexes,
    getVisibilityStatus,
    changeShowRoadVolumes,
    filterRoadSegmentsByRoadClasses,
    filterRoadSegmentsByRange,
    updateFeatureStateWithSelectedLinks,
    updateFeatureStateWithSelectedODs,
    changeVolumesOpacity,
    changeVolumesWidth,
  ]);

  // Set results module data
  useEffect(() => {
    if (
      mode === SelectLinkMode.RESULTS &&
      resultsSelectLinkConfig &&
      selectLinkSegmentCounts.state === DataState.AVAILABLE
    ) {
      const isAllDataAvailable = isODZonesDataAvailable && isRoadsDataAvailable;

      if (selectedFocusArea && isAllDataAvailable) {
        setSelectLinkConfigZonesModuleData(null);
        setSelectLinkConfigLinksModuleData(null);

        maxSegmentVolumeRef.current = selectLinkSegmentCounts.data?.maxVolume || 1;

        const roadsSources = getRoadsSources(selectLinkMetadata.data!.resultsTileService);
        const roadsLayers = getRoadsLayers(
          selectLinkMetadata.data!.resultsTileService.layerName,
          roadsOpacityFactorRef,
          roadsWidthFactorRef,
          true,
        );
        const ODSources = getODSources(ODMetadata.data!.tileService);
        const selectLinkSelectedZonesLayers = getODLayers(
          ODMetadata.data!.tileService,
          odOpacityFactorRef,
          true,
        ).selectLinkSelectedZonesLayers;
        const zoneIds: ZoneIds | null = ODIds.data;

        setSelectLinkResultsModuleData((data) =>
          data
            ? data
            : {
                sources: [...roadsSources, ...ODSources],
                layers: [...roadsLayers, ...selectLinkSelectedZonesLayers],
                data: {
                  isSelectLinkResults: true,
                  segmentGroups: resultsSelectLinkConfig.segmentsGroups,
                  roadsTileService: selectLinkMetadata.data!.resultsTileService,
                  ODTileservice: ODMetadata.data!.tileService,
                  maxSegmentVolumeRef,
                  mapboxSegmentHoverPopupRef,
                  mapboxVolumesPopupRef,
                  showRoadVolumesRef,
                  setVolumeProps,
                  ids: getFromToNonZeroVolumeSegmentIndexes(true),
                  zoneIds,
                  roadClasses,
                  isExportPermitted: selectLinkMetadata.data?.permissions.Export.allow, // TODO should be replaced with permissions from according endpoint
                  minAllowedVolume: selectLinkMetadata.data?.minAllowedVolume,
                  getVisibilityStatus,
                  changeShowRoadVolumes,
                  filterByRoadClasses: filterRoadSegmentsByRoadClasses,
                  filterRoadSegmentsByRange,
                  updateFeatureStateWithSelectedLinks,
                  updateFeatureStateWithSelectedODs,
                  volumesSize: roadsVolumes.data?.size ?? 0,
                  changeVolumesOpacity,
                  changeVolumesWidth,
                  roadsOpacityFactorRef,
                  roadsWidthFactorRef,
                  odOpacityFactorRef,
                },
              },
        );
      }
    }
  }, [
    mode,
    selectLinkMetadata.data,
    resultsSelectLinkConfig,
    roadsVolumes.data?.size,
    ODMetadata.data,
    ODIds.data,
    selectedFocusArea,
    selectLinkSegmentCounts,
    isODZonesDataAvailable,
    isRoadsDataAvailable,
    maxSegmentVolumeRef,
    mapboxSegmentHoverPopupRef,
    mapboxVolumesPopupRef,
    showRoadVolumesRef,
    roadClasses,
    odOpacityFactorRef,
    roadsOpacityFactorRef,
    roadsWidthFactorRef,
    setSelectLinkConfigZonesModuleData,
    setSelectLinkConfigLinksModuleData,
    setSelectLinkResultsModuleData,
    setVolumeProps,
    getFromToNonZeroVolumeSegmentIndexes,
    getVisibilityStatus,
    changeShowRoadVolumes,
    filterRoadSegmentsByRoadClasses,
    filterRoadSegmentsByRange,
    updateFeatureStateWithSelectedLinks,
    updateFeatureStateWithSelectedODs,
    changeVolumesOpacity,
    changeVolumesWidth,
  ]);

  return null;
};
