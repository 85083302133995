import { LogoutOptions, useAuth0 } from "@auth0/auth0-react";
import { BrowserAuthorizationClient } from "@itwin/browser-authorization";
import React, { createContext, useContext, useEffect, useState } from "react";

import { LoadingPage } from "components";
import { licenseActions } from "store/sections/license";

import { ClientContextType, LoginError } from "types/authorization";

import { reportAboutErrorState } from "utils/reports";
import { useDispatch } from "react-redux";
import { useAppSelector } from "hooks";
import { DataState } from "store/interfaces";
import { SessionErrorType } from "types";

const ClientContext = createContext<ClientContextType | undefined>(undefined);

export interface ImsAuthorizationProps {
  children: React.ReactNode;
}

export const ImsAuthorizationProvider: React.FC<ImsAuthorizationProps> = ({ children }) => {
  const dispatch = useDispatch()
  const [client, setClient] = useState<BrowserAuthorizationClient | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [error, setLoginError] = useState<LoginError | undefined>(undefined);
  const [loginErrorDetail, setLoginErrorDetail] = useState<LoginError[]>([]);

  const [logout, setLogout] = useState<(options?: LogoutOptions) => void>(() => {});
  const [loginWithRedirect, setLoginWithRedirect] = useState<() => void>(() => {});
  const userSession = useAppSelector((state) => state.license.session)
  const [getAccessTokenSilently, setGetAccessTokenSilently] = useState<() => Promise<string>>(
    () => () => Promise.reject("token not yet defined"),
  );

  function setLoginFailed(errors: SessionErrorType[]) {
    const errorsList = errors.map(error => {
      switch(error)
        {
            case "userdisabled": 
              return {name: "userdisabled",  message:  "User is disabled" }
            case "seatdisabled": 
              return {name: "seatdisabled",  message: "Seat is disabled"}
            case "organizationdisabled": 
              return {name: "organizationdisabled",  message: "Organization is disabled"}
            case "missingsoftwarelicense": 
              return {name: "missingsoftwarelicense",  message: "Missing a software license"}
            case "organizationnotfound": 
              return {name: "organizationnotfound",  message: "Organization is not configured"}
            default :
              return undefined;  
        }
    }) as LoginError[]

    setLoginError({name:"Access error", message:"Access error, please contact support"})
    setLoginErrorDetail(errorsList)
    setIsLoading(false);
    setIsAuthenticated(false);
  }

  function setLoginSuccess() {
    setIsLoading(false);
  }

  useEffect(() => {
    if(userSession.state === DataState.AVAILABLE) {
      if (userSession.data.errors.length === 0) {
        setLoginSuccess()
      } else {
        setLoginFailed(userSession.data.errors)
      }
    }

  }, [userSession])

  useEffect(() => {
    setIsAuthenticated((client?.isAuthorized && !client?.hasExpired) || false);
  }, [client?.isAuthorized, client?.hasExpired]);

  // Initialize the IMS client
  useEffect(() => {
    if (
      imsEnabled() &&
      process.env.REACT_APP_IMS_CLIENT_ID !== undefined &&
      process.env.REACT_APP_IMS_REDIRECT_URI !== undefined &&
      process.env.REACT_APP_IMS_LOGOUT_REDIRECT_URI !== undefined &&
      process.env.REACT_APP_IMS_SCOPE !== undefined &&
      process.env.REACT_APP_IMS_AUTHORITY !== undefined
    ) {
      const newClient = new BrowserAuthorizationClient({
        authority: process.env.REACT_APP_IMS_AUTHORITY,
        clientId: process.env.REACT_APP_IMS_CLIENT_ID,
        redirectUri: process.env.REACT_APP_IMS_REDIRECT_URI,
        postSignoutRedirectUri: process.env.REACT_APP_IMS_LOGOUT_REDIRECT_URI,
        scope: process.env.REACT_APP_IMS_SCOPE,
        responseType: "code",
      });

      newClient.onAccessTokenChanged.addListener((token) => {
        sessionStorage.setItem("accessToken", extractTokenBody(token));
      });

      setGetAccessTokenSilently(() => () => newClient.getAccessToken().then((token) => extractTokenBody(token)));
      setLogout(() => () => newClient.signOutRedirect());
      setLoginWithRedirect(
        () => () =>
          newClient
            .signInRedirect()
            .then(() => setLoginSuccess())
            .catch((error) => {
              setLoginFailed([]);
              reportAboutErrorState({ error }, "Failed to sign in");
            }),
      );
      setClient(newClient);
    }
  }, []);

  useEffect(() => {
    // On application startup, run the IMS signIn,
    // the library will try to do a silent login othewise it will redirect to IMS login page
    if (client !== null) {
      client
        .signInSilent()
        .then(() => dispatch(licenseActions.triggerStartSession()))
        .catch(async () => await client.signInRedirect());
    }
  }, [client, dispatch]);

  // Handle signin/signout callback url
  useEffect(() => {
    // Handle signin callback url, the bearer token is created in this step
    if (client !== null && window.location.pathname === "/signin-oidc") {
      client
        .handleSigninCallback()
        .then(() => {
          dispatch(licenseActions.triggerStartSession())
        })
        .catch((error) => {
          setLoginFailed([]);
          reportAboutErrorState({ error }, "Failed to handle signin callback");
        });
    } else if (client !== null && window.location.pathname === "/signout-oidc") {
      // redirect to home page after logout
      window.location.href = "/";
    }
  }, [client, dispatch]);

  // Set up a timer to refresh the access token every 15 minutes through silent sign in
  useEffect(() => {
    if (client === null) {
      return;
    }

    const intervalId = setInterval(async () => {
      try {
        await client.signInSilent();
      } catch (error) {
        reportAboutErrorState({ error }, "Failed to get access token in interval");
        await client.signInRedirect();
      }
    }, 15 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [client]);

  return (
    <ClientContext.Provider
      value={{ isLoading, isAuthenticated, logout, loginWithRedirect, getAccessTokenSilently, error, loginErrorDetail }}
    >
      {imsEnabled() && isLoading ? <LoadingPage /> : children}
    </ClientContext.Provider>
  );
};

function useImsAuthentication(): ClientContextType {
  const context = useContext(ClientContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a ClientProvider");
  }
  return context;
}

// @TODO Remove this when we fully migrate to IMS and can save the token with the Bearer prefix
// Extracts the token from the Bearer prefix as it will be added later in the code
const extractTokenBody = (token: string): string => token.replace(/^Bearer /, "");

export const imsEnabled = () => process.env.REACT_APP_IMS_ENABLED === "true";

export const useAuth = (): ClientContextType => {
  const auth0User = useAuth0();
  const imsUser = useImsAuthentication();
  return imsEnabled() ? imsUser : auth0User;
};
