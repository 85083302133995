import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "store";

import { DataDetail, FocusAreaItem, IsAllowed, LicensedArea, SoftwarePermissions, UserPermissions } from "types";

/**
 * Callback to get a permission from a software license
 * @example
 * (sp) => sp.selectLink.allowSelectLink
 */
type LicensePredicate = (sp: SoftwarePermissions) => IsAllowed;

/**
 * Callback to get the data detail of a licensed area
 * @example
 * (dd) => dd.corridorDetail
 */
type DataLicensePredicate = (dd: DataDetail) => boolean;

/**
 * Internal method.
 * Used internally to implement other methods.
 * In the future we may have cases where we may want to relate multiple permissions for a feature, this method is future prof
 */
const filterFocusAreasFilter = (
  focusAreas: FocusAreaItem[] | null,
  licensePermissions: UserPermissions | null,
  licensedAreasMap: Map<string, LicensedArea> | null,
  licensePredicates: LicensePredicate[],
  dataLicensePredicates: DataLicensePredicate[],
  allowedOnDatasets: boolean,
): FocusAreaItem[] | null => {
  const filterAllowedOnDatasets = allowedOnDatasets ? () => true : (fa: FocusAreaItem) => fa.datasetId === undefined;

  return (
    focusAreas
      // apply the allow dataset filter
      ?.filter(filterAllowedOnDatasets)
      ?.filter((fa) => {
        const licensedAreaPermissions = licensedAreasMap?.get(fa.licensedAreaId ?? fa.id);
        if (licensedAreaPermissions !== undefined) {
          // checks if all permissions are allowed
          const areSoftwarePermissionsAllowed = licensePredicates.every(
            (licensePredicate) =>
              (licensePermissions && licensePredicate(licensePermissions.softwarePermissions).Allowed) ||
              licensePredicate(licensedAreaPermissions.softwarePermissions).Allowed,
          );

          // checks if all data permissions are allowed
          const areDataPermissionsAllowed = dataLicensePredicates.every((dataLicensePredicate) =>
            dataLicensePredicate(licensedAreaPermissions.dataDetail),
          );

          // if wasn't found any not allowed permission, user will have access
          return (
            (licensePredicates.length === 0 || areSoftwarePermissionsAllowed) &&
            (dataLicensePredicates.length === 0 || areDataPermissionsAllowed)
          );
        } else {
          // if there aren't permission regarding this data license, user shouldn't have access
          return false;
        }
      }) || null
  );
};

const permissionsData = (state: RootState) => state.license.permissions.data;
const focusAreasAndDatasetsData = (state: RootState) => state.analytics.focusAreasAndDatasets.data;

const selectLicensedAreasPermissionsMap = createSelector([permissionsData], (permissions) =>
  permissions ? new Map(permissions.licensedAreas.map((la) => [String(la.licensedAreaId), la])) : null,
);

/**
 * Checks if the user has at least one licensed area with that permission
 *
 * @param licensePredicate - Callback to get a permission from a software license
 *
 * @example
 * // Create an instance of the selector
 * export const selectAtLeastOneSelectLinkPermission = makeSelectHasAtLeastOneLicensedAreaWithPermission((sp) => sp.selectLink.allowSelectLink);
 * // Use the selector
 * const isSelectLinkAllowed = useAppSelector(selectAtLeastOneSelectLinkPermission)
 */
export const makeSelectHasAtLeastOneLicensedAreaWithPermission = (licensePredicate: LicensePredicate) => {
  const selectAtLeastOnePermission = createSelector([permissionsData], (permissions) =>
    permissions
      ? licensePredicate(permissions?.softwarePermissions).Allowed !== undefined ||
        permissions.licensedAreas.find((la) => licensePredicate(la.softwarePermissions).Allowed) !== undefined
      : false,
  );

  return selectAtLeastOnePermission;
};

export const selectAtLeastOneSelectLinkPermission = makeSelectHasAtLeastOneLicensedAreaWithPermission(
  (sp) => sp.selectLink.allowSelectLink,
);

/**
 * Checks if the user has at least one licensed area with that data permission
 *
 * @param dataLicensePredicate - Callback to get the data detail of a licensed area.
 *
 * @example
 * // Create an instance of the selector
 * export const selectAtLeastOneCorridorDataPermission = makeSelectHasAtLeastOneLicensedAreaWithDataPermission((dp) => dp.corridorDetail);
 * // Use the selector
 * const isCorridorAllowed = useAppSelector(selectAtLeastOneCorridorDataPermission);
 */
export const makeSelectHasAtLeastOneLicensedAreaWithDataPermission = (dataLicensePredicate: DataLicensePredicate) => {
  const selectAtLeastOneDataPermission = createSelector(
    [permissionsData],
    (permissions) => permissions?.licensedAreas.find((la) => dataLicensePredicate(la.dataDetail)) !== undefined,
  );
  return selectAtLeastOneDataPermission;
};

export const selectAtLeastOneCorridorDataPermission = makeSelectHasAtLeastOneLicensedAreaWithDataPermission((dd) =>
  Boolean(dd.corridorDetail),
);
export const selectAtLeastOneRoadVmtDataPermission = makeSelectHasAtLeastOneLicensedAreaWithDataPermission((dd) =>
  Boolean(dd.roadVmtDetail),
);
export const selectAtLeastOneScreenlinePermission = makeSelectHasAtLeastOneLicensedAreaWithPermission(
  (sp) => sp.screenlines.allowUseScreenlines,
);

/**
 * Get focus areas (licensed areas, optionally also datasets) for which specified expectations regarding the available software license are fulfilled
 *
 * @param licensePredicate - Callback to get a permission from a software license.
 * @param allowedOnDatasets - If true, will return also datasets, if false, will return only licensed areas.
 *
 * @example
 * // Create an instance of the selector
 * export const selectFocusAreasByAllowSelectLink = makeSelectFocusAreasWithExpectedSoftwareLicense((sp) => sp.selectLink.allowSelectLink, false);
 * // Use the selector
 * const aoiAreas = useAppSelector(selectFocusAreasByAllowSelectLink)
 */
export const makeSelectFocusAreasWithExpectedSoftwareLicense = (
  licensePredicate: LicensePredicate,
  allowedOnDatasets: boolean = true,
) => {
  const selectFocusAreasByPermission = createSelector(
    [permissionsData, focusAreasAndDatasetsData, selectLicensedAreasPermissionsMap],
    (permissions, focusAreas, licensedAreasPermissionsMap) =>
      filterFocusAreasFilter(
        focusAreas,
        permissions,
        licensedAreasPermissionsMap,
        [licensePredicate],
        [],
        allowedOnDatasets,
      ),
  );

  return selectFocusAreasByPermission;
};

/**
 * Get focus areas (licensed areas, optionally also datasets) for which specified expectations regarding the available data license are fulfilled
 *
 * @param dataLicensePredicate - Callback to get the data detail of a licensed area.
 * @param allowedOnDatasets - If true, will return also datasets, if false, will return only licensed areas.
 *
 * @example
 * // Create an instance of the selector
 * export const selectFocusAreasByCorridorData = makeSelectFocusAreasWithExpectedDataLicense((dd) => dd.corridorDetail, false);
 * // Use the selector
 * const aoiAreas = useAppSelector(selectFocusAreasByCorridorData)
 */
export const makeSelectFocusAreasWithExpectedDataLicense = (
  dataLicensePredicate: DataLicensePredicate,
  allowedOnDatasets: boolean = true,
) => {
  const selectFocusAreasByDataPermission = createSelector(
    [focusAreasAndDatasetsData, permissionsData, selectLicensedAreasPermissionsMap],
    (focusAreas, permissions, licensedAreasPermissionsMap) =>
      filterFocusAreasFilter(
        focusAreas,
        permissions,
        licensedAreasPermissionsMap,
        [],
        [dataLicensePredicate],
        allowedOnDatasets,
      ),
  );

  return selectFocusAreasByDataPermission;
};

const selectLicensedAreasByAllowSelectLinkPermission = makeSelectFocusAreasWithExpectedSoftwareLicense(
  (sp) => sp.selectLink.allowSelectLink,
  false,
);

const selectLicensedAreasByAllowODDetail = makeSelectFocusAreasWithExpectedDataLicense(
  (dd) => Boolean(dd.odDetail),
  false,
);

export const selectLicensedAreasByAllowSelectLink = createSelector(
  [selectLicensedAreasByAllowSelectLinkPermission, selectLicensedAreasByAllowODDetail],
  (areasWithSelectLinkPremissions, areasWithODDetails) => {
    const areasWithODDetailsIds = new Set(areasWithODDetails?.map((area) => area.id));

    return areasWithSelectLinkPremissions?.filter((area) => areasWithODDetailsIds.has(area.id)) || [];
  },
);

export const selectLicensedAreasByAllowVmt = makeSelectFocusAreasWithExpectedDataLicense(
  (dd) => Boolean(dd.roadVmtDetail),
  false,
);

export const selectLicensedAreasByAllowCorridor = makeSelectFocusAreasWithExpectedDataLicense(
  (dd) => Boolean(dd.corridorDetail),
  false,
);

export const selectHasODDetail = createSelector(
  [selectLicensedAreasByAllowODDetail, (state, licensedAreaId) => licensedAreaId],
  (licensedAreas, licensedAreaId) =>
    licensedAreaId &&
    licensedAreas &&
    licensedAreas.some((licensedArea) => licensedArea.licensedAreaId === licensedAreaId),
);

export const selectAreScreenlinesAllowed = createSelector(
  [selectLicensedAreasPermissionsMap, (state, licensedAreaId) => licensedAreaId],
  (permissionsMap, licensedAreaId) => {
    const licensedAreaPermisions = permissionsMap?.get(String(licensedAreaId));
    return licensedAreaPermisions?.softwarePermissions?.screenlines?.allowUseScreenlines?.Allowed ?? false;
  },
);

export const selectAreRoadIntersectionsAllowed = createSelector(
  [selectLicensedAreasPermissionsMap, (state, licensedAreaId) => licensedAreaId],
  (permissionsMap, licensedAreaId) => {
    const licensedAreaPermisions = permissionsMap?.get(String(licensedAreaId));
    return Boolean(
      licensedAreaPermisions?.softwarePermissions?.roadIntersections?.allowRoadIntersections?.Allowed ?? false,
    );
  },
);

// Pedestrians measure

export const selectLicensedAreasByAllowPedestriansMeasure = makeSelectFocusAreasWithExpectedDataLicense(
  (dd) => Boolean(dd.roadDetail.measures.pedestrians),
  false,
);

export const selectAtLeastOnePedestriansMeasure = createSelector(
  [selectLicensedAreasByAllowPedestriansMeasure],
  //  This is a temporary solution with hard coded timeperiod
  (licensedAreas) => licensedAreas?.find((area) => area.timePeriods.includes("2023")) !== undefined,
);

// Temporary selector to check if the selected licensed area has the pedestrians measure and data, and if not select the first available
export const selectPedestriansLicensedAreaId = createSelector(
  [selectLicensedAreasByAllowPedestriansMeasure, (state) => state.global.selectedFocusAreaId],
  (licensedAreas, selectedFocusAreaId) => {
    const licensedArea =
      licensedAreas?.find((area) => area.id === selectedFocusAreaId && area.timePeriods.includes("2023")) ||
      licensedAreas?.find((area) => area.timePeriods.includes("2023"));

    return licensedArea?.id;
  },
);
