import { useEffect, useMemo } from "react";
import { useLocation, useMatch, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { selectLicensedAreasByAllowCorridor, selectLicensedAreasByAllowVmt } from "store/permissionsSelectors";
import { analyticsActions, selectAoiSelectorOptions } from "store/sections/analytics";
import { filtersActions } from "store/sections/filters";
import { globalActions, selectSelectedAreaId } from "store/sections/global";

import { AoiSelectorOption, MapVisualizationType, ROUTES } from "types";

export const useMapSearchParams = () => {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const datasetEditorRouteMatch = useMatch(ROUTES.DatasetEditor);
  const selectLinkRouteMatch = useMatch(ROUTES.SelectLinkEditor);

  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const assignmentMetadata = useAppSelector((state) => state.analytics.assignmentScenarioMetadata);
  const demandMetadata = useAppSelector((state) => state.analytics.demandScenarioMetadata);

  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const mode = useAppSelector((state) => state.analytics.mapVisualizationMode);
  const measure = useAppSelector((state) => state.filters.measure);

  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const studyAreas = useAppSelector((state) => state.analytics.studyAreas);

  const selectedScenarioId = useAppSelector((state) => state.global.selectedScenarioId);

  const selectedAreaId = useAppSelector(selectSelectedAreaId);
  const areas = useAppSelector((state) => selectAoiSelectorOptions(state, true));

  const selectedArea = useMemo(() => areas.find((area) => area.id === selectedAreaId), [areas, selectedAreaId]);

  const vmtLicenseAreas = useAppSelector(selectLicensedAreasByAllowVmt);
  const corridorLicenseAreas = useAppSelector(selectLicensedAreasByAllowCorridor);

  const isDataset = Boolean(selectedArea?.datasetId);

  useEffect(() => {
    if (focusAreas.state === DataState.AVAILABLE && studyAreas.state === DataState.AVAILABLE) {
      const searchParamFocusAreaId = searchParams.get("id");
      const searchParamStudyAreaId = searchParams.get("studyAreaId");
      const searchParamTimePeriod = searchParams.get("timePeriod");
      const searchParamMode = searchParams.get("mode") as MapVisualizationType;
      const searchParamMeasure = searchParams.get("measure");
      const searchParamScenarioId = searchParams.get("scenarioId");

      // Sets the selected focus area id in the state based on the access list and the id in the url
      const setSelectedFocusAreaIdByAccessList = (possibleAreas: AoiSelectorOption[]) => {
        if (!selectedAreaId) {
          const areaParamId = searchParamStudyAreaId || searchParamFocusAreaId;
          //Searches the area corresponding to the id in the url (study area id or focus area id). If not found, selects the first area in the list.
          const targetFocusArea = possibleAreas.find((area) => area.id === areaParamId) ?? possibleAreas[0];

          // If the target focus area is a study area, sets the selected study area id in the state. Otherwise, sets the selected focus area id in the state.
          if (targetFocusArea) {
            if (targetFocusArea.isStudyArea) {
              dispatch(globalActions.setSelectedStudyAreaId(targetFocusArea.id));
              return;
            }

            const targetTimePeriod =
              searchParamTimePeriod &&
              targetFocusArea.timePeriods &&
              targetFocusArea.timePeriods.indexOf(searchParamTimePeriod) > 0
                ? searchParamTimePeriod
                : targetFocusArea.timePeriods?.[0];

            dispatch(
              globalActions.setSelectedFocusAreaId({
                focusAreaId: targetFocusArea.id,
                timePeriod: targetTimePeriod,
              }),
            );
          }
        }
      };

      const setMode = () => {
        if (mode === null) {
          if (
            !selectedArea?.isStudyArea &&
            (searchParamMode === MapVisualizationType.DEMAND || searchParamMode === MapVisualizationType.ASSIGNMENT)
          ) {
            dispatch(analyticsActions.setMapVisualizationMode(MapVisualizationType.OD));
          } else {
            dispatch(analyticsActions.setMapVisualizationMode(searchParamMode ?? MapVisualizationType.OD));
          }
        }
      };

      const setMeasure = () => {
        if (measure === null) {
          if (mode === MapVisualizationType.OD && ODMetadata.state === DataState.AVAILABLE) {
            dispatch(filtersActions.setMeasure(searchParamMeasure || ODMetadata.data?.measures[0].columnName));
          }
          if (mode === MapVisualizationType.ROADS && roadsMetadata.state === DataState.AVAILABLE) {
            dispatch(filtersActions.setMeasure(searchParamMeasure || roadsMetadata.data?.measures[0].columnName));
          }
          if (mode === MapVisualizationType.ASSIGNMENT && assignmentMetadata.state === DataState.AVAILABLE) {
            dispatch(filtersActions.setMeasure(searchParamMeasure || assignmentMetadata.data?.measures[0].columnName));
          }
          if (mode === MapVisualizationType.DEMAND && demandMetadata.state === DataState.AVAILABLE) {
            dispatch(filtersActions.setMeasure(searchParamMeasure || demandMetadata.data?.measures[0].columnName));
          }
        }
      };

      const setScenarioId = () => {
        if (!selectedScenarioId && selectedArea?.scenarios?.length) {
          const scenarioId = searchParamScenarioId || selectedArea?.scenarios?.[0]?.scenarioId;
          if (scenarioId) dispatch(globalActions.setSelectedScenarioId(scenarioId));
        }
      };

      const setFocusAreaIdParam = (selectedFocusAreaId: string | null, isStudyArea?: boolean) => {
        if (isStudyArea && selectedFocusAreaId && searchParamStudyAreaId !== selectedFocusAreaId) {
          searchParams.set("studyAreaId", selectedFocusAreaId);
          searchParams.delete("id");
          return;
        }
        if (selectedFocusAreaId && searchParamFocusAreaId !== selectedFocusAreaId && !isStudyArea) {
          searchParams.set("id", selectedFocusAreaId);
          searchParams.delete("studyAreaId");
        }
      };

      const setTimePeriodParam = (isStudyArea?: boolean) => {
        if (timePeriod && searchParamTimePeriod !== timePeriod && !isStudyArea) {
          searchParams.set("timePeriod", timePeriod);
        } else if (isStudyArea) {
          searchParams.delete("timePeriod");
        }
      };

      const setModeParam = () => {
        if (mode !== null && searchParamMode !== mode) {
          searchParams.set("mode", mode);
        }
      };

      const setSearchParamMeasure = () => {
        if (measure && searchParamMeasure !== measure) {
          searchParams.set("measure", measure);
        }
      };

      const setSearchParamScenarioId = (isStudyArea?: boolean) => {
        if (!selectedArea) return;

        if (!isStudyArea) {
          searchParams.delete("scenarioId");
          return;
        }

        if (selectedScenarioId && searchParamScenarioId !== selectedScenarioId && isStudyArea) {
          searchParams.set("scenarioId", selectedScenarioId);
        }
      };

      if (datasetEditorRouteMatch || selectLinkRouteMatch) {
        setSearchParams(undefined);
        return;
      }

      if (pathname === ROUTES.Map) {
        setSelectedFocusAreaIdByAccessList(areas);
        setMode();
        setMeasure();
        setScenarioId();
        setFocusAreaIdParam(selectedAreaId, selectedArea?.isStudyArea);
        setTimePeriodParam(selectedArea?.isStudyArea);
        setModeParam();
        setSearchParamMeasure();
        setSearchParamScenarioId(selectedArea?.isStudyArea);
        setSearchParams(searchParams, { replace: true });
        return;
      }

      if (pathname === ROUTES.CorridorDiscovery) {
        if (corridorLicenseAreas) {
          setSelectedFocusAreaIdByAccessList(corridorLicenseAreas);
          setMode();
          setFocusAreaIdParam(selectedAreaId);
          setTimePeriodParam();
          setSearchParams(searchParams, { replace: true });
        }
        return;
      }

      if (pathname === ROUTES.RoadVmt) {
        if (vmtLicenseAreas) {
          setSelectedFocusAreaIdByAccessList(vmtLicenseAreas);
          setMode();
          setFocusAreaIdParam(selectedAreaId);
          setTimePeriodParam();
          setSearchParams(searchParams, { replace: true });
        }
        return;
      }
      setSelectedFocusAreaIdByAccessList(areas);
      setMode();

      if (searchParams.get("id") !== null) {
        setSearchParams(undefined);
      }
    }
  }, [
    ODMetadata,
    roadsMetadata,
    assignmentMetadata,
    demandMetadata,
    selectedAreaId,
    selectedArea,
    selectedScenarioId,
    isDataset,
    timePeriod,
    vmtLicenseAreas,
    corridorLicenseAreas,
    mode,
    measure,
    searchParams,
    pathname,
    areas,
    focusAreas.state,
    studyAreas.state,
    datasetEditorRouteMatch,
    selectLinkRouteMatch,
    setSearchParams,
    dispatch,
  ]);
};
