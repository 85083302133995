import MapboxDraw from "@mapbox/mapbox-gl-draw";

const Constants = MapboxDraw.constants;
const SimpleSelect = MapboxDraw.modes.simple_select;
const CustomSimpleSelect = { ...SimpleSelect };

CustomSimpleSelect.clickOnFeature = function (state, e) {
  if (e.featureTarget.geometry.type !== Constants.geojsonTypes.POINT) {
    // switch to direct_select mode for polygon/line features
    this.changeMode(Constants.modes.DIRECT_SELECT, {
      featureId: e.featureTarget.properties.id,
    });
  } else {
    // call parent
    SimpleSelect.clickOnFeature.call(this, state, e);

    // prevent multi-selection for consistency with direct_select mode
    this.setSelected(e.featureTarget.properties.id);
  }
};

CustomSimpleSelect.startOnActiveFeature = function (state, e) {
  // Stop any already-underway extended interactions
  this.stopExtendedInteractions(state);

  // Re-render it and enable drag move
  this.doRender(e.featureTarget.properties.id);

  // Set up the state for drag moving
  state.canDragMove = true;
  state.dragMoveLocation = e.lngLat;
};

CustomSimpleSelect.onDrag = function (state, e) {
  return;
};

export default CustomSimpleSelect;
