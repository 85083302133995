import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { DemandSelectedArea } from "api/analytics/publications";
import { isEqual } from "lodash";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";

import { ModuleData } from "features/map/ModuleManager";

import { useAppSelector, usePrevious, useStateRef } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions, selectActiveDemandScenario, selectActiveStudyArea } from "store/sections/analytics";
import { filtersActions } from "store/sections/filters";

import { Counts, FlowsSettings, MeasureType } from "types";

import { useHandleSetColorScale, useHideTopFlows, useShowTopFlows } from "../../od/ControllerCallbacks";
import {
  useChangeShowZoneCounts,
  useChangeZonesFillOpacity,
  useCloseODAnalyticsPanel,
  useFilterZonesByRange,
  useGetDemandScenarioZoneIds,
  useGetSelectedZoneDetails,
  useGetZoneCounts,
  useHandleSelectZone,
} from "./DemandControllerCallbacks";
import { getODLayers } from "./map-data/layers";
import { getDemandSources } from "./map-data/sources";

interface MapControllerProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  isModuleLoaded: boolean;
  flowsSettings: FlowsSettings;
  closeODAnalyticsPanelRef: React.MutableRefObject<(selectedZone: DemandSelectedArea | null) => void>;
  updateODModeCounts: React.MutableRefObject<(() => void) | null>;
  setDemandModuleData: Dispatch<SetStateAction<ModuleData | null>>;
}

export const MapController = ({
  map,
  layerManagerRef,
  isModuleLoaded,
  flowsSettings,
  closeODAnalyticsPanelRef,
  updateODModeCounts,
  setDemandModuleData,
}: MapControllerProps) => {
  const dispatch = useDispatch();

  const currentDemandFilters = useAppSelector((state) => state.filters.demandFilters);
  const queryType = useAppSelector((state) => state.filters.queryType);
  const measure = useAppSelector((state) => state.filters.measure);

  const selectedStudyArea = useAppSelector(selectActiveStudyArea);
  const selectedScenario = useAppSelector(selectActiveDemandScenario);
  const demandScenarioMetadata = useAppSelector((state) => state.analytics.demandScenarioMetadata);
  const demandScenarioZoneIds = useAppSelector((state) => state.analytics.demandScenarioZoneIds);
  const demandScenarioZoneCounts = useAppSelector((state) => state.analytics.demandScenarioZoneCounts);
  const selectedZone = useAppSelector((state) => state.analytics.selectedZone) as DemandSelectedArea | null;
  const zoneDetails = useAppSelector((state) => state.analytics.zoneDetails);
  const showZoneCounts = useAppSelector((state) => state.map.showZoneCounts);
  const colorScheme = useAppSelector((state) => state.map.colorScheme);

  const odOpacityFactor = useAppSelector((state) => state.analytics.ODOpacityFactor);
  const odOpacityFactorRef = useStateRef(odOpacityFactor);
  const showZoneCountsRef = useRef<boolean>(showZoneCounts);
  const colorSchemeRef = useStateRef(colorScheme);
  const queryTypeRef = useStateRef(queryType);

  const previousFlowSettings = usePrevious(flowsSettings);

  const getDemandScenarioZoneIds = useGetDemandScenarioZoneIds(selectedScenario?.scenarioId);
  const getZoneCounts = useGetZoneCounts(measure, queryType, selectedScenario?.scenarioId);
  const handleSetColorScale = useHandleSetColorScale();
  const handleSelectZone = useHandleSelectZone();
  const changeShowZoneCounts = useChangeShowZoneCounts(layerManagerRef);
  const changeZonesFillOpacity = useChangeZonesFillOpacity(map, layerManagerRef);
  const getODZoneDetails = useGetSelectedZoneDetails(
    queryType,
    demandScenarioMetadata.data,
    selectedScenario?.scenarioId,
  );
  const closeODAnalyticsPanel = useCloseODAnalyticsPanel(map, handleSelectZone);
  const showTopFlows = useShowTopFlows(layerManagerRef);
  const hideTopFlows = useHideTopFlows(layerManagerRef);
  const filterZonesByRange = useFilterZonesByRange(map, layerManagerRef);

  const isAllDemandDataAvailable = useMemo(
    () =>
      Boolean(
        demandScenarioMetadata.state === DataState.AVAILABLE &&
          demandScenarioMetadata.data?.zoneTileServiceLayer &&
          demandScenarioZoneIds.state === DataState.AVAILABLE,
      ),
    [demandScenarioMetadata.state, demandScenarioMetadata.data?.zoneTileServiceLayer, demandScenarioZoneIds.state],
  );

  // Refs
  const ODCountsRef = useRef<Counts>({ zones: new Map(), gates: new Map() });
  const ODSelectedZoneRef = useRef<DemandSelectedArea | null>(selectedZone);

  // Switch to first measure if currently selected is not available
  useEffect(() => {
    if (
      measure &&
      demandScenarioMetadata.state === DataState.AVAILABLE &&
      !demandScenarioMetadata.data?.measures.find((m) => m.columnName === measure)
    ) {
      const firstMeasure = demandScenarioMetadata.data?.measures[0].columnName;
      dispatch(filtersActions.setMeasure(firstMeasure as MeasureType));
    }
  }, [measure, demandScenarioMetadata.state, demandScenarioMetadata.data?.measures, dispatch]);

  // Fetch demand scenario zone ids
  useEffect(() => {
    if (demandScenarioZoneIds.state === DataState.EMPTY && demandScenarioMetadata.state === DataState.AVAILABLE) {
      getDemandScenarioZoneIds();
    }
  }, [demandScenarioZoneIds.state, demandScenarioMetadata.state, getDemandScenarioZoneIds]);

  // Fetch demand scenario zone counts
  useEffect(() => {
    if (
      demandScenarioZoneCounts.state !== DataState.LOADING &&
      currentDemandFilters &&
      demandScenarioMetadata.state === DataState.AVAILABLE
    ) {
      if (selectedZone && demandScenarioZoneCounts.state === DataState.EMPTY) {
        ODSelectedZoneRef.current = selectedZone;

        getZoneCounts(currentDemandFilters, Number(selectedZone.id));
      } else if (
        !selectedZone &&
        demandScenarioZoneCounts.state === DataState.EMPTY &&
        // Check if all dimensions are present in the filters, if not then updating will be done in the Filters component
        isEqual(
          Object.keys(currentDemandFilters),
          demandScenarioMetadata.data.measures
            .find((d) => d.columnName === measure)
            ?.dimensions.filter((d) => d.enabled)
            .map((d) => d.columnName),
        )
      ) {
        getZoneCounts(currentDemandFilters);
      }

      if (demandScenarioZoneCounts.state === DataState.AVAILABLE && demandScenarioZoneCounts.data) {
        ODCountsRef.current = {
          zones: new Map(),
          gates: new Map(),
        };

        // If the selected zone is not in the current zone counts, set it to null
        if (!selectedZone && ODSelectedZoneRef.current) {
          ODSelectedZoneRef.current = null;
        }

        if (typeof updateODModeCounts.current === "function") {
          updateODModeCounts.current();
        }
      }
    }
  }, [
    demandScenarioZoneCounts.state,
    demandScenarioMetadata.state,
    demandScenarioMetadata.data?.measures,
    demandScenarioZoneCounts.data,
    currentDemandFilters,
    selectedZone,
    measure,
    updateODModeCounts,
    getZoneCounts,
  ]);

  // Update query showZoneCounts ref
  useEffect(() => {
    showZoneCountsRef.current = showZoneCounts;
  }, [showZoneCounts]);

  // Update OD analytics panel ref function
  useEffect(() => {
    closeODAnalyticsPanelRef.current = closeODAnalyticsPanel;
  }, [closeODAnalyticsPanelRef, closeODAnalyticsPanel]);

  // Fetch metadata for the selected scenario
  useEffect(() => {
    if (
      selectedScenario &&
      selectedScenario.type === "Demand" &&
      selectedStudyArea &&
      demandScenarioMetadata.state === DataState.EMPTY
    ) {
      dispatch(analyticsActions.fetchDemandScenarioMetadata(selectedScenario.scenarioId));
    }
  }, [selectedStudyArea, selectedScenario, demandScenarioMetadata.state, dispatch]);

  // Fetch zone details
  useEffect(() => {
    if (
      selectedScenario &&
      selectedScenario.type === "Demand" &&
      selectedZone &&
      flowsSettings &&
      (zoneDetails.state === DataState.EMPTY || previousFlowSettings !== flowsSettings)
    ) {
      getODZoneDetails(selectedZone.id, measure, flowsSettings, currentDemandFilters);
    }
  }, [
    selectedScenario,
    selectedZone,
    flowsSettings,
    zoneDetails.state,
    previousFlowSettings,
    getODZoneDetails,
    measure,
    currentDemandFilters,
  ]);

  // Show/hide top flows
  useEffect(() => {
    if (!(map.current as any)?.style || !(layerManagerRef.current?.getMapInstance() as any)?.style || !isModuleLoaded)
      return;

    if (zoneDetails.state === DataState.AVAILABLE && selectedZone && flowsSettings) {
      hideTopFlows();
      showTopFlows(zoneDetails.data, queryType, selectedZone);
    } else {
      hideTopFlows();
    }
  }, [
    map,
    layerManagerRef,
    zoneDetails,
    selectedZone,
    flowsSettings,
    hideTopFlows,
    showTopFlows,
    queryType,
    isModuleLoaded,
  ]);

  // Set demand module data
  useEffect(() => {
    if (isAllDemandDataAvailable) {
      const tileService = {
        ...demandScenarioMetadata.data!.zoneTileServiceLayer,
        url: "https://patterns-api-dev.openpaths.bentley.com" + demandScenarioMetadata.data!.zoneTileServiceLayer.url,
      };

      const ODSources = getDemandSources(tileService);
      const ODLayers = getODLayers(odOpacityFactorRef, tileService.idField).zoningLayers;

      setDemandModuleData({
        sources: ODSources,
        layers: ODLayers,
        data: {
          tileService,
          ODSelectedZoneRef,
          ODCountsRef,
          showZoneCounts: showZoneCountsRef,
          closeODAnalyticsPanelRef,
          colorSchemeRef,
          queryTypeRef,
          showZoneCountsRef,
          changeShowZoneCounts,
          changeZonesFillOpacity,
          handleSetColorScale,
          handleSelectZone,
          filterZonesByRange,
        },
      });
    }
  }, [
    isAllDemandDataAvailable,
    demandScenarioMetadata.data,
    odOpacityFactorRef,
    closeODAnalyticsPanelRef,
    colorSchemeRef,
    queryTypeRef,
    showZoneCountsRef,
    setDemandModuleData,
    changeShowZoneCounts,
    changeZonesFillOpacity,
    handleSetColorScale,
    handleSelectZone,
    filterZonesByRange,
  ]);

  return null;
};
