import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const MergeDifference: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor">
      <path d="M10,6V2H2v8H6v4h8V6Zm3,7H7V7h6Z" />
    </svg>
  </SvgIcon>
);
