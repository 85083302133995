import { styled } from "@mui/material";
import { ScreenlineDetails } from "api/analytics/index.d";
import { AnalyticsContainer, Badge, CircularProgress, DirectionGroup, MapControlContainer } from "components_new";
import { FC, MutableRefObject, memo, useCallback, useEffect, useMemo, useState } from "react";

import { getLabelByCategories, getLabelByDimension } from "features/analytics/ODAnalytics";

import { BarChart, RightSidebarPanel } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { globalActions } from "store/sections/global";
import { selectSelectedScreenline } from "store/sections/screenlines";

import { RoadNetworkType } from "types";

interface ScreenlinesAnalyticsPanelProps {
  closeScreenlineAnalyticsPanelRef: MutableRefObject<(() => void) | null>;
  setSelectedScreenlineId: ((screenlineId: string | null) => void) | null;
}

const RightSidebarLoaderWrapper = styled("div")`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SegmentIdWrapper = styled("div")`
  font-size: 0.6rem;
  color: var(--color-textSecondary);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const ScreenlinesAnalyticsPanel: FC<ScreenlinesAnalyticsPanelProps> = memo(
  ({ closeScreenlineAnalyticsPanelRef, setSelectedScreenlineId }) => {
    const dispatch = useAppDispatch();
    const [selectedDir, setSelectedDir] = useState<keyof ScreenlineDetails | null>(null);
    const selectedScreenlineId = useAppSelector((state) => state.screenlines.selectedScreenlineId);
    const selectedScreenline = useAppSelector(selectSelectedScreenline);
    const screenlineDetails = useAppSelector((state) => state.screenlines.screenlineDetails);
    const isScreelineEditorOpen = useAppSelector((state) => state.screenlines.isScreelineEditorOpen);
    const collapsedDimensionContainers = useAppSelector((state) => state.global.collapsedDimensionContainers);

    const roadsMetadataDimensions = useAppSelector(
      (state) => state.analytics.roadsMetadata.data?.measures[0].dimensions,
    );

    const breakdowns = (selectedDir && screenlineDetails.data?.[selectedDir]?.breakdowns) || [];

    const isLoading = useMemo(() => {
      return screenlineDetails.state === DataState.LOADING;
    }, [screenlineDetails]);

    const isOpen = useMemo(
      () => Boolean(selectedScreenlineId && !isScreelineEditorOpen),
      [selectedScreenlineId, isScreelineEditorOpen],
    );

    const handleClosePanel = useCallback(() => {
      setSelectedScreenlineId?.(null);
    }, [setSelectedScreenlineId]);

    useEffect(() => {
      if (!closeScreenlineAnalyticsPanelRef.current) closeScreenlineAnalyticsPanelRef.current = handleClosePanel;
    }, [closeScreenlineAnalyticsPanelRef, handleClosePanel]);

    useEffect(() => {
      if (screenlineDetails.data) {
        const directionKeys = Object.keys(screenlineDetails.data);
        const lastValidDirection = directionKeys[directionKeys.length - 1] as keyof ScreenlineDetails;
        setSelectedDir(lastValidDirection || null);
      }
    }, [screenlineDetails.data]);

    return (
      <RightSidebarPanel
        title={isLoading ? "Loading..." : selectedScreenline?.name || "No name"}
        subtitle={`Annual average daily trips ${
          screenlineDetails.data && selectedDir
            ? screenlineDetails.data[selectedDir]?.filteredTotals.toLocaleString("en-US")
            : "..."
        }`}
        cardType="screenline"
        onClose={handleClosePanel}
        isOpen={isOpen}
      >
        {!isLoading && roadsMetadataDimensions && screenlineDetails.data ? (
          <>
            <DirectionGroup>
              <Badge
                color={"primary"}
                onClick={() => setSelectedDir("total")}
                variant={selectedDir === "total" ? "filled" : "outlined"}
                clickable
                label={"Total"}
                disabled={!screenlineDetails.data?.total}
              />
              {selectedScreenline?.network !== RoadNetworkType.Pedestrian && (
                <>
                  <Badge
                    color={"primary"}
                    onClick={() => setSelectedDir("toLeft")}
                    variant={selectedDir === "toLeft" ? "filled" : "outlined"}
                    clickable
                    disabled={!screenlineDetails.data?.toLeft}
                    label={selectedScreenline?.leftLabel}
                  />
                  <Badge
                    color={"primary"}
                    onClick={() => setSelectedDir("toRight")}
                    variant={selectedDir === "toRight" ? "filled" : "outlined"}
                    clickable
                    label={selectedScreenline?.rightLabel}
                    disabled={!screenlineDetails.data?.toRight}
                  />
                </>
              )}
            </DirectionGroup>
            <AnalyticsContainer heightOperand={102}>
              {breakdowns?.map((breakdown, index) => {
                const dimension = breakdown.dimensions[0];
                const data = breakdown.rows.map((row) => ({
                  name: getLabelByCategories(row.categories[0], dimension, roadsMetadataDimensions || []),
                  value: row.value,
                }));

                return data.length > 0 ? (
                  <MapControlContainer
                    key={index}
                    title={getLabelByDimension(dimension, roadsMetadataDimensions)}
                    collapse
                    expanded={!collapsedDimensionContainers.includes(`screenlines_${dimension}`)}
                    onChange={() =>
                      dispatch(globalActions.toggleDimensionContainerCollapsedState(`screenlines_${dimension}`))
                    }
                  >
                    <BarChart key={index} data={data} layout="vertical" />
                  </MapControlContainer>
                ) : null;
              })}
              <SegmentIdWrapper>id: {selectedScreenlineId}</SegmentIdWrapper>
            </AnalyticsContainer>
          </>
        ) : null}
        {isLoading && (
          <RightSidebarLoaderWrapper>
            <CircularProgress />
          </RightSidebarLoaderWrapper>
        )}
      </RightSidebarPanel>
    );
  },
);
