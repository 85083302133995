import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { Dispatch, MutableRefObject, SetStateAction, useEffect } from "react";

import { ModuleData } from "features/map/ModuleManager";

import { MapController } from "./MapController";
import { RoadIntersectionsAnalyticsPanel } from "./RoadIntersectionsAnalyticsPanel";

interface RoadIntersectionsModuleComponentProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  measure: string | null;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  setRoadIntersectionsModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  updateVolumesRef: MutableRefObject<(() => void) | null>;
  closeRoadIntersectionsAnalyticsPanelRef: MutableRefObject<(() => void) | null>;
  clearSelectedIntersectionsRef: MutableRefObject<(() => void) | null>;
}

const RoadIntersectionsModuleComponent = ({
  map,
  measure,
  layerManagerRef,
  setRoadIntersectionsModuleData,
  updateVolumesRef,
  closeRoadIntersectionsAnalyticsPanelRef,
  clearSelectedIntersectionsRef,
}: RoadIntersectionsModuleComponentProps) => {
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      closeRoadIntersectionsAnalyticsPanelRef?.current?.();
      setRoadIntersectionsModuleData(null);
    };
  }, [setRoadIntersectionsModuleData, closeRoadIntersectionsAnalyticsPanelRef]);

  return (
    <>
      <MapController
        map={map}
        measure={measure}
        layerManagerRef={layerManagerRef}
        setRoadIntersectionsModuleData={setRoadIntersectionsModuleData}
        updateVolumesRef={updateVolumesRef}
        closeRoadIntersectionsAnalyticsPanelRef={closeRoadIntersectionsAnalyticsPanelRef}
        clearSelectedIntersectionsRef={clearSelectedIntersectionsRef}
      />

      <RoadIntersectionsAnalyticsPanel
        closeRoadIntersectionsAnalyticsPanelRef={closeRoadIntersectionsAnalyticsPanelRef}
      />
    </>
  );
};

export default RoadIntersectionsModuleComponent;
