import {
  DrawCreateEvent,
  DrawModeChangeEvent,
  DrawSelectionChangeEvent,
  DrawUpdateEvent,
} from "@mapbox/mapbox-gl-draw";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import difference from "@turf/difference";
import union from "@turf/union";
import { DeleteVertexPopupContentProps } from "components_new";
import { MultiPolygon, Point } from "geojson";
import { Feature, GeoJsonProperties, Polygon } from "geojson";
import { isEqual } from "lodash";
import { Map, Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, SetStateAction } from "react";

import { DatasetEditorMode } from "types";

export const getDatasetEditorHandlers = (
  map: Map,
  draw: MapboxDraw,
  vertexPopupRef: MutableRefObject<HTMLDivElement | null>,
  setVertexPopupPropsRef: MutableRefObject<Dispatch<SetStateAction<DeleteVertexPopupContentProps | null>> | null>,
  mapboxVertexPopupRef: MutableRefObject<Popup | null>,
  selectedZoningRef: MutableRefObject<string | undefined>,
  editorDrawModeRef: MutableRefObject<DatasetEditorMode>,
  setEditorDrawMode: Dispatch<SetStateAction<DatasetEditorMode>>,
  setSubAreaPolygon: (subareaPolygon: Polygon | MultiPolygon | null) => void,
) => {
  let clickedPoint: Feature<Point, GeoJsonProperties> | null = null;
  let clickedFeature: Feature<Polygon | MultiPolygon, GeoJsonProperties> | null = null;

  const handleSelectionChange = (e: DrawSelectionChangeEvent) => {
    const point = e.points[0] as Feature<Point, GeoJsonProperties>;
    const feature = e.features[0] as Feature<Polygon | MultiPolygon, GeoJsonProperties>;

    if (mapboxVertexPopupRef.current) {
      mapboxVertexPopupRef.current.remove();
    }
    clickedPoint = point;
    clickedFeature = feature;
  };

  const handleSubareaChange = (e: DrawCreateEvent | DrawUpdateEvent) => {
    const feature = e.features?.[0] as Feature<Polygon | MultiPolygon>;

    if (feature && (feature.geometry.type === "Polygon" || feature.geometry.type === "MultiPolygon")) {
      let allFeatures = draw.getAll().features as Feature<Polygon | MultiPolygon, GeoJsonProperties>[];

      if (allFeatures.length === 0) {
        setSubAreaPolygon(null);

        return;
      }

      if (editorDrawModeRef.current === DatasetEditorMode.MergeDifference) {
        allFeatures = allFeatures
          .filter((f) => f.id !== feature.id)
          .map((f) => difference(f, feature) as Feature<Polygon | MultiPolygon, GeoJsonProperties>)
          .filter((f) => f !== null);
      }

      if (allFeatures.length === 0) {
        setSubAreaPolygon(null);
        setEditorDrawMode(DatasetEditorMode.SimpleSelect);

        return;
      }

      let combined = allFeatures[0];
      for (let i = 1; i < allFeatures.length; i++) {
        combined = union(combined, allFeatures[i]) as Feature<Polygon | MultiPolygon>;
      }

      if (
        combined &&
        (combined.geometry.type === "Polygon" || combined.geometry.type === "MultiPolygon") &&
        selectedZoningRef.current
      ) {
        setSubAreaPolygon(combined.geometry);
        setEditorDrawMode(DatasetEditorMode.SimpleSelect);

        draw.set({
          type: "FeatureCollection",
          features: [combined],
        });
      }
    }
  };

  const handleModeChange = (e: DrawModeChangeEvent) => {
    map.dragPan.enable();
    setEditorDrawMode(e.mode as any);
  };

  const handleDoubleClick = () => {
    if (clickedPoint && clickedFeature) {
      const geometry = clickedPoint.geometry;
      const coordinates = geometry.coordinates;
      const featureCoords = clickedFeature.geometry.coordinates;
      const firstPointCoords = featureCoords[0];
      const lastPointCoords = featureCoords[featureCoords.length - 1];

      if (isEqual(coordinates, firstPointCoords) || isEqual(coordinates, lastPointCoords)) return;

      mapboxVertexPopupRef.current = new Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 15,
      })
        .setLngLat(coordinates as any)
        .setDOMContent(vertexPopupRef.current as Node)
        .addTo(map);

      setVertexPopupPropsRef.current?.({
        draw,
        onClose: () => mapboxVertexPopupRef.current?.remove(),
      });
    }
  };

  map.on("draw.create", handleSubareaChange);
  map.on("draw.update", handleSubareaChange);
  map.on("draw.modechange", handleModeChange);
  map.on("draw.selectionchange", handleSelectionChange);
  map.on("dblclick", handleDoubleClick);
};
