import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import memoryStore, { MemoryStoreKeys } from "api/memoryStore";
import { find, isEqual } from "lodash";
import { Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, RefObject, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { ModuleData } from "features/map/ModuleManager";

import { useAppSelector, useStateRef } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions, selectActiveScenario, selectActiveStudyArea } from "store/sections/analytics";
import { filtersActions } from "store/sections/filters";

import { ExtendedDirectionalRoadsTileService, MeasureType, SelectedVolume, Volume } from "types";

import {
  useCloseRoadsAnalyticsPanel,
  useHighlightSelectedSegment,
  useSelectRoadVolume,
  useSelectRoadVolumeId,
  useUpdateFeatureStateForRoads,
} from "../../roads/RoadsControllerCallbacks";
import { getRoadsSources } from "../../roads/map-data/sources";
import {
  useChangeShowRoadVolumes,
  useChangeVolumesOpacity,
  useChangeVolumesWidth,
  useFilterLinksByRange,
  useFilterLinksByRoadClasses,
  useGetAssignmentLinkIds,
  useGetAssignmentLinkValues,
  useGetLinkDetails,
  useGetVisibilityStatus,
} from "./AssignmentControllerCallbacks";
import { getLinksLayers } from "./map-data/layers";

interface MapControllerProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  closeRoadsAnalyticsPanelRef: RefObject<() => void>;
  updateRoadsModeCounts: MutableRefObject<(() => void) | null>;
  updateRoadsPopupCounts: MutableRefObject<
    ((selectedVolume: SelectedVolume | null, volumesProps?: any[]) => void) | null
  >;
  setAssignmentModuleData: Dispatch<SetStateAction<ModuleData | null>>;
}

export const MapController = ({
  map,
  layerManagerRef,
  updateRoadsModeCounts,
  closeRoadsAnalyticsPanelRef,
  updateRoadsPopupCounts,
  setAssignmentModuleData,
}: MapControllerProps) => {
  const dispatch = useDispatch();

  const [volumeProps, setVolumeProps] = useState<Volume[]>([]);
  const [layerName, setLayerName] = useState<string>("");

  const selectedStudyArea = useAppSelector(selectActiveStudyArea);
  const measure = useAppSelector((state) => state.filters.measure);
  const selectedScenario = useAppSelector(selectActiveScenario);
  const assignmentScenarioMetadata = useAppSelector((state) => state.analytics.assignmentScenarioMetadata);
  const assignmentLinksIds = useAppSelector((state) => state.analytics.assignmentLinkIds);
  const assignmentLinkValues = useAppSelector((state) => state.analytics.assignmentLinkValues);
  const selectedRoadVolume = useAppSelector((state) => state.analytics.selectedRoadVolume);
  const currentAssignmentFilters = useAppSelector((state) => state.filters.assignmentFilters);
  const assignmentRange = useAppSelector((state) => state.filters.assignmentRange);
  const selectedRoadClasses = useAppSelector((state) => state.filters.roadClasses);
  const roadSegmentsDetails = useAppSelector((state) => state.analytics.roadSegmentsDetails);

  const roadsOpacityFactor = useAppSelector((state) => state.analytics.roadsOpacityFactor);
  const roadsOpacityFactorRef = useStateRef(roadsOpacityFactor);
  const roadsWidthFactor = useAppSelector((state) => state.analytics.roadsWidthFactor);
  const roadsWidthFactorRef = useStateRef(roadsWidthFactor);

  const showRoadVolumes = useAppSelector((state) => state.map.showRoadVolumes);

  const getAssignmentLinkIds = useGetAssignmentLinkIds(selectedScenario?.scenarioId);
  const getAssignmentLinkValues = useGetAssignmentLinkValues(selectedScenario?.scenarioId);
  const getLinkDetails = useGetLinkDetails(assignmentScenarioMetadata.data, selectedScenario?.scenarioId);

  const maxSegmentVolumeRef = useRef<number>(1);
  const mapboxVolumesPopupRef = useRef<Popup>(null);
  const showRoadVolumesRef = useRef<boolean>(showRoadVolumes);

  const selectRoadVolumeId = useSelectRoadVolumeId();
  const updateFeatureStateForRoads = useUpdateFeatureStateForRoads(map, layerName);
  const selectRoadVolume = useSelectRoadVolume(updateFeatureStateForRoads, false);
  const getVisibilityStatus = useGetVisibilityStatus(showRoadVolumesRef);
  const closeRoadsAnalyticsPanel = useCloseRoadsAnalyticsPanel(
    map,
    selectedRoadVolume,
    mapboxVolumesPopupRef,
    selectRoadVolume,
    selectRoadVolumeId,
    updateFeatureStateForRoads,
    true, // isAssignmentScenario
  );
  const filterLinksByRoadClasses = useFilterLinksByRoadClasses(
    map,
    layerManagerRef,
    assignmentScenarioMetadata.data?.linkTileService,
  );
  const filterRoadSegmentsByRange = useFilterLinksByRange(
    map,
    layerManagerRef,
    assignmentScenarioMetadata.data?.linkTileService,
  );
  const changeVolumesOpacity = useChangeVolumesOpacity(map, layerManagerRef);
  const changeVolumesWidth = useChangeVolumesWidth(map, layerManagerRef);
  const changeShowRoadVolumes = useChangeShowRoadVolumes(layerManagerRef, updateRoadsModeCounts);
  const highlightSelectedSegment = useHighlightSelectedSegment(map, updateFeatureStateForRoads);

  const tileService = useMemo(() => {
    return {
      ...assignmentScenarioMetadata.data?.linkTileService,
      url: "https://patterns-api-dev.openpaths.bentley.com" + assignmentScenarioMetadata.data?.linkTileService?.url,
    } as ExtendedDirectionalRoadsTileService;
  }, [assignmentScenarioMetadata.data?.linkTileService]);

  const isAllAssignmentDataAvailable = useMemo(() => {
    return (
      selectedScenario &&
      selectedScenario.type === "Assignment" &&
      tileService &&
      assignmentScenarioMetadata.state === DataState.AVAILABLE &&
      assignmentLinksIds.state === DataState.AVAILABLE &&
      assignmentLinkValues.state === DataState.AVAILABLE &&
      assignmentLinkValues.data?.size > 0
    );
  }, [
    selectedScenario,
    assignmentScenarioMetadata.state,
    assignmentLinksIds.state,
    assignmentLinkValues.state,
    assignmentLinkValues.data?.size,
    tileService,
  ]);

  // Switch to first measure if currently selected is not available
  useEffect(() => {
    if (
      measure &&
      assignmentScenarioMetadata.state === DataState.AVAILABLE &&
      !assignmentScenarioMetadata.data?.measures.find((m) => m.columnName === measure)
    ) {
      const firstMeasure = assignmentScenarioMetadata.data.measures[0].columnName;
      dispatch(filtersActions.setMeasure(firstMeasure as MeasureType));
    }
  }, [assignmentScenarioMetadata.state, assignmentScenarioMetadata.data?.measures, measure, dispatch]);

  // Fetch metadata for the selected scenario
  useEffect(() => {
    if (
      selectedScenario &&
      selectedScenario.type === "Assignment" &&
      selectedStudyArea &&
      assignmentScenarioMetadata.state === DataState.EMPTY
    ) {
      dispatch(analyticsActions.fetchAssignmentScenarioMetadata(selectedScenario.scenarioId));
    }
  }, [selectedStudyArea, selectedScenario, assignmentScenarioMetadata.state, dispatch]);

  // Fetch link ids for the selected scenario
  useEffect(() => {
    if (
      selectedScenario?.type === "Assignment" &&
      assignmentLinksIds.state === DataState.EMPTY &&
      assignmentScenarioMetadata.state === DataState.AVAILABLE
    ) {
      getAssignmentLinkIds();
    }
  }, [
    selectedScenario?.type,
    assignmentLinksIds.state,
    assignmentScenarioMetadata.state,
    dispatch,
    getAssignmentLinkIds,
  ]);

  // Fetch link values for the selected scenario
  useEffect(() => {
    if (
      selectedScenario?.type === "Assignment" &&
      assignmentLinkValues.state !== DataState.LOADING &&
      assignmentScenarioMetadata.state === DataState.AVAILABLE &&
      assignmentLinksIds.state === DataState.AVAILABLE &&
      currentAssignmentFilters
    ) {
      if (
        assignmentLinkValues.state === DataState.EMPTY &&
        // Check if all dimensions are present in the filters, if not then updating will be done in the Filters component
        isEqual(
          Object.keys(currentAssignmentFilters),
          assignmentScenarioMetadata.data?.measures
            .find((m) => m.columnName === measure)
            ?.dimensions.filter((d) => d.enabled)
            .map((d) => d.columnName),
        )
      ) {
        getAssignmentLinkValues(measure, currentAssignmentFilters);
      } else if (selectedScenario?.type === "Assignment" && assignmentLinkValues.state === DataState.AVAILABLE) {
        maxSegmentVolumeRef.current = assignmentLinkValues.data.max || 1;
      }
    }
  }, [
    measure,
    assignmentLinksIds.state,
    assignmentScenarioMetadata.data?.measures,
    assignmentScenarioMetadata.state,
    assignmentLinkValues,
    currentAssignmentFilters,
    selectedScenario?.type,
    maxSegmentVolumeRef,
    getAssignmentLinkValues,
    dispatch,
  ]);

  // Close roads analytics panel on measure change
  useEffect(() => {
    if (measure) {
      closeRoadsAnalyticsPanelRef.current?.();
    }
  }, [measure, closeRoadsAnalyticsPanelRef]);

  // Fetch segment details for selected road volume
  useEffect(() => {
    if (
      selectedRoadVolume &&
      isAllAssignmentDataAvailable &&
      roadSegmentsDetails.state !== DataState.LOADING &&
      roadSegmentsDetails.state !== DataState.ERROR
    ) {
      if (
        roadSegmentsDetails.data &&
        find(roadSegmentsDetails.data, {
          segmentIndex: selectedRoadVolume.ftSegmentIdx,
        })
      ) {
        if (memoryStore.hasItem(MemoryStoreKeys.LINK_VALUES)) {
          const linkValues = memoryStore.getItem(MemoryStoreKeys.LINK_VALUES);

          if (
            volumeProps.some(
              (v) =>
                v.volumeFT !== (linkValues.get(v.fromToIdx) || 0) || v.volumeTF !== (linkValues.get(v.toFromIdx) || 0),
            )
          ) {
            updateRoadsPopupCounts.current?.(
              selectedRoadVolume,
              volumeProps.map((v) => ({
                ...v,
                volumeFT: linkValues.get(v.fromToIdx),
                volumeTF: linkValues.get(v.toFromIdx) || 0,
              })) as Volume[],
            );
          }
        }

        return;
      }

      getLinkDetails(
        selectedRoadVolume?.tfSegmentIdx
          ? [selectedRoadVolume.ftSegmentIdx, selectedRoadVolume.tfSegmentIdx]
          : [selectedRoadVolume.ftSegmentIdx],
        measure,
        currentAssignmentFilters,
      );
      highlightSelectedSegment(selectedRoadVolume);
    }
  }, [
    measure,
    volumeProps,
    selectedRoadVolume,
    currentAssignmentFilters,
    isAllAssignmentDataAvailable,
    roadSegmentsDetails,
    updateRoadsPopupCounts,
    getLinkDetails,
    highlightSelectedSegment,
  ]);

  // Update showRoadVolumes ref
  useEffect(() => {
    showRoadVolumesRef.current = showRoadVolumes;
  }, [showRoadVolumes]);

  // Update Roads analytics panel ref function
  useEffect(() => {
    (closeRoadsAnalyticsPanelRef as MutableRefObject<() => void>).current = closeRoadsAnalyticsPanel;
  }, [closeRoadsAnalyticsPanelRef, closeRoadsAnalyticsPanel]);

  // Close all roads popups on roads range change
  useEffect(() => {
    if (assignmentRange) {
      selectRoadVolume(null);
      map.current?.fire("closeAllRoadsPopups");
    }
  }, [map, assignmentRange, selectRoadVolume]);

  // Set assignment module data
  useEffect(() => {
    if (isAllAssignmentDataAvailable && tileService) {
      const roadsSources = getRoadsSources(tileService);
      const roadsLayers = getLinksLayers(tileService.layerName, roadsOpacityFactorRef, roadsWidthFactorRef);

      setAssignmentModuleData({
        sources: roadsSources,
        layers: roadsLayers,
        data: {
          type: "Assignment",
          tileService: assignmentScenarioMetadata.data?.linkTileService,
          roadsWidthFactorRef,
          maxSegmentVolumeRef,
          valuesSize: assignmentLinkValues.data?.size ?? 0,
          mapboxVolumesPopupRef,
          roadClasses: assignmentScenarioMetadata.data!.roadClasses.map((rc) => rc.id),
          selectedRoadClasses,
          setVolumeProps,
          selectRoadVolume,
          selectRoadVolumeId,
          getVisibilityStatus,
          filterByRoadClasses: filterLinksByRoadClasses,
          filterRoadSegmentsByRange,
          changeVolumesOpacity,
          changeVolumesWidth,
          changeShowRoadVolumes,
        },
      });

      if (assignmentScenarioMetadata.data?.linkTileService) {
        setLayerName(assignmentScenarioMetadata.data?.linkTileService.layerName);
      }
    }
  }, [
    tileService,
    isAllAssignmentDataAvailable,
    assignmentScenarioMetadata.data,
    assignmentLinkValues.data?.size,
    roadsOpacityFactorRef,
    roadsWidthFactorRef,
    maxSegmentVolumeRef,
    selectedRoadClasses,
    setAssignmentModuleData,
    selectRoadVolume,
    selectRoadVolumeId,
    getVisibilityStatus,
    filterLinksByRoadClasses,
    filterRoadSegmentsByRange,
    changeVolumesOpacity,
    changeVolumesWidth,
    changeShowRoadVolumes,
  ]);

  return null;
};
