import React, { FC, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { LoadingPage } from "components";

import { useAppDispatch, useAppSelector, usePageTracking } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions, selectDatasetLicensedArea } from "store/sections/analytics";

import { DatasetEditor } from "../../../features/dataset-editor/DatasetEditor";

export const DatasetEditorPage: FC = () => {
  const dispatch = useAppDispatch();

  // General and Map
  const navigate = useNavigate();
  const { search } = useLocation();
  const { datasetId } = useParams();

  usePageTracking();

  const isTokenLoaded = useAppSelector((state) => state.analytics.authorizationTokenLoaded);

  const newODDatasetConfiguration = useAppSelector((state) => state.analytics.newODDatasetConfig);
  const ODDatasetConfiguration = useAppSelector((state) => state.analytics.ODDatasetConfig);

  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);

  const datasetLicensedArea = useAppSelector(selectDatasetLicensedArea);

  // Fetch dataset configuration
  useEffect(() => {
    if (datasetId && isTokenLoaded) {
      dispatch(analyticsActions.fetchODDatasetConfig(datasetId));
    }
  }, [datasetId, isTokenLoaded, dispatch]);

  // Replace dataset id in url when, on version conflict, a new dataset is created
  useEffect(() => {
    if (newODDatasetConfiguration) {
      navigate(`/datasets/${newODDatasetConfiguration.datasetId}/edit`);
      dispatch(analyticsActions.clearNewODDatasetConfig());
    }
  }, [newODDatasetConfiguration, search, navigate, dispatch]);

  const loaded =
    ODMetadata.state === DataState.AVAILABLE &&
    roadsMetadata.state === DataState.AVAILABLE &&
    ODDatasetConfiguration.state === DataState.AVAILABLE &&
    datasetLicensedArea;

  return loaded ? (
    <DatasetEditor ODDatasetConfiguration={ODDatasetConfiguration.data} datasetLicensedArea={datasetLicensedArea} />
  ) : (
    <LoadingPage />
  );
};
