import { styled } from "@mui/material";
import { IconButton } from "components_new";

export const DrawIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "selected" && prop !== "width",
})<{ height?: number; selected?: boolean }>(({ theme, height = 29, selected }) => ({
  width: "auto",
  minWidth: `${height}px`,
  height: `${height}px`,
  borderRadius: "6px",
  backgroundColor: selected ? theme.palette.secondary.main : "#ffffff",
  border: `1px solid ${theme.palette.divider}`,

  "& span": {
    fontSize: "12px",
    marginRight: theme.spacing(0.5),
    color: selected ? "#ffffff" : theme.palette.secondary.main,
  },

  "&:hover": {
    backgroundColor: selected ? theme.palette.secondary.main : "default",
    borderColor: theme.palette.secondary.main,
  },

  "& svg": {
    height: "20px",
    width: "20px",

    color: selected ? "#ffffff" : theme.palette.secondary.main,
  },

  "&.Mui-disabled svg": {
    color: theme.palette.text.disabled,
  },
}));
